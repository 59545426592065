/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getCookieLoginData } from '../../../util/cookie_helper';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../sub/MobileWrapper';
import MobileCommunityBestArea from './sub/MobileCommunityBestArea';
import MobileCommunityPostList from './sub/MobileCommunityPostList';
import MobileNoticeBox from '../sub/MobileNoticeBox';
import MobileCategoryBox from '../sub/MobileCategoryBox';
import { RootState } from '../../../redux_saga/modules';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../sub/Adsense';
import { API_TYPES } from '../../../apiAxios';

const MobileCommunity = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
	const [currentCategory, setCurrentCategory] = useState(API_TYPES.CATEGORY_TYPE.ALL);
	
	
	useEffect(()=>{
		console.log("Community useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		if( c ) {
			try{ setCurrentCategory( parseInt(c) ); }catch(e){ console.log("e",e);}
		}
		else{
			setCurrentCategory( API_TYPES.CATEGORY_TYPE.ALL );
		}

		reqCategory();
		
		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
	}

	return (
		<MobileWrapper
			tabType={MOBILE_TAB_TYPE.COMMUNITY}
			currentCategory={currentCategory}>
			<MiddleComponent 
				{...props} 
				currentCategory={currentCategory}
				categoryList={communityCategoryList}/>
		</MobileWrapper>
	);
};

export default MobileCommunity;


function MiddleComponent( props:any ) {
	const navigate = useNavigate();

	return(
		<>
			<MobileCommunityBestArea {...props}/>
			<TopAdComponent {...props}/>

			<MobileCommunityPostList {...props}/>

			<MobileCategoryBox {...props}
				onClick={(cateIdx:number)=>{
					const params : PAGE_POST_HOME_PARAMS = {
						category:cateIdx,
						year:(new Date()).getFullYear(),
						page:1
					}
					var url = makeUrl(PAGE_URL.COMMUNITY, params);
					navigate(url);
				}}
				/>
		</>
	)
}



function TopAdComponent( props:any ) {
	const { currentCategory } = props;
	const [youtubeChannel, setYoutubeChannel] = useState<string|null>(null);
	const [bannerUrl, setBannerUrl] = useState<string|null>(null);
	
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	var height = window.outerWidth * 70 / 450;

    useEffect(()=>{

		if( !!communityCategoryList ) {
			var categoryData = communityCategoryList.filter( (i:any) => i.idx == currentCategory );
			if( !!categoryData && categoryData.length > 0 ) {
				const d = categoryData[0];
				if( !!d.channel && !!d.mob_banner ) {
					setYoutubeChannel(d.channel);
					setBannerUrl(d.mob_banner);
				}
				else {
					setYoutubeChannel(null);
					setBannerUrl(null);
				}
			}
		}

        return(()=>{})
    },[communityCategoryList]);

	if( !!bannerUrl ) {
		return(
			<div className='top_banner' style={{height:height}}
				onClick={()=>{
					if( !!youtubeChannel ) {
						window.open(youtubeChannel, "_blank");
					}
				}}>
				<img src={bannerUrl}/>
			</div>
		)
	}

	return(
		<Adsense 
			{...props}
			type={AdType.MOBILE_TOP}
			marginTop={10}
			marginBottom={10}
			pagePosition={AD_PAGE_POSITION.MOBILE_COMMUNITY_TOP}/>	
	)
}