/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { hideIdStr } from '../../../../../util/Global';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../redux_saga/modules';
import { AiFillMinusCircle } from "react-icons/ai";
import { deleteBlockUser } from '../../../../../apiAxios/v1/data_middleware';

function BlockList( props:any ) {
	
	const location = useLocation();
	const dispatch = useDispatch();

	const [itemList, setItemList] = useState<any>(null);

    useEffect(()=>{

		setItemList(null);

		reqList();

        return(()=>{})
    },[location]);
	

    const reqList = () => {

		apiAxios.user.listBlockUser(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
												
				setItemList( resData.list );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const clickedDelete = ( item:any ) => {
        deleteBlockUser( dispatch, item.nickname, item.block_user_idx, ()=>{
            reqList();
        } );
	}

	return(
		<>
			<div className='mobile_block_user__list_area' id="mobile_block_user__list_area">
				<PostComponent {...props} 
					itemList={itemList}
					clickedDelete={clickedDelete}
					/>
			</div>
		</>
	)
}
export default BlockList;

function PostComponent( props:any ) {
	const { itemList, year, clickedDelete } = props;

    useEffect(()=>{

        return(()=>{})
    },[year]);

	

	if( !itemList || itemList.length == 0 ) {
		return(
			<div className='empty'>
				<span>차단한 사용자 없습니다</span>
			</div>
		)
	}

	return(
		<ul>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} index={index} onClick={clickedDelete}/> )
				})
			}
		</ul>
	)
}

function PostItem( props:any ) {
	const {item, onClick} = props;
	
	return(
		<li>
			<div>
				<div className='top_area'>

					<div className='title_body'>
						<span className='title'>{item.nickname}</span>
					</div>
				</div>
				<div className='bottom'>
					<div className='writer'><span>{hideIdStr(item.id)}</span></div>
				</div>
			</div>
			<div className='right' onClick={()=>{if(onClick) onClick(item); }} >
				<AiFillMinusCircle size={25} color='#f00'/>
			</div>
		</li>
	)
}