/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { hideIdStr } from '../../../../util/Global';
import { useLocation } from 'react-router-dom';
import { AiFillMinusCircle } from "react-icons/ai";
import { deleteBlockUser } from '../../../../apiAxios/v1/data_middleware';

function BlockListComponent( props:any ) {	
	const location = useLocation();
	const dispatch = useDispatch();

	const [itemList, setItemList] = useState<any>(null);

    useEffect(()=>{

		setItemList(null);

		reqList();

        return(()=>{})
    },[location]);
	

    const reqList = () => {

		apiAxios.user.listBlockUser(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
												
				setItemList( resData.list );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const clickedDelete = ( item:any ) => {
        deleteBlockUser( dispatch, item.nickname, item.block_user_idx, ()=>{
            reqList();
        } );
	}

	return(
		<>
			<div className='post_home__list_area block_user__list_area' id="block_user__list_area">
				<div className={'category'}>차단한 사용자</div>
				<table>
					<tr className='table_header'>
						<td className='nickname'>별명</td>
						<td className='id'>아이디</td>
						<td className='cancel'>해제</td>
					</tr>
					
					<PostComponent {...props} 
						itemList={itemList}
						clickedDelete={clickedDelete}
						/>
				</table>
			</div>
		</>
	)
}
export default BlockListComponent;


function PostComponent( props:any ) {
	const { itemList, year, clickedDelete } = props;	

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} index={index} onClick={clickedDelete}/> )
				})
			}
			
			{ ( !itemList || itemList.length == 0 ) &&
				<tr className='empty_item'>
					<td colSpan={4}>차단한 사용자가 없습니다</td>
				</tr>
			}
		</>
	)
}

function PostItem( props:any ) {
	const {item, onClick} = props;

	return(
		<tr className='table_item'>
			<td className='nickname'>
				<div>
					<span className='title'>{item.nickname}</span>
				</div>
			</td>
			<td className='id'><div><span>{hideIdStr(item.id)}</span></div></td>
			<td className='cancel' onClick={()=>{if(onClick) onClick(item); }}>
				<AiFillMinusCircle size={25}/>
			</td>
		</tr>
	)
}