import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import apiAxios, { API_TYPES, API_UTILS } from "../../../../apiAxios";
import ButtonDefault from "../../../sub/ButtonDefault";
import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { getCookieLoginData, setCookieLoginData } from "../../../../util/cookie_helper";
import reduxActions from "../../../../redux_saga/modules/moduleActions";
import { chkPW } from "../../../../util/Global";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";

function UserInfo( props:any ) {
    const [isCheckPassword, setIsCheckPassword] = useState(false);

    return(
        <div className="admin_user_info_box">
            { isCheckPassword == false && 
                <CheckPwBox {...props} setIsCheckPassword={setIsCheckPassword}/> 
            }
            { isCheckPassword == true && <>
                <UserBox {...props}/>
                <PasswordBox {...props}/>
            </>}
        </div>
    )
}
export default UserInfo;

function CheckPwBox( props:any ) {
    const {setIsCheckPassword} = props;

    const dispatch = useDispatch();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const [pwStr, setPwStr] = useState("");
    const [requesting, setRequesting] = useState(false);

    useEffect(()=>{
        return(()=>{})
    },[]);

    const clicked = () => {
        if( !pwStr ) { alert("비밀번호를 입력하세요"); return; }

        setRequesting( true );
        apiAxios.admin.authCheckPw(dispatch, pwStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                
                setIsCheckPassword(true);
			}
			else if( error ) {
                alert("비밀번호를 잘못 입력하셨습니다");
			}
            setRequesting( false );
		})
    }

    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        clicked();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
      }
    }

    return(
        <>
            <div className="input_box">
                <BoxRow value={pwStr} setValue={setPwStr} placeholder="비밀번호를 입력하세요" disabled={requesting} type="password" onKeyUp={handleKeyUp} />
            </div>

            <ButtonDefault 
                {...props}
                className="edit_btn"
                value={"확인"}
                onClick={clicked}
                isLoading={requesting}
                />
        </>
    )
}

function UserBox( props:any ) {
    const dispatch = useDispatch();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const [requesting, setRequesting] = useState(false);
    const [nameStr, setNameStr] = useState("");
    const [numberStr, setNumberStr] = useState("");
    const [emailStr, setEmailStr] = useState("");

    useEffect(()=>{

        if( loginData ) {
            setNameStr(loginData.name);
            setNumberStr(loginData.number);
            setEmailStr(loginData.email);
        }

        return(()=>{})
    },[loginData]);

    const reqUpdate = () => {

        var name:string = nameStr;
        var phone_number:string = numberStr;
        var email:string = emailStr;

        if( !name ) {alert("이름을 입력하세요"); return;}

        setRequesting(true);

        apiAxios.admin.authUserInfoUpdate(dispatch, name, phone_number, email, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                
                
		        const cd = getCookieLoginData();
                var newCd = {...cd};
                newCd.name = name;
                newCd.number = phone_number;
                newCd.email = email;

                setCookieLoginData(newCd);
                reduxActions.data.setLoginData(dispatch, newCd);
                alert("수정 성공");
			}
			else if( error ) {
                alert("저장실패");
			}
            setRequesting( false );
		});
    }

    return(
        <>
            <div className="input_box">
                <BoxRow title="아이디"  value={loginData?loginData.id:""} disabled={true}/>
                <BoxRow title="이름"    value={nameStr} setValue={setNameStr} disabled={loginData.admin_type==API_TYPES.ADMIN_TYPE.TOP}/>
                <BoxRow title="전화번호" value={numberStr} setValue={setNumberStr} placeholder="전화번호를 입력하세요"/>
                <BoxRow title="이메일"  value={emailStr} setValue={setEmailStr} placeholder="이메일을 입력하세요"/>
            </div>

            <ButtonDefault 
                {...props}
                className="edit_btn"
                value={"수정"}
                onClick={()=>{
                    reqUpdate();
                }}
                isLoading={requesting}
                />
        </>
    )
}

function PasswordBox( props:any ) {
    const dispatch = useDispatch();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const [requesting, setRequesting] = useState(false);
    const [beforePwStr, setBeforePwStr] = useState("");
    const [newPwStr, setNewPwStr] = useState("");
    const [rePwStr, setRePwStr] = useState("");

    useEffect(()=>{

        return(()=>{})
    },[]);


    const reqUpdate = () => {
        if( !beforePwStr ) {alert("이전 비밀번호를 입력하세요"); return;}
        if( !newPwStr ) {alert("새 비밀번호를 입력하세요"); return;}
        if( !rePwStr ) {alert("새 비밀번호를 다시 입력하세요"); return;}
        if( newPwStr != rePwStr ) {alert("비밀번호가 일치하지 않습니다."); return;}

        var msg = chkPW(newPwStr);
        if( !!msg ) {alert(msg); return;}

        setRequesting(true);

        apiAxios.admin.authPwUpdate(dispatch, beforePwStr, newPwStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                
                alert("수정 성공");

                setBeforePwStr("");
                setNewPwStr("");
                setRePwStr("");
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("기존 비밀번호를 잘못 입력하였습니다");
				}
			}
            setRequesting( false );
		});
    }

    return(
        <>
            <div className="divider"></div>
            <div className="title">비밀번호</div>
            <div className="input_box">
                <BoxRow title="기존 비밀번호" type="password"  value={beforePwStr} setValue={setBeforePwStr} placeholder="기존의 비밀번호를 입력하세요" showRightEye={true}/>
                <BoxRow title="새 비밀번호"   type="password" value={newPwStr} setValue={setNewPwStr} placeholder="새로운 비밀번호를 입력하세요" showRightEye={true}/>
                <BoxRow title="다시입력"      type="password"  value={rePwStr} setValue={setRePwStr} placeholder="비밀번호를 다시 입력하세요" showRightEye={true}/>
            </div>

            <ButtonDefault 
                {...props}
                className="edit_btn"
                value={"비밀번호 변경"}
                onClick={()=>{
                    reqUpdate();
                }}
                isLoading={requesting}
                />
        </>
    )
}

function BoxRow( props:any ) {
    const {title, type, disabled, value, setValue, placeholder, onKeyUp, showRightEye} = props;
    const [showInput, setShowInput] = useState(false);

    var inputType = !!type?showRightEye!=true||!showInput?type:"":"";

    return(
        <div>
            { !!title && <>
                <div className="left">{title}</div>
                <div className={"right" + (disabled?" disable":"")}>
                    <input 
                        type={inputType}
                        value={value} 
                        placeholder={!!placeholder?placeholder:""}
                        onChange={(e)=> { 
                            if(setValue) {
                                var text = e.target.value;
                                text = text.replace(/ /g, ''); // 공백제거
                                setValue(text); 
                            }
                        }} 
                        disabled={disabled?true:false}
                        onKeyUp={onKeyUp}/>
                    { showRightEye == true &&
                        <div className="icon"><PasswordRightIcon show={showInput} setShow={setShowInput}/></div>
                    } 
                </div>
            </> }
            { !title && <>
                <div className={"right all" + (disabled?" disable":"")}>
                    <input 
                        type={!!type?type:""}
                        value={value} 
                        placeholder={!!placeholder?placeholder:""}
                        onChange={(e)=> { 
                            if(setValue) {
                                var text = e.target.value;
                                text = text.replace(/ /g, ''); // 공백제거
                                setValue(text); 
                            }
                        }} 
                        disabled={disabled?true:false}
                        onKeyUp={onKeyUp}/>
                </div>
            </> }
        </div>
    )
}

const SHOW_TEXT = "숨김해제";
const HIDE_TEXT = "입력숨김";
/**
 * 비밀번호 입력 우측 아이콘
 * @param props 
 * @returns 
 */
function PasswordRightIcon( props:any ) {
    const {show, setShow} = props;

    return( <>
        { show && <FiEye title={HIDE_TEXT} size={24} onClick={()=>{setShow(false)}}/> }
        { !show && <FiEyeOff title={SHOW_TEXT} size={24} onClick={()=>{setShow(true)}}/> }
    </>)
}