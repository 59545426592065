/****************************************************************************************
 * 
 *  하단 회사 소개 부분
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';

function IconButton( props : any ) {
    const {icon, iconSize, className, onClick} = props;

    useEffect(()=>{

        return(()=>{})
    },[]);

    var iconStyle : any = {};
    if( iconSize ) {
        iconStyle["height"] = iconSize;
        iconStyle["width"] = iconSize;
    }

    return (
        <div className={'icon_btn' + (className?" " + className:"")} onClick={onClick}>
            <div className='icon' style={iconStyle}>
                {icon}
            </div>
        </div>
    );
}

export default IconButton;
