/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import InquiryList from './sub/InquiryList';

const AdminInquiry = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.INQUIRY}
			hideSubTab={true}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default AdminInquiry;


function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<InquiryList {...props}/>
		</div>
	)
}