import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import reduxActions from "../../../../redux_saga/modules/moduleActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import { PAGE_URL } from "../../../../util/Page";
import axios, { AxiosError, AxiosResponse } from "axios";
import { IMP_KEY, IMP_SECRET } from "../../../../util/ConstValue";
import apiAxios, { API_TYPES, API_UTILS } from "../../../../apiAxios";
import { Oval } from "react-loader-spinner";
import { APP_INTERFACE_SEND_TYPE, sendDataToApp } from "../../../../util/AppInterface";


enum STEP {
    STEP_01=1,
    STEP_02=2
}

function MobileCertification( props:any ) {
    const {onResult, show, setShow} = props;

	const location = useLocation();
    const dispatch = useDispatch();

    const [currentStep, setCurrentStep] = useState(STEP.STEP_01);

    const sendResult = ( state:number, data : any ) => {
        var json = {state:state, data:data};

        if( onResult ) onResult(JSON.stringify(json));
        if( setShow ) setShow(false);
        
        sendDataToApp( window, APP_INTERFACE_SEND_TYPE.CERTIFICATION_CLOSE, null );

        /*window.opener.postMessage(JSON.stringify(json), '*');
        window.close();*/
    }

    useEffect(()=>{
        if( show ) {
            setStepOne();
            sendDataToApp( window, APP_INTERFACE_SEND_TYPE.CERTIFICATION_OPEN, null );
		}
    },[show]);

    /*useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
        var imp_uid = l_p.get("imp_uid");
        
        // 인증요청이 성공한 경우
        if( imp_uid ) {
            setStepTwo( imp_uid );
		}
        else {
            setStepOne();
        }
    },[location]);*/

    const setStepOne = () => {
        const w : any = window;
        var IMP = w.IMP;
        IMP.init("imp15808199");
        IMP.certification({ // param
            merchant_uid: `mid_${new Date().getTime()}`, // 주문 번호
            popup : false // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨 false인경우 현재 주소로 redirect 된다.
        },function( rsp:any ) {
            console.log(rsp);

            // 요청 성공
            if (rsp.success) {
                // redirect 주소로 데이터 전달
                //window.location.replace(PAGE_URL.CERTIFICATION + "?imp_uid=" + rsp.imp_uid);

                setStepTwo(rsp.imp_uid);
            } 
            // 요청 실패
            else {            
                sendResult( -1, "사용자가 인증을 취소하였습니다.");
            }
        });
    }
    
    const setStepTwo = async ( imp_uid : string ) => {
        setCurrentStep(STEP.STEP_02);

        apiAxios.global.authCertification(dispatch, imp_uid, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                sendResult( 0, resData);
			}
			else if( error ) {
                sendResult( -2, "데이터 요청 실패");
			}
		})
    }

    return null;
}
export default MobileCertification;