/****************************************************************************************
 * 
 *  메인화면 베스트 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch } from 'react-redux';
import { checkCanMoveTimeInBest, getCurrentSecondsTime, getWeekData, getWeekDayStr, makeTwoNumberStr } from '../../../../util/Global';
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";

function MobileStoryBestArea( props:any ) {
    const [currentTime, setCurrentTime] = useState((new Date()).getTime());
    const [sotrType, setSortType] = useState(API_TYPES.POST_LIST_SORT_TYPE.LIKE);
    const [periodType, setPeriodType] = useState(API_TYPES.POST_LIST_TYPE.MONTH_BEST);

    useEffect(()=>{

        return(()=>{})
    },[]);
	return(
        <div className='mobile_post_home__best_area'>
            <TitleCompenent {...props}
                sotrType={sotrType}
                setSortType={setSortType}
                periodType={periodType}
                setPeriodType={setPeriodType}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                />
            <BestComponent {...props}
                sotrType={sotrType}
                periodType={periodType}
                currentTime={currentTime}
                />
        </div>
	)
}
export default MobileStoryBestArea;

function TitleCompenent( props:any ) {
    const {periodType} = props;

    var periodStr = "";
    if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST )       { periodStr = "일간"; }
    else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) { periodStr = "주간"; }
    else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) { periodStr = "월간"; }

    return(
        <div className='title'>
            <span>{periodStr+"베스트"}</span>

            <div className='right_area'>
                <TitleSortComp {...props}/>
                <TitlePeriodComp {...props}/>
                <TitleMoveTimeComp {...props}/>
            </div>
        </div>
    )
}

function TitleMoveTimeComp( props:any ) {
    const {periodType, currentTime, setCurrentTime} = props;
    const [isAbleLeft, setAbleLeft] = useState(true);
    const [isAbleRight, setAbleRight] = useState(true);

    useEffect(()=>{
        setAbleLeft(checkCanMoveTimeInBest(periodType, currentTime, false));
        setAbleRight(checkCanMoveTimeInBest(periodType, currentTime, true));
        return(()=>{})
    },[periodType, currentTime]);

    var d = new Date();
    d.setTime(currentTime);
    const year = d.getFullYear();
    const month = makeTwoNumberStr( d.getMonth() + 1 );
    const day = makeTwoNumberStr( d.getDate() );
    const weekData = getWeekData(d);

    var timeStr = "";
    if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST ) {
        const weekDayStr = getWeekDayStr(d.getDay());
        timeStr = year + "." + month + "." + day + "(" + weekDayStr + ")";
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) {
        timeStr = weekData.year + "." + makeTwoNumberStr(weekData.month+1) + " [ " + weekData.week + "주차 ]";
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) {
        timeStr = year + "년 " + month + "월";
    }

    const clickedLeft = () => {
        if( isAbleLeft ) {
            var d = new Date();
            d.setTime(currentTime);
            d = remakeTime(d, false);
            setCurrentTime(d.getTime());
        }
    }

    const clickedRight = () => {
        if( isAbleRight ) {
            var d = new Date();
            d.setTime(currentTime);
            d = remakeTime(d, true);
            setCurrentTime(d.getTime());
        }
    }

    const remakeTime = (d:Date, isPlus:boolean ) => {
        if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST ) {
            d.setDate( d.getDate() + ( isPlus?1:-1) );
        }
        else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) {
            d.setDate( d.getDate() + ( isPlus?7:-7) );
        }
        else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) {
            d.setMonth( d.getMonth() + ( isPlus?1:-1) );
        }
        return d;
    }

    return(
        <div className='time_box'>
            <div className={'right_btn block_text_select' + (isAbleRight?"":" disable_btn")} onClick={clickedRight}><AiOutlineCaretRight size={15}/></div>
            <div className='time block_text_select'><span>{timeStr}</span></div>
            <div className={'left_btn block_text_select' + (isAbleLeft?"":" disable_btn")} onClick={clickedLeft}><AiOutlineCaretLeft size={15}/></div>
        </div>
    );
}

function TitleSortComp( props:any ) {
    const { sotrType, setSortType, setCurrentTime } = props;

    return(
        <div className='sort'>
            <select className="select"  value={sotrType} 
                onChange={(e)=> {
                    setSortType(e.target.value);  
                }}>
                <option value={API_TYPES.POST_LIST_SORT_TYPE.LIKE}>추천순</option>
                <option value={API_TYPES.POST_LIST_SORT_TYPE.HITS}>조회순</option>
                <option value={API_TYPES.POST_LIST_SORT_TYPE.REPLY}>댓글순</option>
            </select>
        </div>
    )
}

function TitlePeriodComp( props:any ) {
    const {periodType, setPeriodType, setCurrentTime} = props;

    return(
        <div className='period'>
            <select className="select"  value={periodType} 
                onChange={(e)=> {
                    setCurrentTime((new Date()).getTime());
                    setPeriodType(e.target.value);  
                }}>
                <option value={API_TYPES.POST_LIST_TYPE.DAY_BEST}>일간</option>
                <option value={API_TYPES.POST_LIST_TYPE.WEEK_BEST}>주간</option>
                <option value={API_TYPES.POST_LIST_TYPE.MONTH_BEST}>월간</option>
            </select>
        </div>
        
    )
}

function BestComponent( props:any ) {
    const {sotrType, periodType, currentTime} = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [postList, setPostList] = useState<any>(null);

    useEffect(()=>{
        reqList(sotrType, periodType, currentTime );

        return(()=>{})
    },[sotrType, periodType, currentTime]);

    const reqList = ( post_list_sort_type:number, post_list_type:number, time:number ) => {

        var reqTime = Math.floor(time/1000);

        apiAxios.global.listStoryBestRanking( dispatch, post_list_type, post_list_sort_type, reqTime, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.use_state == API_TYPES.USE_STATE.ABLE ):null;
                setPostList( list );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const clickItem = (item:any) =>{

        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:periodType,
            post_type:API_TYPES.POST_TYPE.STORY,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var url = makeUrl(PAGE_URL.STORY_POST, params);
        navigate(url);
    }

	return( 
        <div className='post_box'>
            { !postList || postList.length==0 && 
                <EmptyComponent />
            }
            { postList && postList.length>0 && 
                <ul>
                    { postList.map((item:any, index:number)=>{
                        return(
                            <li key={index}>
                                <PostItem {...props} item={item} ranking={index+1} haveBottomLine={index<postList.length-1} onClick={clickItem}/>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

function PostItem( props:any ) {
    const {item, ranking, haveBottomLine, onClick} = props;

    const haveReplyNum = item.reply_num && item.reply_num > 0;

    // calc(100% - 40px - 20px - 10px - 40px)
    var maxWidth = window.outerWidth - 40 - 20 - 10 - (haveReplyNum?40:0);

    return (
        <div className={'post' + (haveBottomLine?" border_bottom":"")} onClick={()=>{if(onClick) onClick(item)}}>
            <div>
                <div className='ranking'>
                    <span>{ranking}</span>
                </div>
                <div className='body' style={{maxWidth:maxWidth}}>
                    <span className='category'>{"["+item.cate_name+"]"}</span>
                    <span className='title'>{item.title}</span>
                </div>
                { haveReplyNum &&
                    <div className='reply'>
                        <span>{"(" + item.reply_num + ")"}</span>
                    </div>
                }
            </div>
        </div>
    )
}


function EmptyComponent( props:any ) {
	return(
        <div className='empty'>
            <span>{"베스트글이 없습니다"}</span>
        </div>
	)
}