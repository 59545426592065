
export enum APP_INTERFACE_SEND_TYPE {
    PAGE_OPEN = 1,
    LOGIN = 2,
    LOGOUT = 3,
    CERTIFICATION_OPEN = 4,
    CERTIFICATION_CLOSE = 5
}

export interface APP_INTERFACE_SEND_DATA {
    type:APP_INTERFACE_SEND_TYPE
    data:string|null
}

export enum APP_INTERFACE_RECEIVE_TYPE {
    MOVE_PAGE_HOME = 1,
    MOVE_PAGE_LOGIN = 2,
    MOVE_PAGE_POST = 3,
    LOGIN = 4
}

export interface APP_INTERFACE_RECEIVE_DATA {
    type:APP_INTERFACE_RECEIVE_TYPE
    data:any
}

export interface APP_INTERFACE_LOGIN_DATA {
    id:string, pw:string, token:string, code:string|null, userData:any
}

export interface APP_INTERFACE_LOGOUT_DATA {
    token:string
}

export const sendDataToApp = ( window:any, type:APP_INTERFACE_SEND_TYPE, data:any ) => {
    if( window.ReactNativeWebView ) {
        window.ReactNativeWebView.postMessage( makeAppInterfaceSendData(type, data) );
    }
}

export const makeAppInterfaceSendData = ( type:APP_INTERFACE_SEND_TYPE, data:any ) => {
    const d : APP_INTERFACE_SEND_DATA = { type:type, data:data };
    return JSON.stringify(d);
}



export type MobileListener = (e:any) => void;

export const setMobileListener = ( window:any, listener : MobileListener ) => {
    document.addEventListener('message', listener);
    /*window.__WEBVIEW_BRIDGE__={
        init:function(){
            try{
                document.addEventListener("message",listener); // message 이벤트 리스너 추가
            }catch(err){
                console.error(err);
            }
        }
    };
    window.__WEBVIEW_BRIDGE__.init();*/
}

export const removeMobileListener = ( listener : MobileListener ) => {
    try{
        document.removeEventListener("message",listener); // message 이벤트 리스너 추가
    }catch(err){
        console.error(err);
    }
}
