
import * as util from "./v1/util";
import * as global from "./v1/global";
import * as admin from "./v1/admin";
import * as user from "./v1/user";
import * as types from "./v1/type";
import * as data_middleware from "./v1/data_middleware";
import * as Const from "./v1/const";

const defaultFunc = {
    defaultNextListUrl : util.defaultNextListUrl
}

export const API_TYPES = {...types};
export const API_UTILS = {...util};

/*const type = {...types}
export interface type;*/

const apiAxios = {
    global  : global,
    user    : user,
    admin   : admin,
    defaultFunc : defaultFunc,
    data_middleware : data_middleware,
    type:types,
    util:util,
    Const:Const
}
export default apiAxios;


/*********************************************************************************************
    파라미터 
*********************************************************************************************/

/* Admin 파라미터 */
export interface ParamAdminListStoryChoice extends admin.ParamListStoryChoice{}
export interface ParamAdminListPostDelete extends admin.ParamListPostDelete{}
export interface ParamAdminListReplyDelete extends admin.ParamListReplyDelete{}
export interface ParamAdminListPostReport extends admin.ParamListPostReport{}
export interface ParamAdminListReplyReport extends admin.ParamListReplyReport{}
export interface ParamAdminListAdminUser extends admin.ParamListAdminUser{}
export interface ParamAdminListUser extends admin.ParamListUser{}
export interface ParamAdminListBlackList extends admin.ParamListBlackList{}
export interface ParamAdminDataNoticeAdd extends admin.ParamDataNoticeAdd{}
export interface ParamAdminDataNoticeUpdate extends admin.ParamDataNoticeUpdate{}
export interface ParamAdminDataAdminAdd extends admin.ParamDataAdminAdd{}
export interface ParamAdminDataAdminUpdate extends admin.ParamDataAdminUpdate{}
export interface ParamAdminDataStandardUserLevelUpdate extends admin.ParamDataStandardUserLevelUpdate{}
export interface ParamAdminDataStoryChoiceUpdate extends admin.ParamDataStoryChoiceUpdate{}
export interface ParamAdminDataCommunityCategoryAdd extends admin.ParamDataCommunityCategoryAdd{}
export interface ParamAdminDataCommunityCategoryUpdate extends admin.ParamDataCommunityCategoryUpdate{}

/* End - Admin 파라미터 */

/* Global 파라미터 */
export interface ParamGlobalDataPost extends global.ParamDataPost{}
/* End - Global 파라미터 */

/* User 파라미터 */
export interface ParamAuthSignup extends user.ParamAuthSignup{}
export interface ParamUserDataPostAdd extends user.ParamDataPostAdd{}
export interface ParamUserDataPostUpdate extends user.ParamDataPostUpdate{}
export interface ParamUserDataReplyAdd extends user.ParamDataReplyAdd{}
export interface ParamUserDataReplyUpdate extends user.ParamDataReplyUpdate{}
/* End - User 파라미터 */

/* End - 파라미터 
*********************************************************************************************/