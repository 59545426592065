/****************************************************************************************
 * 
 *  홈페이지 상단 로고 및 검색입력 부분
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { PAGE_SEARCH, PAGE_URL, makeUrl } from '../../../util/Page';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux_saga/modules';
import { getCookieLoginData, removeCookieLoginData } from '../../../util/cookie_helper';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { TEST_VERSION } from '../../../util/ConstValue';

function Header( props : any ) {
    const { subTitle } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    /**
     * 웹 페이지 변경될때 상단으로 이동
     */    
	const location = useLocation();
    const loginData = useSelector((state:RootState)=>state.data.loginData);
	
	useEffect(()=>{
		window.scrollTo(0,0);
		return ()=>{}
	},[location])

    useEffect(()=>{

        return(()=>{})
    },[]);

    const clickedLogout = () => {
        reduxActions.data.setLoginData(dispatch, null);
        reduxActions.data.setUserData(dispatch, null);
        //removeCookieLoginData();

        var d = getCookieLoginData();
        console.log("clickLogout",d);
        navigate(PAGE_URL.MAIN, {replace:true});
    }

    return (
        <div className='admin_header'>
            <div className='header_body'>
                <div className='header_title_area'>
                    <div className={'logo'}>
                        <span onClick={()=>{
                            navigate( PAGE_URL.MAIN );
                        }}>{"모여봐" + (TEST_VERSION?" (테스트)":"")}</span>
                        <span onClick={()=>{
                            navigate( PAGE_URL.ADMIN_MAIN );
                        }}>{" 관리자"}</span>
                    </div>

                    <div className='right'>
                        { loginData && <div><span>{loginData.name}</span></div> }
                        <div><span>{"·"}</span></div>
                        <div className='logout' onClick={clickedLogout}><span>{"로그아웃"}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header;