/****************************************************************************************
 * 
 *  공지 게시글
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import apiAxios, { API_TYPES, API_UTILS, ParamGlobalDataPost } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../../redux_saga/modules';
import MobilePostBody from './sub/MobilePostBody';
import { PAGE_NOTICE_HOME_PARAMS, PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import { getDeletePostMsg } from '../../../../util/Global';
import MobileBottomList from './sub/MobileBottomList';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../../sub/Adsense';
import MobileWrapper from '../../sub/MobileWrapper';
import { USER_MANAGE_TYPE, checkAbleManageUser } from '../../../../util/AdminAccessHelper';

const MobileNoticePost = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [idx, setIdx] = useState(-1);
	
	const [postData, setPostData] = useState<any>(null);
	const [postList, setPostList] = useState<any>(null);
	const [postType, setPostType] = useState(API_TYPES.POST_TYPE.ALL);
	const [category, setCategory] = useState(0);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
	

    const loginData = useSelector((state:RootState)=>state.data.loginData);

	var userManageType : USER_MANAGE_TYPE = postType==API_TYPES.POST_TYPE.STORY?USER_MANAGE_TYPE.STORY
											:postType==API_TYPES.POST_TYPE.COMMUNITY?USER_MANAGE_TYPE.COMMUNITY
											:USER_MANAGE_TYPE.NONE;
	var adminAbleManage = checkAbleManageUser( loginData, userManageType, category==0?null:category);

	useEffect(()=>{
		console.log("MobileNoticePost useEffect");

		const l_p = new URLSearchParams(location.search);
		const i = l_p.get("idx");
		const p = l_p.get("post_type");
		const c = l_p.get("category");

		var newIdx = -1;
		var newPostType = API_TYPES.POST_TYPE.ALL;
		var newCategory = 0;

		if( i ) 	{ try{ newIdx 				= parseInt(i); 		}catch(e){ console.log("e",e);} }
		if( p ) 	{ try{ newPostType 			= parseInt(p); 		}catch(e){ console.log("e",e);} }
		if( c ) 	{ try{ newCategory 			= parseInt(c); 		}catch(e){ console.log("e",e);} }

		setIdx(newIdx);
		setPostType(newPostType);
		setCategory(newCategory);

		if( newPostType != API_TYPES.POST_TYPE.ALL ) {
			reqCategory(newPostType);
		}
		reqData( newIdx );

		return(()=>{})
	},[location]);

	const reqCategory = ( postType : number ) => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
		if( postType == API_TYPES.POST_TYPE.COMMUNITY) {
			reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY) {
			reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
		}
	}

	const reqData = ( idx:number ) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				setPostData(resData.post_data);
				setPostList(resData.post_list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
					navigate(PAGE_URL.MAIN, { replace:true });
				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {
					alert(getDeletePostMsg(API_TYPES.POST_USE_STATE.DELETE));
					navigate(PAGE_URL.MAIN, { replace:true });
				}
			}
		}

		apiAxios.global.dataNotice(dispatch,idx,callback);
	}

	const clickToList = ( isReplace : boolean = false) => {
		var year = (new Date()).getFullYear();
        var url = "";
        if( postType == API_TYPES.POST_TYPE.ALL ) {
            const params : PAGE_NOTICE_HOME_PARAMS = {
                page:1
            }
			url = makeUrl(PAGE_URL.NOTICE, params);
		}
		else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			const params : PAGE_POST_HOME_PARAMS = {
				category:category==0?-1:category, year:year, page:1
			}
			url = makeUrl(PAGE_URL.COMMUNITY, params);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			const params : PAGE_POST_HOME_PARAMS = {
				category:category==0?-1:category, year:year, page:1
			}
			url = makeUrl(PAGE_URL.STORY, params);
		}

        if( !!url ) {
            if( isReplace ) {
                navigate(url, {replace:true});
            }
            else {
                navigate(url);
            }
        }
	}

	var categoryList = null;
	var tabType = -1;

	//var isAllList = true;

	return (<>
		<MobileWrapper
			tabType={tabType}
			hideNotice={true}>
			<MiddleComponent 
				{...props} 
				idx={idx} 
				postData={postData}  
				setPostData={setPostData}
				postList={postList}
				setPostList={setPostList}
				onClickToList={clickToList}
				postType={postType}
				category={category}
				adminAbleManage={adminAbleManage}
				onClickBottomList={()=>{
					setPostData(null);
					setPostList(null);
				}}
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileNoticePost;

function MiddleComponent( props:any ) {
	const {postData, onClickBottomList, postList, setPostList} = props;

    useEffect(()=>{

        return(()=>{})
    },[]);

	if( !postData ) return;

	return(
		<div className='middle_area'>
			<MobilePostBody {...props}/>
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				marginTop={10}
				pagePosition={AD_PAGE_POSITION.MOBILE_NOTICE_READ_MIDDLE}/>
			<MobileBottomList {...props} onClickBottomList={onClickBottomList} />
		</div>
	)
}