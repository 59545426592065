/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MobileMyPostList from './sub/MobileMyPostList';
import { checkLogin } from '../../../../util/Global';
import { RootState } from '../../../../redux_saga/modules';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../../sub/MobileWrapper';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../../sub/Adsense';

const MobileMyPost = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{
		checkLogin(navigate, loginData);
        return(()=>{})
    },[loginData]);
	
	useEffect(()=>{
		console.log("Story useEffect");
		
		return(()=>{})
	},[location]);

	return (
		<MobileWrapper
			tabType={MOBILE_TAB_TYPE.NONE}
			hideNotice={true}
			subTitle={"내가쓴글"}>
			<MiddleComponent 
				{...props} />
		</MobileWrapper>
	);
};

export default MobileMyPost;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<Adsense
				{...props}
				type={AdType.MOBILE_TOP}
				marginTop={10}
				marginBottom={10}
				pagePosition={AD_PAGE_POSITION.MOBILE_MY_POST_TOP}/>
			<MobileMyPostList {...props}/>
		</div>
	)
}