/****************************************************************************************
 * 
 *  메인화면 베스트 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch } from 'react-redux';
import { checkCanMoveTimeInBest, getCurrentSecondsTime, getWeekData, getWeekDayStr, makeTwoNumberStr } from '../../../util/Global';
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";

function StoryBestAreaComponent( props:any ) {
    const [currentTime, setCurrentTime] = useState((new Date()).getTime());
    const [sotrType, setSortType] = useState(API_TYPES.POST_LIST_SORT_TYPE.LIKE);
    const [periodType, setPeriodType] = useState(API_TYPES.POST_LIST_TYPE.MONTH_BEST);

    useEffect(()=>{

        return(()=>{})
    },[]);
	return(
        <div className='post_home__best_area'>
            <TitleCompenent {...props}
                sotrType={sotrType}
                setSortType={setSortType}
                periodType={periodType}
                setPeriodType={setPeriodType}
                currentTime={currentTime}
                setCurrentTime={setCurrentTime}
                />
            <BestComponent {...props}
                sotrType={sotrType}
                periodType={periodType}
                currentTime={currentTime}
                />
        </div>
	)
}
export default StoryBestAreaComponent;

function TitleCompenent( props:any ) {
    const {periodType} = props;

    var periodStr = "";
    if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST )       { periodStr = "일간"; }
    else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) { periodStr = "주간"; }
    else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) { periodStr = "월간"; }

    return(
        <div className='title_box'>
            <div className='title'> <span>{periodStr+"베스트"}</span> </div>

            <TitleSortComp {...props}/>

            <div className='right'>
                <TitleMoveTimeComp {...props}/>
                <TitlePeriodComp {...props}/>
            </div>
        </div>
    )
}

function TitleSortComp( props:any ) {
    const { sotrType, setSortType, setCurrentTime } = props;

    const clickedSortType = ( type : number ) => {
        if( sotrType != type ) {
            //setCurrentTime((new Date()).getTime());
            setSortType(type);
        }
    }

    const isSelectLike = sotrType == API_TYPES.POST_LIST_SORT_TYPE.LIKE;
    const isSelectHits = sotrType == API_TYPES.POST_LIST_SORT_TYPE.HITS;
    const isSelectReply = sotrType == API_TYPES.POST_LIST_SORT_TYPE.REPLY;

    return(
        <div className='sort'>
            <div onClick={()=>{clickedSortType(API_TYPES.POST_LIST_SORT_TYPE.LIKE)}}>
                { isSelectLike && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectLike?" select":"")}><div><span>추천순</span></div></div>
            </div>

            <div> <div className='dot'><div><span>·</span></div></div> </div>

            <div onClick={()=>{clickedSortType(API_TYPES.POST_LIST_SORT_TYPE.HITS)}}>
                { isSelectHits && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectHits?" select":"")}><div><span>조회순</span></div></div>
            </div>

            <div> <div className='dot'><div><span>·</span></div></div> </div>

            <div onClick={()=>{clickedSortType(API_TYPES.POST_LIST_SORT_TYPE.REPLY)}}>
                { isSelectReply && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectReply?" select":"")}><div><span>댓글순</span></div></div>
            </div>
        </div>
    )
}

function TitleMoveTimeComp( props:any ) {
    const {periodType, currentTime, setCurrentTime} = props;
    const [isAbleLeft, setAbleLeft] = useState(true);
    const [isAbleRight, setAbleRight] = useState(true);

    useEffect(()=>{
        setAbleLeft(checkCanMoveTimeInBest(periodType, currentTime, false));
        setAbleRight(checkCanMoveTimeInBest(periodType, currentTime, true));
        return(()=>{})
    },[periodType, currentTime]);

    var d = new Date();
    d.setTime(currentTime);
    const year = d.getFullYear();
    const month = makeTwoNumberStr( d.getMonth() + 1 );
    const day = makeTwoNumberStr( d.getDate() );
    const weekData = getWeekData(d);

    var timeStr = "";
    if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST ) {
        const weekDayStr = getWeekDayStr(d.getDay());
        timeStr = year + "." + month + "." + day + "(" + weekDayStr + ")";
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) {
        timeStr = weekData.year + "." + makeTwoNumberStr(weekData.month+1) + " [ " + weekData.week + "주차 ]";
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) {
        timeStr = year + "년 " + month + "월";
    }

    const clickedLeft = () => {
        if( isAbleLeft ) {
            var d = new Date();
            d.setTime(currentTime);
            d = remakeTime(d, false);
            setCurrentTime(d.getTime());
        }
    }

    const clickedRight = () => {
        if( isAbleRight ) {
            var d = new Date();
            d.setTime(currentTime);
            d = remakeTime(d, true);
            setCurrentTime(d.getTime());
        }
    }

    const remakeTime = (d:Date, isPlus:boolean ) => {
        if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST ) {
            d.setDate( d.getDate() + ( isPlus?1:-1) );
        }
        else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) {
            d.setDate( d.getDate() + ( isPlus?7:-7) );
        }
        else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) {
            d.setMonth( d.getMonth() + ( isPlus?1:-1) );
        }
        return d;
    }

    return(
        <div className='time_box'>
            <div className={'right_btn block_text_select' + (isAbleRight?"":" disable_btn")} onClick={clickedRight}><AiOutlineCaretRight size={15}/></div>
            <div className='time block_text_select'><span>{timeStr}</span></div>
            <div className={'left_btn block_text_select' + (isAbleLeft?"":" disable_btn")} onClick={clickedLeft}><AiOutlineCaretLeft size={15}/></div>
        </div>
    );
}

function TitlePeriodComp( props:any ) {
    const {periodType, setPeriodType, setCurrentTime} = props;
    

    const clickedPeriodType = ( type : number ) => {
        if( periodType != type ) { 
            setCurrentTime((new Date()).getTime());
            setPeriodType(type);
        }
    }

    const isSelect1 = periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST;
    const isSelect2 = periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST;
    const isSelect3 = periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST;

    const showDiveder1 = isSelect3 == true;
    const showDiveder2 = isSelect1 == true;

    return(
        <div className='period_box'>
            <div className={'period period_1' + (isSelect1?" select":"")} onClick={()=>{clickedPeriodType(API_TYPES.POST_LIST_TYPE.DAY_BEST)}}><span>일간</span></div>
            { showDiveder1 && <div className='period_divider divider_1'></div> }
            <div className={'period period_2' + (isSelect2?" select":"")} onClick={()=>{clickedPeriodType(API_TYPES.POST_LIST_TYPE.WEEK_BEST)}}><span>주간</span></div>
            { showDiveder2 && <div className='period_divider divider_2'></div> }
            <div className={'period period_3' + (isSelect3?" select":"")} onClick={()=>{clickedPeriodType(API_TYPES.POST_LIST_TYPE.MONTH_BEST)}}><span>월간</span></div>
        </div>
    )
}

function BestComponent( props:any ) {
    const {sotrType, periodType, currentTime} = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [postList, setPostList] = useState<any>(null);

    useEffect(()=>{
        reqList(sotrType, periodType, currentTime );

        return(()=>{})
    },[sotrType, periodType, currentTime]);

    const reqList = ( post_list_sort_type:number, post_list_type:number, time:number ) => {

        var reqTime = Math.floor(time/1000);

        apiAxios.global.listStoryBestRanking( dispatch, post_list_type, post_list_sort_type, reqTime, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.use_state == API_TYPES.USE_STATE.ABLE ):null;
                setPostList( remakePostList(list) );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const remakePostList = (list:any) => {
        if( !list ) return null;

        var newList = [];

        for( var i=0; i<list.length; i++ ) {
            if( i%2 == 0 ) {
                var middle = [];
                middle.push(list[i]);
                newList.push(middle);
            }
            else {
                newList[Math.floor(i/2)].push(list[i]);
            }
        }
        return newList;
    }

    const clickItem = (item:any) =>{

        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:periodType,
            post_type:API_TYPES.POST_TYPE.STORY,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var url = makeUrl(PAGE_URL.STORY_POST, params);
        navigate(url);
    }

	return( 
        <div className='post_box'>
            { !postList || postList.length==0 && 
                <EmptyComponent />
            }
            { postList && postList.length>0 && 
                <table>
                    { postList.map((posts:any, index:number)=>{
                        return(
                            <tr key={index}>
                                { posts.length > 0 && <PostItem {...props} item={posts[0]} ranking={index*2 + 1} haveBottomLine={index<postList.length-1} onClick={clickItem}/>}
                                { posts.length > 1 && <td className='divider'/>}
                                { posts.length > 1 && <PostItem {...props} item={posts[1]} ranking={index*2 + 2} haveBottomLine={index<postList.length-1} onClick={clickItem}/>}
                            </tr>
                        )
                    })}
                </table>
            }
        </div>
    )
}

function PostItem( props:any ) {
    const {item, ranking, haveBottomLine, onClick} = props;

    const haveReplyNum = item.reply_num && item.reply_num > 0;

    return (
        <td className={'post' + (haveBottomLine?" border_bottom":"")} onClick={()=>{if(onClick) onClick(item)}}>
            <div>
                <div className='ranking'>
                    <span>{ranking}</span>
                </div>
                <div className='body'>
                    <span className='category'>{"["+item.cate_name+"]"}</span>
                    <span className='title'>{item.title}</span>
                </div>
                { haveReplyNum &&
                    <div className='reply'>
                        <span>{"(" + item.reply_num + ")"}</span>
                    </div>
                }
            </div>
        </td>
    )
}


function EmptyComponent( props:any ) {
	return(
        <div className='empty'>
            <span>{"베스트글이 없습니다"}</span>
        </div>
	)
}