import { useEffect, useRef, useState } from "react";
import apiAxios, { API_TYPES, API_UTILS, ParamUserDataPostAdd, ParamUserDataPostUpdate } from "../../../../apiAxios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import { ImageData, ResponseImage, getImageFile, makePreViewFile } from "../../../../util/Image";
import { AiFillCloseCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { INPUT_ACCEPT_TYPE, MAX_NICKNAME_LENGTH, TEXT_OVER_NICKNAME_LENGTH } from "../../../../util/ConstValue";
import { chkNickName } from "../../../../util/Global";

const SELECT_CATEGORY_ALL = -1;

const BodyComponent = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {postType, postData, categoryIdx} = props;
    const [bodyStr, setBodyStr] = useState("");
    const [imageDataList, setImageDataList] = useState<UploadImageData[]>(getDefaultImageDataList());
    const [titleStr, setTitleStr] = useState("");
    const [nickNameStr, setNickNameStr] = useState("");
    const [requestingSave, setRequestingSave] = useState(false);

    const [selectPostType, setSelectPostType] = useState<any>(API_TYPES.POST_TYPE.ALL);
    const [selectCategory, setSelectCategory] = useState<any>(API_TYPES.POST_TYPE.ALL);
    
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    
    const getBodyPlaceHolder = () => {
        var text = "";
        if( selectPostType == API_TYPES.POST_TYPE.STORY) {
            text += "사연";
        }
        else {
            text += "게시글";
        }
        text += "을 작성해 주세요.";
        text += "\n퍼온 글 일경우 출처를 분명하게 남겨주세요";
        
        if( selectPostType == API_TYPES.POST_TYPE.STORY) {
            text += "\n\n※ 작성하신 사연은 관리자가 채택하여 유투브에 영상으로 업로드 할 수 있으며";
            text += "\n글 작성시 동의한것으로 간주합니다.";
            text += "\n채택된 사연의 경우 문화상품권을 보내드리며 수정,삭제가 불가능 합니다.";
        }
        return text;
    }

	useEffect(()=>{
        if( postData ) {
            setNickNameStr( postData.nickname );
            setTitleStr( postData.title );
            setBodyStr(postData.body);
            setPostImageDataList( postData );
        }
        else if( loginData ) {
            setNickNameStr( loginData.nickname );
        }
		return(()=>{})
	},[loginData, postData]);


    const setPostImageDataList = ( postData : any ) => {
        var newList : UploadImageData[] = [...getDefaultImageDataList()];
        
        if( postData.image01 ) newList[0].url = postData.image01;
        if( postData.image02 ) newList[1].url = postData.image02;
        if( postData.image03 ) newList[2].url = postData.image03;
        if( postData.image04 ) newList[3].url = postData.image04;
        if( postData.image05 ) newList[4].url = postData.image05;
        if( postData.image06 ) newList[5].url = postData.image06;
        if( postData.image07 ) newList[6].url = postData.image07;
        if( postData.image08 ) newList[7].url = postData.image08;
        if( postData.image09 ) newList[8].url = postData.image09;
        if( postData.image10 ) newList[9].url = postData.image10;

        setImageDataList(newList);
    }


    const clickedSave = async () => {

        if( requestingSave ) return;

        var msg = checkDataMsg();
        if( msg ) {
            alert( msg );
            return;
        }
        
        setRequestingSave(true);

        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setRequestingSave(false);
                alert("저장완료");
                navigate( -1 );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("찾을 수 없습니다");
				}
                setRequestingSave(false);
			}
		}

        // 커뮤니티인 경우
        if( selectPostType == API_TYPES.POST_TYPE.COMMUNITY ) {
            if( postData ) {
                var updateParam = await makeParamUpdate();
                apiAxios.user.dataCommunityUpdate(dispatch, updateParam, callback);
            }
            else {
                apiAxios.user.dataCommunityAdd(dispatch, makeParamAdd(), callback);
            }
        }
        // 사연인 경우
        else if( selectPostType == API_TYPES.POST_TYPE.STORY ) {
            if( postData ) {
                var updateParam = await makeParamUpdate();
                apiAxios.user.dataStoryUpdate(dispatch, updateParam, callback);
            }
            else {
                apiAxios.user.dataStoryAdd(dispatch, makeParamAdd(), callback);
            }
        }
        else {
            setRequestingSave(false);
        }
    }

    const checkDataMsg = () => {
        if( selectPostType == API_TYPES.POST_TYPE.ALL ) { return "게시글 타입을 선택하세요"; }
        if( selectCategory == SELECT_CATEGORY_ALL ) { return "게시글 카테고리를 선택하세요"; }
        if( !nickNameStr )  { return "닉네임을 입력하세요"; }
        if( !titleStr )     { return "제목을 입력하세요"; }
        if( !bodyStr ) {
            var haveImage = false;
            for( var i=0; i<imageDataList.length; i++ ) {
                var data : UploadImageData = imageDataList[i];
                if( data.file || (data.url&&!data.deleteUrl) ) {
                    haveImage = true;
                    break;
                }
            }

            if( haveImage == false ) {
                return "내용을 입력하세요";  
            }   
        }

        return chkNickName(nickNameStr);
    }

    const makeParamAdd = () => {

        var thumbnail : any = null;
        for( var i=0; i<imageDataList.length; i++ ) {
            if( !!imageDataList[i].preView ) {
                thumbnail = imageDataList[i].preView?.file;
                break;
            }
        }

        var param : ParamUserDataPostAdd = {
            cate_idx:selectCategory,
            nickname:nickNameStr,
            title:titleStr,
            body:bodyStr,
            thumbnail:thumbnail,
            image_file_01:imageDataList[0].file,
            image_file_02:imageDataList[1].file,
            image_file_03:imageDataList[2].file,
            image_file_04:imageDataList[3].file,
            image_file_05:imageDataList[4].file,
            image_file_06:imageDataList[5].file,
            image_file_07:imageDataList[6].file,
            image_file_08:imageDataList[7].file,
            image_file_09:imageDataList[8].file,
            image_file_10:imageDataList[9].file
        }
        return param;
    }

    const makeParamUpdate = async () => {

        var uploadDataList : UploadImageData[] = reMakeUploadImageDataList(imageDataList);

        var thumbnail : any = null;
        var delete_thumbnail : any = null;

        // 기존의 첫번째 이미지와 다르다면
        var firstUrl = getUrlFromImageData(uploadDataList[0]);
        if( firstUrl != postData.image01 ) {
            delete_thumbnail = postData.thumbnail;

            for( var i=0; i<uploadDataList.length; i++ ) {
                // 프리뷰가 존재하거나 이전의 주소가 있는경우
                if( uploadDataList[i].preView || getUrlFromImageData(uploadDataList[i]) ) {
                    if( uploadDataList[i].preView ) {
                        thumbnail = uploadDataList[i].preView?.file;
                    }
                    else {
                        const url = getUrlFromImageData(uploadDataList[i])
                        const image : ImageData = await makePreViewFile(url);
                        thumbnail = image.file;
                    }
                    break;
                }
            }
        }

        var param : ParamUserDataPostUpdate = {
            idx:postData.idx,
            year:postData.year,
            title:titleStr,
            body:bodyStr,
            cate_idx:selectCategory,
            thumbnail:thumbnail,
            delete_thumbnail:delete_thumbnail,
            image_file_01:uploadDataList[0].file,
            image_file_02:uploadDataList[1].file,
            image_file_03:uploadDataList[2].file,
            image_file_04:uploadDataList[3].file,
            image_file_05:uploadDataList[4].file,
            image_file_06:uploadDataList[5].file,
            image_file_07:uploadDataList[6].file,
            image_file_08:uploadDataList[7].file,
            image_file_09:uploadDataList[8].file,
            image_file_10:uploadDataList[9].file,
            image_url_01:getUrlFromImageData(uploadDataList[0]),
            image_url_02:getUrlFromImageData(uploadDataList[1]),
            image_url_03:getUrlFromImageData(uploadDataList[2]),
            image_url_04:getUrlFromImageData(uploadDataList[3]),
            image_url_05:getUrlFromImageData(uploadDataList[4]),
            image_url_06:getUrlFromImageData(uploadDataList[5]),
            image_url_07:getUrlFromImageData(uploadDataList[6]),
            image_url_08:getUrlFromImageData(uploadDataList[7]),
            image_url_09:getUrlFromImageData(uploadDataList[8]),
            image_url_10:getUrlFromImageData(uploadDataList[9]),
            delete_url_01:uploadDataList[0].deleteUrl,
            delete_url_02:uploadDataList[1].deleteUrl,
            delete_url_03:uploadDataList[2].deleteUrl,
            delete_url_04:uploadDataList[3].deleteUrl,
            delete_url_05:uploadDataList[4].deleteUrl,
            delete_url_06:uploadDataList[5].deleteUrl,
            delete_url_07:uploadDataList[6].deleteUrl,
            delete_url_08:uploadDataList[7].deleteUrl,
            delete_url_09:uploadDataList[8].deleteUrl,
            delete_url_10:uploadDataList[9].deleteUrl,
        }
        return param;
    };

    const reMakeUploadImageDataList = ( list : UploadImageData[] ) => {
        
        var beforeList = [...list];

        var newList : UploadImageData[] = [];
        var imageList : UploadImageData[] = [];
        var deleteUrlList : UploadImageData[] = [];


        for( var i=0; i<beforeList.length; i++ ) {
            var d : UploadImageData = beforeList[i];

            // 새 파일인 경우
            if( d.file ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.file = d.file;
                emptyData.preView = d.preView;
                imageList.push(emptyData);
            }
            // 삭제되지 않은 이미지 주소가 있는경우
            else if( d.url && !d.deleteUrl ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.url = d.url;
                imageList.push(emptyData);
            }

            // 삭제된 이미지가 있는 경우
            if( d.deleteUrl ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.deleteUrl = d.deleteUrl;
                deleteUrlList.push(emptyData);
            }
        }

        // 이미지 추가
        for( var i=0; i<imageList.length; i++ ) {
            newList.push( imageList[i] );
        }

        // 빈데이터 추가
        var moreAddCount = 10 - imageList.length;
        for( var i=0; i<moreAddCount; i++ ) {
            newList.push(getEmptyImageData());
        }

        // 삭제 주소 추가
        for( var i=0; i<deleteUrlList.length; i++ ) {
            newList[i].deleteUrl = deleteUrlList[i].deleteUrl;
        }

        return newList;
    }

    const getUrlFromImageData = ( imageData : UploadImageData ) => {
        if( imageData.url && !imageData.blob && !imageData.file ) {
            return imageData.url;
        }
        return null;
    }

    return(
        <>
        <div className="write_post">
            <Top {...props}/>
            <Title {...props}
                selectPostType={selectPostType}
                setSelectPostType={setSelectPostType}
                selectCategory={selectCategory}
                setSelectCategory={setSelectCategory}
                titleStr={titleStr}
                setTitleStr={setTitleStr}
                nickNameStr={nickNameStr}
                setNickNameStr={setNickNameStr}
                />
            <div className="body_textarea"><textarea value={bodyStr} placeholder={getBodyPlaceHolder()} onChange={(e)=> { setBodyStr(e.target.value); }}/></div>
            <ImageAreaComplete 
                {...props}
                imageDataList={imageDataList}
                setImageDataList={setImageDataList}
                />
        </div>
        <BottonButtonComponent 
            {...props}
            onSave={clickedSave}
            requestingSave={requestingSave}
            />
        </>
    )
}
export default BodyComponent;

function Top( props:any ) {
    const {idx} = props;

    var title = "게시글";

    if( idx && idx > 0 ) {
        title += " 수정";
    }
    else {
        title += " 쓰기";
    }

    return(
        <div className="top">
            <span>{title}</span>
        </div>
    )
}

function Title( props:any ) {
    const {categoryIdx, 
        titleStr, setTitleStr, 
        nickNameStr, setNickNameStr, postType, postData, selectPostType, setSelectPostType, selectCategory, setSelectCategory} = props;
    const [categoryList, setCategoryList] = useState<any>(null);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	useEffect(()=>{
        resetCategoryList(postType);
        setSelectPostType(postType);
		return(()=>{})
	},[postType, storyCategoryList, communityCategoryList]);

    const resetCategoryList = ( type:any ) => {
        if( type == API_TYPES.POST_TYPE.COMMUNITY ) {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            var newCate : any = communityCategoryList.filter( (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST );
            newList.push(...newCate);
            setSelectCategory( categoryIdx );
            setCategoryList(newList);
        }
        else if( type == API_TYPES.POST_TYPE.STORY ) {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            var newCate : any = storyCategoryList.filter( (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST );
            newList.push(...newCate);
            setSelectCategory( categoryIdx );
            setCategoryList(newList);
        }
        else {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            setSelectCategory( SELECT_CATEGORY_ALL );
            setCategoryList(newList);
        }
    }

    return(
        <div className="title">
            <div>
                <div className="left">카테고리</div>
                <div className="right">
                    
                    <select className={"select" + (!!postData?" disable":"")}  value={selectPostType} disabled={!!postData}
                        onChange={(e)=> {
                            setSelectPostType(e.target.value);  

                            resetCategoryList(e.target.value);
                        }}>
                        <option value={API_TYPES.POST_TYPE.ALL}>타입선택</option>
                        <option value={API_TYPES.POST_TYPE.STORY}>사연</option>
                        <option value={API_TYPES.POST_TYPE.COMMUNITY}>커뮤니티</option>
                    </select>
                    
                    <select className={"select" + (selectPostType==API_TYPES.POST_TYPE.ALL?" disable":"")} style={{marginLeft:10, width:150}} value={selectCategory} disabled={selectPostType==API_TYPES.POST_TYPE.ALL}
                        onChange={(e)=> {
                            setSelectCategory(e.target.value);  
                        }}>
                        
                        { categoryList && categoryList.map((item:any, index:number)=>{
                            return(<option value={item.idx} key={index}>{item.name}</option>)
                        })}
                    </select>
                    
                    { ( selectPostType==API_TYPES.POST_TYPE.ALL || selectCategory == SELECT_CATEGORY_ALL ) && 
                        <div className="error_msg">
                            <span>{
                                selectPostType==API_TYPES.POST_TYPE.ALL?"타입을 선택하세요"
                                :selectCategory == SELECT_CATEGORY_ALL?"카테고리를 선택하세요"
                                :""
                            }</span>
                        </div>
                    }
                </div>
            </div>
            <div>
                <div className="left">닉네임</div>
                <div className="right"><input type="text" className={"input_nickname" + (!!postData?" disable":"")} disabled={!!postData} value={nickNameStr} 
                    onChange={(e)=> { 
                        var text = e.target.value;
                        if( text.length > MAX_NICKNAME_LENGTH ) {
                            alert(TEXT_OVER_NICKNAME_LENGTH);
                        }
                        else {
                            setNickNameStr(text); 
                        }
                    }}/></div>
            </div>
            <div>
                <div className="left">제목</div>
                <div className="right"><input type="text" className="input_title" value={titleStr} onChange={(e)=> { setTitleStr(e.target.value); }}/></div>
            </div>
        </div>
    )
}

function ImageAreaComplete( props:any ) {
    const {imageDataList, setImageDataList} = props;

	const [loadingImage, setLoadingImage] = useState(false);
	const fileRef : any = useRef();

    const checkAbleGetImage = () => {
        var ablePosition = -1;

        for( var i=0; i<imageDataList.length; i++ ) {
            var d : UploadImageData = imageDataList[i];
            if( !d.blob && ( !d.url || ( d.url && d.deleteUrl ) ) ) {
                ablePosition = i;
                break;
            }
        }
        return ablePosition;
    }

    const clickedGetImage = () => {
        if( checkAbleGetImage() != -1 ) {
            document.getElementById("image_box_input")?.click();
        }
        else {
            alert("이미지는 최대 10개까지 추가 가능합니다");
        }
    }

	const onSelectFile = async (e : any) => {
		console.log("onSelectFile");
		setLoadingImage(true);
        setLoadingInImageData();

		getImageFile(e, (responseImage : ResponseImage)=>{
			//document.getElementById(uniqId)?.value = null;
			console.log("getImageFile receive");

			if( responseImage && responseImage.image ) {
				setImageData(responseImage);
			}
					
			if( fileRef && fileRef.current ) {
				fileRef.current.value = "";
			}
			setLoadingImage(false);
		})
	}

    const setLoadingInImageData = () => {
        var pos : number = checkAbleGetImage();
        var newList = imageDataList.map((i:UploadImageData, index:number)=>{
            if( index == pos ) {
                i.loading = true;
            }
            return i;
        })
        setImageDataList(newList);
    }

    const setImageData = ( responseImage : ResponseImage ) => {
        var newList = imageDataList.map((i:UploadImageData, index:number)=>{
            if( i.loading == true ) {
                i.loading = false;
                
                if( responseImage.image ) {
                    i.blob = responseImage.image.blob;
                    i.file = responseImage.image.file;
                }
                if( responseImage.preView ) {
                    i.preView = responseImage.preView;
                }
            }
            return i;
        })
        setImageDataList(newList);
    }

    return(
        <div className="image_area">
            <div className="title">
                <div className="title_str"><span>이미지</span></div>
                <div className="btn" onClick={clickedGetImage}><span>가져오기</span></div>
            </div>
            <div className="image_box">
                {
                    imageDataList.map((item:UploadImageData,index:number)=>{
                        
                        var url = item.blob?item.blob:!item.deleteUrl&&item.url?item.url:null;

                        const clickedDelete = () => {
                            var newList = imageDataList.map((i:UploadImageData, idx:number)=>{
                                if( index == idx ) {
                                    if( i.url ) i.deleteUrl = i.url;
                                    i.blob = null;
                                    i.file = null;
                                    i.preView = null;
                                }
                                return i;
                            })
                            setImageDataList(newList);
                            console.log("clickedDelete " , newList);
                        }

                        return( 
                            <div>
                                { !item.loading && url && <>
                                    <img src={url}/>
                                    <div className="delete" onClick={clickedDelete}>
                                        <AiFillCloseCircle size={20}/>
                                    </div>
                                </>}
                                
                                { item.loading &&
                                    <div className='loader'>
                                        <Oval 
                                            color="#00f" 
                                            height={20} 
                                            width={20}
                                            />
                                    </div>
                                }
                            </div> 
                        )
                    })
                }
            </div>
            <input
                ref={fileRef}
                type='file'
                id='image_box_input'
                name='images'
                hidden={true}
                onChange={onSelectFile}
                accept={INPUT_ACCEPT_TYPE}
				/>
        </div>
    )
}

function BottonButtonComponent(props:any) {
    const {onSave, onClickToList, requestingSave} = props;
	const navigate = useNavigate();
    
    const clickedCancel = () => {
        navigate(-1);
    }

    return(
        <div className='write_post_btn_box'>
            <div className='list' onClick={onClickToList}>  <span>목록</span> </div>
            <div className='save' onClick={onSave}>  
                { !requestingSave && <span>확인</span> }
                { requestingSave && 
                    <div className='loader'>
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                    </div> 
                }
            </div> 
            <div className='cancel' onClick={clickedCancel}>  <span>취소</span> </div> 
        </div>
    )
}

function getEmptyImageData() {
    return {
        url:null, 
        blob:null, 
        file:null,
        deleteUrl:null,
        loading:false,
        preView:null
    };
}

function getDefaultImageDataList() {
    const list : UploadImageData[] = [];
    for( var i=0; i<10; i++ ) {
        list.push(getEmptyImageData());
    }

    return list;
}

interface UploadImageData extends ImageData {
    deleteUrl:any,
    loading:boolean
    preView:ImageData|null
}