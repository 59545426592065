/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../sub/RightArea';
import LeftAd from '../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import StoryBestAreaComponent from './sub/StoryBestAreaComponent';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import StoryPostListComponent from './sub/StoryPostListComponent';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { RootState } from '../../redux_saga/modules';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../sub/Adsense';

const Story = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentCategory, setCurrentCategory] = useState(API_TYPES.CATEGORY_TYPE.ALL);
	
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
	
	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		if( c ) {
			try{ setCurrentCategory( parseInt(c) ); }catch(e){ console.log("e",e);}
		}
		else{
			setCurrentCategory( API_TYPES.CATEGORY_TYPE.ALL );
		}

		reqCategory();
		
		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
	}

	return (
		<Wrapper
			tabType={TAB_TYPE.STORY}
			rightCategoryData={
				!!storyCategoryList?{
					type:API_TYPES.POST_TYPE.STORY,
					currentCategory:!!currentCategory?currentCategory:API_TYPES.CATEGORY_TYPE.ALL,
					list:storyCategoryList
				}
				:null
			}>
			<MiddleComponent {...props} currentCategory={currentCategory}/>
		</Wrapper>
	);
};

export default Story;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.STORY_TOP} marginBottom={30}/>
			<StoryBestAreaComponent {...props}/>
			<StoryPostListComponent {...props}/>
		</div>
	)
}