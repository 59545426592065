/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import LeftAd from '../sub/LeftAd';
import RightArea from '../sub/RightArea';
import { getCookieLoginData } from '../../util/cookie_helper';
import PostListComponent from './sub/PostListComponent';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { useDispatch } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import { API_TYPES } from '../../apiAxios';
import { PAGE_URL } from '../../util/Page';
import TopAdComponent from '../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../sub/Adsense';

const Search = (props:any) => {
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	return (
		<Wrapper
			tabType={TAB_TYPE.NONE}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default Search;


function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>

			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.SEARCH} marginBottom={20}/>
			<PostListComponent {...props}/>
		</div>
	)
}