/****************************************************************************************
 * 
 *  메인화면 베스트 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch } from 'react-redux';
import { getCurrentSecondsTime, movePage } from '../../../util/Global';
import { IMG_URL_CHOICE } from '../../../util/ConstValue';
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';

function MainBestAreaComponent( props:any ) {
    const dispatch = useDispatch();
    const [storyList, setStoryList] = useState<any>(null);
    const [communityList, setCommunityList] = useState<any>(null);

    useEffect(()=>{
        reqList();

        return(()=>{})
    },[]);

    

    const reqList = () => {

        apiAxios.global.listMainBest( dispatch,  (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                setStoryList(resData.story_list);
                setCommunityList(resData.community_list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    // 베스트 글이 둘다 없을때는 표시 X
    if( (!storyList || storyList.length == 0) && (!communityList || communityList.length == 0) ) return null;

	return(
		<table className='home__best_area'>
            <tr>
                <td className='title'>사연 베스트</td>
                <td className='middle'/>
                <td className='title'>커뮤니티 베스트</td>
            </tr>
            <tr>
                <StoryBestComponent {...props} postList={storyList}/>
                <td className='middle'/>
                <CommunityBestComponent {...props} postList={communityList}/>
            </tr>
			
			
		</table>
	)
}
export default MainBestAreaComponent;

function StoryBestComponent( props:any ) {
    const {postList} = props;
    const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[]);

    const clickItem = (item:any, e:any) =>{

        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:API_TYPES.POST_LIST_TYPE.MAIN_BEST_STORY,
            post_type:API_TYPES.POST_TYPE.STORY,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var url = makeUrl(PAGE_URL.STORY_POST, params);
        movePage(navigate, e, url);
    }

	return(        
        <td className='box' valign={!!postList && postList.length > 0?'top':"middle"}> 
            { !!postList && postList.length > 0 &&
                <div className='list_area'>
                        <ul>
                            { postList.map((item:any, index:number)=>{

                                return (
                                    <li key={index} className={""} onClick={(e:any)=>{clickItem(item, e)}}>
                                        <PostItem {...props} item={item}/>
                                    </li>
                                )
                            })}
                        </ul>
                </div>
            }
            
            { (!postList || postList.length == 0) && <EmptyComponent name={"베스트 사연이 없습니다."}/>}
        </td>
	)
}

function CommunityBestComponent( props:any ) {
    const {postList} = props;
    const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[]);

    const clickItem = (item:any) =>{

        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:API_TYPES.POST_LIST_TYPE.MAIN_BEST_COMMUNITY,
            post_type:API_TYPES.POST_TYPE.COMMUNITY,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var url = makeUrl(PAGE_URL.COMMUNITY_POST, params);
        navigate(url);
    }

	return(
        <td className='box' valign={!!postList && postList.length > 0?'top':"middle"}> 
            { !!postList && postList.length > 0 &&
                <div className='list_area'>
                        <ul>
                            { postList.map((item:any, index:number)=>{

                                return (
                                    <li key={index} className={""} onClick={()=>{clickItem(item)}}>
                                        <PostItem {...props} item={item}/>
                                    </li>
                                )
                            })}
                        </ul>
                </div>
            }
            
            { (!postList || postList.length == 0) && <EmptyComponent name={"베스트 게시글이 없습니다."}/>}
        </td>
	)
}

function PostItem( props:any ) {
    const {item} = props;
    const isChoice = item.choice_show == API_TYPES.BOOLEAN.TRUE;
    const haveReplyNum = item.reply_num && item.reply_num > 0;
    
    const maxWidth = 400 + (isChoice?-1:30) + (haveReplyNum?0:30);

    return(
        <> 
            <div className='best_body_div' style={{maxWidth:maxWidth}}>
                <span className='cate_name'>{"["+item.cate_name+"]"}</span>      
                <span className='title'>{item.title}</span>     
            </div>

            { haveReplyNum && 
                <span className='reply'>{"(" + item.reply_num + ")"}</span> 
            }
            { isChoice && <img className="choice_image" src={IMG_URL_CHOICE}/> }
        </>
    )
}

function EmptyComponent( props:any ) {
    const { name } = props;
    
	return(
        <div className='empty'>
            <span>{name}</span>
        </div>
	)
}