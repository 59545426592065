/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../sub/RightArea';
import LeftAd from '../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS, ParamGlobalDataPost } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { RootState } from '../../redux_saga/modules';
import Wrapper from '../sub/Wrapper';
import PostBody from './sub/PostBody';
import { PAGE_MAIN, PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../util/Page';
import { getCookieLoginData } from '../../util/cookie_helper';
import { getDeletePostMsg } from '../../util/Global';
import ReplyBody from './sub/ReplyBody';
import BottomList from './sub/BottomList';
import PopupChoiceStory from '../popup/PopupChoiceStory';
import PopupDeletePost, { DeletePopupType } from '../popup/PopupDeletePost';
import PopupBlackList, { BalckListPopupData } from '../popup/PopupBlackList';
import TopAdComponent from '../sub/TopAdComponent';
import MiddleAdComponent from '../sub/MiddleAdComponent';
import { AD_PAGE_POSITION } from '../sub/Adsense';
import { USER_MANAGE_TYPE, checkAbleManageUser } from '../../util/AdminAccessHelper';

const Post = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

    const loginData = useSelector((state:RootState)=>state.data.loginData);

	const [categoryIdx, setCategoryIdx] = useState(-2);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [idx, setIdx] = useState(-1);
	const [postListType, setPostListType] = useState(API_TYPES.POST_LIST_TYPE.ALL);
	const [postType, setPostType] = useState(API_TYPES.POST_TYPE.ALL);
	const [postListSortType, setPostListSortType] = useState(API_TYPES.POST_LIST_SORT_TYPE.NONE);
	const [searchStr, setSearchStr] = useState<string|null>(null);
	const [searchType, setSearchType] = useState<number|null>(null);

	const [isAllList, setIsAllList] = useState<any>(false);
	const [isAllCategoryList, setIsAllCategoryList] = useState<any>(false);
	
	const [postData, setPostData] = useState<any>(null);
	const [replyTotalData, setReplyTotalData] = useState<any>(null);
	const [replyList, setReplyList] = useState<any>(null);
	const [postList, setPostList] = useState<any>(null);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	const [showChoicePopup, setShowChoicePopup] = useState(false);
	const [deletePopupData, setDeletePopupData] = useState<any>(null);
	const [blacklistPopupData, setBlacklistPopupData] = useState<BalckListPopupData|null>(null);
	const [replyDeleteData, setReplyDeleteData] = useState<any>(null);

	var userManageType : USER_MANAGE_TYPE = postType==API_TYPES.POST_TYPE.STORY?USER_MANAGE_TYPE.STORY
											:postType==API_TYPES.POST_TYPE.COMMUNITY?USER_MANAGE_TYPE.COMMUNITY
											:USER_MANAGE_TYPE.NONE;
	var adminAbleManage = checkAbleManageUser( loginData, userManageType, categoryIdx);
	
	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		const y = l_p.get("year");
		const i = l_p.get("idx");
		const plt = l_p.get("post_list_type");
		const pt = l_p.get("post_type");
		const plst = l_p.get("post_list_sort_type");
		const s = l_p.get("search");
		const st = l_p.get("searchType");


		var newCategory = -1;
		var newYear = (new Date()).getFullYear();
		var newIdx = -1;
		var newPostListType = API_TYPES.POST_LIST_TYPE.ALL;
		var newPostType = API_TYPES.POST_TYPE.ALL;
		var newPostListSortType = API_TYPES.POST_LIST_SORT_TYPE.NONE;
		var newSearch = null;
		var newSearchType = null;

		if( c ) 	{ try{ newCategory 			= parseInt(c); 		}catch(e){ console.log("e",e);} }
		if( y ) 	{ try{ newYear				= parseInt(y); 		}catch(e){ console.log("e",e);} }
		if( i ) 	{ try{ newIdx 				= parseInt(i); 		}catch(e){ console.log("e",e);} }
		if( plt ) 	{ try{ newPostListType 		= parseInt(plt);	}catch(e){ console.log("e",e);} }
		if( pt ) 	{ try{ newPostType 			= parseInt(pt); 	}catch(e){ console.log("e",e);} }
		if( plst ) 	{ try{ newPostListSortType	= parseInt(plst); 	}catch(e){ console.log("e",e);} }
		if( s ) 	{ try{ newSearch			= s; 				}catch(e){ console.log("e",e);} }
		if( st ) 	{ try{ newSearchType		= parseInt(st); 	}catch(e){ console.log("e",e);} }

		setIsAllList( newPostListType==API_TYPES.POST_LIST_TYPE.ALL );

		var isAllCate = false;
		isAllCate = newPostListType!=API_TYPES.POST_LIST_TYPE.ALL && (
			newPostListType==API_TYPES.POST_LIST_TYPE.ALL_COMMUNITY
			||newPostListType==API_TYPES.POST_LIST_TYPE.ALL_STORY
			||newPostListType==API_TYPES.POST_LIST_TYPE.DAY_BEST
			||newPostListType==API_TYPES.POST_LIST_TYPE.WEEK_BEST
			||newPostListType==API_TYPES.POST_LIST_TYPE.MONTH_BEST
			||newPostListType==API_TYPES.POST_LIST_TYPE.MAIN_BEST_STORY
			||newPostListType==API_TYPES.POST_LIST_TYPE.MAIN_BEST_COMMUNITY
		);
		setIsAllCategoryList( isAllCate );

		setCategoryIdx(newCategory);
		setYear(newYear);
		setIdx(newIdx);
		setPostListType(newPostListType);
		setPostType(newPostType);
		setPostListSortType(newPostListSortType);
		setSearchStr(newSearch);
		setSearchType(newSearchType);
		
		reqCategory( newPostType );

		reqData(newYear, newIdx, newPostListType, newPostType, newPostListSortType, newSearch, newSearchType);

		return(()=>{})
	},[location]);

	const reqCategory = ( postType : number ) => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
		if( postType == API_TYPES.POST_TYPE.COMMUNITY) {
			reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY) {
			reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
		}
	}

	const reqData = (year:number, idx:number, postListType:number, postType:number, postListSortType:number, search:string|null, searchType:number|null) => {

		const param : ParamGlobalDataPost = {
			idx:idx,
			year:year,
			post_type:postType,
			post_list_type:postListType,
			post_list_sort_type:postListSortType!=API_TYPES.POST_LIST_SORT_TYPE.NONE?postListSortType:null,
			search_type:searchType,
			total_search:search
		}

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);


				setPostData(resData.post_data);
				setReplyTotalData(resData.reply_total_data);
				setReplyList(resData.reply_list);
				setPostList(resData.post_list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
					navigate(PAGE_URL.MAIN, { replace:true });
				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {
					alert(getDeletePostMsg(API_TYPES.POST_USE_STATE.DELETE));
					navigate(PAGE_URL.MAIN, { replace:true });
				}
			}
		}

		apiAxios.global.dataPost(dispatch,param,callback);
	}

	const clickToList = ( isReplace : boolean = false) => {
        var url = "";
		
        if( postListType==API_TYPES.POST_LIST_TYPE.ALL ) {
            const params : PAGE_MAIN = {
                year:year,
                post_list_type:postListType
            }
            url = makeUrl(PAGE_URL.MAIN, params);
        }
        else {
            if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			const params : PAGE_POST_HOME_PARAMS = {
				category:postListType==API_TYPES.POST_LIST_TYPE.ALL_COMMUNITY?API_TYPES.CATEGORY_TYPE.ALL:categoryIdx,
				 year:year, 
				 page:1
			}
			url = makeUrl(PAGE_URL.COMMUNITY, params);
			}
			else if( postType == API_TYPES.POST_TYPE.STORY ) {
				const params : PAGE_POST_HOME_PARAMS = {
					category:postListType==API_TYPES.POST_LIST_TYPE.ALL_STORY?API_TYPES.CATEGORY_TYPE.ALL:categoryIdx,
					year:year,
					page:1
				}
				url = makeUrl(PAGE_URL.STORY, params);
			}
        }

        if( !!url ) {
            if( isReplace ) {
                navigate(url, {replace:true});
            }
            else {
                navigate(url);
            }
        }
	}

	var categoryList = null;
	var tabType = -1;

	if( isAllList == false && postType == API_TYPES.POST_TYPE.COMMUNITY ) {
		categoryList = communityCategoryList;
		tabType = TAB_TYPE.COMMUNITY;
	}
	else if( isAllList == false && postType == API_TYPES.POST_TYPE.STORY ) {
		categoryList = storyCategoryList;
		tabType = TAB_TYPE.STORY;
	}

	return (<>
		<Wrapper
			tabType={tabType}
			rightCategoryData={
				isAllList?null:
				!!categoryList?{
					type:postType,
					currentCategory:isAllCategoryList?-2:categoryIdx,
					list:categoryList
				}
				:null
			}
			popup={<>
				<PopupChoiceStory
					visible={showChoicePopup}
					onClose={()=>{ setShowChoicePopup(false); }}
					postData={postData}/>

				<PopupDeletePost
					visible={!!deletePopupData}
					onClose={()=>{ setDeletePopupData(null); }}
					data={deletePopupData?deletePopupData.data:null}
					type={deletePopupData?deletePopupData.type:null}
					postType={postType}
					onDelete={()=>{
						if( deletePopupData.type == DeletePopupType.POST ) {
							clickToList(true);
						}
						else if( deletePopupData.type == DeletePopupType.REPLY ) {
							setReplyDeleteData(deletePopupData.data);
							setDeletePopupData(null);
						}
					}}
					onBlackList={()=>{
						setBlacklistPopupData({
							data:deletePopupData.data,
							type:deletePopupData.type,
							userIdx:deletePopupData.data.user_idx
						});
						setDeletePopupData(null);
					}}/>
				<PopupBlackList
					visible={!!blacklistPopupData}
					onClose={()=>{ setBlacklistPopupData(null); }}
					data={blacklistPopupData?blacklistPopupData.data:null}
					type={blacklistPopupData?blacklistPopupData.type:null}
					userIdx={blacklistPopupData?blacklistPopupData.userIdx:null}
					postType={postType}
					onComplete={(isAdd:boolean, isDelete:boolean)=>{ 
						if( isAdd ) {
							if( isDelete ) {
								if( blacklistPopupData && blacklistPopupData.type == DeletePopupType.POST ) {
									clickToList(true);
								}
								else if( blacklistPopupData && blacklistPopupData.type == DeletePopupType.REPLY ) {
									setReplyDeleteData(blacklistPopupData.data);
								}
								alert("블랙리스트 추가 및 삭제 완료");
							}
							else {
								alert("블랙리스트 추가 완료");
							}
						}
						else {
							alert("블랙리스트 취소 완료");
						}
						setBlacklistPopupData(null); 
					}}
					/>
			</>}>
			<MiddleComponent 
				{...props} 
				year={year} 
				idx={idx} 
				isAllList={isAllList}
				isAllCategoryList={isAllCategoryList}
				postListType={postListType} 
				postType={postType} 
				postListSortType={postListSortType} 
				postData={postData}  
				setPostData={setPostData}
				replyTotalData={replyTotalData}
				setDeletePopupData={setDeletePopupData}
				setShowChoicePopup={setShowChoicePopup}
				replyList={replyList}
				postList={postList}
				setPostList={setPostList}
				categoryIdx={categoryIdx}
				replyDeleteData={replyDeleteData}
				setReplyDeleteData={setReplyDeleteData}
				onClickToList={clickToList}
				searchStr={searchStr}
				searchType={searchType}
				adminAbleManage={adminAbleManage}
				onClickBottomList={()=>{
					setPostData(null);
					setReplyTotalData(null);
					setReplyList(null);
					setPostList(null);
				}}
				/>
		</Wrapper>
		</>
	);
};

export default Post;

function MiddleComponent( props:any ) {
	const {postData, onClickBottomList, postList, setPostList} = props;

    useEffect(()=>{

        return(()=>{})
    },[]);

	if( !postData ) return;

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.POST_READ_TOP} marginBottom={20}/>
			<PostBody {...props}/>
			<MiddleAdComponent {...props} pagePosition={AD_PAGE_POSITION.POST_READ_MIDDLE}/>
			<ReplyBody {...props} onAddReply={()=>{
				var newList = postList.map((i:any, index:number)=>{
					if( i.idx == postData.idx ) {
						i.reply_num = (i.reply_num*1) + 1;
					}
					return i;
				})
				setPostList(newList);
			}}/>
			<BottomList {...props} onClickBottomList={onClickBottomList} />
		</div>
	)
}