/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../sub/TopTab';
import LeftAd from '../../sub/LeftAd';
import RightArea from '../../sub/RightArea';
import { getCookieLoginData } from '../../../util/cookie_helper';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch } from 'react-redux';
import Wrapper from '../../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../sub/MobileWrapper';

const MobilePersonal = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

    return (
        <MobileWrapper
            tabType={MOBILE_TAB_TYPE.NONE}
            hideBottomCompanyInfo={true}
            hideNotice={true}>
            <MiddleComponent 
                {...props} />
        </MobileWrapper>
    );
};

export default MobilePersonal;


function MiddleComponent( props:any ) {

	return( <div style={{backgroundColor:"#fff", paddingBottom:50}}>
        
        <TopComp {...props}/>

        <ProvisionBox {...props} body={getItem(1)} title={"제1조 (개인정보의 처리 목적)"}/>
        <ProvisionBox {...props} body={getItem(2)} title={"제2조 (개인정보의 처리 및 보유 기간)"}/>
        <ProvisionBox {...props} body={getItem(3)} title={"제3조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)"}/>
        <ProvisionBox {...props} body={getItem(4)} title={"제4조 (처리하는 개인정보의 항목 작성)"}/>
        <ProvisionBox {...props} body={getItem(5)} title={"제5조 (개인정보의 안전성 확보 조치)"}/>
        <ProvisionBox {...props} body={getItem(6)} title={"제6조 (개인정보 보호책임자)"}/>
        <ProvisionBox {...props} body={getItem(7)} title={"제7조 (개인정보 열람청구)"}/>
        <ProvisionBox {...props} body={getItem(8)} title={"제8조 (권익침해 구제방법)"}/>
        <ProvisionBox {...props} body={getItem(9)} title={"제9조 (개인정보 처리방침 변경)"}/>
    </div>
	)
}

function TopComp(props:any) {

    return(
        <div className='mobile_personal_top'>
            <div className='title'>
                <span>개인정보 처리방침</span>
            </div>
            <div className='box'>
                <span>
                    {"모여봐는 고객의 개인정보를 매우 소중하게 생각하며 고객의 개인정보를 가장 효과적이고 안전하게 보호하기 위하여 최선의 노력을 다하고 있습니다. 모여봐는 개인정보보호 관련 주요 법률『정보통신망이용촉진 및 정보보호등에관한법률』을 비롯하여 정보통신부가 제정한 『개인정보취급지침』등 개인정보보호 관련 각종 법규를 준수하고 있습니다."}
                </span>
            </div>
            <div className='information'>
                <span>
                    {"모여봐는 모여봐 회원으로서 제공하신 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 개인정보보호방침을 제정하여 준수하고 있음과 그 내용을 알려드립니다. 모여봐는 개인정보보호정책을 홈페이지 첫 화면에 공개함으로써 고객이 언제나 용이하게 보실 수 있도록 조치하고 있으며 개인정보보호정책의 지속적인 개선을 위하여 개인정보보호정책을 개정하는데 필요한 절차를 정하고 있습니다. "}
                </span>
            </div>
        </div>
    )
}

function ProvisionBox( props:any ) {
    const {title, body} = props;

    return (
        <div className='mobile_personal_provision_box'>
            <div className='title'>{title}</div>
            <div className='item'>{body}</div>
        </div>
    )
}

function getItem( num:number) {
    var text = "";

    if( num == 1 ) {
        text += "< 스튜디오 아이스 >모여봐 (모여봐 이하 ’서비스’)은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.";
        text += "\n\n1. 서비스 이용에 따른 본인확인, 개인 식별, 비인가 사용 방지, 가입 의사 확인";
        text += "\n2. 불만 처리, 민원처리, 고지사항 전달, 기타 의사소통을 위한 절차 및 이벤트, 개인 맞춤 서비스, 연령별 서비스, 인구통계학적 분석 등 정보의 전달을 위한 절차에 이용";
    }
    else if( num == 2 ) {
        text += "< 스튜디오 아이스 >은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.";
        text += "\n각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.";
        
        text += "\n\n1. 회원 탈퇴 시 파기 처리";
        text += "\n2. 11개월 동안 로그인 기록이 없는 회원은 자동 탈퇴 처리";
    }
    else if( num == 3 ) {
        text += "1. 정보주체는 스튜디오 아이스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.";

        text += "\n\n2. 제1항에 따른 권리 행사는 스튜디오 아이스에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 스튜디오 아이스은(는) 이에 대해 지체 없이 조치하겠습니다.";
        
        text += "\n\n3. 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.";
        
        text += "\n\n4. 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.";
        
        text += "\n\n5. 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.";
        
        text += "\n\n6. 스튜디오 아이스은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.";
    }
    else if( num == 4 ) {
        text += "< 스튜디오 아이스 >은(는) 다음의 개인정보 항목을 처리하고 있습니다.";

        text += "\n\n1. 회원가입 시";
        text += "\n 모여봐 개인 식별 코드, 휴대전화번호, 비밀번호, 로그인ID, 이름, 이메일, 닉네임";

        text += "\n\n2. 서비스 이용 시";
        text += "\n서비스 이용 기록, IP 주소, 기기 정보, 결제 내역, 불량 이용 기록, 성인 인증 값, 쿠키등";
        text += "\n※ 쿠키는 해당 브라우저 또는 기기 등에서 수집하는 것으로 회사가 수집하는 것은 아닙니다.";

        text += "\n\n3. 서비스 이용 시 기기 정보 상세";
        text += "\n· PC웹 : Window, Mac 등 OS 버전 및 웹 브라우저(Chrome, Safari 등) 버전 정보";
        text += "\n· 모바일웹: 단말기 OS 버전 및 웹 브라우저(Crome, Safari 등) 버전 정보";
    }
    else if( num == 5 ) {
        text += "< 스튜디오 아이스 >은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.";

        text += "\n\n1. 내부관리계획의 수립 및 시행";
        text += "\n개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.";
        
        text += "\n\n2. 접속기록의 보관 및 위변조 방지";
        text += "\n개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며,다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유식별정보 또는 민감정보를 처리하는 경우에는 2년이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안기능을 사용하고 있습니다.";
        
        text += "\n\n3. 개인정보에 대한 접근 제한";
        text += "\n개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.";
    }
    else if( num == 6 ) {
        text += "1. 스튜디오 아이스 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.";

        text += "\n\n▶ 개인정보 보호책임자";
        text += "\n성명 :김찬수";
        text += "\n직책 :대표";
        text += "\n연락처 :070-7526-0120, absolutecfo@gmail.com";
        
        text += "\n\n2. 정보주체께서는 스튜디오 아이스 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 스튜디오 아이스 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.";
    }
    else if( num == 7 ) {
        text += "정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. < 스튜디오 아이스 >은(는) 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.";

        text += "\n\n▶ 개인정보 열람청구 접수·처리 부서";
        text += "\n담당자 : 김찬수";
        text += "\n연락처 : 070-7526-0120, absolutecfo@gmail.com";
    }
    else if( num == 8 ) {
        text += "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.";

        text += "\n\n1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)";
        text += "\n2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)";
        text += "\n3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)";
        text += "\n4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)";
        
        text += "\n\n「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.";
        
        text += "\n\n※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.";
    }
    else if( num == 9 ) {
        text += "이 개인정보처리방침은 2024년 1월 1일 부터 적용됩니다.";
    }



    return text;
}