/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import { ADMIN_SUT_TAB_DATA } from '../sub/AdminSubTopTab';
import { ADMIN_SUB_TAP_COMMUNITY, PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import Category from './sub/Category';
import DeletePost from './sub/DeletePost';
import { API_TYPES } from '../../../apiAxios';
import DeleteReply from './sub/DeleteReply';
import ReportPost from './sub/ReportPost';
import ReportReply from './sub/ReportReply';
import PopupCategoryAdd from '../../popup/PopupStoryCategory';
import PopupCommunityCategory from '../../popup/PopupCommunityCategory';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, AdminAccessResult, checkAbleManageAdminPage, makeAdminManageData } from '../../../util/AdminAccessHelper';
import { RootState } from '../../../redux_saga/modules';
import PopupDeletePost, { DeletePopupType } from '../../popup/PopupDeletePost';
import PopupBlackList, { BalckListPopupData } from '../../popup/PopupBlackList';

const SUB_TAB_DATA_LIST_HAVE_CATE : ADMIN_SUT_TAB_DATA[] = [
	{type:ADMIN_SUB_TAP_COMMUNITY.CATEGORY		, name:"카테고리"},
	{type:ADMIN_SUB_TAP_COMMUNITY.DELETE_POST	, name:"삭제글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.DELETE_REPLY	, name:"삭제댓글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.REPORT_POST	, name:"신고글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.REPORT_REPLY	, name:"신고댓글"}
];

const SUB_TAB_DATA_LIST : ADMIN_SUT_TAB_DATA[] = [
	{type:ADMIN_SUB_TAP_COMMUNITY.DELETE_POST	, name:"삭제글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.DELETE_REPLY	, name:"삭제댓글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.REPORT_POST	, name:"신고글"},
	{type:ADMIN_SUB_TAP_COMMUNITY.REPORT_REPLY	, name:"신고댓글"}
];

const AdminCommunity = (props:any) => {
	const [subTabType, setSubTabType] = useState(ADMIN_SUB_TAP_COMMUNITY.CATEGORY);
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const[ showCatagoryPopup, setShowCategoryPopup ] = useState(false);
	const[ catagoryPopupData, setCatagoryPopupData ] = useState(null);
	const[ refreshCategoryList, setRefreshCategoryList] = useState(false);
	const[ showCategory, setShowCategory] = useState(false);
	
	const [deletePopupData, setDeletePopupData] = useState<any>(null);
	const [blacklistPopupData, setBlacklistPopupData] = useState<BalckListPopupData|null>(null);
	const[ refreshReportPostDelete, setRefreshReportPostDelete ] = useState<any>(false);
	const[ refreshReportReplyDelete, setRefreshReportReplyDelete ] = useState<any>(false);

	useEffect(()=>{
		var show = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.COMMUNITY_CATEGORY)).accessType == ADMIN_ACCESS_TYPE.ALL;
		setShowCategory(show);
		/*if( show == false ) {
			setSubTabType(ADMIN_SUB_TAP_COMMUNITY.DELETE_POST);
		}
		else {
			setSubTabType(ADMIN_SUB_TAP_COMMUNITY.CATEGORY);
		}*/
		return(()=>{})
	},[loginData]);

	const openPostPage = ( idx : number, year : number, cate_idx : number ) => {

		var fullUrl = "";
		const params : PAGE_POST_PARAMS = {
			idx:idx,
			year:year,
			post_list_type:API_TYPES.POST_LIST_TYPE.NOMAL,
			post_type:API_TYPES.POST_TYPE.COMMUNITY,
			category:cate_idx,
			search:null,
			searchType:null
		}
		fullUrl = makeUrl(PAGE_URL.COMMUNITY_POST, params);
		window.open(fullUrl);
	}

	const openCategoryAddPopup = ( data : any ) => {
		setShowCategoryPopup(true);
		setCatagoryPopupData(data);
	}

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.COMMUNITY}
			defaultSubTab={ADMIN_SUB_TAP_COMMUNITY.CATEGORY}
			setSubTabType={setSubTabType}
			subTabType={subTabType}
			pageUrl={PAGE_URL.ADMIN_COMMUNITY}
			tabDataList={showCategory?SUB_TAB_DATA_LIST_HAVE_CATE:SUB_TAB_DATA_LIST}
			popup={<>
				<PopupCommunityCategory
					visible={showCatagoryPopup}
					onClose={()=>{ setShowCategoryPopup(false); }}
					categoryData={catagoryPopupData}
					onSuccess={()=>{setShowCategoryPopup(false); setRefreshCategoryList(true);}}/>

				<PopupDeletePost
					visible={!!deletePopupData}
					onClose={()=>{ setDeletePopupData(null); }}
					data={deletePopupData?deletePopupData.data:null}
					type={deletePopupData?deletePopupData.type:null}
					postType={API_TYPES.POST_TYPE.COMMUNITY}
					onDelete={()=>{
						if( deletePopupData.type == DeletePopupType.POST ) {
							setRefreshReportPostDelete(true);
							setDeletePopupData(null);
						}
						else if( deletePopupData.type == DeletePopupType.REPLY ) {
							setRefreshReportReplyDelete(true);
							setDeletePopupData(null);
						}
					}}
					onBlackList={()=>{
						setBlacklistPopupData({
							data:deletePopupData.data,
							type:deletePopupData.type,
							userIdx:deletePopupData.data.user_idx
						});
						setDeletePopupData(null);
					}}/>

				<PopupBlackList
					visible={!!blacklistPopupData}
					onClose={()=>{ setBlacklistPopupData(null); }}
					data={blacklistPopupData?blacklistPopupData.data:null}
					type={blacklistPopupData?blacklistPopupData.type:null}
					userIdx={blacklistPopupData?blacklistPopupData.userIdx:null}
					postType={API_TYPES.POST_TYPE.COMMUNITY}
					onComplete={(isAdd:boolean, isDelete:boolean)=>{ 
						if( isAdd ) {
							if( isDelete ) {
								if( blacklistPopupData && blacklistPopupData.type == DeletePopupType.POST ) {
									setRefreshReportPostDelete(true);
								}
								else if( blacklistPopupData && blacklistPopupData.type == DeletePopupType.REPLY ) {
									setRefreshReportReplyDelete(true);
								}
								alert("블랙리스트 추가 및 삭제 완료");
							}
							else {
								alert("블랙리스트 추가 완료");
							}
						}
						else {
							alert("블랙리스트 취소 완료");
						}
						setBlacklistPopupData(null); 
					}}
					/>
			</>}>

			<MiddleComponent 
				{...props} 
				subTabType={subTabType} 
				openPostPage={openPostPage}
				openCategoryAddPopup={openCategoryAddPopup}

				refreshCategoryList={refreshCategoryList}
				setRefreshCategoryList={setRefreshCategoryList}
				
				setDeletePopupData={setDeletePopupData}
				refreshReportPostDelete={refreshReportPostDelete}
				setRefreshReportPostDelete={setRefreshReportPostDelete}
				refreshReportReplyDelete={refreshReportReplyDelete}
				setRefreshReportReplyDelete={setRefreshReportReplyDelete}
				/>
			
		</Wrapper>
	);
};

export default AdminCommunity;


function MiddleComponent( props:any ) {
	const { subTabType } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);

	var result : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.COMMUNITY_LIST));
	var categorys : any = result.accessType == ADMIN_ACCESS_TYPE.PART?result.categorys:null;

	return(
		<div className='middle_area'>
			{ subTabType == ADMIN_SUB_TAP_COMMUNITY.CATEGORY 		&& <Category {...props} accessCatagorys={categorys}/>}
			{ subTabType == ADMIN_SUB_TAP_COMMUNITY.DELETE_POST 	&& <DeletePost {...props} accessCatagorys={categorys}/>}
			{ subTabType == ADMIN_SUB_TAP_COMMUNITY.DELETE_REPLY 	&& <DeleteReply {...props} accessCatagorys={categorys}/>}
			{ subTabType == ADMIN_SUB_TAP_COMMUNITY.REPORT_POST 	&& <ReportPost {...props} accessCatagorys={categorys}/>}
			{ subTabType == ADMIN_SUB_TAP_COMMUNITY.REPORT_REPLY 	&& <ReportReply {...props} accessCatagorys={categorys}/>}
		</div>
	)
}