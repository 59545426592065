
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import ButtonDefault from '../../../sub/ButtonDefault';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import AdminPageComponent from '../../sub/AdminPageComponent';
import { ADMIN_SUB_TAP_SETTING, PAGE_ADMIN_INQUIRY, PAGE_ADMIN_TABOO, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import { getParamData, makeInsertTimeStr, movePage } from '../../../../util/Global';
import { RootState } from '../../../../redux_saga/modules';
import { ADMIN_MANAGE_TYPE, checkAccessRePage, makeAdminManageData } from '../../../../util/AdminAccessHelper';
import { Oval } from 'react-loader-spinner';
import AdminListItemLoading from '../../sub/AdminListItemLoading';


const TabooList = (props:any) => {
    const {refreshTaboo, setRefreshTaboo} = props;
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
    const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

    useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );

        setPage( newPage );
        setSearchText( newSearch );

        reqList( newSearch, newPage );
        
        return(()=>{})
    },[location]);


    useEffect(()=>{

        if( refreshTaboo ) {
            if( refreshTaboo.idx == -1 ) {
                reqList( null, null );
            }
            else if( refreshTaboo.idx  > 0 ) {
                var newList = itemList.map((item:any,index:number)=>{
                    if( item.idx == refreshTaboo.idx ) {
                        item.name = refreshTaboo.name;
                    }
                    return item;
                });
                setItemList(newList);
            }
            setRefreshTaboo(null);
        }
        return(()=>{})
    },[refreshTaboo]);

    const reqList = ( total_search:string|null, page:number|null ) => {

        apiAxios.admin.listTaboo( dispatch, total_search, page, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_TABOO => {
        //export interface PAGE_ADMIN_INQUIRY { type:number|null, read:number|null, page:number|null, search:string|null }
        var params : PAGE_ADMIN_TABOO = {
            page        :1,
            search      :!!searchText?searchText:null, 
            tab         :ADMIN_SUB_TAP_SETTING.TABOO
        }
        if( !!param ) {
            if( param == "search" )  params["search"]  = !value?null:value;
        }

        return params;
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const changePage = ( params : PAGE_ADMIN_TABOO ) => {
        var url = makeUrl(PAGE_URL.ADMIN_SETTING, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_taboo_list_box'>
            <ListTop 
                {...props}
                onSearch={onSearch}
                />
			<ul>
                <li className={'title' + titleBg}>
                    <div className='name'>금지어</div>
                    <div className='state'>상태</div>
                    <div className='edit'>수정</div>
                </li>
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index} index={index} itemList={itemList} setItemList={setItemList}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>문의사항 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_TABOO = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}

					var url = makeUrl(PAGE_URL.ADMIN_SETTING, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default TabooList;

function ListTop( props:any ) {
    const {openTabooPopup} = props;
    return(
        <div className='list_top'>
            
            <AdminSearchBox {...props} placeholder={"금지어"}/>
            <ButtonDefault 
                {...props}
                className="add_btn"
                value={"추가"}
                onClick={()=>{
                    openTabooPopup( null );
                }}
                />
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openTabooPopup, index, itemList, setItemList} = props;
	const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    var stateStr = item.use_state==API_TYPES.USE_STATE.ABLE?"사용중":"정지중";
    var state_class = item.use_state==API_TYPES.USE_STATE.ABLE?"color_blue":"color_red";

    const clickedUpdateState = () => {
        if( requesting ) return;
        var title = item.name + "을 ";
        if( item.use_state==API_TYPES.USE_STATE.ABLE ) {
            title += "사용정지로 변경하시겠습니까?";
        }
        else {
            title += "사용상태로 변경하시겠습니까?";
        }
        
		var returnValue = window.confirm(title);
        if( returnValue ) {
            setRequesting(true);
            var use_state = item.use_state==API_TYPES.USE_STATE.ABLE?API_TYPES.USE_STATE.DISABLE:API_TYPES.USE_STATE.ABLE;
            apiAxios.data_middleware.updateUseState(dispatch, itemList, setItemList, API_TYPES.TABLE_TYPE.TABOO, item.idx, null, index, use_state, ()=>{
                setRequesting(false);
            });
        }
    }

    return(
        <li className='item'>
            <div className='name'>{item.name}</div>
            <div className={'state pointer ' + state_class} onClick={clickedUpdateState}>{
                requesting?<AdminListItemLoading/>:stateStr
            }</div>
            <div className={'edit pointer'} onClick={()=>{ openTabooPopup(item); }}>{"수정"}</div>
        </li>
    )
}
/*
<div className='name'>금지어</div>
<div className='state'>등록일</div>
<div className='edit'>수정</div>*/