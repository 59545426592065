
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminListBlackList, ParamAdminListUser } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, isToday, makeInsertTimFullStr, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { ADMIN_SUB_TAP_USER, PAGE_ADMIN_ACCOUNT_ADMIN_USER, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import { isMobile } from 'react-device-detect';


const BlackList = (props:any) => {
    const {refreshBlackListData, setRefreshBlackListData} = props;

    const [itemList, setItemList] = useState<any>(null);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectState, setSelectState] = useState(FILTER_VALUE_ALL);
    const [selectSort, setSelectSort] = useState(API_TYPES.POST_LIST_SORT_TYPE.INSERT);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newSort         = getParamData(l_p, "sort", API_TYPES.POST_LIST_SORT_TYPE.INSERT );
        var newState         = getParamData(l_p, "state", FILTER_VALUE_ALL );

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );

        setSelectSort(newSort);
        setSelectState(newState);
        setSearchText(newSearch);
        setPage(newPage);

        reqList(newSort, newState, newSearch, newPage);

        return(()=>{})
    },[location]);
    
	useEffect(()=>{

        if( refreshBlackListData ) {
            reqList(selectSort, selectState, searchText, page);

            setRefreshBlackListData(false);
        }

        return(()=>{})
    },[refreshBlackListData]);


    const reqList = ( sort:number, use_state:number|null, searchText:string|null, page:number ) => {

        var param : ParamAdminListBlackList = {
            sort            :sort,
            use_state       :use_state==FILTER_VALUE_ALL?null:use_state,
            total_search    :searchText,
            page            :page
        }

        if( param.use_state == FILTER_VALUE_ALL ) param.use_state = null;

        apiAxios.admin.listBlackList( dispatch, param, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_ACCOUNT_ADMIN_USER => {
        var params : PAGE_ADMIN_ACCOUNT_ADMIN_USER = {
            tab         :ADMIN_SUB_TAP_USER.BLACKLIST, 
            state       :selectState==FILTER_VALUE_ALL?null:selectState,
            sort        :selectSort,
            page        :1,
            search      :!!searchText?searchText:null
        }
        if( !!param ) {
            if( param == "sort" )           params["sort"]      = value;
            else if( param == "state" )     params["state"]     = value;
            else if( param == "search" )    params["search"]    = value;
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_ACCOUNT_ADMIN_USER ) => {
        var url = makeUrl(PAGE_URL.ADMIN_USER, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_account_blacklist_post_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                selectState={selectState}
                setSelectState={(value:any)=>{
                    setSelectState(value);
                    
                    changePage( getMovePageParam( "state", value ) );
                }}
                
                selectSort={selectSort}
                setSelectSort={(value:any)=>{
                    setSelectSort(value);
                    
                    changePage( getMovePageParam( "sort", value ) );
                }}
                />
			<ul>
                { isMobile != true && 
                <li className={'title' + titleBg}>
                    <div className='insert_time'>등록일</div>
                    <div className='end_time'>종료일</div>
                    <div className={'state'}>상태</div>
                    <div className={'id'}>아이디</div>
                    <div className={'name'}>이름</div>
                    <div className={'nickname'}>닉네임</div>
                    <div className='number'>전화번호</div>
                    <div className={'email'}>이메일</div>
                    <div className={'reason'}>차단사유</div>
                    <div className={'cancel_reason'}>취소사유</div>
                    <div className={'cancel'}>취소처리</div>
                </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>블랙리스트 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_ACCOUNT_ADMIN_USER = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}

					var url = makeUrl(PAGE_URL.ADMIN_USER, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default BlackList;

function ListTop( props:any ) {
    const {
        selectState, setSelectState,
        selectSort, setSelectSort,
        onSearch, searchText
    } = props;

    const stateOption : OptionData[] = [
        ALL_FilTER_DATA,
        {value:API_TYPES.USE_STATE.ABLE, name:"차단중"}, 
        {value:API_TYPES.USE_STATE.DISABLE, name:"취소됨"}, 
        {value:API_TYPES.USE_STATE.EXPIRATION, name:"만료됨"}
    ];

    const sortOption : OptionData[] = [
        {value:API_TYPES.POST_LIST_SORT_TYPE.INSERT, name:"등록순"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.END, name:"종료일"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.ID, name:"아이디"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.NAME, name:"이름"}
    ];

    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
                <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
                <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
            </div>
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"아이디&이름&닉네임&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>
            </div>
        </>)
    }

    return(
        <div className='list_top'>
            <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
            
            <AdminSearchBox {...props} placeholder={"아이디&이름&닉네임&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>

            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, setBlacklistPopupData} = props;

    const getBlacklistStr = () => {
        if( item.blacklist_infinite == API_TYPES.BOOLEAN.TRUE ) {
            return "블랙리스트\n영구차단";
        }
        else {
            return "블랙리스트\n" + item.blacklist_block_day + "일차단";
        }
    }

    const stateStr = item.use_state==API_TYPES.USE_STATE.ABLE&&item.blacklist_idx>0?getBlacklistStr()
                    :item.use_state==API_TYPES.USE_STATE.ABLE?"차단중"
                    :item.use_state==API_TYPES.USE_STATE.DISABLE?"취소됨"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?"탈퇴":"";

    const stateClass = item.use_state==API_TYPES.USE_STATE.DISABLE?" color_red"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?" color_red"
                    :item.blacklist_idx>0?" color_red two_line":"";


    var eDate = null;
    if( item.end > 0 ) {
        eDate = new Date();
        eDate.setTime(item.end*1000);
    }
    var endTime = eDate?makeInsertTimFullStr(eDate):".";

    const stateData = getStateData(item);

    const clickedBlackList = () => {
        if( !!stateData.class ) {
            setBlacklistPopupData({
                data:null,
                type:null,
                userIdx:item.user_idx
            });
        }
    }

    if( isMobile ) {
        return(
            <li className="item" style={{height:(160 - (!!item.cancel_reason?0:25))}}>
                <div className='sub'>
                    <div className='id'>{item.user_id}</div>
                    <div className='divide'>·</div>
                    <div className={'state' + stateData.class}>{stateData.mobStr}</div>
                    <div className='divide'>·</div>
                    <div className='name'>{item.user_name}</div>
                    <div className='divide'>·</div>
                    <div className='nickname'>{item.user_nickname}</div>
                </div>
                <div className='sub height_25'>
                    <div className={'insert_time'}>{makeInsertTimFullStr(item.insert_time)}</div>
                    <div className='divide'>~</div>
                    <div className={'end_time' + stateData.class}>{endTime}</div>
                </div>
                <div className='sub height_25'>
                    <div className='number'>{item.user_number}</div>
                    <div className='divide'>·</div>
                    <div className='email'>{item.user_email}</div>
                </div>
                { !!item.block_reason &&
                    <div className='sub height_25 sub_bg top_border'>
                        <div className='reason'>{"차단사유 : "+item.block_reason}</div>
                    </div>
                }
                { !!item.cancel_reason &&
                    <div className='sub height_25 sub_bg'>
                        <div className='reason'>{"취소사유 : "+item.cancel_reason}</div>
                    </div>
                }
                <div className='sub button_area'>
                    <div className={'cancel' + (!stateData.class?"":" pointer")} onClick={clickedBlackList}>{!stateData.class?".":"취소"}</div>
                </div>
            </li>
        )
    }

    return(
        <li className="item">
            <div className={'insert_time' + (isToday(item.insert_time)?'':' two_line')}>{makeInsertTimFullStr(item.insert_time)}</div>
            <div className={'end_time' + (isToday(item.end)||!eDate ?'':' two_line')}>{endTime}</div>
            <div className={'state two_line' + stateData.class}>{stateData.str}</div>
            <div className='id'>{item.user_id}</div>
            <div className='name'>{item.user_name}</div>
            <div className='nickname'>{item.user_nickname}</div>
            <div className='number'>{item.user_number}</div>
            <div className='email'>{item.user_email}</div>
            <div className='reason two_line'>{item.block_reason}</div>
            <div className='cancel_reason'>{item.cancel_reason}</div>
            <div className={'cancel' + (!stateData.class?"":" pointer")} onClick={clickedBlackList}>{!stateData.class?".":"취소"}</div>
        </li>
    )
}

const getStateData = ( item:any ) => {
    var d = {str:"", class:""};
    var firstStr = "";
    var secondStr = "";
    var className = "";

    var cDate = new Date();
    var isFinish = Math.floor(cDate.getTime()/1000) > item.end;
    
    if( item.use_state==API_TYPES.USE_STATE.ABLE ) {
        if( item.infinite == API_TYPES.BOOLEAN.TRUE ) { firstStr = "차단중"; className = " color_red"; }
        else {
            if( isFinish ) { firstStr = "만료"; }
            else { firstStr = "차단중"; className = " color_red"; }
        }
    }
    else {
        firstStr = "취소됨";
    }

    if( item.infinite == API_TYPES.BOOLEAN.TRUE ) {
        secondStr = "(영구정지)";
    }
    else {
        secondStr = "(" + item.block_day + "일)";
    }
    
    return {str:firstStr+"\n"+secondStr, class:className, mobStr:firstStr+secondStr};
}