/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { makeDateFromTimeStamp, makeInsertTimeStr } from '../../../util/Global';
import { IMG_URL_CHOICE } from '../../../util/ConstValue';
import { PAGE_NOTICE_HOME_PARAMS, PAGE_NOTICE_POST_PARAMS, PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';

const MobileNoticeBox = (props:any) => {
    const{marginTop, marginBottom} = props;
	
	const navigate = useNavigate();
	const dispatch = useDispatch();
    const [noticeList, setNoticeList] = useState<any>(null);
    const location = useLocation();

    useEffect(()=>{
        reqNoticeList();

        return(()=>{})
    },[location]);
    

	const reqNoticeList = () => {

		apiAxios.global.listNotice(dispatch, API_TYPES.POST_TYPE.ALL, null, null, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				setNoticeList(resData.list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
	}

    if( !noticeList )   return null;

    const clickedNotice = ( item:any ) => {
        var fullUrl = "";

        if( item ) {
            const params : PAGE_NOTICE_POST_PARAMS = {
                idx:item.idx,
                post_type:API_TYPES.POST_TYPE.ALL, 
                category:0
            }
            fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
        }
        else {
            const params : PAGE_NOTICE_HOME_PARAMS = {
                page:1
            }
            fullUrl = makeUrl(PAGE_URL.NOTICE, params);
        }
        navigate(fullUrl);
    }

    return(
        <div className='mobile_notice_box' style={{marginTop:marginTop}}>
            <div className='title'>
                <span>공지사항</span>
                <div className='right_area'>
                    <span onClick={()=>{
                        clickedNotice(null);
                    }}>전체보기</span>
                </div>
            </div>
                <ul>
                    { noticeList.map((item:any, index:number)=>{
                        return (
                            <li key={index} onClick={()=>{
                                clickedNotice(item);
                                }}>
                                <div>
                                    <div className='title'>{item.title}</div>
                                    <div className='date'>{makeInsertTimeStr(item.insert_time)}</div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
        </div>
    )
};

export default MobileNoticeBox;