
import { AxiosError, AxiosResponse } from "axios";
import {RESPONSE_CODE, TABLE_TYPE} from "./type";
import { getResponseSuccessData } from "./util";
import apiAxios, { API_TYPES, API_UTILS } from "..";
import { TEXT_BLOCK, TEXT_BLOCK_CANCEL, TEXT_CANT_ADMIN_LOGIN, TEXT_PLEASE_LOGIN } from "../../util/ConstValue";


/**
 * 사용상태 변경요청 결과를 리스트에 반영하는 메소드
 * @param props 
 * @param dispatch 
 * @param apiAxios 
 * @param listItems 
 * @param setListItems 
 * @param idx 
 * @param use_state 
 */
export function updateUseState( dispatch:any, listItems:any, setListItems:any, table_type:number, idx:number, year:number|null, index:number, use_state:number, callback:any = null ) {
    //props.handleSetLoading( true );
    apiAxios.admin.dataUseStateUpdate(dispatch, idx, table_type, use_state, year, (response:AxiosResponse|null, error:AxiosError|null)=>{
      console.log("updateUseStateItem", response);
      if( response && response.status == RESPONSE_CODE.SUCCESS ) {
        const updateItems = [ ...listItems ];
        updateItems[index].use_state = use_state;
        setListItems(updateItems);
      }

      if( callback ) callback();
      //props.handleSetLoading( false );
    });
}

const emptyText = ( text:any ) => {
  if( text == null || text == undefined ) return true;
  text = text + "";
  if( text == "" ) return true;
  text = text.replace(/ /g, '');
  if( text == "" ) return true;
  return false;
}

const trimtext = (text:any ) => {
  if( text == null || text == undefined ) return text;
  text = text + "";
  return text.trim();
}

const isNumber = (text:string) => {
  for( var i=0; i<text.length; i++ ) {
    var subText = text.substring(i,i+1);
    if( isNaN(parseInt(subText)) == true ) return false;
  }

  return true;
}

/**
 * 유저 차단 
 * @param dispatch 
 * @param loginData 
 * @param nickname 
 * @param block_user_idx 
 * @param successCallback 
 * @returns 
 */
export function addBlockUser( dispatch:any, loginData : any, nickname: string = "", block_user_idx:number, is_admin:any, successCallback:any ) {
  if( !loginData ) { 
    alert(TEXT_PLEASE_LOGIN); 
    return;
  }
  if( loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
    alert(TEXT_CANT_ADMIN_LOGIN);
    return;
  }
  if( is_admin == API_TYPES.BOOLEAN.TRUE ) {
    alert("관리자는 차단할 수 없습니다");
    return;
  }

  var title = "'" + nickname + "'" + TEXT_BLOCK;
  var returnValue = window.confirm(title);
  if (returnValue) {
      const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
          if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
              alert("차단완료");
              successCallback();
          }
          else if( error ) {
              const errorData : any = API_UTILS.getErrorResponseData(error);
              if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {
                  alert("자기 자신을 차단할 수 없습니다.");
              }
              else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                  alert("이미 차단되어 있습니다.");
              }
              else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                  alert("유저를 찾을 수 없습니다.");
              }
          }
      }

      apiAxios.user.dataBlockUserAdd(dispatch, block_user_idx, callback);
  }
}

/**
 * 차단 유저 삭제
 * @param dispatch 
 * @param nickname 
 * @param block_user_idx 
 * @param successCallback 
 */
export function deleteBlockUser( dispatch:any, nickname: string = "", block_user_idx:number, successCallback:any ) {
  var title = "'" + nickname + "'" + TEXT_BLOCK_CANCEL;
  var returnValue = window.confirm(title);
  if (returnValue) {
      const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
          if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
              alert("차단해제완료");
              successCallback();
          }
          else if( error ) {
              const errorData : any = API_UTILS.getErrorResponseData(error);
              
              if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                  alert("차단된 유저가 아닙니다");
              }
          }
      }

      apiAxios.user.dataBlockUserDelete(dispatch, block_user_idx, callback);
  }
}