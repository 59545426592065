/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { RootState } from '../../../../redux_saga/modules';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { TAB_TYPE } from '../../../sub/TopTab';
import Wrapper from '../../../sub/Wrapper';
import MobileBodyComponent from './sub/MobileBodyComponent';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import TopAdComponent from '../../../sub/TopAdComponent';
import MobileWrapper from '../../sub/MobileWrapper';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../../sub/Adsense';

const MobileWriteInquiry = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const loginData = useSelector((state:RootState)=>state.data.loginData);

	


	var tabType = -1;

	return (<>
		<MobileWrapper
			subTitle={"문의"}
			hideNotice={true}
			tabType={tabType}>
			<MiddleComponent 
				{...props} 
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileWriteInquiry;

function MiddleComponent( props:any ) {
    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				pagePosition={AD_PAGE_POSITION.MOBILE_INQUIRY_WRITE_TOP}/>
            <MobileBodyComponent {...props}/>
		</div>
	)
}

