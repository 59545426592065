import { useEffect, useRef, useState } from "react";
import apiAxios, { API_TYPES, API_UTILS, ParamUserDataPostAdd, ParamUserDataPostUpdate } from "../../../../apiAxios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import { ImageData, ResponseImage, getImageFile } from "../../../../util/Image";
import { AiFillCloseCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { PAGE_URL } from "../../../../util/Page";


const BodyComponent = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [bodyStr, setBodyStr] = useState("");
    const [titleStr, setTitleStr] = useState("");
    const [requestingSave, setRequestingSave] = useState(false);

    const [selectType, setSelectType] = useState(API_TYPES.INQUIRY_TYPE.CUSTOMER);
    
	const adminEmail = useSelector((state:RootState)=>state.data.adminEmail);
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	const location = useLocation();
    
	
	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const t = l_p.get("type");
		if( t ) { try{ setSelectType( parseInt(t) ); }catch(e){ console.log("e",e);} }

		return(()=>{})
	},[location]);


    const clickedSave = () => {

        if( requestingSave ) return;

        if( !loginData ) {
            alert("문의는 로그인 후 가능합니다.\n로그인이 어려우시면 아래 이메일로 문의주세요\n\n" + adminEmail );
            return;
        }

        var msg = checkDataMsg();
        if( msg ) {
            alert( msg );
            return;
        }

        
		var title = "문의 내용을 저장하시겠습니까?";
		var returnValue = window.confirm(title);

        if( returnValue ) {
        
            setRequestingSave(true);
    
            
            const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);
    
                    setRequestingSave(false);
                    alert("저장완료");
                    navigate(PAGE_URL.MY_INQUIRY, {replace:true});
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert("찾을 수 없습니다");
                    }
                    setRequestingSave(false);
                }
            }
    
            apiAxios.user.dataInquiryAdd( dispatch, selectType, titleStr, bodyStr, callback );
        }
    }

    const checkDataMsg = () => {
        if( !titleStr ) { return "제목을 입력하세요"; }
        if( !bodyStr )  { return "내용을 입력하세요";   }
        
        return null;
    }

    return(
        <>
        <div className="inquiry_write_post">
            <Top {...props}/>
            <Title {...props}
                titleStr={titleStr}
                setTitleStr={setTitleStr}
                selectType={selectType}
                setSelectType={setSelectType}
                />
            <div className="body_textarea"><textarea value={bodyStr} placeholder="문의 내용 입력" onChange={(e)=> { setBodyStr(e.target.value); }} disabled={!loginData}/></div>
        </div>
        <BottonButtonComponent 
            {...props}
            onSave={clickedSave}
            requestingSave={requestingSave}
            />
        </>
    )
}
export default BodyComponent;

function Top( props:any ) {
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    return(
        <div className="top">
            <span>문의</span>
            { !loginData && <span className="no_login">※ 문의는 로그인 후 가능합니다. 로그인이 어려우시면 아래 이메일로 문의주세요</span> }
        </div>
    )
}

function Title( props:any ) {
    const {titleStr, setTitleStr, postType, postData, selectType, setSelectType} = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);

	useEffect(()=>{
        setSelectType(postType);
		return(()=>{})
	},[postType]);

    return(
        <div className="title">
            <div>
                <div className="left">문의유형</div>
                <div className="right">
                    
                    <select className={"select" + (!!postData||!loginData?" disable":"")}  value={selectType} disabled={!!postData||!loginData}
                        onChange={(e)=> {
                            setSelectType(e.target.value);  
                        }}>
                        <option value={API_TYPES.INQUIRY_TYPE.CUSTOMER}>고객</option>
                        <option value={API_TYPES.INQUIRY_TYPE.AD}>광고</option>
                    </select>
                </div>
            </div>
            <div>
                <div className="left">제목</div>
                <div className="right"><input type="text" className="input_title" value={titleStr} onChange={(e)=> { setTitleStr(e.target.value); }} disabled={!loginData}/></div>
            </div>
        </div>
    )
}


function BottonButtonComponent(props:any) {
    const {onSave, onClickToList, requestingSave} = props;
	const adminEmail = useSelector((state:RootState)=>state.data.adminEmail);
	const navigate = useNavigate();
    
    const clickedCancel = () => {
        navigate(-1);
    }

    return(
        <div className='inquiry_write_post_btn_box'>
            <div className='email'>  <span>{"※ 이메일 문의 주소 : " + adminEmail}</span> </div>
            <div className='save' onClick={onSave}>  
                { !requestingSave && <span>확인</span> }
                { requestingSave && 
                    <div className='loader'>
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                    </div> 
                }
            </div> 
            <div className='cancel' onClick={clickedCancel}>  <span>취소</span> </div> 
        </div>
    )
}

interface UploadImageData extends ImageData {
    deleteUrl:any,
    loading:boolean
}