/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, isToday, makeDateFromTimeStamp, makeInsertTimeStr, makeTwoNumberStr } from '../../../../util/Global';
import { CANT_READ_POST_ON_DELETE, IMG_URL_CHOICE } from '../../../../util/ConstValue';
import { PAGE_NOTICE_POST_PARAMS, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../redux_saga/modules';
import { AiFillPicture } from "react-icons/ai";
import MobilePageNumberBox, { MobileTopPageNumberBox } from '../../sub/MobilePageNumber';

function MobileCommunityPostList( props:any ) {
	const {currentCategory} = props;
	
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [itemList, setItemList] = useState<any>(null);
	const [categoryIdx, setCategoryIdx] = useState(-1);
	const [totalData, setTotalData] = useState<any>(null);

    useEffect(()=>{

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		const y = l_p.get("year");
		const p = l_p.get("page");

		var newCategoryIdx = API_TYPES.CATEGORY_TYPE.ALL;
		var newYear = (new Date()).getFullYear();
		var newPage = 1;

		if( c ) try{ newCategoryIdx = parseInt(c); }catch(e){ console.log("e",e);}
		if( y ) try{ newYear = parseInt(y); }catch(e){ console.log("e",e);}
		if( p ) try{ newPage = parseInt(p); }catch(e){ console.log("e",e);}

		setItemList(null);
		setTotalData(null);

		setCategoryIdx( newCategoryIdx );
		setYear( newYear );
		setPage( newPage );

		reqNotice(newYear, newPage, newCategoryIdx);

        return(()=>{})
    },[location]);
	

    const reqNotice = ( year:number, page:number, categoryIdx:number ) => {
		const reqCategoreIdx = categoryIdx==API_TYPES.CATEGORY_TYPE.ALL||categoryIdx==API_TYPES.CATEGORY_TYPE.BEST?0:categoryIdx;

		apiAxios.global.listNotice(dispatch, API_TYPES.POST_TYPE.COMMUNITY, reqCategoreIdx, 1, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				
				const newList = !resData.list?null:resData.list.map((item:any,index:number)=>{ item.is_notice = true; return item; })
				
				console.log("reqNotice newList : " , newList);
				reqList(year, page, categoryIdx, newList);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const reqList = ( year:number, page:number, categoryIdx:number, noticeList:any ) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				const newList = [];

				if( noticeList ) 	{ newList.push(...noticeList); }
				if( resData.list ) 	{ newList.push(...resData.list); }

				setItemList( newList );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		}

		if( categoryIdx == API_TYPES.CATEGORY_TYPE.ALL ) {
			apiAxios.global.listCommunityAllPost(dispatch, year, page, null, null, callback);
		}
		else {
			apiAxios.global.listCommunityPost(dispatch, year, page, categoryIdx, callback);
		}
	}

	var categoryName = "";
	var isBest = false;

	if( !!communityCategoryList ) {
		var categoryData = communityCategoryList.filter( (i:any) => i.idx == currentCategory );
		if( !!categoryData && categoryData.length > 0 ) {
			categoryName = categoryData[0].name;
			isBest = categoryData[0].idx == -1;
		}
	}

	const onClickPage = (page:number, isYear:boolean = false)=>{
		var params : PAGE_POST_HOME_PARAMS = {
			category:categoryIdx,
			year:year,
			page:page
		}

		if( isYear ) {
			params.year = page;
			params.page = 1;
		}
		else {
			params.page = page;
		}

		var url = makeUrl(PAGE_URL.COMMUNITY, params);
		navigate(url);
	}

	return(
		<>
			<div className='mobile_post_home__list_area' id="mobile_post_home__list_area">
				<div className='title'>
					<div className={'category' + (isBest?" best":"")}><span>{categoryName}</span></div>
					<MobileTopPageNumberBox 
						{...props}
						year={year}
						page={page}
						totalData={totalData}
						marginTop={5}
						onClickPage={onClickPage}/>
				</div>
				<PostComponent {...props} 
					year={year}
					page={page}
					itemList={itemList}
					/>
				<MobilePageNumberBox {...props}
					totalData={totalData}
					year={year}
					page={page}
					onClickPage={onClickPage}
				/>
			</div>
		</>
	)
}
export default MobileCommunityPostList;


function PostComponent( props:any ) {
	const { itemList, year, page, currentCategory } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[year, page]);

	

	const clickedPost = ( item:any ) => {
		
		// 사용가능 상태가 아니고 관리자 로그인이 아니라면 접근 불가
		if( item.use_state != API_TYPES.POST_USE_STATE.ABLE && (!loginData||loginData.is_admin!=API_TYPES.BOOLEAN.TRUE) ) {
			alert(CANT_READ_POST_ON_DELETE);
			return;
		}

		var fullUrl = "";

		if( item.is_notice == true ) {
			const params : PAGE_NOTICE_POST_PARAMS = {
				idx:item.idx,
				post_type:API_TYPES.POST_TYPE.COMMUNITY, 
				category:currentCategory==API_TYPES.CATEGORY_TYPE.ALL||currentCategory==API_TYPES.CATEGORY_TYPE.BEST?0:currentCategory
			}
			fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
		}
		else {
			const params : PAGE_POST_PARAMS = {
				idx:item.idx,
				year:item.year,
				post_list_type:
					currentCategory==API_TYPES.CATEGORY_TYPE.ALL?API_TYPES.POST_LIST_TYPE.ALL_COMMUNITY
					:currentCategory==API_TYPES.CATEGORY_TYPE.BEST?API_TYPES.POST_LIST_TYPE.BEST
					:API_TYPES.POST_LIST_TYPE.NOMAL,
				post_type:API_TYPES.POST_TYPE.COMMUNITY,
				category:currentCategory==API_TYPES.CATEGORY_TYPE.BEST||currentCategory==API_TYPES.CATEGORY_TYPE.ALL?currentCategory:item.cate_idx,
				search:null,
				searchType:null
			}
			fullUrl = makeUrl(PAGE_URL.COMMUNITY_POST, params);
		}
		navigate(fullUrl);
	}

	if( !itemList || itemList.length == 0 ) {
		return(
			<div className='empty'>
				<span>게시글이 없습니다</span>
			</div>
		)
	}

	return(
		<ul>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					
					if( item.use_state == API_TYPES.POST_USE_STATE.ABLE ) {
						return( <PostItem {...props} item={item} index={index} onClick={clickedPost}/> )
					}
					else {
						return( <DeleteItem {...props} item={item} index={index} onClick={clickedPost}/> )
					}
				})
			}
		</ul>
	)
}

function DeleteItem( props:any ) {
	const {item, onClick, currentCategory} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

	const showCategory = item.is_notice!=true && currentCategory == -1;

	var titleMaxWidth = 510;

	const insertTimeStr = makeInsertTimeStr( timeDate );
	var deleteMsg = getDeletePostMsg(item.use_state);

	return(
		<li onClick={()=>{if(onClick) onClick(item); }}>
			<div className='top_area'>
				{ item.is_notice==true 					&& <div className='notice'><span>공지</span></div> }

				<div className='title_body' style={{maxWidth:titleMaxWidth}}>
					{ showCategory && <span className='category delete'>{"["+item.cate_name+"]"}</span> }
					<span className='title delete'>{deleteMsg}</span>
				</div>
			</div>
			<div className='bottom'>
				<div className='insert_time delete'><span>{insertTimeStr}</span></div>
				<div className='hits delete'><span>{"조회:"+item.hits}</span></div>
				<div className='likes delete'><span>{"추천:"+item.likes}</span></div>
			</div>
		</li>
	)
}

function PostItem( props:any ) {
	const {item, onClick, currentCategory} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

    const showReplyNum = item.is_notice!=true && item.reply_num && item.reply_num > 0;
	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showBest = currentCategory != API_TYPES.CATEGORY_TYPE.BEST && item.best==API_TYPES.BOOLEAN.TRUE;
	const showCategory = item.is_notice!=true && ( currentCategory == API_TYPES.CATEGORY_TYPE.BEST || currentCategory == API_TYPES.CATEGORY_TYPE.ALL );

	var titleMaxWidth = window.outerWidth - 40;
	titleMaxWidth = titleMaxWidth - (item.is_notice==true?40:0);
	titleMaxWidth = titleMaxWidth - (showBest?40:0);
	titleMaxWidth = titleMaxWidth - (item.choice_show == API_TYPES.BOOLEAN.TRUE?25:0);
	titleMaxWidth = titleMaxWidth - (showNew?25:0);

	titleMaxWidth = titleMaxWidth - (showReplyNum?50:0);
	titleMaxWidth = titleMaxWidth - (havePicture?25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	return(
		<li onClick={()=>{if(onClick) onClick(item); }} className={item.is_notice==true?"notice":''}>
			<div className='top_area'>
				{ item.is_notice==true 					&& <div className='notice'><span>공지</span></div> }
				{ showBest 								&& <div className='best'><span>Best</span></div> }

				<div className='title_body' style={{maxWidth:titleMaxWidth}}>
					{ showCategory && <span className='category'>{"["+item.cate_name+"]"}</span> }
					<span className='title'>{item.title}</span>
				</div>
				
				{ showReplyNum 							&& <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
				{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
				{ item.choice_show == API_TYPES.BOOLEAN.TRUE 	&& <img className="choice_image" src={IMG_URL_CHOICE}/> }
				{ showNew 								&& <div className='new'><span>N</span></div> }
			</div>
			<div className='bottom'>
				<div className='writer'><span>{item.is_notice==true?"운영자":item.nickname}</span></div>
				<div className='hits'><span>{"조회:"+item.hits}</span></div>
				{ item.is_notice!=true && <div className='likes'><span>{"추천:"+item.likes}</span></div> }
				<div className='insert_time'><span>{insertTimeStr}</span></div>
			</div>
		</li>
	)
}