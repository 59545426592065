/****************************************************************************************
 * 
 *  상단 탭
 *  
 *  홈 , 사연 , 커뮤니티
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { PAGE_URL } from '../../util/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux_saga/modules';
import { AiFillHome } from "react-icons/ai";

export enum TAB_TYPE {
    ADMIN = -2,
    NONE = -1,
    HOME = 1,
    STORY = 2,
    COMMUNITY = 3
}

function TopTab( props : any ) {
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    

    useEffect(()=>{

        return(()=>{})
    },[]);

    return (
        <div className='top_tab'>
            <div className='top_tab_body'>
                <Tab {...props} tabType={TAB_TYPE.HOME} icon={<AiFillHome size={30}/>}/>

                <Tab {...props} tabType={TAB_TYPE.STORY} name="사연"/>
                <Tab {...props} tabType={TAB_TYPE.COMMUNITY} name="커뮤니티"/>
                { loginData && loginData.is_admin == API_TYPES.BOOLEAN.TRUE &&
                    <Tab {...props} tabType={TAB_TYPE.ADMIN} name="관리자 페이지"/>
                }
            </div>
        </div>
    );
}

export default TopTab;

function Tab( props : any ) {
    const { foucsType, tabType, name, icon } = props;
	const navigate = useNavigate();
    const isFocus = foucsType == tabType;

    const movePage = () => {
        if( isFocus ) return;
        
        if( tabType == TAB_TYPE.HOME ) {
            navigate( PAGE_URL.MAIN );
        }
        else if( tabType == TAB_TYPE.STORY ) {

            navigate( PAGE_URL.STORY );
        }
        else if( tabType == TAB_TYPE.COMMUNITY ) {
            navigate( PAGE_URL.COMMUNITY );
        }
        else if( tabType == TAB_TYPE.ADMIN ) {
            navigate( PAGE_URL.ADMIN_MAIN );
        }
    }

    var rightStyle = {};
    if( tabType == TAB_TYPE.ADMIN ) {
        rightStyle = {float:"right"};   
    }

    return (
        <div className={isFocus?"tab_item tab_item_select":'tab_item'} onClick={movePage} style={rightStyle}>
            { !!icon && <div className='icon'> {icon}</div> }
            { !icon && <span>{name}</span> }
        </div>
    )
}