/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import LeftAd from '../sub/LeftAd';
import RightArea from '../sub/RightArea';
import { getCookieLoginData } from '../../util/cookie_helper';
import MainBestAreaComponent from './sub/MainBestAreaComponent';
import MainPostListComponent from './sub/MainPostListComponent';
import MainCategoryAreaComponent from './sub/MainCategoryAreaComponent';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { useDispatch } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../sub/TopAdComponent';
import Adsense, { AD_PAGE_POSITION, AdType } from '../sub/Adsense';

const Main = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	/////////////////////////////////////////////////////////////////////
	// 페이지 라우팅을 위한 구문
	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const page = l_p.get("route_page");
        if( !!page ) { navigate( makeMovePageStr( location.search ) ); }
		
		return(()=>{})
	},[location]);

	const makeMovePageStr = ( search : string ) => {
		var movePage = search.replace("?route_page=","/");
		var firstDivideIndex = movePage.indexOf("&");
		if( firstDivideIndex > -1 ) {
			var left = movePage.substring(0, firstDivideIndex);
			var right = movePage.substring(firstDivideIndex+1);
			movePage = left + "?" + right;
		}
		return movePage;
	}
	// End - 페이지 라우팅을 위한 구문
	/////////////////////////////////////////////////////////////////////

	return (
		<Wrapper
			tabType={TAB_TYPE.HOME}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default Main;


function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>

			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.MAIN_TOP} marginBottom={10}/>
			<MainBestAreaComponent {...props}/>
			<MainPostListComponent {...props}/>
			<MainCategoryAreaComponent {...props}/>
			<BottomAdComponent {...props}/>
		</div>
	)
}

function BottomAdComponent( props:any ) {

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='home_bottom_ad_area'>
			
			<Adsense 
				{...props}
				className='ad'
				type={AdType.WEB_MAIN_BOTTOM}
				pagePosition={AD_PAGE_POSITION.MAIN_BOTTOM}
				/>
			<Adsense 
				{...props}
				className='ad right'
				type={AdType.WEB_MAIN_BOTTOM_RIGHT}
				pagePosition={AD_PAGE_POSITION.MAIN_BOTTOM}
				/>
		</div>
	)
}