import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";

import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { BsPerson } from "react-icons/bs";
import { FiLock } from "react-icons/fi";
import ButtonDefault from "../../../../sub/ButtonDefault";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../../../util/Page";
import { useDispatch, useSelector } from "react-redux";
import apiAxios, { API_TYPES, API_UTILS } from "../../../../../apiAxios";
import { AxiosError, AxiosResponse } from "axios";
import { getCookieLoginData, removeCookieLoginData, setCookieLoginData } from "../../../../../util/cookie_helper";
import reduxActions from "../../../../../redux_saga/modules/moduleActions";
import LevelIcon from "../../../../sub/LevelIcon";
import { RootState } from "../../../../../redux_saga/modules";
import { AiOutlineRight } from "react-icons/ai";
import { APP_INTERFACE_SEND_TYPE, APP_INTERFACE_LOGOUT_DATA, sendDataToApp } from "../../../../../util/AppInterface";

function UserBox( props:any ) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
    const userData = useSelector((state:RootState)=>state.data.userData);
    const loginData = useSelector((state:RootState)=>state.data.loginData);

    const clickLogout = () => {
        reduxActions.data.setLoginData(dispatch, null);
        reduxActions.data.setUserData(dispatch, null);
        //removeCookieLoginData();

        var d = getCookieLoginData();
        console.log("clickLogout",d);

        navigate(PAGE_URL.MAIN, {replace:true});
    }

    if( !userData ) {
        return (
            <div className='mobile_user_box'>
                <div className='loader'>
                    <Oval 
                        color="#00f" 
                        height={20} 
                        width={20}
                    />
                </div>
            </div>
        );
    }

    var countData = { post:0, best:0, choice:0 }
    var name = "";

    if( userData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
        name = userData.name;
    }
    else {
        countData.post = parseInt(userData.counts.story) + parseInt(userData.counts.community);
        countData.best = parseInt(userData.counts.story_best) + parseInt(userData.counts.community_best);
        countData.choice = userData.counts.story_choice;

        name = !!userData.nickname?userData.nickname:userData.name;
    }
    return(
        <>
        <div className='mobile_user_box'>
            <div className='info_area'>
                <div className="left">
                    <LevelIcon 
                        is_admin={userData.is_admin}
                        level={userData.level}/>
                    <div className='name'><span>{name + "님"}</span></div>
                </div>
                { userData.is_admin == API_TYPES.BOOLEAN.TRUE &&
                    <div className='right'>
                        <div className="post">
                            <span className='title'>권한</span>
                            <span className='count'>{userData.access==API_TYPES.MANAGE_ACCESS.ALL?"전체":"부분"}</span>
                        </div>
                    </div>
                }
                { userData.is_admin != API_TYPES.BOOLEAN.TRUE &&
                    <div className='right'>
                        <div className="post">
                            <span className='title'>내가 쓴글</span>
                            <span className='count'>{countData.post+" 개"}</span>
                        </div>
                        <div className="post">
                            <span className='title'>베스트</span>
                            <span className='count'>{countData.best+" 개"}</span>
                        </div>
                        <div className="post">
                            <span className='title'>채택사연</span>
                            <span className='count'>{countData.choice+" 개"}</span>
                        </div>
                    </div>
                }
            </div>
            <div className='logout_btn default_btn' onClick={clickLogout}>
                <span>로그아웃</span>
            </div>

        
        </div>
        <div className='mobile_user_info_box'>
            <MovePageItem {...props} value="정보수정" pageUrl={
                userData.is_admin == API_TYPES.BOOLEAN.FALSE?PAGE_URL.USER_INFO:PAGE_URL.ADMIN_SETTING
                }/>
            
            {  userData.is_admin == API_TYPES.BOOLEAN.FALSE && <>
            <MovePageItem {...props} value="내가 쓴글 보기" pageUrl={PAGE_URL.MY_POST}/>
            <MovePageItem {...props} value="차단한 사용자" pageUrl={PAGE_URL.MY_BLOCK}/>
            <MovePageItem {...props} value="나의 문의 내역" pageUrl={PAGE_URL.MY_INQUIRY}/>
            </>}
        </div>
        </>
    )
}
export default UserBox;

function MovePageItem( props:any ) {
    const {pageUrl, value} = props;
	const navigate = useNavigate();

    const movePage = ( url:string, params:string|null = null ) => {
        navigate( url + (!!params?params:"") );
    }

    return(
        <div onClick={()=>{ movePage(pageUrl); }}>
            <span>{value}</span>
            
            <div className="right_icon">
                <AiOutlineRight size={20}/>
            </div>
        </div>
    )
}
