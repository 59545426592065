/****************************************************************************************
 * 
 *  하단 회사 소개 부분
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { TEXT_PLEASE_LOGIN } from '../../util/ConstValue';
import { PAGE_NOTICE_POST_PARAMS, PAGE_URL, makeUrl } from '../../util/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux_saga/modules';

function BottomCompanyInfo( props : any ) {
    const navigate = useNavigate();
    const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{

        return(()=>{})
    },[]);

    const clickedInquiry = ( type : number ) => {
        navigate(PAGE_URL.WRITE_INQUIRY + "?type=" + type);
        /*if( loginData ) {
            navigate(PAGE_URL.WRITE_INQUIRY + "?type=" + type);
        }
        else {
            alert(TEXT_PLEASE_LOGIN);
        }*/
    }

    const clickedNotice = () => {
        const params : PAGE_NOTICE_POST_PARAMS = {
            idx:1,
            post_type:API_TYPES.POST_TYPE.ALL, 
            category:0
        }
        var fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
		navigate(fullUrl);
    }
    

    return (
        <div className='bottom_company_info'>
            <div className='divider'></div>
            <div className='top'>
                <span className='link' onClick={()=>{navigate(PAGE_URL.PERSONAL)}}>개인정보처리방침</span>
                <span className='dot'>·</span>
                <span className='link' onClick={()=>{clickedNotice()}}>이용약관</span>
                <span className='dot'>·</span>
                <span className='link' onClick={()=>{clickedInquiry(API_TYPES.INQUIRY_TYPE.AD)}}>광고문의</span>
                <span className='dot'>·</span>
                <span className='link' onClick={()=>{clickedInquiry(API_TYPES.INQUIRY_TYPE.CUSTOMER)}}>고객문의</span>
            </div>
            <div className='info'>
                <span>정보통신망을 통하여 불법촬영물 등 음란, 저작권 침해 명예훼손, 청소년 유해물, 기타 위법 자료 등을 게시 또는 배포하면
                    <br></br>해당 게시물은 경고 없이 삭제되며,<br></br>게시자는 법률에 따라 징역형 또는 벌금형에 처해질 수 있습니다.</span>
            </div>
            <div className='copyright'>
                <span>Copyright ⓒ </span>
                <span className='red'>스튜디오아이스</span>
                <span>, all rights reserved.</span>
            </div>
        </div>
    );
}

export default BottomCompanyInfo;
