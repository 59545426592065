import React, { useEffect } from 'react'

const DisplayAds = ( props:any ) => {
    const { className, style, adClient, adSlot, adFormat, fullWidthResponsive } = props;

    const w : any = window;

    useEffect(() => {

        const pushAd = () => {
            try {
                const adsbygoogle = w.adsbygoogle;
                // console.log({ adsbygoogle })
                adsbygoogle.push({})
            } catch (e) {
                console.error(e)
            }
        }

        let interval = setInterval(() => {
            // Check if Adsense script is loaded every 300ms
            if (w.adsbygoogle) {
                pushAd()
                // clear the interval once the ad is pushed so that function isn't called indefinitely
                clearInterval(interval)
            }
        }, 300)

        return () => {
            clearInterval(interval)
        }
    }, [])

    var class_name  = className ?className  :"adsbygoogle";
    var st          = style     ?style      :{ display: 'block' };
    var ad_client   = adClient  ?adClient   :"";
    var ad_slot     = adSlot    ?adSlot     :"";
    var ad_format   = adFormat  ?adFormat   :"auto";
    var full_width_responsive = fullWidthResponsive?fullWidthResponsive:"true";

    return (
        <ins
            className       ={class_name}
            style           ={st}
            data-ad-client  ={ad_client}
            data-ad-slot    ={ad_slot}
            data-ad-format  ={ad_format}
            data-full-width-responsive={full_width_responsive}
        ></ins>
    )
}

export default DisplayAds