
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import ButtonDefault from '../../../sub/ButtonDefault';
import AdminListItemLoading from '../../sub/AdminListItemLoading';


const Category = (props:any) => {
    const {refreshCategoryList, setRefreshCategoryList} = props;

    const [categoryList, setCategoryList] = useState<any>(null);
    const [displayCategoryList, setDisplayCategoryList] = useState<any>(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

    useEffect(()=>{
        reqList();
        
        return(()=>{})
    },[]);

    useEffect(()=>{
        if( refreshCategoryList ) {
            reqList();
            setRefreshCategoryList(false);
        }

        return(()=>{})
    },[refreshCategoryList]);

    const reqList = () => {
        apiAxios.global.listCommunityCategory( dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;
                setCategoryList( list );
                setDisplayCategoryList( list );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {
        if( !!search && search != "" ) {
            const list = categoryList.filter( (i:any) => i.name.indexOf( search ) > -1 );
            setDisplayCategoryList( list );
        }
        else {
            setDisplayCategoryList( categoryList );
        }
    }
    var titleBg = !displayCategoryList || displayCategoryList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_community_category_box'>
            <ListTop 
                {...props}
                onSearch={onSearch}/>
			<ul>
                <li className={'title' + titleBg}>
                    <div className='name'>카테고리 명</div>
                    <div className='channel'>채널</div>
                    <div className='banner'>배너</div>
                    <div className='mob_banner two_line'>{"모바일\n배너"}</div>
                    <div className='state'>상태</div>
                    <div className='edit'>수정</div>
                </li>
                { displayCategoryList && displayCategoryList.length > 0 && displayCategoryList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index} index={index} itemList={displayCategoryList} setItemList={setDisplayCategoryList}/>)
                })}
                { (!displayCategoryList || displayCategoryList.length == 0 ) &&
                    <li className='empty'><span>카테고리 없음</span></li>
                }
            </ul>
		</div>
	);
};

export default Category;

function ListTop( props:any ) {
    const {openCategoryAddPopup}= props;
    return(
        <div className='list_top'>
            <AdminSearchBox {...props} placeholder={"카테고리명 검색"}/>
            <ButtonDefault 
                {...props}
                className="add_btn"
                value={"추가"}
                onClick={()=>{
                    openCategoryAddPopup(null);
                }}
                />
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openCategoryAddPopup, index, itemList, setItemList} = props;
	const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    const clickChannel = () => {
        if( !!item.channel ) { window.open(item.channel); }
    }

    const clickBanner = () => {
        if( !!item.banner ) { window.open(item.banner); }
    }

    const clickMobBanner = () => {
        if( !!item.mob_banner ) { window.open(item.mob_banner); }
    }

    const clickedUpdateState = () => {
        if( requesting ) return;
        var title = item.name + "을 ";
        if( item.use_state==API_TYPES.USE_STATE.ABLE ) {
            title += "사용정지로 변경하시겠습니까?";
        }
        else {
            title += "사용상태로 변경하시겠습니까?";
        }
        
		var returnValue = window.confirm(title);
        if( returnValue ) {
            setRequesting(true);
            var use_state = item.use_state==API_TYPES.USE_STATE.ABLE?API_TYPES.USE_STATE.DISABLE:API_TYPES.USE_STATE.ABLE;
            apiAxios.data_middleware.updateUseState(dispatch, itemList, setItemList, API_TYPES.TABLE_TYPE.COMMUNITY_CATEGORY, item.idx, null, index, use_state, ()=>{
                setRequesting(false);
            });
        }
    }

    var state_class = item.use_state==API_TYPES.USE_STATE.ABLE?"color_blue":"color_red";
    var stateStr = item.use_state==API_TYPES.USE_STATE.ABLE?"사용중":"정지중";

    return(
        <li className='item'>
            <div className='name'>{item.name}</div>
            <div className={'channel' + (!!item.channel?" pointer":"")} onClick={clickChannel}>{!!item.channel?"보기":"."}</div>
            <div className={'banner' + (!!item.banner?" pointer":"")} onClick={clickBanner}>{!!item.banner?"보기":"."}</div>
            <div className={'mob_banner' + (!!item.banner?" pointer":"")} onClick={clickMobBanner}>{!!item.mob_banner?"보기":"."}</div>
            <div className={'state pointer ' + state_class} onClick={clickedUpdateState}>
                {requesting?<AdminListItemLoading/>:stateStr}
            </div>
            <div className={'edit pointer'} onClick={()=>{openCategoryAddPopup(item);}}>{"수정"}</div>
        </li>
    )
}