
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminListPostDelete } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, isToday, makeInsertTimFullStr, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { PAGE_ADMIN_COMMUNITY_DELETE_POST, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl, ADMIN_SUB_TAP_COMMUNITY } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import AdminListItemLoading from '../../sub/AdminListItemLoading';
import { isMobile } from 'react-device-detect';


const DeletePost = (props:any) => {
    const { accessCatagorys } = props;

    const [itemList, setItemList] = useState<any>(null);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [searchSelect, SetSearchSelect] = useState(API_TYPES.SEARCH_TYPE.ALL);
    const [selectSort, setSelectSort] = useState(API_TYPES.POST_LIST_SORT_TYPE.CHOICE_DATE);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newYear         = getParamData(l_p, "year", (new Date()).getFullYear() );
        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );
        var newSearchType   = getParamData(l_p, "searchType", API_TYPES.SEARCH_TYPE.ALL );
        var newSort         = getParamData(l_p, "sort", API_TYPES.POST_LIST_SORT_TYPE.CHOICE_DATE );

        setYear(newYear);
        setPage(newPage);
        setSearchText(newSearch);
        SetSearchSelect(newSearchType);
        setSelectSort(newSort);

        reqList(newYear, newPage, newSearch, newSort, newSearchType);

        return(()=>{})
    },[location]);


    const reqList = ( year:number, page:number, searchText:string|null, sort:number, search_type:number ) => {

        var param : ParamAdminListPostDelete = {
            year            :year,
            page            :page,
            sort            :sort,
            search_type     :search_type,
            total_search    :searchText,
            categorys       :accessCatagorys
        }

        apiAxios.admin.listCommunityPostDelete( dispatch, param, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_COMMUNITY_DELETE_POST => {
        var params : PAGE_ADMIN_COMMUNITY_DELETE_POST = {
            tab         :ADMIN_SUB_TAP_COMMUNITY.DELETE_POST, 
            year        :year,
            page        :1,
            search      :!!searchText?searchText:null, 
            searchType  :searchSelect,
            sort        :selectSort
        }
        if( !!param ) {
            if( param == "search" )             params["search"]        = value;
            else if( param == "searchType" )    params["searchType"]    = value==FILTER_VALUE_ALL?null:value;
            else if( param == "sort" )          params["sort"]          = value;
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_COMMUNITY_DELETE_POST ) => {
        var url = makeUrl(PAGE_URL.ADMIN_COMMUNITY, params);
        navigate(url);
    }

    const onCancel = () => {
        reqList(year, page, searchText, selectSort, searchSelect);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_community_delete_post_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                searchSelect  ={searchSelect}
                SetSearchSelect={(value:any)=>{
                    SetSearchSelect(value);
                    
                    changePage( getMovePageParam( "searchType", value ) );
                }}
                selectSort={selectSort}
                setSelectSort={(value:any)=>{
                    setSelectSort(value);
                    
                    changePage( getMovePageParam( "sort", value ) );
                }}
                />
			<ul>
                { isMobile != true && 
                <li className={'title' + titleBg}>
                    <div className='title'>제목 / 내용</div>
                    <div className={'writer'}>글쓴이</div>
                    <div className='insert_time'>작성일</div>
                    <div className={'delete_date'}>삭제일</div>
                    <div className='delete_user'>삭제자</div>
                    <div className={'reason'}>사유</div>
                    <div className='cancel'>취소</div>
                    <div className={'real_delete'}>영구삭제</div>
                </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index} onCancel={onCancel}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>게시글 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_COMMUNITY_DELETE_POST = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}

					var url = makeUrl(PAGE_URL.ADMIN_COMMUNITY, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default DeletePost;

function ListTop( props:any ) {
    const {
        searchSelect, SetSearchSelect,
        selectSort, setSelectSort,
        onSearch, searchText
    } = props;

    const searchOption : OptionData[] = [{value:API_TYPES.SEARCH_TYPE.ALL, name:"전체"}, {value:API_TYPES.SEARCH_TYPE.TITLE, name:"제목"}, {value:API_TYPES.SEARCH_TYPE.WRITER, name:"글쓴이"}];
    
    const sortOption : OptionData[] = [
        {value:API_TYPES.POST_LIST_SORT_TYPE.INSERT, name:"작성일"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.DELETE_DATE, name:"삭제일"}
    ];

    return(
        <div className='list_top'>
            <AdminSearchBox {...props} placeholder={"검색"} filterOption={searchOption} searchSelect={searchSelect} SetSearchSelect={SetSearchSelect} onSearch={onSearch} defaultSearchText={searchText}/>

            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openPostPage, onCancel} = props;
	const dispatch = useDispatch();
    const [requestingCancel, setRequestingCancel] = useState(false);
    const [requestingDelete, setRequestingDelete] = useState(false);

    var choiceDate = null;
    if( item.choice_date>0 ) { choiceDate = new Date(); choiceDate.setTime(item.choice_date*1000); }
    var choiceSendDate = null;
    if( item.choice_send_date>0 ) { choiceSendDate = new Date(); choiceSendDate.setTime(item.choice_send_date*1000); }


    var deleteUser = item.use_state==API_TYPES.POST_USE_STATE.DELETE?"유저":item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE?"관리자":".";
    
    const showReplyNum = item.reply_num > 0;
    
    const listMaxWidth = isMobile?window.outerWidth:500;

    var titleWidth = listMaxWidth - 20;
    var bodyWidth = listMaxWidth - 20;
    if( !!item.thumbnail ) {
        titleWidth = titleWidth - 50;
        bodyWidth = bodyWidth - 50;
    }
    if( showReplyNum ) titleWidth = titleWidth - 40;

    var titleStyle = {maxWidth:titleWidth};
    var bodyStyle = {maxWidth:bodyWidth};

    const clickedDeleteCancel = () => {
        if( requestingCancel ) return;
        if( item.use_state!=API_TYPES.POST_USE_STATE.ADMIN_DELETE ) return;

        var title = item.title;
        title += "\n게시글 삭제상태 취소처리 하시겠습니까?";
        
        var returnValue = window.confirm(title);
        if( returnValue ) {
            setRequestingCancel(true);

            apiAxios.admin.dataCommunityPostDeleteCancel(dispatch, item.idx, item.year, null, (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);

                    alert("취소완료");
                    onCancel();
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    }
                    alert("취소처리를 실패 했습니다");
                }
                setRequestingCancel(false);
            })
        }
    }

    const clickedCompletelyDelete = () => {
        if( requestingDelete ) return;

        var title = item.title;
        title += "\n게시글을 영구삭제 하시겠습니까?";
        title += "\n\n게시글과 이미지가 영구 삭제되며 하위 댓글은 삭제되지 않습니다";
        title += "\n\n비밀번호를 입력하세요\n";
        
        var returnValue = window.prompt(title, "");
        if( returnValue ) {
            setRequestingDelete(true);

            apiAxios.admin.authCheckPw(dispatch, returnValue, (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {

                    apiAxios.admin.dataCommunityPostCompletelyDelete(dispatch, item.idx, item.year, (response:AxiosResponse|null, error:AxiosError|null) => {
                        if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                            const resData = API_UTILS.getResponseSuccessData(response);
        
                            alert("영구삭제 완료");
                            onCancel();
                        }
                        else if( error ) {
                            const errorData : any = API_UTILS.getErrorResponseData(error);
                            if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                            }
                            alert("영구삭제를 실패 했습니다");
                        }
                        setRequestingDelete(false);
                    })
                }
                else if( error ) {
                    alert("비밀번호를 잘못 입력하셨습니다");
                    setRequestingDelete(false);
                }
            })
        }
    }

    const clickedOpenPage = () => {
        openPostPage(item.idx, item.year, item.cate_idx);
    }

    if( isMobile ) {
        return(
            <li className="item">
                <div className='title two_line' onClick={clickedOpenPage}>
                    { !!item.thumbnail &&
                        <img src={item.thumbnail}/>
                    }
                    <div>
                        <div className='title'>
                            <div className='top' style={titleStyle}> <span>{"[" + item.cate_name + "]" + item.title}</span> </div>
                            { showReplyNum && <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
                        </div>
                        <div className='body' style={bodyStyle}>{item.body}</div>
                    </div>
                </div>
                <div className='sub' onClick={clickedOpenPage}>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time, true)}</div>
                    <div className='divide'>·</div>
                    <div className={'writer'}>{item.user_id + "(" + item.nickname + ")"}</div>
                </div>
                <div className='sub sub_bg top_border' onClick={clickedOpenPage}>
                    <div className='delete_user'>{deleteUser}</div>
                    <div className='divide'>·</div>
                    <div className={'delete_date'}>{makeInsertTimeStr(item.update_time, true)}</div>
                    { !!item.note && <>
                        <div className='divide'>·</div>
                        <div className='reason'>{item.note}</div>
                    </>}
                </div>
                <div className='sub button_area'>
                    <div className={'cancel' + (item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE?' pointer':"")} onClick={clickedDeleteCancel}>{requestingCancel?<AdminListItemLoading/>:item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE?"취소":"."}</div>
                    <div className='real_delete pointer' onClick={clickedCompletelyDelete}>{requestingDelete?<AdminListItemLoading/>:"영구삭제"}</div>
                </div>
            </li>
        )
    }

    return(
        <li className="item">
            <div className='title two_line' onClick={clickedOpenPage}>
                { !!item.thumbnail &&
				    <img src={item.thumbnail}/>
                }
                <div>
                    <div className='title'>
                        <div className='top' style={titleStyle}> <span>{"[" + item.cate_name + "]" + item.title}</span> </div>
				        { showReplyNum && <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
                    </div>
                    <div className='body' style={bodyStyle}>{item.body}</div>
                </div>
            
            </div>
            <div className='writer two_line'>{item.user_id + "\n(" + item.nickname + ")"}</div>
            <div className={'insert_time' + (isToday(item.insert_time)?'':' two_line')}>{makeInsertTimFullStr(item.insert_time)}</div>
            <div className={'delete_date' + (isToday(item.update_time)?'':' two_line')}>{makeInsertTimFullStr(item.update_time)}</div>
            <div className={'delete_user'}>{deleteUser}</div>
            <div className='reason'>{item.note?item.note:"."}</div>
            <div className={'cancel' + (item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE?' pointer':"")} onClick={clickedDeleteCancel}>{requestingCancel?<AdminListItemLoading/>:item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE?"취소":"."}</div>
            <div className='real_delete pointer' onClick={clickedCompletelyDelete}>{requestingDelete?<AdminListItemLoading/>:"영구삭제"}</div>
        </li>
    )
}
