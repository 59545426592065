import cookies from "react-cookies";


export const setCookie = ( name:string, value:string, option?:any) => {
    cookies.save(name, value, { ...option});
}

export const getCookie = ( name:string ) => {
    return cookies.load(name, true);
}

export const removeCookie = ( name:string ) => {
    cookies.remove(name);
}

export enum COOKIE_NAME  {
    LISTEN_LOGIN_DATA = "LISTEN_LOGIN_DATA",
} 

/********************************************************
 * 
 * 사용 편의를 위한 단축 함수들
 * 
 ********************************************************/

// 로그인 데이터를 쿠키에 저장하는 메소드
export const setCookieLoginData = ( data : any ) => {
    setCookie( COOKIE_NAME.LISTEN_LOGIN_DATA, JSON.stringify(data), null );
}
// 쿠키의 로그인 데이터를 삭제하는 메소드
export const removeCookieLoginData = () => {
    removeCookie( COOKIE_NAME.LISTEN_LOGIN_DATA );
}

// 로그인 데이터를 가져오는 메소드
export const getCookieLoginData = () => {
    var dataStr = getCookie( COOKIE_NAME.LISTEN_LOGIN_DATA );
    if( dataStr ) return JSON.parse( dataStr );
    return null;
}