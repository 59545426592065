/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { RootState } from '../../../../redux_saga/modules';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { TAB_TYPE } from '../../../sub/TopTab';
import Wrapper from '../../sub/Wrapper';
import BodyComponent from './sub/BodyComponent';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import TopAdComponent from '../../../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../../../sub/Adsense';
import { ADMIN_TAB_TYPE } from '../../sub/AdminTopTab';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, checkAbleManageAdminPage, makeAdminManageData } from '../../../../util/AdminAccessHelper';
import { movePage } from '../../../../util/Global';

const AdminNoticeWrite = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [categoryIdx, setCategoryIdx] = useState(-2);
	const [page, setPage] = useState(-1);
	const [idx, setIdx] = useState(-1);
	const [postType, setPostType] = useState(API_TYPES.POST_TYPE.ALL);
	
	const [noticeData, setNoticeData] = useState<any>(null);
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
	
	useEffect(()=>{
		if( !loginData || loginData.is_admin != API_TYPES.BOOLEAN.TRUE ) {
			alert("관리자만 글을 작성할 수 있습니다");
			navigate(-1);
		}
		else {
			var show = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.NOTICE)).accessType == ADMIN_ACCESS_TYPE.ALL;
			if( show == false ) {
				navigate(-1);
			}
		}

		return(()=>{})
	},[loginData]);


	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		const p = l_p.get("page");
		const i = l_p.get("idx");
		const pt = l_p.get("post_type");


		var newCategory = -1;
		var newPage = -1;
		var newIdx = -1;
		var newPostType = API_TYPES.POST_TYPE.ALL;

		if( c ) 	{ try{ newCategory 			= parseInt(c); 		}catch(e){ console.log("e",e);} }
		if( p ) 	{ try{ newPage				= parseInt(p); 		}catch(e){ console.log("e",e);} }
		if( i ) 	{ try{ newIdx 				= parseInt(i); 		}catch(e){ console.log("e",e);} }
		if( pt ) 	{ try{ newPostType 			= parseInt(pt); 	}catch(e){ console.log("e",e);} }

		setIdx(newIdx);
		setPage(newPage);
		
        reqCategory();

		if( newIdx != -1 ) {
			reqData(newIdx);
		}
		else {
			setCategoryIdx(newCategory);
			setPostType(newPostType);
		}

		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
	}

	const reqData = (idx:number) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);


				setNoticeData(resData.post_data);
				if( resData.post_type != 0 )	setPostType( resData.post_data.post_type );
				if( resData.cate_idx != 0 )		setCategoryIdx( resData.post_data.cate_idx );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				/*if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("내가 작성한 게시물이 아닙니다");
					clickToList(true);
				}*/
			}
		}

		apiAxios.global.dataNotice(dispatch, idx, callback);		
	}

	var tabType = -1;

	return (<>
		<Wrapper
			hideSubTab={true}
			tabType={ADMIN_TAB_TYPE.NOTICE}>
			<MiddleComponent 
				{...props} 
                categoryIdx={categoryIdx}
                page={page}
                idx={idx}
                postType={postType}
                noticeData={noticeData}
				/>
		</Wrapper>
		</>
	);
};

export default AdminNoticeWrite;

function MiddleComponent( props:any ) {
    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
            <BodyComponent {...props}/>
		</div>
	)
}

