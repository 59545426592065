
import { defaultAxiosPromise, METHOD, CallFunc, defaultCallbackAxios, addParam, appendFormData, getLoginToken } from "./util";
import { API_URL_USER } from "./const";
import { DefaultParamAddImage, DefaultParamUpdateImage } from "./type";

const URL_AUTH = API_URL_USER + "auth/";
const URL_DATA = API_URL_USER + "data/";
const URL_LIST = API_URL_USER + "list/";



/*********************************************************************************************************
 * 
 * 계정 관련
 * 
 *********************************************************************************************************/

/* 회원가입 */
export function authSignup( dispatch:any, params:ParamAuthSignup, callBack:CallFunc|null ) {
    let param : any = {
        id          :params.id,
        pw          :params.pw,
        email       :params.email,
        nickname    :params.nickname,
        name        :params.name,
        number      :params.number,
        birth       :params.birth,
        gender      :params.gender,
        foreigner   :params.foreigner,
        unique_key  :params.unique_key,
    };

    var formData = null;
    const URL = URL_AUTH + "signup.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 아이디 찾기 */
export function authFindId( dispatch:any, unique_key:string|null, name:string|null, email:string|null, callBack:CallFunc|null ) {
    let param : any = {};
    param = addParam( param, "unique_key", unique_key);
    param = addParam( param, "name", name);
    param = addParam( param, "email", email);

    var formData = null;
    const URL = URL_AUTH + "find_id.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 비밀번호 찾기 */
export function authFindPw( dispatch:any, id:string, unique_key:string|null, email:string|null, callBack:CallFunc|null ) {
    let param : any = {id:id};
    param = addParam( param, "unique_key", unique_key);
    param = addParam( param, "email", email);

    var formData = null;
    const URL = URL_AUTH + "find_pw.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 비밀번호 찾기로 비밀번호 변경 */
export function authFindPwUpdate( dispatch:any, idx:number, id:string, unique_key:string, pw:string, callBack:CallFunc|null ) {
    let param : any = {idx:idx, id:id, unique_key:unique_key, pw:pw};

    var formData = null;
    const URL = URL_AUTH + "find_pw_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 비밀번호 확인 */
export function authCheckPw( dispatch:any, pw:string, callBack:CallFunc|null ) {
    let param : any = {pw:pw};

    var formData = null;
    const URL = URL_AUTH + "check_pw.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 비밀번호 변경 */
export function authPwUpdate( dispatch:any, pw:string, new_pw:string, callBack:CallFunc|null ) {
    let param : any = {pw:pw, new_pw:new_pw};

    var formData = null;
    const URL = URL_AUTH + "pw_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 닉네임 변경 */
export function authNickNameUpdate( dispatch:any, nickname:string, callBack:CallFunc|null ) {
    let param : any = {nickname:nickname};

    var formData = null;
    const URL = URL_AUTH + "nick_name_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 이메일 변경 */
export function authEmailUpdate( dispatch:any, email:string, callBack:CallFunc|null ) {
    let param : any = {email:email};

    var formData = null;
    const URL = URL_AUTH + "email_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 회원탈퇴 */
export function authWithdraw( dispatch:any, pw:string, callBack:CallFunc|null ) {
    let param : any = {pw:pw};

    var formData = null;
    const URL = URL_AUTH + "withdraw.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 계정 관련
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 사연 데이터 요청
 * 
 *********************************************************************************************************/

/* 사연 추가 */
export function dataStoryAdd( dispatch:any, params:ParamDataPostAdd, callBack:CallFunc|null ) {
    let param : any = {cate_idx:params.cate_idx, nickname:params.nickname, title:params.title};
    param = addParam( param, "body", params.body);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",     params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "story_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 삭제 */
export function dataStoryDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "story_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 수정 */
export function dataStoryUpdate( dispatch:any, params:ParamDataPostUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, year:params.year};
    param = addParam( param, "title", params.title);
    param = addParam( param, "body", params.body);
    param = addParam( param, "cate_idx", params.cate_idx);

    param = addParam( param, "image_url_01", params.image_url_01);
    param = addParam( param, "image_url_02", params.image_url_02);
    param = addParam( param, "image_url_03", params.image_url_03);
    param = addParam( param, "image_url_04", params.image_url_04);
    param = addParam( param, "image_url_05", params.image_url_05);
    param = addParam( param, "image_url_06", params.image_url_06);
    param = addParam( param, "image_url_07", params.image_url_07);
    param = addParam( param, "image_url_08", params.image_url_08);
    param = addParam( param, "image_url_09", params.image_url_09);
    param = addParam( param, "image_url_10", params.image_url_10);
    
    param = addParam( param, "delete_thumbnail", params.delete_thumbnail);

    param = addParam( param, "delete_url_01", params.delete_url_01);
    param = addParam( param, "delete_url_02", params.delete_url_02);
    param = addParam( param, "delete_url_03", params.delete_url_03);
    param = addParam( param, "delete_url_04", params.delete_url_04);
    param = addParam( param, "delete_url_05", params.delete_url_05);
    param = addParam( param, "delete_url_06", params.delete_url_06);
    param = addParam( param, "delete_url_07", params.delete_url_07);
    param = addParam( param, "delete_url_08", params.delete_url_08);
    param = addParam( param, "delete_url_09", params.delete_url_09);
    param = addParam( param, "delete_url_10", params.delete_url_10);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",   params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "story_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 추가 */
export function dataStoryReplyAdd( dispatch:any, params:ParamDataReplyAdd, callBack:CallFunc|null ) {
    let param : any = {post_idx:params.post_idx, nickname:params.nickname, body:params.body, year:params.year};
    param = addParam( param, "reply_idx", params.reply_idx);

    var formData = null;
    formData = appendFormData( formData, "image_file", params.image_file );

    const URL = URL_DATA + "story_reply_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 삭제 */
export function dataStoryReplyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "story_reply_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 수정 */
export function dataStoryReplyUpdate( dispatch:any, params:ParamDataReplyUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, year:params.year};
    param = addParam( param, "body", params.body);
    param = addParam( param, "image_url", params.image_url);
    param = addParam( param, "delete_url", params.delete_url);

    var formData = null;
    formData = appendFormData( formData, "image_file", params.image_file );

    const URL = URL_DATA + "story_reply_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 게시글 & 댓글 좋아요 */
export function dataStoryLike( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "story_like.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 게시글 & 댓글 싫아요 */
export function dataStoryHate( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "story_hate.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 게시글 & 댓글 신고 */
export function dataStoryReport( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "story_report.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 사연 데이터 요청
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 커뮤니티 데이터 요청
 * 
 *********************************************************************************************************/

/* 커뮤니티 추가 */
export function dataCommunityAdd( dispatch:any, params:ParamDataPostAdd, callBack:CallFunc|null ) {
    let param : any = {cate_idx:params.cate_idx, nickname:params.nickname, title:params.title};
    param = addParam( param, "body", params.body);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",     params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "community_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 삭제 */
export function dataCommunityDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "community_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 수정 */
export function dataCommunityUpdate( dispatch:any, params:ParamDataPostUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, year:params.year};
    param = addParam( param, "title", params.title);
    param = addParam( param, "body", params.body);
    param = addParam( param, "cate_idx", params.cate_idx);

    param = addParam( param, "image_url_01", params.image_url_01);
    param = addParam( param, "image_url_02", params.image_url_02);
    param = addParam( param, "image_url_03", params.image_url_03);
    param = addParam( param, "image_url_04", params.image_url_04);
    param = addParam( param, "image_url_05", params.image_url_05);
    param = addParam( param, "image_url_06", params.image_url_06);
    param = addParam( param, "image_url_07", params.image_url_07);
    param = addParam( param, "image_url_08", params.image_url_08);
    param = addParam( param, "image_url_09", params.image_url_09);
    param = addParam( param, "image_url_10", params.image_url_10);
    
    param = addParam( param, "delete_thumbnail", params.delete_thumbnail);

    param = addParam( param, "delete_url_01", params.delete_url_01);
    param = addParam( param, "delete_url_02", params.delete_url_02);
    param = addParam( param, "delete_url_03", params.delete_url_03);
    param = addParam( param, "delete_url_04", params.delete_url_04);
    param = addParam( param, "delete_url_05", params.delete_url_05);
    param = addParam( param, "delete_url_06", params.delete_url_06);
    param = addParam( param, "delete_url_07", params.delete_url_07);
    param = addParam( param, "delete_url_08", params.delete_url_08);
    param = addParam( param, "delete_url_09", params.delete_url_09);
    param = addParam( param, "delete_url_10", params.delete_url_10);

    var formData = null;
    formData = appendFormData( formData, "thumbnail",     params.thumbnail );
    formData = appendFormData( formData, "image_file_01", params.image_file_01 );
    formData = appendFormData( formData, "image_file_02", params.image_file_02 );
    formData = appendFormData( formData, "image_file_03", params.image_file_03 );
    formData = appendFormData( formData, "image_file_04", params.image_file_04 );
    formData = appendFormData( formData, "image_file_05", params.image_file_05 );
    formData = appendFormData( formData, "image_file_06", params.image_file_06 );
    formData = appendFormData( formData, "image_file_07", params.image_file_07 );
    formData = appendFormData( formData, "image_file_08", params.image_file_08 );
    formData = appendFormData( formData, "image_file_09", params.image_file_09 );
    formData = appendFormData( formData, "image_file_10", params.image_file_10 );

    const URL = URL_DATA + "community_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 추가 */
export function dataCommunityReplyAdd( dispatch:any, params:ParamDataReplyAdd, callBack:CallFunc|null ) {
    let param : any = {post_idx:params.post_idx, nickname:params.nickname, body:params.body, year:params.year};
    param = addParam( param, "reply_idx", params.reply_idx);

    var formData = null;
    formData = appendFormData( formData, "image_file", params.image_file );

    const URL = URL_DATA + "community_reply_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 삭제 */
export function dataCommunityReplyDelete( dispatch:any, idx:number, year:number, callBack:CallFunc|null ) {
    let param : any = {idx:idx, year:year};

    var formData = null;
    const URL = URL_DATA + "community_reply_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 수정 */
export function dataCommunityReplyUpdate( dispatch:any, params:ParamDataReplyUpdate, callBack:CallFunc|null ) {
    let param : any = {idx:params.idx, year:params.year};
    param = addParam( param, "body", params.body);
    param = addParam( param, "image_url", params.image_url);
    param = addParam( param, "delete_url", params.delete_url);

    var formData = null;
    formData = appendFormData( formData, "image_file", params.image_file );

    const URL = URL_DATA + "community_reply_update.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 게시글 & 댓글 좋아요 */
export function dataCommunityLike( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "community_like.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 게시글 & 댓글 싫아요 */
export function dataCommunityHate( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "community_hate.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 게시글 & 댓글 신고 */
export function dataCommunityReport( dispatch:any, post_idx:number, year:number, reply_idx:number|null, callBack:CallFunc|null ) {
    let param : any = {post_idx:post_idx, year:year};
    param = addParam( param, "reply_idx", reply_idx);

    var formData = null;
    const URL = URL_DATA + "community_report.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 커뮤니티 데이터 요청
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 데이터 요청
 * 
 *********************************************************************************************************/

/* 문의하기 */
export function dataInquiryAdd( dispatch:any, type:number, title:string, body:string, callBack:CallFunc|null ) {
    let param : any = {type:type, title:title, body:body};

    var formData = null;
    const URL = URL_DATA + "inquiry_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 문의 답변 하기 */
export function dataInquiryReplyAdd( dispatch:any, inqury_idx:number, body:string, callBack:CallFunc|null ) {
    let param : any = {inqury_idx:inqury_idx, body:body};

    var formData = null;
    const URL = URL_DATA + "inquiry_reply_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 문의 데이터 요청 */
export function dataInquiry( dispatch:any, inqury_idx:number, callBack:CallFunc|null ) {
    let param : any = {inqury_idx:inqury_idx};

    var formData = null;
    const URL = URL_DATA + "inquiry.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 통합 글 데이터 요청 */
export function dataPost( dispatch:any, idx:number, year:number, post_type:number, callBack:CallFunc|null ) {
    
    let param : any = {
        idx             :idx, 
        year            :year, 
        post_type       :post_type};

    var formData = null;
    const URL = URL_DATA + "post.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 차단 유저 추가 */
export function dataBlockUserAdd( dispatch:any, block_user_idx:number, callBack:CallFunc|null ) {
    
    let param : any = {block_user_idx:block_user_idx};

    var formData = null;
    const URL = URL_DATA + "block_user_add.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 차단 유저 제거 */
export function dataBlockUserDelete( dispatch:any, block_user_idx:number, callBack:CallFunc|null ) {
    
    let param : any = {block_user_idx:block_user_idx};

    var formData = null;
    const URL = URL_DATA + "block_user_delete.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 데이터 요청
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 데이터 리스트 요청
 * 
 *********************************************************************************************************/

/* 나의 문의 리스트 */
export function listInquiry( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_LIST + "inquiry.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 내가 쓴글 */
export function listMyPost( dispatch:any, year:number, page:number, post_list_sort_type:number, filter:string|null, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page, post_list_sort_type:post_list_sort_type};
    param = addParam( param, "filter", filter);

    var formData = null;
    const URL = URL_LIST + "my_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 차단 유저 리스트 */
export function listBlockUser( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_LIST + "block_user.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 데이터 리스트 요청
 *********************************************************************************************************/



// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //
//                                                                                                                 //
//                                                ↓↓↓↓ 파라미터 ↓↓↓↓                                                //
//                                                                                                                 //
// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //

/*********************************************************************************************************
 * 계정 관련 파라미터
 *********************************************************************************************************/

export interface ParamAuthSignup {
    id:string,
    pw:string,
    email:string,
    nickname:string,
    name:string,
    number:string,
    birth:number,
    gender:number,
    foreigner:number,
    unique_key:string,
}

/*********************************************************************************************************
 * 데이터 파라미터
 *********************************************************************************************************/

export interface ParamDataPostAdd extends DefaultParamAddImage {
    cate_idx:number,
    nickname:string,
    title:string,
    body:string|null
}

export interface ParamDataPostUpdate extends DefaultParamUpdateImage {
    idx:number,
    year:number,
    title:string|null,
    body:string|null,
    cate_idx:number|null
}

export interface ParamDataReplyAdd {
    post_idx:number,
    reply_idx:number|null,
    nickname:string,
    body:string,
    year:number,
    image_file:File|null
}

export interface ParamDataReplyUpdate {
    idx:number,
    year:number,
    body:string|null,
    image_file:File|null,
    image_url:string|null,
    delete_url:string|null
}