import { NavigateFunction, NavigateOptions } from "react-router-dom";
import { API_TYPES } from "../apiAxios";
import { getCookieLoginData } from "./cookie_helper";
import { PAGE_URL } from "./Page";
import { MAX_NICKNAME_LENGTH, TABLE_START_YEAR_DEFAULT } from "./ConstValue";
import reduxActions from "../redux_saga/modules/moduleActions";

export const getParams = () => {
    const location = window.location;
}

// 현재시간을 초단위로 가져오는 메소드
export const getCurrentSecondsTime = () => {
    const d = new Date();
    return Math.floor(d.getTime()/1000);
}

/**
 * 게시글 삭제 메시지
 * @param use_state 
 */
export const getDeletePostMsg = ( use_state:number ) => {
    if( use_state==API_TYPES.POST_USE_STATE.DELETE )            return "삭제된 글입니다";
    else if( use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE ) return "관리자에 의해 삭제된 글입니다";
    else if( use_state==API_TYPES.POST_USE_STATE.REPORT )       return "신고 누적으로 삭제된 글입니다.";
    return "";
}

/**
 * 댓글 삭제 메시지
 * @param use_state 
 */
export const getDeleteReplyMsg = ( use_state:number ) => {
    if( use_state==API_TYPES.POST_USE_STATE.DELETE )            return "삭제된 댓글입니다";
    else if( use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE ) return "관리자에 의해 삭제된 댓글입니다";
    else if( use_state==API_TYPES.POST_USE_STATE.REPORT )       return "신고 누적으로 삭제된 댓글입니다.";
    return "";
}

/**
 * 두자리수 숫자를 만들어주는 메소드
 */
export const makeTwoNumberStr = ( n:number ) => {
    if( n < 10 ) return "0" + n;
    return "" + n;
}

/**
 * 두자리수 숫자를 만들어주는 메소드
 */
export const makeNumberStr = ( n:number, count:number ) => {
    var str = "";
    for( var i=count-1; i>=0; i-- ) {

        var checkNumber = 1;
        for( var j=0; j<i; j++ ) {
            checkNumber = checkNumber * 10;
        }

        if( checkNumber > n ) {
            str = str + "0";
        }
        else {
            break;
        }
    }
    return str + n;
}

/**
 * 오늘이 몇주차인지를 가져오는 메소드
 * @param d 
 */
export const getWeekData = ( d : Date ) => {
    var newDate = new Date();
    newDate.setTime(d.getTime());

    var data = {
        year:newDate.getFullYear(),
        month:newDate.getMonth(),
        week:0
    };
    // Sunday - Saturday : 0 - 6

    const t = newDate.getDate() - newDate.getDay();
    console.log("getWeekData t : " + t);
    if( t <= 0 ) {
        newDate.setDate(t);
        data = getWeekData(newDate);
    }
    else {
        if( t % 7 == 0 ) {
            data.week = t/7;
        }
        else {
            data.week = Math.floor(t/7) + 1;
        }
    }
    return data;
}

/**
 * 요일 텍스트
 */
export const getWeekDayStr = ( weekDay:number ) => {
    switch(weekDay){
        case 0: return "일";
        case 1: return "월";
        case 2: return "화";
        case 3: return "수";
        case 4: return "목";
        case 5: return "금";
        case 6: return "토";
    }
    return "";
}

/**
 * 게시글 상단 베스트 박스에서 시간이동(일,주,월)이 가능한지를 체크
 * @param periodType 
 * @param currentTime 
 */
export const checkCanMoveTimeInBest = ( periodType:number, currentTime:number, isNext : boolean ) => {
    var isAble = true;
    var d = new Date();
    d.setTime(currentTime);

    if( periodType == API_TYPES.POST_LIST_TYPE.DAY_BEST ) {
        d.setDate( d.getDate() + (isNext?1:-1) );
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.WEEK_BEST ) {
        d.setDate( d.getDate() + (isNext?7:-7) );
    }
    else if( periodType == API_TYPES.POST_LIST_TYPE.MONTH_BEST ) {
        d.setMonth( d.getMonth() + (isNext?1:-1) );
    }

    if( isNext ) {
        var c = new Date();
        if( d.getTime() > c.getTime() ) isAble = false;
    }
    else {
        if( TABLE_START_YEAR_DEFAULT > d.getFullYear() ) isAble = false;
    }

    return isAble;
}


/**
 * DB의 Timestamp 데이터를 가져와 Date 데이터를 생성하는 메소드
 * @param {*} timeStamp 
 */
export const makeDateFromTimeStamp = ( timeStamp:string ) => {
    const dateObject : Date = new Date(timeStamp);
    return dateObject;
}

export const isToday = ( date : Date ) => {
    
	if( !date ) return null;

    date = reMakeDate( date );

	var cDate = new Date();
	const cYear = cDate.getFullYear();
	const cMonth = cDate.getMonth();
	const cDay = cDate.getDate();
    
	return date.getFullYear() == cYear && date.getMonth() == cMonth && date.getDate() == cDay;
}

/**
 * 로그인 유무를 체크하고 로그인이 안되어있다면 메인페이지로 이동하는 메소드
 */
export const checkLogin = ( navigate:NavigateFunction, loginData:any, checkAdmin:boolean = false, dispatch:any = null ) => {
    var data = loginData;
    if( !loginData ) {
		const cd = getCookieLoginData();
        data = cd;
        if( !cd ) {
            navigate(PAGE_URL.MAIN, { replace:true });
        }
    }

    if( checkAdmin ) {
        if( !data || data.is_admin != API_TYPES.BOOLEAN.TRUE ) {
            navigate(PAGE_URL.MAIN, { replace:true });
        }
    }

    if( !loginData && data && dispatch != null ) {
        reduxActions.data.setLoginData(dispatch, data);
    }
}

/**
 * 게시글 전체 시간
 * @param date 
 * @returns 
 */
export const makePostInsertFullTime = ( date:Date ) =>{
	if( !date ) return "";

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

    return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day) + " " + makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
}

/**
 * 천의자리별로 구분자가 들어가는 숫자를 만드는 메소드
 * @param n 
 * @returns 
 */
export const makeThousandNumberStr = ( n : number ) => {
    if( n == 0 ) return "0";

    var str = "";
    var rNumber = n;
    var nList = [];

    while( rNumber > 0 ) {
        var num = rNumber % 1000;
        nList.push(num);
        rNumber = Math.floor( rNumber / 1000 );
    }

    for( var i=0; i<nList.length; i++ ) {
        if( i > 0 ) {
            str = "," + str;
        }
        // 마지막 일떄
        if( i == nList.length - 1 ) {
            str = nList[i] + str;
        }
        else {
            str = makeNumberStr(nList[i], 3) + str;
        }
    }
    return str;
}

const reMakeDate = (date:any) => {
    if( date > 1000000 ) {
        date = makeDateFromTimeStamp(date);
    }
    else if( date instanceof Date ) {

    }
    else {
        date = makeDateFromTimeStamp(date);
    }
    return date;
};

export function makeInsertTimeStr( date:any, isFullDate:boolean = false ) {
	if( !date ) return null;

    date = reMakeDate( date );

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

	// 오늘 일떄
	if( isFullDate == false && isToday(date) ) {
		return makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
	else {
		return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day);
	}
}

export function makeInsertTimFullStr( date:any ) {
	if( !date ) return null;

    date = reMakeDate( date );

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

	// 오늘 일떄
	if( isToday(date) ) {
		return makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
	else {
		return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day) + " " + makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
}

export function makeInsertTimeFullFromTimeStamp( date:any ) {
	if( !date ) return null;

    date = reMakeDate( date );

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

    return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day) + " " + makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
}

export const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('클립보드에 복사되었습니다.');
    } catch (e) {
      alert('복사에 실패하였습니다');
    }
};

/**
 * 특수문자가 들어있는지 체크
 * @param text 
 * @returns 
 */
const checkSpe = ( text:string ) => {
    const reg = /[a-zA-Z0-9ㄱ-힣]/g;
    var replaceText = text.replace(reg, "");
    if( replaceText && replaceText != "" && replaceText.length > 0 ) {
        return true;
    }
    return false;
}

/**
 * 아이디 유효성 체크
 * @param id 
 * @returns 
 */
export const chkID = ( id:string ) => {
    var kr  = id.search(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig);
    var num = id.search(/[0-9]/g);
    var eng = id.search(/[a-z]/ig);
    var spe = id.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/ig);
    var upper = id.search(/[A-Z]/g);
   
    console.log("chkID kr " + kr);
    console.log("chkID upper " + upper);

    if(id.length < 4 || id.length > 20) { return "아이디는 4자리 ~ 20자리 이내로 입력해주세요"; }
    else if(kr >= 0 )                   { return "아이디에 한글을 사용할 수 없습니다"; }
    else if(upper >= 0 )                { return "아이디에 대문자를 사용할 수 없습니다"; }
    else if( checkSpe(id) )             { return "아이디에 특수문자를 사용할 수 없습니다"; }
    
    return null;
}

/**
 * 비밀번호 유효성 체크
 * @param pw 
 * @returns 
 */
export const chkPW = ( pw:string ) => {
    var kr  = pw.search(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/ig);
    var num = pw.search(/[0-9]/g);
    var eng = pw.search(/[a-z]/ig);
   
    if(pw.length < 8 || pw.length > 20)     { return "비밀번호는 8자리 ~ 20자리 이내로 입력해주세요"; }
    else if(pw.search(/\s/) != -1)          { return "비밀번호는 공백 없이 입력해주세요"; }
    else if(num < 0 || eng < 0 || !checkSpe(pw) ) { return "비밀번호는 영문, 숫자, 특수문자를 혼합하여 입력해주세요"; }
    else if(kr >= 0 )                       { return "비밀번호에 한글이 포함되어 있습니다"; }
    return null;
}

/**
 * 닉네임 유효성 체크
 * @param id 
 * @returns 
 */
export const chkNickName = ( nickname:string ) => {

    if(nickname.length > MAX_NICKNAME_LENGTH)   { return "닉네임은 " + MAX_NICKNAME_LENGTH + "자리 이내로 입력해주세요"; }
    else if( checkSpe(nickname) )               { return "닉네임에 특수문자를 사용할 수 없습니다"; }
    else if( nickname.indexOf("관리자") > -1 )  { return "닉네임에 관리자를 포함할 수 없습니다"; }
    else if( nickname.indexOf("운영자") > -1 )  { return "닉네임에 운영자를 포함할 수 없습니다"; }
    else if( nickname.indexOf("매니저") > -1 )  { return "닉네임에 매니저를 포함할 수 없습니다"; }
    else if( nickname.indexOf("메니저") > -1 )  { return "닉네임에 메니저를 포함할 수 없습니다"; }
    
    return null;
}

/**
 * 본인인증 팝업
 * @param window 
 * @returns 
 */
export const openCertificationPopup = (window:any) =>{
    var Width = 380;
    var Height = 610;
    
    var features = "width="+ Width + ",height=" + Height + ",left=" + 10 + ",top=" + 10 +",toolbar=no,resizable=no,popup=yes";
    
    var win = window.open(PAGE_URL.CERTIFICATION, "socialPopupWindow", features);
    
    return win;
}

/**
 * 리스트의 내용의 개행을 없애는 메소드
 * @param str 
 * @returns 
 */
export const remakeBodyStrInList = ( str : string ) => {
    return str.replace(/\n/g, " ");
}

export interface CertificationData {
    birth:number,
    unique_key:string,
    phone:string,
    name:string,
    gender:number,
    foreigner:number
}

/**
 * 파라미터 데이터를 추출하는 메소드
 * @param params 
 * @param key 
 * @param defaultData 
 * @param isString 
 * @returns 
 */
export const getParamData = ( params : URLSearchParams, key:string, defaultData:any, isString:boolean = false ) => {
    const data : any = params.get(key);
    
    var newData : any = defaultData;
    if( data ) 	{ 
        if( isString )  { try{ newData = data; 		        }catch(e){ console.log("e",e);} }
        else            { try{ newData = parseInt(data);    }catch(e){ console.log("e",e);} }
    }

    return newData;
}

/**
 * 숫자여부 체크
 * @param text 
 * @returns 
 */
export const isNumber = (text:string) => {
  for( var i=0; i<text.length; i++ ) {
    var subText = text.substring(i,i+1);
    if( isNaN(parseInt(subText)) == true ) return false;
  }

  return true;
}

export const movePage = ( navigate:NavigateFunction, event:any, url:string, options?:NavigateOptions|undefined ) => {
    if( event && event.ctrlKey ) {
        window.open(url);
    }
    else {
        navigate(url, options);
    }
}

export const hideIdStr = ( id:string ) => {
    if( !!id && id.length > 3 ) {
        return id.substring(0,3) + "*****";
    }
    return id;
}