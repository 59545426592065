/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../../sub/TopTab';
import LeftAd from '../../../sub/LeftAd';
import RightArea from '../../../sub/RightArea';
import { getCookieLoginData } from '../../../../util/cookie_helper';
import BottomCompanyInfo from '../../../sub/BottomCompanyInfo';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../../../sub/Wrapper';
import TopAdComponent from '../../../sub/TopAdComponent';
import LoginBox from './sub/LoginBox';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../../sub/MobileWrapper';
import { RootState } from '../../../../redux_saga/modules';
import UserBox from './sub/UserBox';

const MobileLogin = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
    const userData = useSelector((state:RootState)=>state.data.userData);
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

    return (
        <MobileWrapper
            tabType={MOBILE_TAB_TYPE.NONE}
            hideBottomCompanyInfo={true}
            hideNotice={true}
            subTitle={userData?"내정보":"로그인"}>
            <MiddleComponent 
                {...props} />
        </MobileWrapper>
    );
};

export default MobileLogin;


function MiddleComponent( props:any ) {
    const loginData = useSelector((state:RootState)=>state.data.loginData);

	return( <>
        { loginData && <UserBox {...props}/>}
        { !loginData && <LoginBox {...props}/>}
    </>
	)
}