/****************************************************************************************
 * 
 *  메인화면 카테고리 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../../redux_saga/modules';

function MobileMainCategoryArea( props:any ) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

    const [storyList, setStoryList] = useState<any>(null);
    const [communityList, setCommunityList] = useState<any>(null);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    useEffect(()=>{
        reqCategory();
        return(()=>{})
    },[]);

    useEffect(()=>{

        var maxCount = 0;
        if( storyCategoryList && communityCategoryList ) {
            maxCount = storyCategoryList.length;
            maxCount = communityCategoryList.length > maxCount?communityCategoryList.length:maxCount;
            
           

            var newStoryList =  makeRowList(storyCategoryList, maxCount);
            var newCommunityList =  makeRowList(communityCategoryList, maxCount);

           /* for( var i=0; i<maxCount - storyCategoryList.length; i++ ) {
                newStoryList.push(null);
            }
            for( var i=0; i<maxCount - communityCategoryList.length; i++ ) {
                newCommunityList.push(null);
            }*/
            setStoryList(newStoryList);
            setCommunityList(newCommunityList);
        }

        
        return(()=>{})
    },[storyCategoryList, communityCategoryList]);

    const makeRowList = (list:any, maxCount:number) => {
        var rowList : any = [];

        for( var i=0; i<maxCount; i++ ) {
            var rowNum = Math.floor( i / 2 ) + 1;
            if( rowList.length < rowNum ) {
                rowList.push([]);
            }

            if( i < list.length ) {
                rowList[rowNum-1].push(list[i]);
            }
            else {
                rowList[rowNum-1].push(null);
            }
        }
        return rowList;
    }
    
	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
	}

	return(
        <table className='mobile_home__category_area' border={1}>
            <tr>
                <th>사연</th>
                <th>커뮤니티</th>
            </tr>
            <tr>
                <td>
                    <table>
                        <CategoryComponent {...props} categoryList={storyList} 
                            onClick={(item:any)=>{
                                navigate( PAGE_URL.STORY + "?category="+item.idx );
                            }}/>
                    </table>
                </td>
                <td>
                    <table>
                        <CategoryComponent {...props} categoryList={communityList} 
                            onClick={(item:any)=>{
                                navigate( PAGE_URL.COMMUNITY + "?category="+item.idx );
                            }}/>
                    </table>
                </td>
            </tr>
        </table>
	)
}
export default MobileMainCategoryArea;

function CategoryComponent( props : any ) {
    const {categoryList, onClick} = props;

    return ( <>
        { !!categoryList && categoryList.length > 0 &&
            categoryList&&categoryList.map((rowList:any, index:number)=>{

                        
                var item_1 = rowList[0];
                var item_2 = rowList[1];


                return(
                    <tr key={index}>
                        <CategoryItem {...props} item={item_1} />
                        <CategoryItem {...props} item={item_2} />
                    </tr>
                )
            })}
    </>)
    /*
    
            categoryList.map((item:any, index:number)=>{

                if( !item ) {
                    return(<div className='empty' key={index}>{"·"}</div>)
                }
                return( 
                    <div onClick={()=>{onClick(item)}} key={index}>{item.name}</div>
                )
            })
        }
    */
}



function CategoryItem( props:any ) {
    const {item, currentCategory, onClick} = props;

    if( !item ) {
        return(
            <td className={"empty"}>
                <span>{"·"}</span>
            </td>
        )
    }

    return(
        <td onClick={()=>{onClick(item)}}><span>{item.name}</span></td>
    )
}