import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { Oval } from 'react-loader-spinner';


function ButtonDefault( props : any ) {
    const { onClick, className, value, isLoading, loaderSize, loaderColor, fontSize } = props;
    
    var style : any = {};
    if( fontSize ) style["fontSize"] = fontSize;

    useEffect(()=>{
        return(()=>{})
    },[]);

    return (
        <div className={'button_default' + (className?" " + className:"")} style={style} onClick={(e:any)=>{
            if( isLoading != true && onClick ) onClick(e);
        }}> 
            { isLoading &&
                <div className='loader'>
                    <Oval 
                        color={loaderColor?loaderColor:"#fff"}
                        height={loaderSize?loaderSize:20} 
                        width={loaderSize?loaderSize:20}
                        />
                </div> 
            }
            { !isLoading && <span>{value}</span> }
        </div>
    );
}

export default ButtonDefault;