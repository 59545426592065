import { ChangeEvent } from "react";
import FileResizer from "react-image-file-resizer";
import { expose } from "comlink";
import { TEST_VERSION } from "./ConstValue";

const IMG_MAX_SIZE = 1024 * 1024; // 이미지 최대 용량 제한 : 1MB
const PRE_VIEW_IMG_MAX_SIZE = 10 * 1024; // 이미지 최대 용량 제한 : 10KB
const IMAGE_MAX_WIDTH = 5000;
const PRE_VIEW_IMAGE_MAX_WIDTH = 1000;

export interface ResponseImage {
    image:ImageData|null,
    preView:ImageData|null
}

export interface ImageData {
	url:any, 
	blob:any, 
	file:any
}

export const resizeFile = (file: File, width:number) =>
    new Promise((res) => {
    FileResizer.imageFileResizer(
        file, // target file
        width, // maxWidth
        width, // maxHeight
        "JPEG", // compressFormat : Can be either JPEG, PNG or WEBP.
        90, // quality : 0 and 100. Used for the JPEG compression
        0, // rotation
        (uri) => res(uri), // responseUriFunc
        "file" // outputType : Can be either base64, blob or file.(Default type is base64)	
    );
});

export const resizePreview = (file: File, width:number) =>
    new Promise((res) => {
        FileResizer.imageFileResizer(
        file,
        width,
        width,
        "JPEG",
        60,
        0,
        (uri) => res(uri),
        "file"
    );
});

export const loadImage = (file:File) => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => {    
            resolve(reader.result);
        });
        reader.readAsDataURL(file);
    })
}

export const getImageFile = async (e: ChangeEvent<HTMLInputElement>, callback:Function) => {
    const target : any = e.target.files;
    if( target && target.length > 0 ) {
        var responseImage : ResponseImage = { image:null, preView:null };

        const newFiles: File[] = Array.from(target);
        const file:File = newFiles[0];

        var reFile = file;

        // 이미지 생셩
        var width = IMAGE_MAX_WIDTH;
        while( reFile.size > IMG_MAX_SIZE ) {
            reFile = (await resizeFile(reFile, width)) as File;
            console.log("getImageFile image ------ size : " + reFile.size);
            if( reFile.size > IMG_MAX_SIZE ) {
                var reWidth = width * 8 / 10;
                if( width * IMG_MAX_SIZE / reFile.size < reWidth ) {
                    width = Math.floor(width * IMG_MAX_SIZE / reFile.size);
                }
                width = Math.floor(width * 8 / 10);
            } 
        }
        console.log("getImageFile image size : " + reFile.size);
        var blob = await loadImage(reFile);
        responseImage.image = {url:null, blob:blob, file:reFile};
        
        // Pre View 이미지 생셩
        width = PRE_VIEW_IMAGE_MAX_WIDTH;
        while( reFile.size > PRE_VIEW_IMG_MAX_SIZE ) {
            reFile = (await resizePreview(reFile, width)) as File;
            console.log("getImageFile preView ------ size : " + reFile.size);
            if( reFile.size > PRE_VIEW_IMG_MAX_SIZE ) width = Math.floor(width * 8 / 10);
        }
        console.log("getImageFile preView size : " + reFile.size);
        var blob = await loadImage(reFile);
        responseImage.preView = {url:null, blob:blob, file:reFile};
        
        callback(responseImage);
    }
    else {
        callback(null);
    }
}

export const makePreViewFile = async ( url:string ) => {
    const file : File = await convertURLtoFile(url);
    var reFile = file;

    var width = PRE_VIEW_IMAGE_MAX_WIDTH;
    while( reFile.size > PRE_VIEW_IMG_MAX_SIZE ) {
        reFile = (await resizePreview(reFile, width)) as File;
        console.log("getImageFile preView ------ size : " + reFile.size);
        if( reFile.size > PRE_VIEW_IMG_MAX_SIZE ) width = Math.floor(width * 8 / 10);
    }
    console.log("getImageFile preView size : " + reFile.size);
    var blob = await loadImage(reFile);
    var preViewImage = {url:null, blob:blob, file:reFile};
    
    return preViewImage;
}

const convertURLtoFile = async (url: string) => {
    const response = await fetch(resetHostName(url));
    const data = await response.blob();
    const ext = url.split(".").pop(); // url 구조에 맞게 수정할 것
    const filename = url.split("/").pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };
    return new File([data], filename!, metadata);
};

/**
 * 이미지 주소의 도메인을 현재 도메인으로 맞춰주는 메소드
 * @param url 
 * @returns 
 */
const resetHostName = (url:string) => {
    if( window.location.hostname!="localhost" ) {
        if( url.indexOf(window.location.hostname) == -1 && !TEST_VERSION ) {
            return url.replace("listen85.mycafe24.com", window.location.hostname);
        }
    }
    return url;
}

/*
const handleAddImg = async (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.files;

    if (target && target.length) {
        const newFiles: File[] = Array.from(target);
        newFiles.map(async (file) => {
        if (file.size > IMG_MAX_SIZE) {
            // 이미지 최대 용량 제한
            window.alert(
            `${Math.round(
                IMG_MAX_SIZE / 1000000
            )}MB 이하의 사진만 등록할 수 있습니다.`
            );
        } else {
            try {
            const compressedFile = (await resizeFile(file)) as File;
            const compressedPreview = (await resizePreview(file)) as string;
            setFiles((prev) => [...prev, compressedFile]);
            setPreviewList((prev: DiskImgType[]) => [
                ...prev,
                { imgId: "new", imgUrl: compressedPreview },
            ]);
            } catch (err) {
            window.alert("사진을 불러올 수 없습니다.");
            throw err;
            }
        }
        });
    }
};*/