import React, { useEffect, useRef, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupTaboo = ( props:any ) => {
    const { visible, tabooData, onSuccess }=props;
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nameStr, setNameStr] = useState("");
    const [requesting, setRequesting] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const inputRef : any = useRef();

    useEffect(()=>{
        if( tabooData ) {
            setNameStr(tabooData.name);
        }
        else {
            setNameStr("");
        }
        setIsCheck(false);
        return(()=>{})
    },[tabooData, visible])

    var popupTitle = "금지어";
    if( tabooData )  { popupTitle += " 수정"; }
    else             { popupTitle += " 추가"; }

    const clickedSave = () => {
        if( nameStr == "" ) { alert("금지어를 입력하세요"); return; }
        if( requesting ) { return; };

        setRequesting(true);
        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setNameStr("");
                var isClosePopup = !isCheck;
                onSuccess( isClosePopup, nameStr );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 금지어 입니다");
				}
			}
            setRequesting(false);
            
            if( inputRef && inputRef.current ) {
                setTimeout(()=>{
                    if( inputRef && inputRef.current ) {
                        inputRef.current.focus();
                    }
                },100);
            }
		}

        if( tabooData )  apiAxios.admin.dataTabooUpdate(dispatch, tabooData.idx, nameStr, callback);
        else                apiAxios.admin.dataTabooAdd(dispatch, nameStr, callback);
    }
  
    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        clickedSave();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
        setNameStr("");
      }
    }

    return (
        <PopupWrapper {...props}
            title={popupTitle}
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title={"저장"} onClick={clickedSave} loading={requesting}/>
            </>}>
            
            <div className='body_area popup_taboo'>
                <input ref={inputRef} value={nameStr} className={requesting?"disabled":""} placeholder='금지어를 추가해주세요' onChange={(e)=> { setNameStr(e.target.value); }} 
                    onKeyUp={handleKeyUp} disabled={requesting}/>
                
                { !tabooData &&
                    <div className={'check_area' + (!isCheck?" no_check":"") } onClick={()=>{setIsCheck(!isCheck);}}>
                        <div className='checkbox'>
                            {isCheck && <BsCheckSquareFill size={20}/>}
                            {!isCheck && <BsCheckSquare size={20}/>}
                        </div>
                        <div className='check_title'><span>연속으로 추가하기 ( 팝업답힘X )</span></div>
                    </div>
                }
            </div>
        </PopupWrapper>
    );
};
  
export default PopupTaboo;