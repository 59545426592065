/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../../apiAxios';
import { RootState } from '../../../../../redux_saga/modules';
import { PAGE_URL } from '../../../../../util/Page';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import ButtonDefault from '../../../../sub/ButtonDefault';
import { chkPW } from '../../../../../util/Global';
import { FiLock } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import MobileInputBox from '../sub/MobileInputBox';
import { BsPerson } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import MobileWrapper from '../../../sub/MobileWrapper';
import MobileCertification from '../../certification';
import { APP_INTERFACE_SEND_TYPE, sendDataToApp } from '../../../../../util/AppInterface';

const MobileFindPw = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();    
    const loginData = useSelector((state:RootState)=>state.data.loginData);
	
	useEffect(()=>{
        if( loginData ) {
            navigate(PAGE_URL.MAIN, {replace:true});
        }

		return(()=>{})
	},[loginData]);

	var tabType = -1;

	return (<>
		<MobileWrapper
            hideNotice={true}
            hideTab={true}
            hideBottomCompanyInfo={true}
            subTitle={"비밀번호 찾기"}
			tabType={tabType}>
			<MiddleComponent 
				{...props} 
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileFindPw;

enum Tab {
    CERTIFICATION=1,
    EMAIL=2
}

interface ChangeData {
	idx:number,
	id:string,
	unique_key:string
}

const BTN_FONT_SIZE = 14;

function MiddleComponent( props:any ) {

    const [tab, setTab] = useState(Tab.CERTIFICATION);
    const [changeData, setChangeData] = useState<ChangeData|null>(null);

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
            <div className='mobile_find'>
                <div className='box'>
                    { !changeData && <>
                    <FindUseCertification 
                        {...props}
                        tab={tab}
                        setTab={setTab}
                        setChangeData={setChangeData}
                        />
                    <FindUseEmail 
                        {...props}
                        tab={tab}
                        setTab={setTab}
                        />
                    </>}
                    { !!changeData && 
						<UpdatePassword 
							{...props}
							changeData={changeData}
							/>
                    }
                </div>
            </div>
		</div>
	)
}

function FindUseCertification( props:any ) {
    const {tab, setTab, setChangeData} = props;
    const [idStr, setIdStr] = useState("");
    const [showCertification, setShowCertification] = useState(false);
    const dispatch = useDispatch();

    
    const receiveMessage = (receiveData : any) => {
        // event.data에 전송된 데이터가 있음
        console.log('받은 데이터:', receiveData);

        if( receiveData ) {
          var resData : any = null;
          try{resData = JSON.parse(receiveData);}
          catch(e){}
          
          // 수신 성공
          if( resData && resData.state == 0 ) {
              if( resData.data && resData.data.code == 0 ) {
                  const responseData = resData.data.response;
                  console.log("responseData", responseData);

                  apiAxios.user.authFindPw(dispatch, idStr, responseData.unique_key, null, (response:AxiosResponse|null, error:AxiosError|null) => {
                      if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                          const resData = API_UTILS.getResponseSuccessData(response);

                          var d : ChangeData = {
                              idx:resData.idx,
                              id:idStr,
                              unique_key:responseData.unique_key
                          }
                          setChangeData( d );
                          alert("계정을 찾았습니다");
                      }
                      else if( error ) {
                          const errorData : any = API_UTILS.getErrorResponseData(error);
                          if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                              alert("가입한 계정이 없습니다");
                          }
                      }
                  })
              }
          }
          else if( resData && resData.state == -2 ) {
              alert("사용자 정보를 가지고 오지 못합니다.");
          }
        }
    };

    return(
        <div className='item'>
            <div className={'tab' + (tab==Tab.CERTIFICATION?" tab_focus":"")} onClick={()=>{setTab(Tab.CERTIFICATION);}}>
                <div className='checkbox'>
                    {tab==Tab.CERTIFICATION && <BsCheckSquareFill size={20}/>}
                    {tab!=Tab.CERTIFICATION && <BsCheckSquare size={20}/>}
                </div>
                <div className='tab_title'><span>본인인증으로 찾기</span></div>
            </div>

            {tab==Tab.CERTIFICATION && 
                <div className='item_body'>
					<MobileInputBox 
                        disabled={!!showCertification}
                        placeholder={"아이디 입력"}
                        leftIcon={<BsPerson size={20}/>}
                        value={idStr}
                        setValue={(text:string)=>{
                            text = text.replace(/ /g, ''); // 공백제거
                            setIdStr(text); 
                        }}
                        />
                    <ButtonDefault
                        value={"본인인증"}
                        isLoading={!!showCertification}
                        fontSize={BTN_FONT_SIZE}
                        onClick={()=>{ 
							if( !idStr ) {
								alert("아이디를 입력하세요");
								return;
							}
                            setShowCertification( true );
						}}
                        />
                </div>
            }
        
            { showCertification && 
                <MobileCertification {...props}
                    onResult={receiveMessage}
                    show={showCertification}
                    setShow={setShowCertification}
                />
            }
        </div>
    )
}

function FindUseEmail( props:any ) {
    const {tab, setTab} = props;
    const dispatch = useDispatch();
	const navigate = useNavigate();

    const [idStr, setIdStr] = useState("");
    const [emailStr, setEmailStr] = useState("");
    const [requesting, setRequesting] = useState(false);

    const clickFind = () => {
        if( requesting ) return;

        var errorMsg = checkInput();
        if( !!errorMsg ) {
            alert(errorMsg);
            return;
        }

        setRequesting(true);
        
        apiAxios.user.authFindPw(dispatch, idStr, null, emailStr, (response:AxiosResponse|null, error:AxiosError|null) => {
            if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                const resData = API_UTILS.getResponseSuccessData(response);
                alert(resData.email + "로\n새로운 비밀번호를 전송했습니다");

                setIdStr("");
                setEmailStr("");
				navigate(PAGE_URL.MAIN, {replace:true});
            }
            else if( error ) {
                const errorData : any = API_UTILS.getErrorResponseData(error);
                if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("계정을 찾을 수 없습니다");
                }
            }
            setRequesting(false);
        })
    }

    const checkInput = () => {
        if( !idStr ) return "아이디를 입력하세요";
        if( !emailStr ) return "이메일을 입력하세요";
        return null;
    }

    return(
        <div className='item second'>
            <div className={'tab' + (tab==Tab.EMAIL?" tab_focus":"")} onClick={()=>{setTab(Tab.EMAIL);}}>
                <div className='checkbox'>
                    {tab==Tab.EMAIL && <BsCheckSquareFill size={20}/>}
                    {tab!=Tab.EMAIL && <BsCheckSquare size={20}/>}
                </div>
                <div className='tab_title'><span>이메일로 찾기</span></div>
            </div>

            {tab==Tab.EMAIL && 
                <div className='item_body'>
					<MobileInputBox 
						disabled={!!requesting}
						placeholder={"아이디 입력"}
						leftIcon={<BsPerson size={20}/>}
						value={idStr}
						setValue={(text:string)=>{
							text = text.replace(/ /g, ''); // 공백제거
							setIdStr(text); 
						}}
						/>
					<MobileInputBox 
						disabled={!!requesting}
						placeholder={"이메일 입력"}
						leftIcon={<BsEnvelope size={20}/>}
						value={emailStr}
						setValue={(text:string)=>{
							text = text.replace(/ /g, ''); // 공백제거
							setEmailStr(text); 
						}}
						/>
                    <ButtonDefault
                        value={"찾기"}
                        onClick={clickFind}
                        isLoading={requesting}
                        fontSize={BTN_FONT_SIZE}
                        />
                </div>
            }
        </div>
    )
}



function UpdatePassword( props:any ) {
    const changeData : ChangeData = props.changeData;
    const dispatch = useDispatch();
	const navigate = useNavigate();
    const [pwStr, setPwStr] = useState("");
    const [rePwStr, setRePwStr] = useState("");
    const [requesting, setRequesting] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showRePassword, setShowRePassword] = useState(false);

    const clickFind = () => {
        if( requesting ) return;

        var errorMsg = checkInput();
        if( !!errorMsg ) {
            alert(errorMsg);
            return;
        }

        setRequesting(true);
        
    	apiAxios.user.authFindPwUpdate(dispatch, changeData.idx, changeData.id, changeData.unique_key, pwStr, (response:AxiosResponse|null, error:AxiosError|null) => {
            if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                const resData = API_UTILS.getResponseSuccessData(response);
                alert("비밀번호를 변경했습니다");
				navigate(PAGE_URL.MAIN, {replace:true});
            }
            else if( error ) {
                const errorData : any = API_UTILS.getErrorResponseData(error);
                if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("계정을 찾을 수 없습니다");
                }
            }
            setRequesting(false);
        })
    }

    const checkInput = () => {
        if( !pwStr ) return "새 비밀번호를 입력하세요";
        if( !rePwStr ) return "비밀번호를 다시 입력하세요";
		if( pwStr != rePwStr ) return "비밀번호가 일치하지 않습니다";
		
		return chkPW(pwStr);
    }

    return(
        <div className='item'>
            <div className={'title'}>
				<span>비밀번호를 변경하여 사용하세요</span>
            </div>

            <div className='item_body'>
				<MobileInputBox 
					placeholder={"새 비밀번호 입력"}
					disabled={!!requesting}
					leftIcon={<FiLock size={20}/>}
					rightIcon={ <PasswordRightIcon show={showPassword} setShow={setShowPassword}/> }
					value={pwStr}
					setValue={(text:string)=>{
						text = text.replace(/ /g, ''); // 공백제거
						setPwStr(text); 
					}}
					isPassword={showPassword==false}
					/>

				<MobileInputBox 
					disabled={!!requesting}
					placeholder={"비밀번호 다시 입력"}
					leftIcon={<FiLock size={20}/>}
					rightIcon={ <PasswordRightIcon show={showRePassword} setShow={setShowRePassword}/> }
					value={rePwStr}
					setValue={(text:string)=>{
						text = text.replace(/ /g, ''); // 공백제거
						setRePwStr(text); 
					}}
					isPassword={showRePassword==false}
					/>
				<ButtonDefault
					value={"변경"}
					onClick={clickFind}
					isLoading={requesting}
                    fontSize={BTN_FONT_SIZE}
					/>
			</div>
        </div>
    )
}



const SHOW_TEXT = "숨김해제";
const HIDE_TEXT = "입력숨김";

/**
 * 비밀번호 입력 우측 아이콘
 * @param props 
 * @returns 
 */
function PasswordRightIcon( props:any ) {
    const {show, setShow} = props;

    return( <>
        { show && <FiEye title={HIDE_TEXT} size={24} onClick={()=>{setShow(false)}}/> }
        { !show && <FiEyeOff title={SHOW_TEXT} size={24} onClick={()=>{setShow(true)}}/> }
    </>)
}