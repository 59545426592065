
/*
STORY           = "STORY",		    // 사연 관리
STORY_PART      = "STORY_PART",	    // 사연 부분 관리
COMMUNITY       = "COMMUNITY",	    // 커뮤니티 관리
COMMUNITY_PART  = "CMU_PART", // 커뮤니티 부분 관리
NOTICE          = "NOTICE",		    // 공지사항 관리
USER_ADMIN      = "USER_ADMIN",	    // 관리자 관리
USER            = "USER",		    // 사용자 관리
SYSTEM          = "SYSTEM",		    // 시스템 관리
INQUIRY         = "INQUIRY"		    // 문의 관리*/

import { Location } from "react-router-dom";
import { API_TYPES } from "../apiAxios";
import { ADMIN_SUB_TAP_COMMUNITY, ADMIN_SUB_TAP_SETTING, ADMIN_SUB_TAP_STORY, ADMIN_SUB_TAP_USER, PAGE_URL } from "./Page";

/*************************************************************************************************
 * 
 * 유저 페이지 관리 가능 유무
 * 
 *************************************************************************************************/

export enum USER_MANAGE_TYPE {
    NONE=0,
    STORY=1,
    COMMUNITY=2,
    NOTICE=3
}

/**
 * 유저 페이지 관리 가능한지를 체크하는 구문
 * @param loginData 
 * @param data 
 * @returns 
 */
export const checkAbleManageUser = ( loginData:any, type:USER_MANAGE_TYPE, category:number|null, post_type:number|null=null) => {
    if( loginData && loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
        if( loginData.admin_type == API_TYPES.ADMIN_TYPE.TOP || loginData.access == API_TYPES.MANAGE_ACCESS.ALL )  return true;
        
        // 사연 관리
        if( type == USER_MANAGE_TYPE.STORY ) {
            // 사연 전체 관리
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 ) { return true; }
            // 사연 부분 관리
            else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 ) {
                if( checkAbleManageCategory(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART, category) == true ) return true;
            }
        }
        // 커뮤니티 관리
        else if( type == USER_MANAGE_TYPE.COMMUNITY ) {
            // 커뮤니티 전체 관리
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 ) { return true; }
            // 커뮤니티 부분 관리
            else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) {
                if( checkAbleManageCategory(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART, category) == true ) return true;
            }
        }
        // 공지사항 관리
        else if( type == USER_MANAGE_TYPE.NOTICE ) {
            // 공지사항 관리권한이 있을때
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) > -1 ) { return true; }
            // 공지사항 타깃이 사연 일때
            else if( post_type == API_TYPES.POST_TYPE.STORY ) {
                // 사연전체 관리일떄 
                if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 ) { return true; }
                // 사연 특정 카테고리 관리일떄
                else if( category != null && checkAbleManageCategory(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART, category) == true ) return true;
            }
            // 공지사항 타깃이 커뮤니티 일떄
            else if( post_type == API_TYPES.POST_TYPE.COMMUNITY ) {
                // 커뮤니티 전체 관리일떄
                if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 ) { return true; }
                // 커뮤니티 특정 카테고리 관리일떄
                else if( category != null && checkAbleManageCategory(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART, category) == true ) return true;
            }
        }
    }
    return false;
}

/** End - 유저 페이지 관리 가능 유무
 *************************************************************************************************/



/*************************************************************************************************
 * 
 * 관리자 페이지 관리 가능 유무
 * 
 *************************************************************************************************/

export enum ADMIN_MANAGE_TYPE {
    STORY=10,
    STORY_CATEGORY=11,
    STORY_LIST=12,
    COMMUNITY=20,
    COMMUNITY_CATEGORY=21,
    COMMUNITY_LIST=22,
    NOTICE=30,
    ACCOUNT=40,
    ACCOUNT_ADMIN=41,
    ACCOUNT_USER=42,
    INQUIRY=50,
    TABOO=60,
    SYSTEM=100
}

export interface AdminManageData {
    type:ADMIN_MANAGE_TYPE
    category:number|null
    post_type:number|null
}

export enum ADMIN_ACCESS_TYPE {
    ALL=1,  // 접근가능
    PART=2, // 부분 접근가능
    CANT=3, // 접근 불가
}

export interface AdminAccessResult {
    accessType:ADMIN_ACCESS_TYPE// 접근 가능 타입
    categorys:string|null // 접근가능한 카테고리 리스트
}

export const makeAdminManageData = ( type:ADMIN_MANAGE_TYPE, category:number|null = null, post_type:number|null = null ) : AdminManageData => {
    return{ type:type, category:category, post_type:post_type};
}

/**
 * 접근 불가능한 페이지 일때 홈화면으로 이동
 * @param navigate 
 * @param loginData 
 * @param data 
 */
export const checkAccessRePage = ( navigate:any, loginData:any, location:Location ) => {

    console.log("checkAccessRePage", location);
    const l_p = new URLSearchParams(location.search);
    const t : any = l_p.get("tab");

    var adminManageType = null;
    
    if( location.pathname == "/admin/main" ) {

    }
    else if( location.pathname == "/admin/story" ) {
        if( t == ADMIN_SUB_TAP_STORY.CATEGORY )      { adminManageType = ADMIN_MANAGE_TYPE.STORY_CATEGORY; }
        else if( t != ADMIN_SUB_TAP_STORY.CATEGORY ) { adminManageType = ADMIN_MANAGE_TYPE.STORY_LIST; }
    }
    else if( location.pathname == "/admin/community" ) {
        if( t == ADMIN_SUB_TAP_COMMUNITY.CATEGORY )      { adminManageType = ADMIN_MANAGE_TYPE.COMMUNITY_CATEGORY; }
        else if( t != ADMIN_SUB_TAP_COMMUNITY.CATEGORY ) { adminManageType = ADMIN_MANAGE_TYPE.COMMUNITY_LIST; }
    }
    else if( location.pathname == "/admin/notice" ) {
        adminManageType = ADMIN_MANAGE_TYPE.NOTICE;
    }
    else if( location.pathname == "/admin/user" ) {
        if( t == ADMIN_SUB_TAP_USER.ADMIN_USER )        { adminManageType = ADMIN_MANAGE_TYPE.ACCOUNT_ADMIN; }
        else if( t == ADMIN_SUB_TAP_USER.USER )         { adminManageType = ADMIN_MANAGE_TYPE.ACCOUNT_USER; }
        else if( t == ADMIN_SUB_TAP_USER.BLACKLIST )    { adminManageType = ADMIN_MANAGE_TYPE.ACCOUNT_USER; }
    }
    else if( location.pathname == "/admin/inquiry" ) {
        adminManageType = ADMIN_MANAGE_TYPE.INQUIRY;
    }
    else if( location.pathname == "/admin/setting" ) {
        if( t == ADMIN_SUB_TAP_SETTING.SYSTEM_SETTING ) { adminManageType = ADMIN_MANAGE_TYPE.SYSTEM; }
        else if( t == ADMIN_SUB_TAP_SETTING.TABOO )     { adminManageType = ADMIN_MANAGE_TYPE.TABOO; }
    }

    if( adminManageType ) {
        var result : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(adminManageType));
        if( result.accessType == ADMIN_ACCESS_TYPE.CANT ) {
            navigate( PAGE_URL.MAIN, {replace:true});
        }
    }
}

/**
 * 관리 가능한 관리자 페이지 인지를 체크하는 구문
 * @param loginData 
 * @param data 
 * @returns 
 */
export const checkAbleManageAdminPage = ( loginData:any, data:AdminManageData ) : AdminAccessResult => {
    if( loginData && loginData.is_admin == API_TYPES.BOOLEAN.TRUE && data ) {
        console.log("checkAbleManageAdminPage access : " + loginData.access + ", part : " , loginData.access_type );

        if( loginData.admin_type == API_TYPES.ADMIN_TYPE.TOP || loginData.access == API_TYPES.MANAGE_ACCESS.ALL ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        
        //=================================================================================
        // 사연

        // 사연 관리 페이지
        if( data.type == ADMIN_MANAGE_TYPE.STORY ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        // 사연 카테고리 관리 페이지
        else if( data.type == ADMIN_MANAGE_TYPE.STORY_CATEGORY ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 )  return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        // 사연 카테고리 관리 페이지
        else if( data.type == ADMIN_MANAGE_TYPE.STORY_LIST ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 ) {
                return makeResult( ADMIN_ACCESS_TYPE.ALL );
            }
            else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 ) {
                return makeResult( ADMIN_ACCESS_TYPE.PART, makePartCategorys(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART) );
            }
        }
        //======================== End - 사연

        //=================================================================================
        // 커뮤니티

        // 커뮤니티 관리 페이지
        else if( data.type == ADMIN_MANAGE_TYPE.COMMUNITY ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        // 커뮤니티 카테고리 관리 페이지
        else if( data.type == ADMIN_MANAGE_TYPE.COMMUNITY_CATEGORY ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 )  return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        // 커뮤니티 카테고리 관리 페이지
        else if( data.type == ADMIN_MANAGE_TYPE.COMMUNITY_LIST ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 ) {
                return makeResult( ADMIN_ACCESS_TYPE.ALL );
            }
            else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) {
                return makeResult( ADMIN_ACCESS_TYPE.PART, makePartCategorys(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) );
            }
        }
        //======================== End - 커뮤니티

        // 공지사항
        else if( data.type == ADMIN_MANAGE_TYPE.NOTICE ) {
            const isAccess = loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) > -1
                            || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1
                            || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1
                            || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1
                            || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1;
            return makeResult( isAccess?ADMIN_ACCESS_TYPE.ALL:ADMIN_ACCESS_TYPE.CANT );
        }
        
        //=================================================================================
        // 계정

        // 계정 탭
        else if( data.type == ADMIN_MANAGE_TYPE.ACCOUNT ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER_ADMIN) > -1
             || loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        
        // 관리자
        else if( data.type == ADMIN_MANAGE_TYPE.ACCOUNT_ADMIN ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER_ADMIN) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        
        // 사용자
        else if( data.type == ADMIN_MANAGE_TYPE.ACCOUNT_USER ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        //======================== End - 계정
        
        // 문의
        else if( data.type == ADMIN_MANAGE_TYPE.INQUIRY ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.INQUIRY) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        
        // 금지어
        else if( data.type == ADMIN_MANAGE_TYPE.TABOO ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.TABOO) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
        
        // 시스템 설정
        else if( data.type == ADMIN_MANAGE_TYPE.SYSTEM ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.SYSTEM) > -1 ) return makeResult( ADMIN_ACCESS_TYPE.ALL );
        }
    }
    return makeResult(ADMIN_ACCESS_TYPE.CANT);
}


export interface AdminPostAccessResult extends AdminAccessResult {
    postType:number // 게시글 타입
}

export interface AdminNoticeAccessResult {
    accessType:ADMIN_ACCESS_TYPE,
    posts:AdminPostAccessResult[]|null
}

/**
 * 관리 가능한 공지사항 데이터 정보를 return
 */
export const getAbleManageAdminNoticeListData = ( loginData:any ) : AdminNoticeAccessResult => {
    if( !loginData ) return {accessType:ADMIN_ACCESS_TYPE.CANT, posts:null};
    if( loginData.admin_type == API_TYPES.ADMIN_TYPE.TOP || loginData.access == API_TYPES.MANAGE_ACCESS.ALL ) return {accessType:ADMIN_ACCESS_TYPE.ALL, posts:null};

    if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) > -1 ) return {accessType:ADMIN_ACCESS_TYPE.ALL, posts:null};


    const posts : AdminNoticeAccessResult = { accessType:ADMIN_ACCESS_TYPE.CANT, posts:null };

    // 파트 타입인경우
    if( isPartNoticeAccessType( loginData.access_type ) ) {
        posts.accessType = ADMIN_ACCESS_TYPE.PART;
        posts.posts = [];
    }

    if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 ) {
        posts.posts?.push({postType:API_TYPES.POST_TYPE.STORY, accessType:ADMIN_ACCESS_TYPE.ALL, categorys:null});
    }
    else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 ) {
        posts.posts?.push({postType:API_TYPES.POST_TYPE.STORY, accessType:ADMIN_ACCESS_TYPE.PART, categorys:makePartCategorys(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART)});
    }
    if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 ) {
        posts.posts?.push({postType:API_TYPES.POST_TYPE.COMMUNITY, accessType:ADMIN_ACCESS_TYPE.ALL, categorys:null});
    }
    else if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) {
        posts.posts?.push({postType:API_TYPES.POST_TYPE.COMMUNITY, accessType:ADMIN_ACCESS_TYPE.PART, categorys:makePartCategorys(loginData.access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART)});
    }

    return posts;
}

const isPartNoticeAccessType = ( access_type : string ) => {
    if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1 )          return true;
    if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 )     return true;
    if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1 )      return true;
    if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) return true;

    return false;
}

/**
 * 결과 데이터를 만드는 메소드
 * @param accessType 
 * @param categorys 
 * @returns 
 */
const makeResult = ( accessType:ADMIN_ACCESS_TYPE ,categorys:string|null = null ) : AdminAccessResult => {
    return {accessType:accessType, categorys:categorys};
}

/**
 * 부분 접근가능한 카테고리 리스트를 만드는 메소드
 * @param access 
 * @param part 
 * @returns 
 */
const makePartCategorys = ( access:string, part:string ) => {

    if( !access || access == "" ) return null;

    var splits = access.split("$");
    if( splits ) {
        for( var i=0; i<splits.length; i++ ) {
            if( splits[i].indexOf(part) > -1 ) {
                var removePartNameStr = splits[i].replace(part+"_","");
                return removePartNameStr;
            }
        }
    }

    return null;
}

/**
 * 부분 접근가능한 카테고리 리스트를 만드는 메소드
 * @param access 
 * @param part 
 * @returns 
 */
export const makePartCategoryArray = ( access:string, part:string ) => {
    const list : number[] = [];
    const categorys = makePartCategorys( access, part );
    if( categorys ) {
        var idxList = categorys.split("_");
        for( var i=0; i<idxList.length; i++ ) {
            var idxStr : string = idxList[i];
            try{ list.push(parseInt(idxStr)); }catch(e){}
        }
    }
    return list;
}

/** End - 관리자 페이지 관리 가능 유무
 *************************************************************************************************/


/**
 * 관리 가능한 카테고리인지 체크
 * @param access 
 * @param part 
 * @param category 
 */
const checkAbleManageCategory = ( access:string, part:string, category:number|null ) => {
    var splits = access.split("$");
    var categorys : any = null;
    if( splits ) {
        for( var i=0; i<splits.length; i++ ) {
            if( splits[i].indexOf(part) > -1 ) {
                var removePartNameStr = splits[i].replace(part+"_","");
                categorys = removePartNameStr.split("_");
                break;
            }
        }
    }

    if( categorys ) {
        for( var i=0; i<categorys.length; i++ ) {
            var intData = 0;
            try{ intData = parseInt(categorys[i]); } catch(e){}
            if( intData == category ) return true;
        }
    }

    return false;
}

/**
 * 카테고리 고유값 텍스트를 배열로 만드는 메소드
 * @param categorys 
 * @returns 
 */
export const makeCategoryIdxArray = ( categorys:string|null ) => {
    var c_arr :number[]|null = null;

    if( !!categorys && categorys != "" ) {
        c_arr = [];
        var c_split = categorys.split("_");
        for( var i=0; i<c_split.length; i++ ) {
            try{ c_arr.push( parseInt(c_split[i]) ); } catch(e){}
        }
    }

    return c_arr;
}