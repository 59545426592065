import { useEffect, useState } from "react";
import { PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, makeBottomPageNumberList } from "../../../util/Page";


function AdminPageComponent(props:any) {
	const {totalData, page, year, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area admin__page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:80}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:80}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={(e)=>{ if( onClickPage ) { onClickPage(item, e); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}

export default AdminPageComponent