
import { combineReducers } from "redux";
import { all } from "@redux-saga/core/effects";
import data, {dataSaga} from "./data";
import {axiosSaga} from "./axios";

const rootReducer = combineReducers({
    data
});

export function* rootSaga() {
    yield all([
        dataSaga(), axiosSaga()
    ]); // all 은 배열 안의 여러 사가를 동시에 실행시켜줍니다
  }
  
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>