import React, { useEffect } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import {Oval} from 'react-loader-spinner';

const PopupWrapper = ( props:any ) => {
    const {
        children,
        title,
        visible,
        onClose,
        width, height,
        buttonChildren,
        fixDisable  // 고정 취소
    }=props;

	useEffect(()=>{
        if( fixDisable != true && visible ) {
            handleScroll();
    
            window.addEventListener("scroll", handleScroll);
            return ()=>{
                window.removeEventListener("scroll", handleScroll);
            }
        }
        else if( visible ) {
            window.scrollTo(0,0);
        }
        else {
            return ()=>{}
        }
	},[visible])

    const handleScroll = () => {
        console.log("scrolling");
        
        const ele : any = document.getElementById("popup_wrapper");
        if (ele) { ele.style.marginTop = window.scrollY + "px"; }
        const ele2 : any = document.getElementById("popup_body");
        if (ele2) { ele2.style.marginTop = window.scrollY + "px"; }
    };

    if( visible != true ) return null;

    var bodyStyle : any = {};

    if( fixDisable != true )    bodyStyle.marginTop = window.scrollY;
    if( width > 0 )             bodyStyle.width = width;
    if( height > 0 )            bodyStyle.height = height;

    var buttonAreaStyle = {};
    if( buttonChildren && buttonChildren.props && buttonChildren.props.children ) {
        var cnt = buttonChildren.props.children.length;
        if( !cnt ) cnt = 1;
        var margin = ( width - ( cnt * 120 ) ) / 2;
        buttonAreaStyle = {marginLeft:margin}
    }

    

    return (<>
        <div className='popup_wrapper' id='popup_wrapper' >
            
        </div>
        <div className='popup_body' id='popup_body' style={bodyStyle}>
            <div className='title_area'>
                
                {!!title&& <div className='title_text'><span>{title}</span></div> }
                <div className='close' onClick={()=>{if(onClose) onClose();}}>
                    <AiOutlineClose className='icon' size={20}/>
                </div>
            </div>
            {children}

            { !!buttonChildren &&
                <div className='button_area'>
                    <div style={buttonAreaStyle}>
                    {buttonChildren}
                    </div>
                </div>
            }
        </div>
        </>
    );
};
  
export default PopupWrapper;

export const BlackButton = ( props:any ) => {
    return( <Button {...props} className='black_button'/> )
}

export const RedButton = ( props:any ) => {
    return( <Button {...props} className='red_button'/> )
}

const Button = (props:any) => {
    const {title, onClick, loading, className, disable} = props;
    return(
        <div className={className + (disable?" disable":"")} onClick={()=>{
            if( disable != true ) {
                if( onClick ) onClick();
            }
            }}>
            { loading != true && <span>{title}</span>}
            { loading == true && 
                <div className='loader'>
                    <Oval 
                        color="#fff" 
                        height={20} 
                        width={20}
                        />
                </div>
            }
        </div>
    )
}