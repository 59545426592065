import React, { useEffect, useRef, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminDataCommunityCategoryAdd, ParamAdminDataCommunityCategoryUpdate } from '../../apiAxios';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import { ImageData, ResponseImage, getImageFile } from '../../util/Image';
import { AiFillCloseCircle } from "react-icons/ai";
import { isMobile } from 'react-device-detect';
import { INPUT_ACCEPT_TYPE } from '../../util/ConstValue';

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupCommunityCategory = ( props:any ) => {
    const { categoryData, onSuccess }=props;
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nameStr, setNameStr] = useState("");
    const [channelStr, setChannelStr] = useState("");
    const [requesting, setRequesting] = useState(false);
    

	const [bannerLoadingImage,      setBannerLoadingImage] = useState(false);
	const [mobBannerLoadingImage,   setMobBannerLoadingImage] = useState(false);

	const [bannerImageData,     setBannerImageData] = useState<ImageData|null>(null);
	const [mobBannerImageData,  setMobBannerImageData] = useState<ImageData|null>(null);

	const [deleteBannerImageUrl,    setDeleteBannerImageUrl] = useState<string|null>(null);
	const [deleteMobBannerImageUrl, setDeleteMobBannerImageUrl] = useState<string|null>(null);

    const [bannerUniqId,    setBannerUniqId] = useState("");
    const [mobBannerUniqId, setMobBannerUniqId] = useState("");

	const bannerRef : any = useRef();
	const mobBannerRef : any = useRef();

	useEffect(()=>{

		if( !!categoryData ) {
            if( !!categoryData.banner) {    setBannerImageData({url:categoryData.banner, blob:null, file:null}); }
            if( !!categoryData.mob_banner) { setMobBannerImageData({url:categoryData.mob_banner, blob:null, file:null}); }
		}
		
		setBannerUniqId((new Date()).getTime()+"_b");
		setMobBannerUniqId((new Date()).getTime()+"_m_b");
		return(()=>{})
	},[]);

    useEffect(()=>{
        if( categoryData ) {
            setNameStr(categoryData.name);
            setChannelStr(categoryData.channel);
            
            if( !!categoryData.banner) {    setBannerImageData({url:categoryData.banner, blob:null, file:null}); }
            else { setBannerImageData(null); }

            if( !!categoryData.mob_banner) { setMobBannerImageData({url:categoryData.mob_banner, blob:null, file:null}); }
            else { setMobBannerImageData(null); }
        }
        else {
            setNameStr("");
            setChannelStr("");
            
            setBannerImageData(null);
            setMobBannerImageData(null);
        }
        
		setBannerUniqId((new Date()).getTime()+"_b");
		setMobBannerUniqId((new Date()).getTime()+"_m_b");

        return(()=>{})
    },[categoryData])

    var popupTitle = "카테고리";
    if( categoryData )  { popupTitle += " 수정"; }
    else                { popupTitle += " 추가"; }

    const clickedSave = () => {
        if( nameStr == "" ) { alert("카테고리명을 입력하세요"); return; }

        setRequesting(true);
        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 카테고리 입니다");
				}
			}
            setRequesting(false);
		}

        if( categoryData ) {
            const params : ParamAdminDataCommunityCategoryUpdate = {
                idx:categoryData.idx,
                name:nameStr, 
                channel:channelStr, 
                banner:bannerImageData?bannerImageData.file:null, 
                mob_banner:mobBannerImageData?mobBannerImageData.file:null,
                delete_banner:!!deleteBannerImageUrl?deleteBannerImageUrl:null,
                delete_mob_banner:!!deleteMobBannerImageUrl?deleteMobBannerImageUrl:null
            }
            apiAxios.admin.dataCommunityCategoryUpdate(dispatch, params, callback);
        } 
        else {
            const params : ParamAdminDataCommunityCategoryAdd = {
                name:nameStr, 
                channel:!!channelStr?channelStr:null, 
                banner:bannerImageData?bannerImageData.file:null, 
                mob_banner:mobBannerImageData?mobBannerImageData.file:null
            }
            apiAxios.admin.dataCommunityCategoryAdd(dispatch, params, callback);
        }
    }
	
	const onSelectFile = async (e : any, isMobile:boolean) => {
		console.log("onSelectFile");

        if( isMobile )  setMobBannerLoadingImage(true);
        else            setBannerLoadingImage(true);

		getImageFile(e, (responseImage : ResponseImage)=>{
			//document.getElementById(uniqId)?.value = null;
			console.log("getImageFile receive");
            
			if( responseImage && responseImage.image ) {
                if( isMobile )  setMobBannerImageData(responseImage.image);
                else            setBannerImageData(responseImage.image);
			}
					
            if( isMobile ) {
                if( mobBannerRef && mobBannerRef.current ) {
                    mobBannerRef.current.value = "";
                }
            }
            else {
                if( bannerRef && bannerRef.current ) {
                    bannerRef.current.value = "";
                }
            }

            if( isMobile )  setMobBannerLoadingImage(false);
            else            setBannerLoadingImage(false);
		})
	}

	const clickedUploadImage = ( isMobile:boolean ) => {
		if( requesting ) return;

        if( isMobile ) {
            document.getElementById(mobBannerUniqId)?.click();
        }
        else {
            document.getElementById(bannerUniqId)?.click();
        }
	}

    const clickedImageDelete = ( isMobile:boolean ) => {
		if( requesting ) return;

        if( categoryData ) {
            if( isMobile ) {
                if( !deleteMobBannerImageUrl ) setDeleteMobBannerImageUrl(categoryData.mob_banner);
            }
            else {
                if( !deleteBannerImageUrl ) setDeleteBannerImageUrl(categoryData.banner);
            }
        }

        if( isMobile )  { setMobBannerImageData(null); }
        else            { setBannerImageData(null); }
    }

    return (
        <PopupWrapper {...props}
            title={popupTitle}
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title={"저장"} onClick={clickedSave} loading={requesting}/>
            </>}>
            
            <div className='body_area popup_category'>
                <input value={nameStr}      placeholder='카테고리명 입력'   onChange={(e)=> { setNameStr(e.target.value); }} disabled={requesting}/>
                <input value={channelStr}   placeholder='(선택) 유튜브 채널 주소 입력'    onChange={(e)=> { setChannelStr(e.target.value); }} disabled={requesting}/>
                <div className='banner'>
                    { !!bannerImageData && <>
                        <div className='banner_top'>
                            <div className='title'>배너이미지</div>
                            <AiFillCloseCircle className='close' size={25} onClick={()=>{clickedImageDelete(false);}}/>
                        </div>
                        <div className='image'>
                            <img src={!!bannerImageData.url?bannerImageData.url:bannerImageData.blob} />
                        </div>
                    </>}
                    { !bannerImageData &&
                        <div className='button' onClick={()=>{clickedUploadImage(false);}}>
                            배너추가
                        </div>
                    }
                </div>
                <div className='banner'>
                    { !!mobBannerImageData && <>
                        <div className='banner_top'>
                            <AiFillCloseCircle className='close' size={25} onClick={()=>{clickedImageDelete(true);}}/>
                            <div className='title'>모바일용 배너이미지</div>
                        </div>
                        <div className='image'>
                            <img src={!!mobBannerImageData.url?mobBannerImageData.url:mobBannerImageData.blob} />
                        </div>
                    </>}
                    { !mobBannerImageData &&
                        <div className='button' onClick={()=>{clickedUploadImage(true);}}>
                            모바일용 배너추가
                        </div>
                    }
                </div>
                
				<input
					ref={bannerRef}
					type='file'
					id={bannerUniqId}
					name='images'
					hidden={true}
					onChange={(e:any)=>{onSelectFile(e,false);}}
                    accept={INPUT_ACCEPT_TYPE}
				/>
				<input
					ref={mobBannerRef}
					type='file'
					id={mobBannerUniqId}
					name='images'
					hidden={true}
					onChange={(e:any)=>{onSelectFile(e,true);}}
                    accept={INPUT_ACCEPT_TYPE}
				/>
            </div>
        </PopupWrapper>
    );
};
  
export default PopupCommunityCategory;