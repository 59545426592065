/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { checkLogin, getDeletePostMsg, isToday, makeDateFromTimeStamp, makeTwoNumberStr } from '../../../../util/Global';
import { CANT_READ_POST_ON_DELETE, IMG_URL_CHOICE } from '../../../../util/ConstValue';
import { PAGE_MY_INQUIRY_POST, PAGE_MY_POST_PARAMS, PAGE_NOTICE_HOME_PARAMS, PAGE_NOTICE_POST_PARAMS, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../redux_saga/modules';
import { AiFillPicture } from "react-icons/ai";
import { AiOutlineCheck } from "react-icons/ai";

enum FILTER {
	ALL=0, BEST=1, CHOICE=2
}

function MyPostListComponent( props:any ) {
	const {currentCategory} = props;
	
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);

	const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);
    const [sortType, setSortType] = useState(API_TYPES.POST_LIST_SORT_TYPE.INSERT);
    const [filterType, setFilterType] = useState(FILTER.ALL);

    useEffect(()=>{

		console.log("location : ", location);

		const l_p = new URLSearchParams(location.search);
		const y = l_p.get("year");
		const p = l_p.get("page");
		const s = l_p.get("post_list_sort_type");
		const f = l_p.get("filter");

		var newYear = (new Date()).getFullYear();
		var newPage = 1;
		var newSortType = API_TYPES.POST_LIST_SORT_TYPE.INSERT;
		var newFilter = FILTER.ALL;

		if( y ) try{ newYear = parseInt(y); }		catch(e){ console.log("e",e);}
		if( p ) try{ newPage = parseInt(p); }		catch(e){ console.log("e",e);}
		if( s ) try{ newSortType = parseInt(s); }	catch(e){ console.log("e",e);}
		if( f ) try{ newFilter = parseInt(f); }		catch(e){ console.log("e",e);}

		setItemList(null);
		setTotalData(null);

		setYear( newYear );
		setPage( newPage );
		setSortType( newSortType );
		setFilterType( newFilter );

		reqList(newYear, newPage, newSortType, newFilter);

        return(()=>{})
    },[location]);
	

    const reqList = ( year:number, page:number, post_list_sort_type:number, filter:number ) => {
		var filterStr : string|null = null;
		if( filter == FILTER.BEST )	filterStr = "best";
		else if( filter == FILTER.CHOICE )	filterStr = "choice";

		apiAxios.user.listMyPost(dispatch, year, page, post_list_sort_type, filterStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
												
				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const clickedSortType = ( type : number ) => {
		if( sortType == type ) return;

        var params : PAGE_MY_POST_PARAMS = {
			year:year,
			page:1,
			post_list_sort_type:type,
			filter:filterType
		}
		var url = makeUrl(PAGE_URL.MY_POST, params);
		navigate(url);
    }

    const clickedPeriodType = ( type : number ) => {
		if( filterType == type ) return;
		
        var params : PAGE_MY_POST_PARAMS = {
			year:year,
			page:1,
			post_list_sort_type:sortType,
			filter:type
		}
		var url = makeUrl(PAGE_URL.MY_POST, params);
		navigate(url);
    }

	return(
		<>
			<div className='post_home__list_area my_post__list_area' id="post_home__list_area">
				
				<div className='post_home__best_area'>
					<TitleCompenent {...props}
						sortType={sortType}
						setSortType={setSortType}
						filterType={filterType}
						setFilterType={setFilterType}
						onClickSortType={clickedSortType}
						onClickPeriodType={clickedPeriodType}
						/>
				</div>
				<table>
					<tr className='table_header'>
						<td className='title'>제목</td>
						<td className='insert_time'>작성일</td>
						<td className='hits'>조회</td>
						<td className='likes'>추천</td>
					</tr>
					
					<PostComponent {...props} 
						page={page}
						itemList={itemList}
						/>
				</table>
			</div>
			
			<PageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA)=>{

					var params : PAGE_MY_POST_PARAMS = {
						year:year,
						page:page,
						post_list_sort_type:sortType,
						filter:filterType
					}

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}

					var url = makeUrl(PAGE_URL.MY_POST, params);
					navigate(url);
				}}
			/>
		</>
	)
}
export default MyPostListComponent;


function TitleCompenent( props:any ) {

    return(
        <div className='title_box'>
            <div className='title'> <span>내가쓴글</span> </div>

            <TitleSortComp {...props}/>

            <div className='right'>
                <TitlePeriodComp {...props}/>
            </div>
        </div>
    )
}

function TitleSortComp( props:any ) {
    const { sortType, onClickSortType } = props;

    const isSelectInsert = sortType == API_TYPES.POST_LIST_SORT_TYPE.INSERT;
    const isSelectLike = sortType == API_TYPES.POST_LIST_SORT_TYPE.LIKE;
    const isSelectHits = sortType == API_TYPES.POST_LIST_SORT_TYPE.HITS;
    const isSelectReply = sortType == API_TYPES.POST_LIST_SORT_TYPE.REPLY;

    return(
        <div className='sort'>
            <div onClick={()=>{onClickSortType(API_TYPES.POST_LIST_SORT_TYPE.INSERT)}}>
                { isSelectInsert && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectInsert?" select":"")}><div><span>작성순</span></div></div>
            </div>

            <div> <div className='dot'><div><span>·</span></div></div> </div>

            <div onClick={()=>{onClickSortType(API_TYPES.POST_LIST_SORT_TYPE.LIKE)}}>
                { isSelectLike && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectLike?" select":"")}><div><span>추천순</span></div></div>
            </div>

            <div> <div className='dot'><div><span>·</span></div></div> </div>

            <div onClick={()=>{onClickSortType(API_TYPES.POST_LIST_SORT_TYPE.HITS)}}>
                { isSelectHits && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
                <div className={'span_div' + (isSelectHits?" select":"")}><div><span>조회순</span></div></div>
            </div>

			<div> <div className='dot'><div><span>·</span></div></div> </div>

			<div onClick={()=>{onClickSortType(API_TYPES.POST_LIST_SORT_TYPE.REPLY)}}>
				{ isSelectReply && <div className='check_div'><div className='check'><AiOutlineCheck size={15}/></div></div> }
				<div className={'span_div' + (isSelectReply?" select":"")}><div><span>댓글순</span></div></div>
			</div>
        </div>
    )
}

function TitlePeriodComp( props:any ) {
    const {filterType, onClickPeriodType} = props;

    const isSelect1 = filterType == FILTER.ALL;
    const isSelect2 = filterType == FILTER.BEST;
    const isSelect3 = filterType == FILTER.CHOICE;

    const showDiveder1 = isSelect3 == true;
    const showDiveder2 = isSelect1 == true;

    return(
        <div className='period_box'>
            <div className={'period period_1' + (isSelect1?" select":"")} onClick={()=>{onClickPeriodType(FILTER.ALL)}}><span>전체</span></div>
            { showDiveder1 && <div className='period_divider divider_1'></div> }
            <div className={'period period_2' + (isSelect2?" select":"")} onClick={()=>{onClickPeriodType(FILTER.BEST)}}><span>베스트</span></div>
            { showDiveder2 && <div className='period_divider divider_2'></div> }
            <div className={'period period_3' + (isSelect3?" select":"")} onClick={()=>{onClickPeriodType(FILTER.CHOICE)}}><span>채택</span></div>
        </div>
    )
}

function PageComponent(props:any) {
	const {totalData, year, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, year );
		setPageList(list);

        return(()=>{})
    },[totalData, year, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}

function PostComponent( props:any ) {
	const { itemList, year, page, currentCategory } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[year, page]);

	

	const clickedPost = ( item:any ) => {
		
		var fullUrl = "";
		const params : PAGE_POST_PARAMS = {
			idx:item.idx, 
			year:item.year, 
			post_list_type:API_TYPES.POST_LIST_TYPE.NOMAL,
            post_type:item.post_type,
            category:item.cate_idx,
            search:null,
            searchType:null
		}

		var url = "";
		if( item.post_type == API_TYPES.POST_TYPE.COMMUNITY )	url = PAGE_URL.COMMUNITY_POST;
		else if( item.post_type == API_TYPES.POST_TYPE.STORY )	url = PAGE_URL.STORY_POST;

		fullUrl = makeUrl(url, params);
		navigate(fullUrl);
	}

	return(
		<>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					
					if( item.use_state == API_TYPES.POST_USE_STATE.ABLE ) {
						return( <PostItem {...props} item={item} key={index} index={index} onClick={clickedPost}/> )
					}
					else {
						return( <DeleteItem {...props} item={item} key={index} index={index}/> )
					}
				})
			}
			
			{ ( !itemList || itemList.length == 0 ) &&
				<tr className='empty_item'>
					<td colSpan={4}>내가 쓴글이 없습니다</td>
				</tr>
			}
		</>
	)
}

function DeleteItem( props:any ) {
	const {item, onClick} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

	const showBest = item.best==API_TYPES.BOOLEAN.TRUE;

	var titleMaxWidth = 660;
	titleMaxWidth = titleMaxWidth + (showBest?-40:0);
	titleMaxWidth = titleMaxWidth + (item.choice_show == API_TYPES.BOOLEAN.TRUE?-25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	var postTypeStr : string = "";
	if( item.post_type == API_TYPES.POST_TYPE.COMMUNITY ) postTypeStr = "커뮤니티/";
	else if( item.post_type == API_TYPES.POST_TYPE.STORY ) postTypeStr = "사연/";

	const clickedItem = (item:any) => {
		if( item.use_state==API_TYPES.POST_USE_STATE.REPORT ) {
			var text = "신고 누적으로 삭제된 게시글입니다.";
			alert(text);
		}
		else if( item.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE ) {
			var text = "관리자가 삭제한 게시글입니다.";
			text = text + "\n\n사유 : " + item.note;
			alert(text);
		}
	}

	return(
		<tr className='table_item' onClick={()=>{ clickedItem(item); }}>
			<td className='title'>
				<div>
					{ showBest 								&& <div className='best best_delete'><span>Best</span></div> }
					{ item.choice_show == API_TYPES.BOOLEAN.TRUE 	&& <img className="choice_image choice_image_delete" src={IMG_URL_CHOICE}/> }

					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						<span className='category delete_line'>{"["+postTypeStr+item.cate_name+"]"}</span>
						<span className='title delete_line'>{item.title}</span>
					</div>
					
				</div>
			</td>
			<td className='insert_time'><div><span>{insertTimeStr}</span></div></td>
			<td className='hits'><div><span>{item.hits}</span></div></td>
			<td className='likes'><div><span>{item.likes}</span></div></td>
		</tr>
	)
}

function PostItem( props:any ) {
	const {item, onClick} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

    const showReplyNum = item.is_notice!=true && item.reply_num && item.reply_num > 0;
	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showBest = item.best==API_TYPES.BOOLEAN.TRUE;

	var titleMaxWidth = 660;
	titleMaxWidth = titleMaxWidth + (item.is_notice==true?-40:0);
	titleMaxWidth = titleMaxWidth + (showBest?-40:0);
	titleMaxWidth = titleMaxWidth + (item.choice_show == API_TYPES.BOOLEAN.TRUE?-25:0);
	titleMaxWidth = titleMaxWidth + (showNew?-25:0);

	titleMaxWidth = titleMaxWidth + (showReplyNum?-50:0);
	titleMaxWidth = titleMaxWidth + (havePicture?-25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	var postTypeStr : string = "";
	if( item.post_type == API_TYPES.POST_TYPE.COMMUNITY ) postTypeStr = "커뮤니티/";
	else if( item.post_type == API_TYPES.POST_TYPE.STORY ) postTypeStr = "사연/";

	return(
		<tr className='table_item' onClick={()=>{if(onClick) onClick(item); }}>
			<td className='title'>
				<div>
					{ item.is_notice==true 					&& <div className='notice'><span>공지</span></div> }
					{ showBest 								&& <div className='best'><span>Best</span></div> }
					{ item.choice_show == API_TYPES.BOOLEAN.TRUE 	&& <img className="choice_image" src={IMG_URL_CHOICE}/> }

					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						<span className='category'>{"["+postTypeStr+item.cate_name+"]"}</span>
						<span className='title'>{item.title}</span>
					</div>
					
					{ showReplyNum 							&& <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
					{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
					{ showNew 								&& <div className='new'><span>N</span></div> }
				</div>
			</td>
			<td className='insert_time'><div><span>{insertTimeStr}</span></div></td>
			<td className='hits'><div><span>{item.hits}</span></div></td>
			<td className='likes'><div><span>{item.likes}</span></div></td>
		</tr>
	)
}

function makeInsertTimeStr( date:Date ) {
	if( !date ) return null;

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

	// 오늘 일떄
	if( isToday(date) ) {
		return makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
	else {
		return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day);
	}
}