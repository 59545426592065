
import { defaultAxiosPromise, defaultCallbackAxios, METHOD, CallFunc, addParam, getLoginToken, getConnectInformation } from "./util";
import { API_URL_GLOBAL } from "./const";
import { ConnectInformation } from "./type";

const URL_AUTH = API_URL_GLOBAL + "auth/";
const URL_DATA = API_URL_GLOBAL + "data/";
const URL_LIST = API_URL_GLOBAL + "list/";



/*********************************************************************************************************
 * 
 * 계정 관련
 * 
 *********************************************************************************************************/

/* 사용자&관리자 로그인 */
export function authLogin( dispatch:any, id:string, pw:string, code:string|null, os:string|null, fcmToken:string|null, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();

    let param : any = {id:id, pw:pw};
    param = addParam( param, "code",        code);
    param = addParam( param, "os",          os?os:connectInfo.os);
    param = addParam( param, "fcm_token",   fcmToken?fcmToken:connectInfo.fcm_token);
    param = addParam( param, "app_version", connectInfo.app_version);
    param = addParam( param, "uuid",        connectInfo.uuid);

    var formData = null;
    const URL = URL_AUTH + "login.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, null, formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, null, formData );
}

/* 사용자&관리자 로그아웃 */
export function authLogout( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_AUTH + "logout.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack, true );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData, true );
}

/* 사용자 데이터 요청 */
export function authUserData( dispatch:any, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();
    let param : any = {};
    param = addParam( param, "os",          connectInfo.os);
    param = addParam( param, "fcm_token",   connectInfo.fcm_token);
    param = addParam( param, "uuid",        connectInfo.uuid);
    param = addParam( param, "version_code",connectInfo.version_code);
    param = addParam( param, "version_name",connectInfo.version_name);

    var formData = null;
    const URL = URL_AUTH + "user_data.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 본인인증 데이터 요청 */
export function authCertification( dispatch:any, imp_uid:string, callBack:CallFunc|null ) {
    let param : any = {imp_uid:imp_uid};

    var formData = null;
    const URL = URL_AUTH + "certification.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 계정 관련
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 데이터 요청
 * 
 *********************************************************************************************************/

/* 홈화면 데이터 요청 & 접속 기록 저장 */
export function dataHomeData( dispatch:any, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();
    let param : any = {os:connectInfo.os};
    param = addParam( param, "uuid",        connectInfo.uuid);
    param = addParam( param, "version_code",connectInfo.version_code);
    param = addParam( param, "version_name",connectInfo.version_name);
    param = addParam( param, "fcm_token",   connectInfo.fcm_token);

    var formData = null;
    const URL = URL_DATA + "home_data.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 통합 글 데이터 요청 */
export function dataPost( dispatch:any, params:ParamDataPost, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();
    let param : any = {
        idx             :params.idx, 
        year            :params.year, 
        post_type       :params.post_type, 
        post_list_type  :params.post_list_type, 
        os              :connectInfo.os
    };
    param = addParam( param, "post_list_sort_type", params.post_list_sort_type);
    param = addParam( param, "search_type", params.search_type);
    param = addParam( param, "total_search", params.total_search);
    param = addParam( param, "uuid",                connectInfo.uuid);
    param = addParam( param, "version_code",        connectInfo.version_code);
    param = addParam( param, "version_name",        connectInfo.version_name);
    param = addParam( param, "fcm_token",           connectInfo.fcm_token);

    var formData = null;
    const URL = URL_DATA + "post.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지사항 데이터 요청 */
export function dataNotice( dispatch:any, idx:number, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();
    let param : any = { idx:idx, os:connectInfo.os };
    param = addParam( param, "uuid",                connectInfo.uuid);
    param = addParam( param, "version_code",        connectInfo.version_code);
    param = addParam( param, "version_name",        connectInfo.version_name);
    param = addParam( param, "fcm_token",           connectInfo.fcm_token);

    var formData = null;
    const URL = URL_DATA + "notice.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 닉네임 중복 체크 */
export function dataCheckNickNameOverlap( dispatch:any, nickname:string, callBack:CallFunc|null ) {
    let param : any = { nickname:nickname };

    var formData = null;
    const URL = URL_DATA + "check_nick_name_overlap.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 아이디 중복 체크 */
export function dataCheckIdOverlap( dispatch:any, id:string, callBack:CallFunc|null ) {
    let param : any = { id:id };

    var formData = null;
    const URL = URL_DATA + "check_id_overlap.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 이용약관요청 */
export function dataUseAgreement( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_DATA + "use_agreement.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 관리자 메일 요청 */
export function dataAdminEmail( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL = URL_DATA + "admin_email.php";
    const method = METHOD.post;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}


/**
 * End - 데이터 요청
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 데이터 요청 리스트
 * 
 *********************************************************************************************************/

/* 전체 글 (사연,게시판) 리스트 요청 */
export function listAllPost( dispatch:any, year:number, page:number, search_type:number|null, total_search:string|null, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page};
    param = addParam( param, "search_type",     search_type);
    param = addParam( param, "total_search",    total_search);

    var formData = null;
    const URL =  URL_LIST + "all_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 전체 */
export function listStoryAllPost( dispatch:any, year:number, page:number, search_type:number|null, total_search:string|null, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page};
    param = addParam( param, "search_type",     search_type);
    param = addParam( param, "total_search",    total_search);

    var formData = null;
    const URL =  URL_LIST + "story_all_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 전체 */
export function listCommunityAllPost( dispatch:any, year:number, page:number, search_type:number|null, total_search:string|null, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page};
    param = addParam( param, "search_type",     search_type);
    param = addParam( param, "total_search",    total_search);

    var formData = null;
    const URL =  URL_LIST + "community_all_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지사항 */
export function listMainBest( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL =  URL_LIST + "main_best.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 공지사항 */
export function listNotice( dispatch:any, post_type:number|null, cate_idx:number|null, page:number|null, callBack:CallFunc|null ) {
    let param : any = {};

    param = addParam( param, "post_type",   post_type);
    param = addParam( param, "cate_idx",    cate_idx);
    param = addParam( param, "page",        page);

    var formData = null;
    const URL =  URL_LIST + "notice.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, null, formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, null, formData );
}

/* 사연 카테고리 */
export function listStoryCategory( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL =  URL_LIST + "story_category.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 카테고리 */
export function listCommunityCategory( dispatch:any, callBack:CallFunc|null ) {
    let param : any = {};

    var formData = null;
    const URL =  URL_LIST + "community_category.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 게시글 */
export function listStoryPost( dispatch:any, year:number, page:number, cate_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page, cate_idx:cate_idx};

    var formData = null;
    const URL =  URL_LIST + "story_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 게시글 */
export function listCommunityPost( dispatch:any, year:number, page:number, cate_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, page:page, cate_idx:cate_idx};

    var formData = null;
    const URL =  URL_LIST + "community_post.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 베스트 순위 */
export function listStoryBestRanking( dispatch:any, post_list_type:number, post_list_sort_type:number, time:number, callBack:CallFunc|null ) {
    let param : any = {post_list_type:post_list_type, post_list_sort_type:post_list_sort_type, time:time};

    var formData = null;
    const URL =  URL_LIST + "story_best_ranking.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 베스트 순위 */
export function listCommunityBestRanking( dispatch:any, post_list_type:number, post_list_sort_type:number, time:number, callBack:CallFunc|null ) {
    let param : any = {post_list_type:post_list_type, post_list_sort_type:post_list_sort_type, time:time};

    var formData = null;
    const URL =  URL_LIST + "community_best_ranking.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 댓글 리스트 */
export function listStoryReply( dispatch:any, year:number, page:number|null, post_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, post_idx:post_idx};
    param = addParam( param, "page",     page);

    var formData = null;
    const URL =  URL_LIST + "story_reply.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 사연 대 댓글 리스트 */
export function listStoryReReply( dispatch:any, year:number, page:number|null, post_idx:number, reply_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, post_idx:post_idx, reply_idx:reply_idx};
    param = addParam( param, "page",     page);

    var formData = null;
    const URL =  URL_LIST + "story_re_reply.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 댓글 리스트 */
export function listCommunityReply( dispatch:any, year:number, page:number|null, post_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, post_idx:post_idx};
    param = addParam( param, "page",     page);

    var formData = null;
    const URL =  URL_LIST + "community_reply.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 커뮤니티 대 댓글 리스트 */
export function listCommunityReReply( dispatch:any, year:number, page:number|null, post_idx:number, reply_idx:number, callBack:CallFunc|null ) {
    let param : any = {year:year, post_idx:post_idx, reply_idx:reply_idx};
    param = addParam( param, "page",     page);

    var formData = null;
    const URL =  URL_LIST + "community_re_reply.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/* 검색 */
export function listSearch( dispatch:any, search_type:number, total_search:string, callBack:CallFunc|null ) {
    const connectInfo : ConnectInformation = getConnectInformation();
    let param : any = {search_type:search_type, total_search:total_search, os:connectInfo.os};
    param = addParam( param, "os",          connectInfo.os);
    param = addParam( param, "uuid",        connectInfo.uuid);
    param = addParam( param, "version_code",connectInfo.version_code);
    param = addParam( param, "version_name",connectInfo.version_name);

    var formData = null;
    const URL =  URL_LIST + "search.php";
    const method = METHOD.get;
    if( callBack )  defaultCallbackAxios( dispatch, method, URL, param, getLoginToken(), formData, callBack );
    else      return defaultAxiosPromise( dispatch, method, URL, param, getLoginToken(), formData );
}

/**
 * End - 데이터 요청 리스트
 *********************************************************************************************************/



// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //
//                                                                                                                 //
//                                                ↓↓↓↓ 파라미터 ↓↓↓↓                                                //
//                                                                                                                 //
// --------------------------------------------------------------------------------------------------------------- //
// --------------------------------------------------------------------------------------------------------------- //



/*********************************************************************************************************
 * 데이터 파라미터
 *********************************************************************************************************/

export interface ParamDataPost {
    idx:number,
    year:number,
    post_type:number,
    post_list_type:number,
    post_list_sort_type:number|null,
    search_type:number|null,
    total_search:string|null
}