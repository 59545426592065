/****************************************************************************************
 * 
 *  댓글 구역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isToday, makeDateFromTimeStamp, makeInsertTimeStr } from '../../../util/Global';
import { IMG_URL_CHOICE } from '../../../util/ConstValue';
import { API_TYPES } from '../../../apiAxios';
import { AiFillPicture } from "react-icons/ai";
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';

const BottomList = (props:any) => {
	const { postList } = props;

	const navigate = useNavigate();
	
	useEffect(()=>{

		return(()=>{})
	},[]);

	return (<>
		{ !!postList && postList.length > 0 &&
			<div className='post_bottom_list_body'>
				<table>
					<tr className='table_header'>
						<td className='title'>제목</td>
						<td className='writer'>글쓴이</td>
						<td className='insert_time'>작성일</td>
						<td className='hits'>조회</td>
						<td className='likes'>추천</td>
					</tr>
					
					<PostComponent {...props}/>
				</table>
			</div>
		}
	</>
	);
};

export default BottomList;

function PostComponent( props:any ) {
	const { postList, postType, categoryIdx, postListType, onClickBottomList, searchStr, searchType } = props;
	
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[]);

	const clickedPost = ( item:any ) => {
		if( onClickBottomList )	onClickBottomList();

		var fullUrl = "";

		const params : PAGE_POST_PARAMS = {
			idx:item.idx,
			year:item.year,
			post_list_type:postListType,
			post_type:!!item.post_type?item.post_type:postType,
			category:item.cate_idx,
            search:searchStr,
            searchType:searchType
		}
		fullUrl = makeUrl(PAGE_URL.STORY_POST, params);
		navigate(fullUrl);
	}

	return(
		<>
			{ !!postList && postList.length > 0 &&
				postList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} key={index} onClick={clickedPost}/> )
				})
			}
		</>
	)
}

function PostItem( props:any ) {
	const {item, onClick, postType, categoryIdx, postData, isAllList, isAllCategoryList} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

    const showReplyNum = item.is_notice!=true && item.reply_num && item.reply_num > 0;
	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showBest = categoryIdx != -1 && item.best==API_TYPES.BOOLEAN.TRUE;
	const showCategory = (item.is_notice!=true && categoryIdx == -1)||isAllList||isAllCategoryList;

	var titleMaxWidth = 510;
	titleMaxWidth = titleMaxWidth + (item.is_notice==true?-40:0);
	titleMaxWidth = titleMaxWidth + (showBest?-40:0);
	titleMaxWidth = titleMaxWidth + (item.choice_show == API_TYPES.BOOLEAN.TRUE?-25:0);
	titleMaxWidth = titleMaxWidth + (showNew?-25:0);

	titleMaxWidth = titleMaxWidth + (showReplyNum?-50:0);
	titleMaxWidth = titleMaxWidth + (havePicture?-25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	var isFocus = postData.idx == item.idx && postData.year == item.year && (isAllList==false || (isAllList && postType == item.post_type) );
	
	
	var postName = "";

	if( isAllList ) {
		if( item.post_type==API_TYPES.POST_TYPE.STORY ) { postName = "사연/"; }
		else if( item.post_type==API_TYPES.POST_TYPE.COMMUNITY ) { postName = "커뮤니티/"; }
	}

	return(
		<tr className={'table_item' + (isFocus?" focus":"")} onClick={()=>{
			if(onClick && isFocus == false) {
				onClick(item);
			} 
		}}>
			<td className='title'>
				<div>
					{ showBest 								&& <div className='best'><span>Best</span></div> }

					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						{ showCategory && <span className='category'>{"["+postName+item.cate_name+"]"}</span> }
						<span className='title'>{item.title}</span>
					</div>
					
					{ showReplyNum 							&& <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
					{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
					{ item.choice_show == API_TYPES.BOOLEAN.TRUE 	&& <img className="choice_image" src={IMG_URL_CHOICE}/> }
					{ showNew 								&& <div className='new'><span>N</span></div> }
				</div>
			</td>
			<td className='writer'><div><span>{item.is_notice==true?"운영자":item.nickname}</span></div></td>
			<td className='insert_time'><div><span>{insertTimeStr}</span></div></td>
			<td className='hits'><div><span>{item.hits}</span></div></td>
			<td className='likes'><div><span>{item.is_notice==true?"-":item.likes}</span></div></td>
		</tr>
	)
}