import React, { useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import { ALERT_TEXT_SUCCESS_CHOICE, TEXT_HAVE_CHOICE, TEXT_NOT_FOUND_POST } from '../../util/ConstValue';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupChoiceStory = ( props:any ) => {
    const {postData,onClose}=props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const [selectType, setSelectType] = useState<any>(API_TYPES.STORY_CHOICE_TYPE.NOMAL);
    const [requesting, setRequesting] = useState(false);

    if( !postData ) return null;

    const clickedChoice = () => {
        if( requesting ) return;

        setRequesting(true);
        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
            if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                alert(ALERT_TEXT_SUCCESS_CHOICE);
                onClose();
            }
            else if( error ) {
                const errorData : any = API_UTILS.getErrorResponseData(error);
                if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert(TEXT_NOT_FOUND_POST);
                    navigate(PAGE_URL.MAIN);
                }
                else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert(TEXT_HAVE_CHOICE);
                    onClose();
                }
            }
            setRequesting(false);
        }
        apiAxios.admin.dataStoryChoiceAdd(dispatch, postData.idx, postData.year, selectType, callback);
    }
    return (
        <PopupWrapper {...props}
            title="사연채택"
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title="채택" onClick={clickedChoice} loading={requesting}/>
            </>}>
            
            <div className='body_area popup_choice'>

                <table className='post_box'>
                    <tr>
                        <td className='left'>글제목</td>
                        <td className='right'><span>{postData.title}</span></td>
                    </tr>
                    <tr>
                        <td className='left'>글쓴이</td>
                        <td className='right'><span>{postData.user_id + " (" + postData.nickname + ")"}</span></td>
                    </tr>
                    <tr>
                        <td className='left'>작성일</td>
                        <td className='right'><span>{makeInsertTimeFullFromTimeStamp(postData.insert_time)}</span></td>
                    </tr>
                </table>

                <div className='select_box'>
                    
                    <div className='title'><span>채택타입</span></div>
                    <select className="select"  value={selectType} 
                        onChange={(e)=> {
                            setSelectType(e.target.value);  
                        }}>
                        <option value={API_TYPES.STORY_CHOICE_TYPE.NOMAL}>일반(상품권)</option>
                    </select>

                </div>
            </div>
        </PopupWrapper>
    );
};
  
export default PopupChoiceStory;