/****************************************************************************************
 * 
 *  댓글 구역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isToday, makeDateFromTimeStamp, makeInsertTimeStr } from '../../../../util/Global';
import { IMG_URL_CHOICE } from '../../../../util/ConstValue';
import { API_TYPES } from '../../../../apiAxios';
import { AiFillPicture } from "react-icons/ai";
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';

const MobileBottomList = (props:any) => {
	const { postList } = props;

	const navigate = useNavigate();
	
	useEffect(()=>{

		return(()=>{})
	},[]);

	return (<>
		{ !!postList && postList.length > 0 &&
			<div className='mobile_post_bottom_list_body'>
				<PostComponent {...props}/>
			</div>
		}
	</>
	);
};

export default MobileBottomList;

function PostComponent( props:any ) {
	const { postList, postType, categoryIdx, postListType, onClickBottomList, searchStr, searchType } = props;
	
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[]);

	const clickedPost = ( item:any ) => {
		if( onClickBottomList )	onClickBottomList();

		var fullUrl = "";

		const params : PAGE_POST_PARAMS = {
			idx:item.idx,
			year:item.year,
			post_list_type:postListType,
			post_type:!!item.post_type?item.post_type:postType,
			category:item.cate_idx,
            search:searchStr,
            searchType:searchType
		}
		fullUrl = makeUrl(PAGE_URL.STORY_POST, params);
		navigate(fullUrl);
	}

	return(
		<ul>
			{ !!postList && postList.length > 0 &&
				postList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} key={index} onClick={clickedPost}/> )
				})
			}
		</ul>
	)
}

function PostItem( props:any ) {
	const {item, onClick, postType, categoryIdx, postData, isAllList, isAllCategoryList} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

    const showReplyNum = item.is_notice!=true && item.reply_num && item.reply_num > 0;
	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showBest = categoryIdx != -1 && item.best==API_TYPES.BOOLEAN.TRUE;
	const showCategory = (item.is_notice!=true && categoryIdx == -1)||isAllList||isAllCategoryList;

	var titleMaxWidth = window.outerWidth - 40;
	titleMaxWidth = titleMaxWidth + (showBest?-40:0);
	titleMaxWidth = titleMaxWidth + (item.choice_show == API_TYPES.BOOLEAN.TRUE?-25:0);
	titleMaxWidth = titleMaxWidth + (showNew?-25:0);

	titleMaxWidth = titleMaxWidth + (showReplyNum?-50:0);
	titleMaxWidth = titleMaxWidth + (havePicture?-25:0);


	const insertTimeStr = makeInsertTimeStr( timeDate );

	var isFocus = postData.idx == item.idx && postData.year == item.year && (isAllList==false || (isAllList && postType == item.post_type) );
	
	
	var postName = "";

	if( isAllList ) {
		if( item.post_type==API_TYPES.POST_TYPE.STORY ) { postName = "사연/"; }
		else if( item.post_type==API_TYPES.POST_TYPE.COMMUNITY ) { postName = "커뮤니티/"; }
	}
	return(
		<li className={(isFocus?" focus":"")} onClick={()=>{
			if(onClick && isFocus == false) {
				onClick(item);
			} 
			}}>
			<div className='top_area'>
				{ showBest 								&& <div className='best'><span>Best</span></div> }

				<div className='title_body' style={{maxWidth:titleMaxWidth}}>
					{ showCategory && <span className='category'>{"["+postName+item.cate_name+"]"}</span> }
					<span className='title'>{item.title}</span>
				</div>
				
				{ showReplyNum 							&& <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
				{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
				{ item.choice_show == API_TYPES.BOOLEAN.TRUE 	&& <img className="choice_image" src={IMG_URL_CHOICE}/> }
				{ showNew 								&& <div className='new'><span>N</span></div> }
			</div>
			<div className='bottom'>
				<div className='hits'><span>{"조회:"+item.hits}</span></div>
				<div className='insert_time'><span>{insertTimeStr}</span></div>
			</div>
		</li>
	)
}