/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, isToday, makeDateFromTimeStamp, makeTwoNumberStr } from '../../../../../util/Global';
import { CANT_READ_POST_ON_DELETE } from '../../../../../util/ConstValue';
import { PAGE_MY_INQUIRY_POST, PAGE_NOTICE_HOME_PARAMS, PAGE_NOTICE_POST_PARAMS, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../redux_saga/modules';
import { AiFillPicture } from "react-icons/ai";
import MobilePageNumberBox, { MobileTopPageNumberBox } from '../../../sub/MobilePageNumber';

function MobileInquiryList( props:any ) {
	const {currentCategory} = props;
	
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);

	const [page, setPage] = useState(1);
	const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    useEffect(()=>{

		const l_p = new URLSearchParams(location.search);
		const p = l_p.get("page");

		var newPage = 1;

		if( p ) try{ newPage = parseInt(p); }catch(e){ console.log("e",e);}

		setItemList(null);
		setTotalData(null);

		setPage( newPage );

		reqList(newPage);

        return(()=>{})
    },[location]);
	

    const reqList = ( page:number ) => {

		apiAxios.user.listInquiry(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
												
				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const onClickPage = (page:number, isYear:boolean = false)=>{
		var params : PAGE_NOTICE_HOME_PARAMS = {
			page:page
		}

		if( isYear ) {
			params.page = 1;
		}
		else {
			params.page = page;
		}

		var url = makeUrl(PAGE_URL.MY_INQUIRY, params);
		navigate(url);
	}

	return(
		<>
			<div className='mobile_post_home__list_area' id="mobile_post_home__list_area">
				<div className='title'>
					<div className={'category'}><span>{"나의 문의 내역"}</span></div>
					<MobileTopPageNumberBox 
						{...props}
						page={page}
						totalData={totalData}
						marginTop={5}
						onClickPage={onClickPage}/>
				</div>
				<PostComponent {...props} 
					page={page}
					itemList={itemList}
					/>
				<MobilePageNumberBox {...props}
					totalData={totalData}
					page={page}
					onClickPage={onClickPage}
				/>
			</div>
		</>
	)
}
export default MobileInquiryList;

function PostComponent( props:any ) {
	const { itemList, year, page, currentCategory } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[year, page]);

	

	const clickedPost = ( item:any ) => {
		
		var fullUrl = "";
		const params : PAGE_MY_INQUIRY_POST = {
			idx:item.idx
		}
		fullUrl = makeUrl(PAGE_URL.MY_INQUIRY_POST, params);
		navigate(fullUrl);
	}

	if( !itemList || itemList.length == 0 ) {
		return(
			<div className='empty'>
				<span>문의 내역이 없습니다</span>
			</div>
		)
	}

	return(
		<ul>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} index={index} onClick={clickedPost}/> )
				})
			}
		</ul>
	)
/*
	return(
		<>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} index={index} onClick={clickedPost}/> )
				})
			}
			
			{ ( !itemList || itemList.length == 0 ) &&
				<tr className='empty_item'>
					<td colSpan={4}>문의 내역이 없습니다</td>
				</tr>
			}
		</>
	)*/
}

function PostItem( props:any ) {
	const {item, onClick} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);


	var titleMaxWidth = window.outerWidth - 40;

	const insertTimeStr = makeInsertTimeStr( timeDate );

	const typeStr = item.type == API_TYPES.INQUIRY_TYPE.CUSTOMER?"고객문의":item.type == API_TYPES.INQUIRY_TYPE.AD?"광고문의":"없음";
	const replyStr = item.read == API_TYPES.BOOLEAN.TRUE?"답변완료":"답변안됨";

	return(
		<li onClick={()=>{if(onClick) onClick(item); }} >
			<div className='top_area'>

				<div className='title_body' style={{maxWidth:titleMaxWidth}}>
					<span className='title'>{item.title}</span>
				</div>
			</div>
			<div className='bottom'>
				<div className='writer'><span>{insertTimeStr}</span></div>
				<div className='hits'><span>{typeStr}</span></div>
				<div className='insert_time'><span>{replyStr}</span></div>
			</div>
		</li>
	)
/*
	return(
		<tr className='table_item' onClick={()=>{if(onClick) onClick(item); }}>
			<td className='title'>
				<div>
					<div className='title_body' style={{maxWidth:titleMaxWidth}}>
						<span className='title'>{item.title}</span>
					</div>
				</div>
			</td>
			<td className='insert_time'><div><span>{insertTimeStr}</span></div></td>
			<td className='type'><div><span>{typeStr}</span></div></td>
			<td className='hits'><div><span>{replyStr}</span></div></td>
		</tr>
	)*/
}

function makeInsertTimeStr( date:Date ) {
	if( !date ) return null;

	const year = date.getFullYear();
	const month = date.getMonth();
	const day = date.getDate();
	
	const hour = date.getHours();
	const minute = date.getMinutes();

	// 오늘 일떄
	if( isToday(date) ) {
		return makeTwoNumberStr(hour) + ":" + makeTwoNumberStr(minute);
	}
	else {
		return year + "." + makeTwoNumberStr(month+1) + "." + makeTwoNumberStr(day);
	}
}