import React, { useEffect, useState } from 'react';
import Adsense, { AdType } from './Adsense';

function MiddleAdComponent( props:any ) {

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<Adsense 
            {...props}
            className='middle_ad'
            type={AdType.WEB_MIDDLE_WIDTH_LONG}
            />
	)
}


export default MiddleAdComponent;