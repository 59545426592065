/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';


const AdminMain = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.HOME}
			hideSubTab={true}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default AdminMain;


function MiddleComponent( props:any ) {

	return(
		<div className='middle_area admin_main'>
			<VisitantComponent {...props}/>
		</div>
	)
}

function VisitantComponent( props:any ) {
	enum TYPE { DAY=1, WEEK=2, MONTH=3 }

	const [data, setData] = useState<GRAPH_DATA|null>(null);
	const [currentType, setCurrentType] = useState(TYPE.DAY);

	
	useEffect(()=>{

		const maxCount = 100;
		const d : GRAPH_DATA = {
			max:maxCount + (maxCount/5),
			list:[
				{value:0,label:"03.16"},
				{value:100,label:"03.17"},
				{value:5,label:"03.18"},
				{value:33,label:"03.19"},
				{value:80,label:"03.20"},
				{value:70,label:"03.21"},
				{value:34,label:"03.22"}
			]
		}
		setData( d );
		
		return(()=>{})
	},[]);

	return(
		<div className='visitant'>
			<div className='top'>
				<div className='title'><span>방문자</span></div>
				<div className={'tab_item' + (currentType==TYPE.MONTH?" tab_item_select":"")} onClick={()=>{setCurrentType(TYPE.MONTH)}}><span>월별</span></div>
				<div className={'tab_item' + (currentType==TYPE.WEEK?" tab_item_select":"")} onClick={()=>{setCurrentType(TYPE.WEEK)}}><span>주별</span></div>
				<div className={'tab_item' + (currentType==TYPE.DAY?" tab_item_select":"")} onClick={()=>{setCurrentType(TYPE.DAY)}}><span>일별</span></div>
			</div>
			<Graph {...props} data={data} height={180}/>
		</div>
	)
}

interface GRAPH_DATA {
	max:number,
	list:GRAPH_ITEM[]
}

interface GRAPH_ITEM {
	value:number,
	label:string
}

function Graph( props:any ) {
	const { height, loading} = props;
	const data : GRAPH_DATA = props.data;
	if( !data ) return null;


	const maxWidth = 400;
	const width = window.outerWidth > maxWidth?maxWidth:window.outerWidth;
	const itemWidth = data.list && data.list.length > 0 ? (width-2) / data.list.length : 0;
	const dividerMarginTop = height / 6 - 1;

	return(
		<div className='graph'>
			{ data.list && <>
			
				<div className='graph_value_box_bg'> 
					<div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
						<div style={{marginTop:dividerMarginTop}}></div>
					</div>
				</div>

				<div className='graph_value_box'> {
					data.list.map( (item:GRAPH_ITEM, index:number)=>{

						var turm = 23;
						var boxHeight = height;
						var marginTop = ( data.max - item.value ) / data.max * boxHeight - turm;
						//ata.max;

						return (
							<div key={index} style={{width:itemWidth}}>
								<div style={{marginTop:marginTop}}>
									<div className='value'>{item.value}</div>
									<div className='dot'></div>
								</div>
							</div>
						);
					})
					}
				</div>
				<div className='graph_label_box'> {
					data.list.map( (item:GRAPH_ITEM, index:number)=>{
						return (
							<div key={index} style={{width:itemWidth}}>
								<span>{item.label}</span>
							</div>
						);
					})
					}
				</div>
			</>
			}
		</div>
	)
}