

import { REQ_DATA } from "../domain/axios"
import {} from "../domain/data"
import { AxiosReqDataAdminEmail, AxiosReqListCommunityCategory, AxiosReqListStoryCategory, makeReqData } from "./axios"
import { SetAdminEmail, SetApplicationOS, SetCommunityCategoryList, SetDataInit, SetFcmToken, SetLoginData, SetMobileOpenUseEffect, SetStoryCategoryList, SetUserData } from "./data"



/*********************************************************************************************************** 
    Data 
***********************************************************************************************************/

const setDataInit               = ( dispatch:any )                            => {dispatch(SetDataInit())}
const setLoginData              = ( dispatch:any, loginData:any )             => {dispatch(SetLoginData(dispatch, loginData))}
const setUserData               = ( dispatch:any, userData:any )              => {dispatch(SetUserData(userData))}
const setStoryCategoryList      = ( dispatch:any, storyCategoryList:any )     => {dispatch(SetStoryCategoryList(storyCategoryList))}
const setCommunityCategoryList  = ( dispatch:any, communityCategoryList:any ) => {dispatch(SetCommunityCategoryList(communityCategoryList))}
const setAdminEmail             = ( dispatch:any, adminEmail:string|null )    => {dispatch(SetAdminEmail(adminEmail))}
const setApplicationOS          = ( dispatch:any, applicationOS:string|null ) => {dispatch(SetApplicationOS(applicationOS))}
const setFcmToken               = ( dispatch:any, fcmToken:string|null )      => {dispatch(SetFcmToken(fcmToken))}
const setMobileOpenUseEffect    = ( dispatch:any, mobileOpenUseEffect:boolean ) => {dispatch(SetMobileOpenUseEffect(mobileOpenUseEffect))}

const data = {
    setInit                     :setDataInit,
    setLoginData                :setLoginData,
    setUserData                 :setUserData,
    setStoryCategoryList        :setStoryCategoryList,
    setCommunityCategoryList    :setCommunityCategoryList,
    setAdminEmail               :setAdminEmail,
    setApplicationOS            :setApplicationOS,
    setFcmToken                 :setFcmToken,
    setMobileOpenUseEffect      :setMobileOpenUseEffect
}

/** End - Data 
***********************************************************************************************************/

/*********************************************************************************************************** 
    axios 
***********************************************************************************************************/

const ReqListStoryCategory      = ( dispatch:any, d:REQ_DATA )  => {dispatch(AxiosReqListStoryCategory(d))}
const ReqListCommunityCategory  = ( dispatch:any, d:REQ_DATA )  => {dispatch(AxiosReqListCommunityCategory(d))}
const ReqDataAdminEmail         = ( dispatch:any, d:REQ_DATA )  => {dispatch(AxiosReqDataAdminEmail(d))}

const axios = {
    makeReqData                 :makeReqData,
    ReqListStoryCategory        :ReqListStoryCategory,
    ReqListCommunityCategory    :ReqListCommunityCategory,
    ReqDataAdminEmail           :ReqDataAdminEmail
}

/** End - axios 
***********************************************************************************************************/

const initAll = ( dispatch:any ) => {
    data.setInit(dispatch);
}

const reduxActions = {
    data:data,
    axios:axios,
    initAll:initAll
}
export default reduxActions;