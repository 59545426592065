import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

function MobileCategoryBox( props : any ) {
    const {categoryList, onClick, currentCategory} = props;
	const dispatch = useDispatch();
    const [itemList, setItemList] = useState<any>(null);

    useEffect(()=>{

        if( categoryList ) {
            var newList = [...categoryList];

            const ROW_NUM = 4;
            const emptyItemNum = newList.length % ROW_NUM;


            var length = newList.length + ( emptyItemNum > 0?ROW_NUM-emptyItemNum:0 );

            var rowList : any = [];

            for( var i=0; i<length; i++ ) {
                var rowNum = Math.floor( i / 4 ) + 1;
                if( rowList.length < rowNum ) {
                    rowList.push([]);
                }

                if( i < newList.length ) {
                    rowList[rowNum-1].push(newList[i]);
                }
                else {
                    rowList[rowNum-1].push(null);
                }
            }
            setItemList(rowList);
        }

        return(()=>{})
    },[categoryList]);

    return ( 
        <div className="mobile_category_box">
            <div className="title"><span>카테고리</span></div>

            <table className="item">
                {itemList&&itemList.map((rowList:any, index:number)=>{

                    
                    var item_1 = rowList[0];
                    var item_2 = rowList[1];
                    var item_3 = rowList[2];
                    var item_4 = rowList[3];


                    return(
                        <tr className="item_row" key={index}>
                            <CategoryItem {...props} item={item_1} />
                            <CategoryItem {...props} item={item_2} />
                            <CategoryItem {...props} item={item_3} />
                            <CategoryItem {...props} item={item_4} />
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}
export default MobileCategoryBox;

function CategoryItem( props:any ) {
    const {item, currentCategory, onClick} = props;

    var className = "";
    if( item && currentCategory == item.idx ) {
        className = "select";
    }

    if( !item ) {
        return(
            <td className={"empty"}>
                <span>{"·"}</span>
            </td>
        )
    }

    return(
        <td className={className} onClick={()=>{
            if( onClick && currentCategory != item.idx) onClick(item.idx);
        }}><span>{item.name}</span></td>
    )
}