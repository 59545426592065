/****************************************************************************************
 * 
 *  게시글 구역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../../../../../redux_saga/modules';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../../apiAxios';

import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { handleCopyClipBoard, makeDateFromTimeStamp, makePostInsertFullTime, makeThousandNumberStr } from '../../../../../util/Global';
import { ALERT_TEXT_SUCCESS_DELETE, ALERT_TEXT_SUCCESS_DELETE_CANCEL, ALERT_TEXT_SUCCESS_HATE, ALERT_TEXT_SUCCESS_LIKE, ALERT_TEXT_SUCCESS_REPORT, IMG_URL_CHOICE, IMG_URL_CHOICE_WHITE, TEXT_HAVE_HATE, TEXT_HAVE_LIKE, TEXT_HAVE_REPORT, TEXT_NOT_FOUND_POST, TEXT_PLEASE_LOGIN } from '../../../../../util/ConstValue';
import LevelIcon from '../../../../sub/LevelIcon';
import YouTube from 'react-youtube';

import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";
import { AiOutlineAlert } from "react-icons/ai";
import { FaPlay } from "react-icons/fa";
import { PAGE_ADMIN_NOTICE, PAGE_ADMIN_NOTICE_WRITE, PAGE_MAIN, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, PAGE_WRITE_POST, makeUrl } from '../../../../../util/Page';
import { DeletePopupType } from '../../../../popup/PopupDeletePost';

const MobilePostBody = (props:any) => {
	const {postData, categoryIdx, year, postList, postListType, postType, isAllList, onClickBottomList} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

    const [beforeUrl, setBeforeUrl] = useState<any>(null);
    const [nextUrl, setNextUrl] = useState<any>(null);
	
    useEffect(()=>{

        var nUrl = getNextUrl( postList, postData, postListType, categoryIdx, isAllList, postType );
        var bUrl = getBeforeUrl( postList, postData, postListType, categoryIdx, isAllList, postType );

        setNextUrl(nUrl);
        setBeforeUrl(bUrl);

        return(()=>{})
    },[postList, postData, postListType, categoryIdx, isAllList, postType]);

    const clickToBeforePost = () => {
        if( !!beforeUrl ) {
            onClickBottomList();
            navigate(beforeUrl);
        }
    }

    const clickToNextPost = () => {
        if( !!nextUrl ) {
            onClickBottomList();
            navigate(nextUrl);
        }
    }
	
	useEffect(()=>{

		return(()=>{})
	},[]);

	return (
		<div className='mobile_post_body mobile_notice_post_body'>
            <TopComponent {...props} onClickToBeforePost={clickToBeforePost} onClickToNextPost={clickToNextPost} beforeUrl={beforeUrl} nextUrl={nextUrl}/>
            <TitleComponent {...props}/>
            <BodyComponent {...props} onClickToBeforePost={clickToBeforePost} onClickToNextPost={clickToNextPost} beforeUrl={beforeUrl} nextUrl={nextUrl}/>
            <BottonButtonComponent {...props} />
        </div>
	);
};

export default MobilePostBody;


function getNextUrl( list:any, postData:any, postListType:number, categoryIdx:number, isAllList:boolean, postType:number ) {
    if( !list || list.length < 2 ) return null;
    var currentPosition = getCurrentPositionInList( list, postData, isAllList, postType);

    if( currentPosition == list.length - 1 ) return null;
    var item = list[currentPosition+1];

    const params : PAGE_POST_PARAMS = {
        idx:item.idx,
        year:item.year,
        post_list_type:postListType,
        post_type:!!item.post_type?item.post_type:postType,
        category:categoryIdx,
        search:null,
        searchType:null
    }
    return makeUrl(PAGE_URL.STORY_POST, params);
}

function getBeforeUrl( list:any, postData:any, postListType:number, categoryIdx:number, isAllList:boolean, postType:number ) {
    if( !list || list.length < 2 ) return false;
    var currentPosition = getCurrentPositionInList( list, postData, isAllList, postType);

    if( currentPosition == 0 ) return null;
    var item = list[currentPosition-1];

    const params : PAGE_POST_PARAMS = {
        idx:item.idx,
        year:item.year,
        post_list_type:postListType,
        post_type:!!item.post_type?item.post_type:postType,
        category:categoryIdx,
        search:null,
        searchType:null
    }
    return makeUrl(PAGE_URL.STORY_POST, params);

}

function getCurrentPositionInList( list:any, postData:any, isAllList:boolean, postType:number ) {

    var currentPosition = 0;

    for( var i=0; i<list.length; i++ ) {
        var item = list[i];
        if( postData.idx == item.idx && postData.year == item.year && (isAllList==false || (isAllList && postType == item.post_type) ) ) {
            currentPosition = i;
            break;
        }
    }
    return currentPosition;
}

/* 상단 컴포넌트 */
function TopComponent(props:any) {
	const {postData, onClickToList, postType, category} = props;

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    var categoryName = "공지사항";

    
	var categoryList = null;

    if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
        if( category > 0 && communityCategoryList && communityCategoryList.length>0 ) {
            var categoryData = communityCategoryList.filter((i:any) => i.idx == category );
            if( categoryData && categoryData.length>0) categoryName = "[커뮤니티/"+categoryData[0].name+"] · " + categoryName;
        }
        else {
            categoryName = "커뮤니티 · " + categoryName;
        }
    }
    else if( postType == API_TYPES.POST_TYPE.STORY ) {
        if( category > 0 && storyCategoryList && storyCategoryList.length>0 ) {
            var categoryData = storyCategoryList.filter((i:any) => i.idx == category );
            if( categoryData && categoryData.length>0) categoryName = "[사연/"+categoryData[0].name+"]· " + categoryName;
        }
        else {
            categoryName = "사연 · " + categoryName;
        }
    }

	return (
		<div className='top'>
            <div className='category'>
                {categoryName}
            </div>
            <div className='right'>
                <div className='list' onClick={onClickToList}>
                    <div className='icon'>
                        <AiOutlineMenu size={16}/>
                    </div>
                    <div>
                        <span>목록</span>
                    </div>
                </div>
            </div>
        </div>
	);
} // End - TopComponent

function TitleComponent( props:any ) {
	const {postData, postType} = props;

    var showBest = postData && postData.best == API_TYPES.BOOLEAN.TRUE;
    var showChice = postType==API_TYPES.POST_TYPE.STORY && postData && postData.choice_show == API_TYPES.BOOLEAN.TRUE;

    var timeStr = "";
    var body_box_width = window.outerWidth - 40;
    var title_width = window.outerWidth - 85;
    if( showChice ) {
        body_box_width = body_box_width - 100;
        title_width = title_width - 100;
    }

    if( postData ) {
        var d = makeDateFromTimeStamp(postData.insert_time);
        timeStr = makePostInsertFullTime(d);
    }
    return(
        <div className='title_box'>
            { postData && <>
                { showChice && <img className="choice" src={IMG_URL_CHOICE}/> }
                <div className='body_box' style={{width:body_box_width}}>
                    <div className='top'>
                        { showBest && <div className='best'><span>Best</span></div> }
                        <div className='title' style={{width:title_width}}><span>{postData.title}</span></div>
                    </div>
                    <div className='bottom'>
                        <div className='time' style={{marginLeft:0}}>{timeStr}</div>
                        <div className='hits'>{makeThousandNumberStr( postData.hits )}</div>
                        <div className='hits_title'>조회</div>
                    </div>
                </div>
            </>}
        </div>
    )
} // End - TitleComponent

function BodyComponent(props:any) {
	const {postData, postType} = props;

    return(<> 
    {postData&&
        <div className='body_box'>
            <div className='contents'>
                <div className='body_str'><span>{postData.body}</span></div>
                <ImagesComponent {...props}/>
            </div>
        </div>
    }</>)
} // End - BodyComponent



function ImagesComponent(props:any) {
	const {postData} = props;
    return(
        <> { postData && !!postData.image01 &&
            <div className='image'>
                { !!postData.image01 && <img src={postData.image01}/>}
                { !!postData.image02 && <img src={postData.image02}/>}
                { !!postData.image03 && <img src={postData.image03}/>}
                { !!postData.image04 && <img src={postData.image04}/>}
                { !!postData.image05 && <img src={postData.image05}/>}
                { !!postData.image06 && <img src={postData.image06}/>}
                { !!postData.image07 && <img src={postData.image07}/>}
                { !!postData.image08 && <img src={postData.image08}/>}
                { !!postData.image09 && <img src={postData.image09}/>}
                { !!postData.image10 && <img src={postData.image10}/>}
            </div>
            }
        </>
    )
} // End - ImagesComponent

function BottonButtonComponent(props:any) {
    const {adminAbleManage, postType, onClickToList, postData, setDeletePopupData} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const clickedUpdate = () => {
        
        var params : PAGE_WRITE_POST = {
            category:postData.cate_idx>0?postData.cate_idx:null,
            year:null,
            idx:postData.idx,
            post_type:postData.post_type>0?postData.post_type:null
        }
        var url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, params);
        navigate(url);
    }

    return(
        <div className='btn_box'>
            <div className='list' onClick={onClickToList}> <span>목록</span> </div>
            { adminAbleManage    && <div className='edit' onClick={clickedUpdate}>     <span>수정</span> </div> }
        </div>
    )
}