/****************************************************************************************
 * 
 *  상단 탭
 *  
 *  홈 , 사연 , 커뮤니티
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { PAGE_URL, makeUrl } from '../../../util/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux_saga/modules';
import { movePage } from '../../../util/Global';

export interface ADMIN_SUT_TAB_DATA {
    type:number,
    name:string
}

function AdminSubTopTab( props : any ) {
    const {tabDataList, subTabType} = props;
       

    useEffect(()=>{

        return(()=>{})
    },[]);

    return (
        <div className='admin_sub_top_tab'>
            <div className='top_tab_body'>
                { tabDataList && tabDataList.map((item:ADMIN_SUT_TAB_DATA, index:number)=>{
                    
                    return (
                        <Tab {...props} key={index} tabData={item} subTabType={subTabType}/>
                    )
                })
                }
            </div>
        </div>
    );
}

export default AdminSubTopTab;

function Tab( props : any ) {
    const { tabData, subTabType, pageUrl } = props;
	const navigate = useNavigate();
    const isFocus = tabData.type == subTabType;

    const move = ( e:any ) => {
        if( isFocus ) return;

        
        const params = {
            tab:tabData.type
        }
        var fullUrl = makeUrl(pageUrl, params);
        movePage(navigate, e, fullUrl);
    }

    var rightStyle = {};

    return (
        <div className={isFocus?"tab_item_select":'tab_item'} onClick={move} style={rightStyle}>
            <span>{tabData.name}</span>
        </div>
    )
}