import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import Adsense, { AD_PAGE_POSITION, AdType } from './Adsense';

export enum TAB_TYPE {
    HOME = 1,
    STORY = 2,
    COMMUNITY = 3
}

function LeftAd( props : any ) {

    useEffect(()=>{

        return(()=>{})
    },[]);

    return (
        <Adsense 
            {...props}
            className='left_ad'
            type={AdType.WEB_LEFT}
            pagePosition={AD_PAGE_POSITION.LEFT}
            />
    );
}

export default LeftAd;