
/**************************************************************************
* 
* 각종 상수값
*
**************************************************************************/

export const TEST_VERSION :boolean = false;

export const MAX_NICKNAME_LENGTH = 15;

export const TEXT_OVER_NICKNAME_LENGTH = "닉네임은 최대 " + MAX_NICKNAME_LENGTH + "자까지 입력가능합니다";
export const TEXT_PLEASE_LOGIN = "로그인하셔야 가능합니다.";
export const TEXT_CANT_ADMIN_LOGIN = "관리자는 사용이 불가능합니다";
export const TEXT_BLOCK = "님을 차단하시겠습니까?\n앞으로 해당유저의 게시물&대ㅅ글은 나타나지 않습니다";
export const TEXT_BLOCK_CANCEL = "님을 차단 해제 하시겠습니까?";
export const CANT_READ_POST_ON_DELETE = "삭제된 글입니다";
export const TABLE_START_YEAR_DEFAULT = TEST_VERSION?2023:2024;
export const IMG_URL_CHOICE = "/react_images/choice.png";
export const IMG_URL_CHOICE_WHITE = "/react_images/choice_white.png";
export const TEXT_HAVE_LIKE = "이미 추천한 글입니다";

export const TEXT_HAVE_HATE = "이미 반대한 글입니다";
export const TEXT_HAVE_REPORT = "이미 신고한 글입니다";

export const TEXT_HAVE_CHOICE = "이미 채택한 글입니다";
export const TEXT_NOT_FOUND_POST = "글을 찾을 수 없습니다";
export const ALERT_TEXT_SUCCESS_LIKE = "추천 완료";
export const ALERT_TEXT_SUCCESS_HATE = "반대 완료";
export const ALERT_TEXT_SUCCESS_REPORT = "신고 완료";
export const ALERT_TEXT_SUCCESS_DELETE = "삭제 완료";
export const ALERT_TEXT_SUCCESS_DELETE_CANCEL = "복원 완료";
export const ALERT_TEXT_SUCCESS_CHOICE = "채택 완료";

export const IMP_KEY = "8169484715073239";
export const IMP_SECRET = "187442cb275036622544381948881fe366395114be64e042f5b7dfc59195bfb20c76f5774347df49";

//export const INPUT_ACCEPT_TYPE = "image/jpeg,jpg,png,gif,bmp";
export const INPUT_ACCEPT_TYPE = "image/jpeg, image/jpg, image/png, image/gif, image/bmp";