import { Oval } from "react-loader-spinner";

function AdminListItemLoading( props:any ) {
    return(
        <div className='loading'>
            <Oval 
                color="#00f" 
                height={20} 
                width={20}
                />
        </div>
    )
}
export default AdminListItemLoading;