import React, { forwardRef, useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { isNumber, makeInsertTimeFullFromTimeStamp, makeInsertTimeStr, makePostInsertFullTime } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminDataStoryChoiceUpdate } from '../../apiAxios';
import { DeletePopupType, DeleteReasonType, getDeleteReasonStr } from './PopupDeletePost';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import { request } from 'http';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import { AiFillCalendar } from "react-icons/ai";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko'; 
var local : any = ko;
registerLocale('ko', local);


/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupChoiceUpdate = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, onSuccess}=props;

    const [requesting, setRequesting] = useState(false);

    const [selectType, setSelectType] = useState<any>(API_TYPES.STORY_CHOICE_TYPE.NOMAL);
    const [priceStr, setPriceStr] = useState("");
    const [checkSendDate, setCheckSendDate] = useState(false);
    const [sendDate, setSendDate] = useState<Date>(new Date());
    const [urlStr, setUrlStr] = useState("");
    const [isCheck, setIsCheck] = useState(false);


    useEffect(()=>{

        if( data ) {
            setSelectType(data.choice_type);
            setPriceStr(data.choice_price);
            var d = new Date();
            if( data.choice_send_date > 0 ) {
                setCheckSendDate(true);
                d.setTime(data.choice_send_date * 1000);
            }
            setSendDate(d);
            setUrlStr(data.choice_video);
            setIsCheck(data.choice_show==API_TYPES.BOOLEAN.TRUE);
        }
        else {
            setSelectType(API_TYPES.STORY_CHOICE_TYPE.NOMAL);
            setPriceStr("");
            setCheckSendDate(false);
            setSendDate(new Date());
            setUrlStr("");
            setIsCheck(false);
        }
        setRequesting( false );
        return(()=>{})
    },[data]);
    
    const requestUpdate = () => {

        setRequesting( true );

        var choice_price : number       = priceStr?parseInt(priceStr):0;
        var choice_is_send : number     = checkSendDate?API_TYPES.BOOLEAN.TRUE:API_TYPES.BOOLEAN.FALSE;
        var choice_send_date : number   = checkSendDate?Math.floor(sendDate.getTime()/1000):0;
        var choice_video : string       = urlStr;
        var choice_show : number        = isCheck?API_TYPES.BOOLEAN.TRUE:API_TYPES.BOOLEAN.FALSE;

        const param : ParamAdminDataStoryChoiceUpdate = {
            idx             :data.idx,
            year            :data.year,
            choice_type     :selectType,
            choice_price    :choice_price,
            choice_is_send  :choice_is_send,
            choice_send_date:choice_send_date,
            choice_video    :choice_video,
            choice_show     :choice_show
        }
        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                var newData = {...data};
                newData.choice_type         = param.choice_type;
                newData.choice_price        = param.choice_price;
                newData.choice_send_date    = param.choice_send_date;
                newData.choice_video        = param.choice_video;
                newData.choice_show         = param.choice_show;
                onSuccess( newData );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("글을 찾을 수 없습니다");
				}
			}
            setRequesting( false );
		}


        apiAxios.admin.dataStoryChoiceUpdate(dispatch, param, callback);
    }


    //if( !data ) return null;

    var choiceDate = new Date(); 
    if( data ) {
        choiceDate.setTime(data.choice_date*1000);
    }

    return (
        <PopupWrapper {...props}
            title={"채택 정보 수정"}
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title={"저장"} disable={requesting} loading={requesting} onClick={requestUpdate}/>
            </>}>
            
            <div className='body_area popup_choice_info_update'>
                { data && <>
                    <table className='post_box'>
                        <tr>
                            <td className='left'>글제목</td>
                            <td className='right'><span>{data.title}</span></td>
                        </tr>
                        <tr>
                            <td className='left'>글쓴이</td>
                            <td className='right'><span>{data.user_id + " (" + data.nickname + ")"}</span></td>
                        </tr>
                        <tr>
                            <td className='left'>작성일</td>
                            <td className='right'>
                                <span>{makeInsertTimeFullFromTimeStamp(data.insert_time)}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='left'>채택일</td>
                            <td className='right'>
                                <span>{makeInsertTimeFullFromTimeStamp(choiceDate)}</span>
                            </td>
                        </tr>
                    </table>

                    <div className='select_box'>
                        <div className='title'><span>채택타입</span></div>
                        <select className="select"  value={selectType} 
                            onChange={(e)=> {
                                setSelectType(e.target.value);  
                            }}>
                            <option value={API_TYPES.STORY_CHOICE_TYPE.NOMAL}>일반(상품권)</option>
                        </select>
                    </div>

                    <div className={'input_title_unit_box' + (requesting?" input_title_box_disable":"")}>
                        <div className='title'><span>가격</span></div>
                        <input className='input' type='text' disabled={requesting} value={priceStr} onChange={(e)=> { if(isNumber(e.target.value)) setPriceStr(e.target.value); }}/>
                        <div className='unit'><span>원</span></div>
                    </div>

                    <div className={'input_title_calendar_check_box' + (requesting||!checkSendDate?" input_title_box_disable":"")}>
                        <div className='title' onClick={()=>{setCheckSendDate(!checkSendDate)}}>
                            <div className={'check_box' + (checkSendDate?"":" check_box_not_check")}>
                                {checkSendDate && <BsCheckSquareFill size={20}/>}
                                {!checkSendDate && <BsCheckSquare size={20}/>}
                            </div>
                            <span>지급</span>
                        </div>
                        <input className={'input'} type='text' value={makeInsertTimeStr(sendDate, true)+""} onChange={(e)=> {}} disabled={requesting||!checkSendDate}/>
                        <div className='unit'>
                            <DatePicker className="datepicker" selected={sendDate} onChange={(date:Date) => setSendDate(date)} 
                                locale="ko"
                                popperPlacement="top-end"
                                disabled={requesting||!checkSendDate}
                                customInput={<ExampleCustomInput />}/>
                        </div>
                    </div>

                    <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                        <div className='title'><span>영상주소</span></div>
                        <input className='input' type='text' disabled={requesting} value={urlStr} onChange={(e)=> { setUrlStr(e.target.value); }}/>
                    </div>

                    

                    <div className={'check_area' + (!isCheck?" no_check":"") } onClick={()=>{setIsCheck(!isCheck);}}>
                        <div className='checkbox'>
                            {isCheck && <BsCheckSquareFill size={20}/>}
                            {!isCheck && <BsCheckSquare size={20}/>}
                        </div>
                        <div className='check_title'><span>사연채택 게시글에 노출</span></div>
                    </div>
                </>}
            </div>
        </PopupWrapper>
    );
};
  
export default PopupChoiceUpdate;


  /* 달력용 input */
  const ExampleCustomInput = forwardRef(
    ( props : any, ref:any ) => (
      <div className={ "datepicker-custom-input-disabled"} style={{marginTop:5}} onClick={props.onClick} ref={ref}>
        <AiFillCalendar size={20}/>
      </div>
    ),
  );