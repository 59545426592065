/****************************************************************************************
 * 
 *  댓글 구역
 * 
 ****************************************************************************************/

import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux_saga/modules';
import { chkNickName, getDeleteReplyMsg, makeInsertTimeFullFromTimeStamp } from '../../../util/Global';
import { ALERT_TEXT_SUCCESS_DELETE, ALERT_TEXT_SUCCESS_HATE, ALERT_TEXT_SUCCESS_LIKE, ALERT_TEXT_SUCCESS_REPORT, INPUT_ACCEPT_TYPE, MAX_NICKNAME_LENGTH, TEXT_BLOCK, TEXT_CANT_ADMIN_LOGIN, TEXT_HAVE_HATE, TEXT_HAVE_LIKE, TEXT_HAVE_REPORT, TEXT_NOT_FOUND_POST, TEXT_OVER_NICKNAME_LENGTH, TEXT_PLEASE_LOGIN } from '../../../util/ConstValue';
import { PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, getStartPage, makeBottomPageNumberList } from '../../../util/Page';
import apiAxios, { API_TYPES, API_UTILS, ParamUserDataReplyAdd, ParamUserDataReplyUpdate } from '../../../apiAxios';
import LevelIcon from '../../sub/LevelIcon';
import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineAlert } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";
import { AiFillCaretUp } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";

import { AxiosError, AxiosResponse } from 'axios';
import { BsArrowReturnRight } from "react-icons/bs";
import { ImageData, ResponseImage, getImageFile } from '../../../util/Image';
import {Oval} from 'react-loader-spinner';
import { DeletePopupType } from '../../popup/PopupDeletePost';
import HyperLink from '../../sub/HyperLink';
import { addBlockUser } from '../../../apiAxios/v1/data_middleware';

enum LIST_UPDATE_TYPE {
	LIKE=1,
	HATE=2,
	DELETE=3,
	DELETE_ADMIN=4
}

const ReplyBody = (props:any) => {
	const {postData, year, idx, replyDeleteData, setReplyDeleteData, postType, postListSortType, replyTotalData, replyList, onAddReply} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [page, setPage] = useState(1);
	const [reList, setReList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);
	
	useEffect(()=>{

		reqReplyList( page );
		return(()=>{})
	},[page]);

	useEffect(()=>{

		if( replyDeleteData && replyDeleteData.reply_idx == 0 && reList ) {
			var newList = reList.map((i:any, index:number)=>{
				if( replyDeleteData.idx == i.idx ) i.use_state = API_TYPES.POST_USE_STATE.ADMIN_DELETE;
				return i;
			})
			setReList( newList );
			setReplyDeleteData(null);
		}
		return(()=>{})
	},[replyDeleteData, reList]);

	const reqReplyList = ( p : number ) => {
		
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				setTotalData(resData.total_data);
				setReList(resData.list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {

				}
			}
		}

		if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.global.listCommunityReply(dispatch, year, p, idx, callback);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.global.listStoryReply(dispatch, year, p, idx, callback);
		}
	}

	if( !postData )	return null;

	return (
		<div className='post_reply_body' id="post_reply_body">
			<ReplyListComponent {...props} reList={reList?reList:replyList} setReList={setReList} totalData={totalData?totalData:replyTotalData}/>

			<ReplyPageComponent {...props}
				totalData={replyTotalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA)=>{
					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						if( page == item.page ) return;
						setPage(item.page);
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						setPage(getStartPage(page) - 10);
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						setPage(getStartPage(page) + 10);
					}
					setReList(null);
					moveScroll("post_reply_body");
				}}
			/>
			<BottomWriteReplyComponent {...props}
				onUpdateReply={( resData:any )=>{
					setTotalData(null);
					setReList(null);

					setPage(1);
					reqReplyList(1);
					moveScroll("post_reply_body");

					if( onAddReply ) onAddReply();
				}}/>
        </div>
	);
};

export default ReplyBody;

function ReplyListComponent( props:any ) {
	const {replyTotalData, reList} = props;

	return(
		<div className='reply_list'>
			<div className='title'>
				<span>댓글</span>
				<span className='count'>{replyTotalData?replyTotalData.total_count:0}</span>
				<span>개</span>
			</div>
			<div className='title_line'/>

			{ (!reList || reList.length == 0) &&
				<div className='empty_item'>
					<span>댓글이 없습니다</span>
				</div>
			}
			{ reList && reList.length > 0 &&
				reList.map((item:any, index:number)=>{
					return( <ReplyItem {...props} item={item} key={index}/>)
				})
			}
		</div>
	)
}

function moveScroll( id :string) {
	
	const ele : any = document.getElementById(id);
	const elePosition = window.scrollY + ele.getBoundingClientRect().top;
	window.scrollTo(0,elePosition-20);
}

/**
 * 답장 아이템
 * @param props 
 * @returns 
 */
function ReplyItem( props:any ) {
	const {item, idx, adminAbleManage, year, postType, replyDeleteData, setReplyDeleteData, reList, setReList, setDeletePopupData, onClickToList} = props;
	const dispatch = useDispatch();
    const loginData = useSelector((state:RootState)=>state.data.loginData);

	const [openReReply, setOpenReReply] = useState(false);
	const [totalData, setTotalData] = useState<any>(null);
	const [reReplyList, setReReplyList] = useState<any>(null);
	const [page, setPage] = useState(1);
	const [isEdit, setEdit] = useState(false);

    var showBest = item.best == API_TYPES.BOOLEAN.TRUE;
	
	const clickeOpenReReply = () => {
		setOpenReReply(!openReReply);
	}

	useEffect(()=>{
		setTotalData(null);
		setOpenReReply(false);
		return(()=>{})
	},[reList]);
	

	useEffect(()=>{

		if( replyDeleteData && replyDeleteData.reply_idx > 0 && reReplyList ) {
			var newList = reReplyList.map((i:any, index:number)=>{
				if( replyDeleteData.idx == i.idx ) i.use_state = API_TYPES.POST_USE_STATE.ADMIN_DELETE;
				return i;
			})
			setReReplyList( newList );
			setReplyDeleteData(null);
		}
		return(()=>{})
	},[replyDeleteData, reReplyList]);


	useEffect(()=>{

		if( openReReply ) {
			reqReReplyList(page);
		}
		return(()=>{})
	},[openReReply, year, page, idx]);

	const reqReReplyList = ( page:number ) => {
		
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				setTotalData(resData.total_data);
				setReReplyList(resData.list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {

				}
			}
		}

		if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.global.listCommunityReReply(dispatch, year, page, idx, item.idx, callback);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.global.listStoryReReply(dispatch, year, page, idx, item.idx, callback);
		}
	}

	const clickedBlock = () => {
        addBlockUser( dispatch, loginData, item.nickname, item.user_idx, item.user_is_admin, ()=>{
            onClickToList();
        } );
    }

	const clickedUpdate = () => {
		setEdit(true);
	}

	const updateList = ( cnt:number, type:LIST_UPDATE_TYPE ) =>{
		var newList = reList.map((i:any, index:number)=>{
			if( i.idx == item.idx ) {
				if( type == LIST_UPDATE_TYPE.LIKE ) { i.likes = cnt; }
				else if( type == LIST_UPDATE_TYPE.HATE ) { i.hates = cnt; }
				else if( type == LIST_UPDATE_TYPE.DELETE ) { i.use_state = API_TYPES.POST_USE_STATE.DELETE; }
				else if( type == LIST_UPDATE_TYPE.DELETE_ADMIN ) { i.use_state = API_TYPES.POST_USE_STATE.ADMIN_DELETE; }
			}
			return i;
		});

		setReList(newList);
	}

	if( item.use_state != API_TYPES.POST_USE_STATE.ABLE ) {
		return(
			<div className='reply_item reply_item_delete'>
				<div className='title'>
					{ showBest && <div className='best'><span>Best</span></div> }
					<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
				</div>
				<div className='reply_item_body'>
					<div className='body_str'>
						<span>{getDeleteReplyMsg(item.use_state)}</span>
					</div>
				</div>
			</div>
		)
	}

	if( isEdit ) {
		return(
			<div className='reply_item'>
				<div className='title'>
					{ showBest && <div className='best'><span>Best</span></div> }
					<LevelIcon is_admin={item.user_is_admin} level={item.user_level}/>
					<div className='nickname'><span>{item.nickname}</span></div>
					<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
					<div className='right' onClick={()=>{
						setEdit(false);
						}}>
						<span>취소</span>
					</div>
				</div>
				<WriteReplyArea 
					{...props} 
					disableNickName={true}
					isEdit={true}
					nickname={item.nickname}
					body={item.body}
					image={item.image}
					marginTop={20}
					type={ReplyAreaType.UPDATE_REPLY}
					onUpdateReply={( resData:any )=>{
						if( resData ) {
							var newList = reList.map((i:any, index:number)=>{
								if( i.idx == item.idx ) {
									var newItem = {...i};
									newItem.body = resData.body;
									newItem.image = resData.image;
									return newItem;
								}
								return i;
							})
							setReList( newList );
							setEdit(false);
						}
					}}
					/> 
			</div>
		)
	}

	return (
		<div className='reply_item'>

			<div className='title'>
                { showBest && <div className='best'><span>Best</span></div> }
				<LevelIcon is_admin={item.user_is_admin} level={item.user_level}/>
				<div className='nickname'><span>{item.nickname}</span></div>
				<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
				<div className='right' onClick={()=>{clickedReport(loginData, dispatch, postType, item.post_idx, item.year, item.idx);}}>
					<div className='icon'><AiOutlineAlert size={20}/></div>
				</div>
				<div className='right' onClick={()=>{clickedHate(loginData, dispatch, postType, item.post_idx, item.year, item.idx, (cnt:number)=>{
						updateList(cnt, LIST_UPDATE_TYPE.HATE);
					});}}>
                    <div className='count'><span>{item.hates}</span></div>
					<div className='icon'><AiOutlineDislike size={20}/></div>
				</div>
				<div className='right' onClick={()=>{clickedLike(loginData, dispatch, postType, item.post_idx, item.year, item.idx, (cnt:number)=>{
						updateList(cnt, LIST_UPDATE_TYPE.LIKE);
					});}}>
                    <div className='count'><span>{item.likes}</span></div>
					<div className='icon'><AiOutlineLike size={20}/></div>
				</div>
				{ loginData && loginData.is_admin != API_TYPES.BOOLEAN.TRUE && loginData.idx != item.user_idx &&
					<div className='right' onClick={clickedBlock}>
						<span className='color_red'>차단</span>
					</div>
				}
				{ loginData && loginData.idx == item.user_idx &&
				<div className='right' onClick={clickedUpdate}>
					<span>수정</span>
				</div>
				}
				{ loginData && ( adminAbleManage || loginData.idx == item.user_idx) &&
				<div className='right' onClick={()=>{
					
					if( adminAbleManage && loginData.idx != item.user_idx ) {
						setDeletePopupData({data:item, type:DeletePopupType.REPLY});
					}
					else {
						clickedDelete(dispatch, postType, item.year, item.idx,()=>{
							updateList(0, LIST_UPDATE_TYPE.DELETE);
						})
					}
					}}>
					<span>삭제</span>
				</div>
				}
			</div>
			<div className='reply_item_body'>
				{ !!item.image && <img src={item.image}/> }
				<div className='body_str'>
					{/*<span>{item.body}</span>*/}
                    <HyperLink content={item.body}/> 
				</div>
			</div>
			<div className='re_reply_area'>
				<div className='count' onClick={clickeOpenReReply}>
					<div><span>답글</span></div>
					<div className='count'><span>{totalData?totalData.total_count:item.reply_num?item.reply_num:0}</span></div>
					<div><span>개</span></div>
					<div className='icon'>
						{ openReReply && <AiFillCaretUp size={20}/> }
						{ !openReReply && <AiFillCaretDown size={20}/> }
					</div>
				</div>
				
				{ openReReply && <>

					<div className='re_reply_list' id={"re_reply_list_" + item.idx}>
						<div className='enter_area'>
							<WriteReplyArea {...props} 
								item={item}
								type={ReplyAreaType.ADD_RE_REPLY}
								onUpdateReply={( resData:any )=>{
									setPage(1);
									reqReReplyList(1);
								}}/>
						</div>
						{ reReplyList && reReplyList.map((i:any,index:number)=>{
							return(<ReReplyItem {...props} item={i} key={index} reReplyList={reReplyList} setReReplyList={setReReplyList}/>)
						})}

						{ reReplyList && reReplyList.length>0 && 
						<ReReplyPageComponent {...props}
							totalData={totalData}
							page={page}
							onClickPage={(i:PAGE_NUMBER_DATA)=>{
								if( i.type==PAGE_NUMBER_TYPE.NUMBER ) { 
									if( page == i.page * 1 ) return;
									setPage(i.page);
								}
								else if( i.type==PAGE_NUMBER_TYPE.BEFORE ) { 
									setPage(getStartPage(page) - 10);
								}
								else if( i.type==PAGE_NUMBER_TYPE.NEXT ) { 
									setPage(getStartPage(page) + 10);
								}

								setReReplyList(null);
								moveScroll( "re_reply_list_" + item.idx );
							}}
						/>
						}
					</div>
				</>}
			</div>
			
		</div>
	)
}

function ReReplyItem( props:any ) {
	const {item, adminAbleManage, reReplyList, postType, setReReplyList, setDeletePopupData, onClickToList} = props;
	const dispatch = useDispatch();

    const loginData = useSelector((state:RootState)=>state.data.loginData);
	const [isEdit, setEdit] = useState(false);


	const clickedBlock = () => {
        addBlockUser( dispatch, loginData, item.nickname, item.user_idx, item.user_is_admin, ()=>{
            onClickToList();
        } );
    }

	const clickedUpdate = () => {
		setEdit(true);
	}

	const updateList = ( cnt:number, type:LIST_UPDATE_TYPE ) =>{
		var newList = reReplyList.map((i:any, index:number)=>{
			if( i.idx == item.idx ) {
				if( type == LIST_UPDATE_TYPE.LIKE ) { i.likes = cnt; }
				else if( type == LIST_UPDATE_TYPE.HATE ) { i.hates = cnt; }
				else if( type == LIST_UPDATE_TYPE.DELETE ) { i.use_state = API_TYPES.POST_USE_STATE.DELETE; }
				else if( type == LIST_UPDATE_TYPE.DELETE_ADMIN ) { i.use_state = API_TYPES.POST_USE_STATE.ADMIN_DELETE; }
			}
			return i;
		});

		setReReplyList(newList);
	}

	if( item.use_state != API_TYPES.POST_USE_STATE.ABLE ) {
		return(
			<div className='re_reply_item re_reply_item_delete'>
				<div className='left_icon'>
					<div className='icon'>
						<BsArrowReturnRight size={20}/>
					</div>
				</div>
				<div className='right_body'>
					<div className='title'>
						<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
							
					</div>
					<div className='body_str'>
						<div className='str'> {getDeleteReplyMsg(item.use_state)} </div>
					</div>
				</div>
			</div>
		)
	}

	if( isEdit ) {
		
		return(
			<div className='re_reply_item'>
				<div className='left_icon'>
					<div className='icon'>
						<BsArrowReturnRight size={20}/>
					</div>
				</div>
				<div className='right_body'>
				<div className='title'>
						<LevelIcon is_admin={item.user_is_admin} level={item.user_level}/>
						<div className='nickname'> {item.nickname} </div>
						<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
						<div className='right' onClick={()=>{
							setEdit(false);
						}}>
							<span>취소</span>
						</div>
						
					</div>

					<WriteReplyArea 
						{...props} 
						disableNickName={true}
						isEdit={true}
						nickname={item.nickname}
						body={item.body}
						image={item.image}
						marginTop={20}
						type={ReplyAreaType.UPDATE_RE_REPLY}
						onUpdateReply={( resData:any )=>{
							if( resData ) {
								var newList = reReplyList.map((i:any, index:number)=>{
									if( i.idx == item.idx ) {
										var newItem = {...i};
										newItem.body = resData.body;
										newItem.image = resData.image;
										return newItem;
									}
									return i;
								})
								setReReplyList( newList );
								setEdit(false);
							}
						}}
						/> 
				</div>
			</div>
		)
	}

	return (
		<div className='re_reply_item'>
			<div className='left_icon'>
				<div className='icon'>
					<BsArrowReturnRight size={20}/>
				</div>
			</div>
			<div className='right_body'>
				<div className='title'>
					<LevelIcon is_admin={item.user_is_admin} level={item.user_level}/>
					<div className='nickname'> {item.nickname} </div>
					<div className='date'><span>{makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
					<div className='right' onClick={()=>{clickedReport(loginData, dispatch, postType, item.post_idx, item.year, item.idx);}}>
						<div className='icon'><AiOutlineAlert size={16}/></div>
					</div>
					<div className='right' onClick={()=>{clickedHate(loginData, dispatch, postType, item.post_idx, item.year, item.idx, (cnt:number)=>{
							updateList(cnt, LIST_UPDATE_TYPE.HATE);
						});}}>
						<div className='count'><span>{item.hates}</span></div>
						<div className='icon'><AiOutlineDislike size={16}/></div>
					</div>
					<div className='right' onClick={()=>{clickedLike(loginData, dispatch, postType, item.post_idx, item.year, item.idx, (cnt:number)=>{
							updateList(cnt, LIST_UPDATE_TYPE.LIKE);
						});}}>
						<div className='count'><span>{item.likes}</span></div>
						<div className='icon'><AiOutlineLike size={16}/></div>
					</div>
					{ loginData && loginData.is_admin != API_TYPES.BOOLEAN.TRUE && loginData.idx != item.user_idx &&
						<div className='right' onClick={clickedBlock}>
							<span className='color_red'>차단</span>
						</div>
					}
					{ loginData && loginData.idx == item.user_idx &&
					<div className='right' onClick={clickedUpdate}>
						<span>수정</span>
					</div>
					}
					{ loginData && ( adminAbleManage || loginData.idx == item.user_idx) &&
					<div className='right' onClick={()=>{
					
						if( adminAbleManage && loginData.idx != item.user_idx ) {
							setDeletePopupData({data:item, type:DeletePopupType.REPLY});
						}
						else {
							clickedDelete(dispatch, postType, item.year, item.idx,()=>{
								updateList(0, LIST_UPDATE_TYPE.DELETE);
							})
						}
						}}>
						<span>삭제</span>
					</div>
					}
					
				</div>
				<div className='body_str'>
					{ !!item.image && <img src={item.image}/> }
					{/*<div className='str'> {item.body} </div>*/}
                    <div className='str'><HyperLink content={item.body}/>  </div>
				</div>
			</div>
			
		</div>
	)
}

function ReplyPageComponent(props:any) {
	const {totalData, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, null );
		setPageList(list);

        return(()=>{})
    },[totalData, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}
function ReReplyPageComponent(props:any) {
	const {totalData, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, null );
		setPageList(list);

        return(()=>{})
    },[totalData, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='post__reply_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR )	{ numberStr = item.page+"년"; 	className="before_year";  yearStyle={width:100}}
				else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR )	{ numberStr = item.page+"년"; 	className="next_year";  	yearStyle={width:100}}

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						{ item.type==PAGE_NUMBER_TYPE.NEXT_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>

						{ item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR && item.page != -1 &&
							<span className="dot">{"·"}</span>
						}
					</div>
				)
			})}
		</div>
	)
}


function BottomWriteReplyComponent( props:any ) {

	return(
		<div className='bottom_write_reply'>
			<span>댓글쓰기</span>
			<div className='title_line'/>
			<WriteReplyArea {...props} type={ReplyAreaType.ADD_REPLY}/>
		</div>
	)
}

enum ReplyAreaType {
	ADD_REPLY = 1,
	UPDATE_REPLY = 2,
	ADD_RE_REPLY = 3,
	UPDATE_RE_REPLY = 4
}

function WriteReplyArea( props:any ) {
	const {idx, year, postType, item, disableNickName, isEdit, nickname, body, image, marginTop, onUpdateReply, type} = props;
	const dispatch = useDispatch();

    const loginData = useSelector((state:RootState)=>state.data.loginData);
	const [nickNameStr, setNickNameStr] = useState("");
	const [replyStr, setReplyStr] = useState("");
	const [uniqId, setUniqId] = useState("");
	const nickNameRef : any = useRef();
	const replyRef : any = useRef();
	const [imageData, setImageData] = useState<ImageData|null>(null);
	const [loadingImage, setLoadingImage] = useState(false);
	const fileRef : any = useRef();
	const [requesting, setRequesting] = useState(false);

	useEffect(()=>{

		if( !!image ) {
			setImageData({url:image, blob:null, file:null});
		}
		
		setUniqId((new Date()).getTime()+"");
		return(()=>{})
	},[]);

	useEffect(()=>{
		if( loginData ) {
			if( isEdit ) {
				setNickNameStr(nickname);
				setReplyStr(body);
			}
			else {
				setNickNameStr(loginData.nickname);
			}
		}
		else {
			setNickNameStr("");
			setReplyStr("");
		}
		return(()=>{})
	},[loginData]);

	const onFocus = (e:any)=>{
		if( !loginData ) {
			nickNameRef.current.blur();
			replyRef.current.blur();
			alert(TEXT_PLEASE_LOGIN);
		}
	}

	const clickedEnter = () => {
		if( requesting ) return;

		if( !loginData ) { alert(TEXT_PLEASE_LOGIN); return; }
		if( !nickNameStr ) { alert("닉네임을 입력해주세요"); return; }
		if( !replyStr && !imageData ) { alert("내용을 입력해주세요"); return; }

		if( loginData.is_admin != API_TYPES.BOOLEAN.TRUE ) {
			var errorMsg = chkNickName(nickNameStr);
			if( !!errorMsg ) { alert(errorMsg); return; }
		}

		if( type == ReplyAreaType.ADD_REPLY ) {
			clickAddReply(null, nickNameStr, replyStr, imageData);
		}
		else if( type == ReplyAreaType.ADD_RE_REPLY ) {
			clickAddReply(item.idx, nickNameStr, replyStr, imageData);
		}
		else if( type == ReplyAreaType.UPDATE_REPLY ) {
			clickUpdateReply(item.idx, replyStr, imageData);
		}
		else if( type == ReplyAreaType.UPDATE_RE_REPLY ) {
			clickUpdateReply(item.idx, replyStr, imageData);
		}
		
	}

	// 댓글 추가
	const clickAddReply = ( reply_idx:number|null, nickNameStr:string, replyStr:string, imageData:ImageData|null) => {
		setRequesting( true );

		var params : ParamUserDataReplyAdd = {
			post_idx:idx,
			reply_idx:reply_idx,
			nickname:nickNameStr,
			body:replyStr,
			year:year,
			image_file:imageData?imageData.file:null
		}

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				if( onUpdateReply ) {
					onUpdateReply( resData );
				}
				setReplyStr("");
				setImageData(null);

				alert("댓글 등록 완료");
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
				}
			}
			setRequesting( false );
		}

		if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.user.dataCommunityReplyAdd(dispatch,params, callback);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.user.dataStoryReplyAdd(dispatch,params, callback);
		}
		else {
			setRequesting( false );
		}
	} // End - clickAddReply

	// 댓글 수정
	const clickUpdateReply = ( idx:number, replyStr:string|null, imageData:ImageData|null) => {
		setRequesting( true );

		var image_file = imageData&&imageData.file?imageData.file:null;
		var image_url = imageData&&!!imageData.url?imageData.url:null;
		var delete_url = imageData&&imageData.file&&!!item.image?item.image:null;

		var params : ParamUserDataReplyUpdate = {
			idx:idx,
			year:year,
			body:replyStr,
			image_file:image_file,
			image_url:image_url,
			delete_url:delete_url
		}

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				if( onUpdateReply ) {
					onUpdateReply( resData );
				}
				setReplyStr("");
				setImageData(null);

				alert("댓글 수정 완료");
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
				}
			}
			setRequesting( false );
		}

		if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.user.dataCommunityReplyUpdate(dispatch,params, callback);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.user.dataStoryReplyUpdate(dispatch,params, callback);
		}
		else {
			setRequesting( false );
		}
	} // End - clickUpdateReply

	const clickedUploadImage = () => {
		if( requesting ) return;

		if( !loginData ) {
			alert(TEXT_PLEASE_LOGIN);
			return;
		}
		document.getElementById(uniqId)?.click();
	}
	
	const onSelectFile = async (e : any) => {
		console.log("onSelectFile");
		setLoadingImage(true);

		getImageFile(e, (responseImage : ResponseImage)=>{
			//document.getElementById(uniqId)?.value = null;
			console.log("getImageFile receive");

			if( responseImage && responseImage.image ) {
				setImageData(responseImage.image);
			}
					
			if( fileRef && fileRef.current ) {
				fileRef.current.value = "";
			}
			setLoadingImage(false);
		})
	}

	var style = {};
	if( marginTop > 0 ) style = {marginTop:marginTop};

	var showImage = loadingImage || !!imageData;
	var nickNameInputDisable = disableNickName||requesting||(loginData&&loginData.is_admin==API_TYPES.BOOLEAN.TRUE);

	return(
		<div className='write_reply' style={style}>
			{ showImage &&
			<div className='left'>
				{ loadingImage &&
					<div className='image'>
						<div className='loader'>
							<Oval 
								color="#00f" 
								height={20} 
								width={20}
								/>
						</div>
					</div>
				}
				{ !!imageData &&
					<div className='image'>
						<img src={!!imageData.url?imageData.url:imageData.blob} />
						<AiFillCloseCircle className='close' size={20} onClick={()=>{setImageData(null);}}/>
					</div>
				}
			</div>
			}
			<div className={'right' + (showImage?" show_image":"")}>
				<div className='first'>
					<div className={'nickname' + (nickNameInputDisable?" disable":"")}>
						<input ref={nickNameRef} value={nickNameStr} onChange={(e)=> { 
							var text = e.target.value;
							if( text.length > MAX_NICKNAME_LENGTH ) {
								alert(TEXT_OVER_NICKNAME_LENGTH);
							}
							else {
								setNickNameStr(text); 
							}

						}} onFocus={onFocus} disabled={nickNameInputDisable?true:false}/>
					</div>
					{ !loadingImage && !imageData &&
						<div className='img' onClick={clickedUploadImage}>
								<span>이미지 첨부</span>
						</div>
					}
				</div>
				<div className='second'>
					<div className='input_body_div'>
						<textarea ref={replyRef} value={replyStr} onChange={(e)=> { setReplyStr(e.target.value); }} onFocus={onFocus} disabled={requesting}/>
					</div>
					<div className='enter_btn' onClick={clickedEnter}>
						{ !requesting && <span>{isEdit?"수정":"등록"}</span> }
						{ requesting &&
							<div className='loader'>
								<Oval 
									color="#fff" 
									height={20} 
									width={20}
									/>
							</div>
						}
					</div>
				</div>
				<input
					ref={fileRef}
					type='file'
					id={uniqId}
					name='images'
					hidden={true}
					onChange={onSelectFile}
					accept={INPUT_ACCEPT_TYPE}
				/>
			</div>
		</div>
	)
}

	
const clickedLike = ( loginData:any, dispatch:any, postType:number, post_idx:number, year:number, reply_idx:number, resFunction:Function ) => {
	if( !loginData ) { 
		alert(TEXT_PLEASE_LOGIN); 
		return;
	}

	const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
		if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
			const resData = API_UTILS.getResponseSuccessData(response);
			var cnt = resData.count;
			resFunction(cnt);
			/*var newData = {...postData};
			newData.likes = cnt;
			setPostData(newData);*/
			alert(ALERT_TEXT_SUCCESS_LIKE);
		}
		else if( error ) {
			const errorData : any = API_UTILS.getErrorResponseData(error);
			if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
				alert(TEXT_HAVE_LIKE);
			}
		}
	}
	if( postType == API_TYPES.POST_TYPE.STORY ) {
		apiAxios.user.dataStoryLike(dispatch, post_idx, year, reply_idx, callback);
	}
	else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
		apiAxios.user.dataCommunityLike(dispatch, post_idx, year, reply_idx, callback);
	}
}

const clickedHate = ( loginData:any, dispatch:any, postType:number, post_idx:number, year:number, reply_idx:number, resFunction:Function ) => {
	if( !loginData ) { 
		alert(TEXT_PLEASE_LOGIN); 
		return;
	}

	const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
		if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
			const resData = API_UTILS.getResponseSuccessData(response);
			var cnt = resData.count;
			resFunction(cnt);
			/*var newData = {...postData};
			newData.hates = cnt;
			setPostData(newData);*/
			alert(ALERT_TEXT_SUCCESS_HATE);
		}
		else if( error ) {
			const errorData : any = API_UTILS.getErrorResponseData(error);
			if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
				alert(TEXT_HAVE_HATE);
			}
		}
	}

	if( postType == API_TYPES.POST_TYPE.STORY ) {
		apiAxios.user.dataStoryHate(dispatch, post_idx, year, reply_idx, callback);
	}
	else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
		apiAxios.user.dataCommunityHate(dispatch, post_idx, year, reply_idx, callback);
	}
}

const clickedReport = ( loginData:any, dispatch:any, postType:number, post_idx:number, year:number, reply_idx:number ) => {
	if( !loginData ) { 
		alert(TEXT_PLEASE_LOGIN); 
		return;
	}

	const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
		if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
			const resData = API_UTILS.getResponseSuccessData(response);
			var cnt = resData.count;
			alert(ALERT_TEXT_SUCCESS_REPORT);
		}
		else if( error ) {
			const errorData : any = API_UTILS.getErrorResponseData(error);
			if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
				alert(TEXT_HAVE_REPORT);
			}
		}
	}

	if( postType == API_TYPES.POST_TYPE.STORY ) {
		apiAxios.user.dataStoryReport(dispatch, post_idx, year, reply_idx, callback);
	}
	else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
		apiAxios.user.dataCommunityReport(dispatch, post_idx, year, reply_idx, callback);
	}
}

const clickedDelete = ( dispatch:any, postType:number, year:number, reply_idx:number, resFunction:Function) => {
	// 관리자인경우
	var title = "댓글을 삭제하시겠습니까?";
	var returnValue = window.confirm(title);
	if (returnValue) {
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				alert(ALERT_TEXT_SUCCESS_DELETE);
				resFunction();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert(TEXT_NOT_FOUND_POST);
				}
			}
		}

		if( postType == API_TYPES.POST_TYPE.STORY ) {
			apiAxios.user.dataStoryReplyDelete(dispatch, reply_idx, year, callback);
		}
		else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			apiAxios.user.dataCommunityReplyDelete(dispatch, reply_idx, year, callback);
		}
	}
}