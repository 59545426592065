import React, { useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import reduxActions from '../../redux_saga/modules/moduleActions';

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupWithdraw = ( props:any ) => {
    const { visible, onClose}=props;
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordStr, setPasswordStr] = useState("");
    const [requesting, setRequesting] = useState(false);


    useEffect(()=>{
        if( visible != true ) { initData(); }
        return(()=>{})
    },[visible]);
    

    const initData = () => {
        setRequesting(false);
        setPasswordStr("");
    }

    const clickedWithdraw = () => {
        if( requesting ) return;

        if( !passwordStr ) {
            alert("비밀번호를 입력하세요");
            return;
        }
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                reduxActions.data.setLoginData( dispatch, null );
                reduxActions.data.setUserData(dispatch, null);

                if( onClose ) onClose();
                navigate(PAGE_URL.MAIN, { replace:true });
                
                alert( "회원탈퇴 완료" );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("비밀번호를 잘못 입력하였습니다");
				}
			}
		}

        setRequesting( true );

        apiAxios.user.authWithdraw(dispatch,passwordStr, callback);
    }


    return (
        <PopupWrapper {...props}
            title={"회원탈퇴"}
            width={300}
            buttonChildren={<>
                <RedButton {...props} title="탈퇴" onClick={clickedWithdraw} disable={requesting} loading={requesting}/>
            </>}>
            
            <div className='body_area popup_withdraw'>
                <div className='guide'>
                    <span> 
                        {"회원탈퇴시 작성하신 글&댓글은 삭제되지 않으며 추후 삭제 수정 불가능합니다.\n\n회원탈퇴를 하시겠습니까?"}
                    </span>
                </div>
                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <input className='input' type='password' placeholder='비밀번호를 입력하세요' disabled={requesting} value={passwordStr} onChange={(e)=> { setPasswordStr(e.target.value); }}/>
                </div>
            </div>
        </PopupWrapper>
    );
};
  
export default PopupWithdraw;

export const getDeleteReasonStr = ( selectType:number, etcStr:string|null ) => {
    if( selectType == DeleteReasonType.TABOO ) { return "불건전한 내용을 포함하고 있습니다"; }
    if( selectType == DeleteReasonType.DISS ) { return "비방글 입니다"; }
    if( selectType == DeleteReasonType.POLITICS ) { return "정치글 입니다"; }
    if( selectType == DeleteReasonType.PLASTER ) { return "도배"; }
    if( selectType == DeleteReasonType.REPORT ) { return "신고누적"; }
    if( selectType == DeleteReasonType.ETC ) { 
        return etcStr; 
    }
    return null;
}