/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../../sub/TopTab';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../../../../redux_saga/modules';
import Wrapper from '../../sub/Wrapper';
import { checkLogin, makeInsertTimeFullFromTimeStamp } from '../../../../util/Global';
import TopAdComponent from '../../../sub/TopAdComponent';
import { Oval } from 'react-loader-spinner';
import { PAGE_URL } from '../../../../util/Page';
import { AD_PAGE_POSITION } from '../../../sub/Adsense';
import { ADMIN_TAB_TYPE } from '../../sub/AdminTopTab';
import { isMobile } from 'react-device-detect';

const AdminInquiryPost = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	const [idx, setIdx] = useState(-1);
    const [inquryData, setInquryData] = useState<any>(null);
    const [replyList, setReplyList] = useState<any>(null);
    const [replyStr, setReplyStr] = useState("");
    const [requestingSave, setRequestingSave] = useState(false);


    useEffect(()=>{
		checkLogin(navigate, loginData);
        return(()=>{})
    },[loginData]);

    
	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const i = l_p.get("idx");

		var newIdx = -1;
		if( i ) 	{ try{ newIdx 				= parseInt(i); 		}catch(e){ console.log("e",e);} }

		setIdx(newIdx);
        setReplyStr("");

        reqData(newIdx);

		return(()=>{})
	},[location]);


    const reqData = ( idx:number ) => {
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                console.log("InquiryPost ====================== ", resData);
                setInquryData(resData.inqury);
                setReplyList(resData.reply);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
					navigate(-1);
				}
			}
		}

        apiAxios.admin.dataInquiry(dispatch, idx, callback);
    }

    const onClickToList = () => {
        navigate(PAGE_URL.MY_INQUIRY);
    }

    const onclickSave = () => {
        if( !replyStr ) {
            alert("답변 내용이 없습니다");
            return;
        }
		var title = "댓글을 저장하시겠습니까?";
		var returnValue = window.confirm(title);
        
        if( returnValue ) {
            setRequestingSave(true);

            const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);
                    
                    reqData(idx);
                    setReplyStr("");
                    alert("저장완료");
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert("게시글을 찾을 수 없습니다");
                        navigate(-1);
                    }
                }
                setRequestingSave(false);
            }
    
            apiAxios.admin.dataInquiryReplyAdd( dispatch, idx, replyStr, callback);
        }
    }

	return (
		<Wrapper
            hideSubTab={true}
            tabType={ADMIN_TAB_TYPE.INQUIRY}>
			<MiddleComponent {...props} 
                inquryData={inquryData} 
                replyList={replyList} 
                onClickToList={onClickToList}
                replyStr={replyStr}
                setReplyStr={setReplyStr}
                onSave={onclickSave}
                requestingSave={requestingSave}
                />
		</Wrapper>
	);
};

export default AdminInquiryPost;

function MiddleComponent( props:any ) {
    const { inquryData } = props;

	return(
		<div className='middle_area'>
            { inquryData && <>
                <BodyBox {...props}/>
                <WriteArea {...props}/>
                <BottonButtonComponent {...props}/>
            </>}
		</div>
	)
}

function BodyBox( props:any ) {
    const { inquryData } = props;

    const getTypeStr = (inquryData:any) => {
        if( !inquryData ) return "";

        if( inquryData.type == API_TYPES.INQUIRY_TYPE.AD ) {
            return "광고";
        }
        else if( inquryData.type == API_TYPES.INQUIRY_TYPE.CUSTOMER ) {
            return "고객";
        }
        return "";
    } 

    return(
    <div className='admin_inquiry_post'>
        <div className='title'> {inquryData?inquryData.title:""} </div>
        { isMobile && <>
            <div className='title_box'>
                <div className='nickname'> 
                    <div className='title'>닉네임(아이디)</div>
                    <div className='id'>{inquryData.nickname + "(" + inquryData.id + ")"}</div>
                </div>
            </div>
            <div className='title_box'>
                <div className='second'>
                    <div className='title'>문의타입</div>
                    <div className='type'>{getTypeStr(inquryData)}</div>
                    <div className='title'>작성시간</div>
                    <div className='time'>{inquryData?makeInsertTimeFullFromTimeStamp(inquryData.insert_time):"-"}</div>
                </div>
            </div>
            </>
        }
        { !isMobile && 
            <div className='title_box'>
                <div className='left'> 
                    <div className='title'>닉네임(아이디)</div>
                    <div className='id'>{inquryData.nickname + "(" + inquryData.id + ")"}</div>
                </div>
                <div className='right'>
                    <div className='title'>문의타입</div>
                    <div className='type'>{getTypeStr(inquryData)}</div>
                    <div className='title'>작성시간</div>
                    <div className='time'>{inquryData?makeInsertTimeFullFromTimeStamp(inquryData.insert_time):"-"}</div>
                </div>
            </div>
        }

        { inquryData &&  
            <div className='body_box'><span>{inquryData.body}</span></div>
        }
        <ReplyBox {...props}/>
    </div>)
}

function ReplyBox(props:any) {
    const { replyList } = props;

    return(<>
    { replyList && replyList.map((item:any, index:number)=>{

        var whoStr = "답변";
        var bgStyle = {};
        if( item.user_idx > 0 ) {
            whoStr = "사용자 답변";
            bgStyle = {backgroundColor:"#a0b6ff50"};
        }

        return(
            <div className='reply_box' style={bgStyle} key={index}>
                <div className='top'><span>{ whoStr + " · " + makeInsertTimeFullFromTimeStamp(item.insert_time)}</span></div>
                <div><span>{item.body}</span></div>
            </div>
        )
    })}
    </>)
}

function WriteArea(props:any) {
    const { replyStr, setReplyStr, inquryData } = props;

    if( inquryData.read == API_TYPES.BOOLEAN.TRUE ) return null;

    return(
        <div className='admin_write_reply_area'>
            <div className='title'><span>답변 쓰기</span></div>
            <textarea value={replyStr} onChange={(e)=> { setReplyStr(e.target.value); }}/>
        </div>
    )
}

function BottonButtonComponent(props:any) {
    const {onSave, onClickToList, requestingSave, inquryData} = props;
	const navigate = useNavigate();
    
    const clickedCancel = () => {
        navigate(-1);
    }

    return(
        <div className='admin_write_post_btn_box'>
            { inquryData.read == API_TYPES.BOOLEAN.FALSE &&
            <div className='save' onClick={onSave}>  
                { !requestingSave && <span>저장</span> }
                { requestingSave && 
                    <div className='loader'>
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                    </div> 
                }
            </div> 
            }
            <div className='back' onClick={clickedCancel}>  
                <span>목록</span>
            </div> 
        </div>
    )
}