/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import { ADMIN_SUT_TAB_DATA } from '../sub/AdminSubTopTab';
import { ADMIN_SUB_TAB_NONE, ADMIN_SUB_TAP_USER, PAGE_URL } from '../../../util/Page';
import { RootState } from '../../../redux_saga/modules';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, AdminAccessResult, checkAbleManageAdminPage, makeAdminManageData } from '../../../util/AdminAccessHelper';
import AdminUserList from './sub/AdminUserList';
import UserList from './sub/UserList';
import BlackList from './sub/BlackList';
import PopupBlackList, { BalckListPopupData } from '../../popup/PopupBlackList';
import PopupAdmin from '../../popup/PopupAdmin';

const SUB_TAB_DATA_LIST : ADMIN_SUT_TAB_DATA[] = [
	{type:ADMIN_SUB_TAP_USER.ADMIN_USER	, name:"관리자"},
	{type:ADMIN_SUB_TAP_USER.USER		, name:"사용자"},
	{type:ADMIN_SUB_TAP_USER.BLACKLIST	, name:"블랙리스트"}
];

const AdminAccount = (props:any) => {
	const [blacklistPopupData, setBlacklistPopupData] = useState<BalckListPopupData|null>(null);
	const [subTabType, setSubTabType] = useState(ADMIN_SUB_TAB_NONE);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	const [subTabDataList, setSubTabDataList] = useState<ADMIN_SUT_TAB_DATA[]|null>(null);
	const [refreshBlackListData, setRefreshBlackListData] = useState(false);

	const [showAdminPopup, setShowAdminPopup] = useState(false);
	const [adminPopupData, setAdminPopupData] = useState<any>(null);
	const [refreshAdminData, setRefreshAdminData] = useState(false);
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	
	useEffect(()=>{
		console.log("AdminUser loginData : ",loginData);

		if( loginData ) {
			var newList : ADMIN_SUT_TAB_DATA[] = [];
	
			var showAdmin = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.ACCOUNT_ADMIN)).accessType == ADMIN_ACCESS_TYPE.ALL;
			var showUser = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.ACCOUNT_USER)).accessType == ADMIN_ACCESS_TYPE.ALL;
			
			if( showAdmin ) {
				newList.push({type:ADMIN_SUB_TAP_USER.ADMIN_USER	, name:"관리자"});
			}

			if( showUser ) {
				newList.push({type:ADMIN_SUB_TAP_USER.USER		, name:"사용자"});
				newList.push({type:ADMIN_SUB_TAP_USER.BLACKLIST	, name:"블랙리스트"});
			}
	
			if( newList.length > 0 ) {
				setSubTabType(newList[0].type);
			}
			setSubTabDataList(newList);
		}
		
		return(()=>{})
	},[loginData]);

	const openAdminPopup = ( item : any ) => {
		setShowAdminPopup(true);
		setAdminPopupData(item);
	}

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.USER}
			defaultSubTab={ADMIN_SUB_TAP_USER.ADMIN_USER}
			setSubTabType={setSubTabType}
			subTabType={subTabType}
			pageUrl={PAGE_URL.ADMIN_USER}
			tabDataList={subTabDataList}
			popup={
				<>
				<PopupBlackList
					visible={!!blacklistPopupData}
					onClose={()=>{ setBlacklistPopupData(null); }}
					data={blacklistPopupData?blacklistPopupData.data:null}
					type={blacklistPopupData?blacklistPopupData.type:null}
					userIdx={blacklistPopupData?blacklistPopupData.userIdx:null}
					postType={null}
					onComplete={(isAdd:boolean, isDelete:boolean)=>{ 
						if( isAdd ) {
							alert("블랙리스트 추가 완료");
						}
						else {
							alert("블랙리스트 취소 완료");
						}

						setRefreshBlackListData(true);
						setBlacklistPopupData(null); 
					}}
					/>
					
				<PopupAdmin
					visible={!!showAdminPopup}
					onClose={()=>{ setShowAdminPopup(false); setAdminPopupData(null); }}
					data={adminPopupData}
					onSuccess={()=>{
						setShowAdminPopup(false);
						setAdminPopupData(null);
						setRefreshAdminData(true);
					}}
					/>
				</>
			}>
			<MiddleComponent 
				{...props}
				subTabType={subTabType}
				setBlacklistPopupData={setBlacklistPopupData}
				refreshBlackListData={refreshBlackListData}
				setRefreshBlackListData={setRefreshBlackListData}
				refreshAdminData={refreshAdminData}
				setRefreshAdminData={setRefreshAdminData}
				openAdminPopup={openAdminPopup} />
		</Wrapper>
	);
};

export default AdminAccount;


function MiddleComponent( props:any ) {
	const { subTabType } = props;
	return(
		<div className='middle_area'>
			{ subTabType == ADMIN_SUB_TAP_USER.ADMIN_USER	&& <AdminUserList {...props}/>}
			{ subTabType == ADMIN_SUB_TAP_USER.USER 		&& <UserList {...props}/>}
			{ subTabType == ADMIN_SUB_TAP_USER.BLACKLIST 	&& <BlackList {...props}/>}
		</div>
	)
}