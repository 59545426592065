/****************************************************************************************
 * 
 *  게시글 구역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from '../../../../redux_saga/modules';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';

import { AiOutlineMenu } from "react-icons/ai";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import { BsBan } from "react-icons/bs";
import { handleCopyClipBoard, makeDateFromTimeStamp, makePostInsertFullTime, makeThousandNumberStr } from '../../../../util/Global';
import { ALERT_TEXT_SUCCESS_DELETE, ALERT_TEXT_SUCCESS_DELETE_CANCEL, ALERT_TEXT_SUCCESS_HATE, ALERT_TEXT_SUCCESS_LIKE, ALERT_TEXT_SUCCESS_REPORT, IMG_URL_CHOICE, IMG_URL_CHOICE_WHITE, TEXT_BLOCK, TEXT_CANT_ADMIN_LOGIN, TEXT_HAVE_HATE, TEXT_HAVE_LIKE, TEXT_HAVE_REPORT, TEXT_NOT_FOUND_POST, TEXT_PLEASE_LOGIN } from '../../../../util/ConstValue';
import LevelIcon from '../../../sub/LevelIcon';
import YouTube from 'react-youtube';

import { AiOutlineLike } from "react-icons/ai";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineShareAlt } from "react-icons/ai";
import { AiOutlineAlert } from "react-icons/ai";
import { FaPlay } from "react-icons/fa";
import { PAGE_MAIN, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, PAGE_WRITE_POST, makeUrl } from '../../../../util/Page';
import { DeletePopupType } from '../../../popup/PopupDeletePost';
import HyperLink from '../../../sub/HyperLink';
import { addBlockUser } from '../../../../apiAxios/v1/data_middleware';

const MobilePostBody = (props:any) => {
	const {postData, categoryIdx, year, postList, postListType, postType, isAllList, onClickBottomList} = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

    const [beforeUrl, setBeforeUrl] = useState<any>(null);
    const [nextUrl, setNextUrl] = useState<any>(null);
	
    useEffect(()=>{

        var nUrl = getNextUrl( postList, postData, postListType, categoryIdx, isAllList, postType );
        var bUrl = getBeforeUrl( postList, postData, postListType, categoryIdx, isAllList, postType );

        setNextUrl(nUrl);
        setBeforeUrl(bUrl);

        return(()=>{})
    },[postList, postData, postListType, categoryIdx, isAllList, postType]);

    const clickToBeforePost = () => {
        if( !!beforeUrl ) {
            onClickBottomList();
            navigate(beforeUrl);
        }
    }

    const clickToNextPost = () => {
        if( !!nextUrl ) {
            onClickBottomList();
            navigate(nextUrl);
        }
    }
	
	useEffect(()=>{

		return(()=>{})
	},[]);

	return (
		<div className='mobile_post_body'>
            <TopComponent {...props} onClickToBeforePost={clickToBeforePost} onClickToNextPost={clickToNextPost} beforeUrl={beforeUrl} nextUrl={nextUrl}/>
            <TitleComponent {...props}/>
            <BodyComponent {...props} onClickToBeforePost={clickToBeforePost} onClickToNextPost={clickToNextPost} beforeUrl={beforeUrl} nextUrl={nextUrl}/>
            <BottonButtonComponent {...props} />
        </div>
	);
};

export default MobilePostBody;


function getNextUrl( list:any, postData:any, postListType:number, categoryIdx:number, isAllList:boolean, postType:number ) {
    if( !list || list.length < 2 ) return null;
    var currentPosition = getCurrentPositionInList( list, postData, isAllList, postType);

    if( currentPosition == list.length - 1 ) return null;
    var item = list[currentPosition+1];

    const params : PAGE_POST_PARAMS = {
        idx:item.idx,
        year:item.year,
        post_list_type:postListType,
        post_type:!!item.post_type?item.post_type:postType,
        category:categoryIdx,
        search:null,
        searchType:null
    }
    return makeUrl(PAGE_URL.STORY_POST, params);
}

function getBeforeUrl( list:any, postData:any, postListType:number, categoryIdx:number, isAllList:boolean, postType:number ) {
    if( !list || list.length < 2 ) return false;
    var currentPosition = getCurrentPositionInList( list, postData, isAllList, postType);

    if( currentPosition == 0 ) return null;
    var item = list[currentPosition-1];

    const params : PAGE_POST_PARAMS = {
        idx:item.idx,
        year:item.year,
        post_list_type:postListType,
        post_type:!!item.post_type?item.post_type:postType,
        category:categoryIdx,
        search:null,
        searchType:null
    }
    return makeUrl(PAGE_URL.STORY_POST, params);

}

function getCurrentPositionInList( list:any, postData:any, isAllList:boolean, postType:number ) {

    var currentPosition = 0;

    for( var i=0; i<list.length; i++ ) {
        var item = list[i];
        if( postData.idx == item.idx && postData.year == item.year && (isAllList==false || (isAllList && postType == item.post_type) ) ) {
            currentPosition = i;
            break;
        }
    }
    return currentPosition;
}

/* 상단 컴포넌트 */
function TopComponent(props:any) {
	const {postData, postType, isAllList, onClickToList, onClickToBeforePost, onClickToNextPost, beforeUrl, nextUrl} = props;
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	var categoryName = "";

    
	var categoryList = null;
    var postName = "";
	if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
        if( isAllList ) postName = "커뮤니티 · ";
		categoryList = communityCategoryList;
	}
	else if( postType == API_TYPES.POST_TYPE.STORY ) {
        if( isAllList ) postName = "사연 · ";
		categoryList = storyCategoryList;
	}

	if( !!categoryList ) {
		var categoryData = categoryList.filter( (i:any) => !!postData && i.idx == postData.cate_idx );
		if( !!categoryData && categoryData.length > 0 ) { 
            categoryName = postName + categoryData[0].name; 
        }
	}
    
	return (
		<div className='top'>
            <div className='category'>
                {categoryName}
            </div>
            <div className='right'>
                <div className='list' onClick={onClickToList}>
                    <div className='icon'>
                        <AiOutlineMenu size={16}/>
                    </div>
                    <div>
                        <span>목록</span>
                    </div>
                </div>
                <div className={'before' + (!beforeUrl?" disable":"")} onClick={onClickToBeforePost}>
                    <div className='icon'>
                        <AiOutlineLeft size={16}/>
                    </div>
                    <div>
                        <span>이전글</span>
                    </div>
                </div>
                <div className={'next' + (!nextUrl?" disable":"")} onClick={onClickToNextPost}>
                    <div>
                        <span>다음글</span>
                    </div>
                    <div className='icon'>
                        <AiOutlineRight size={16}/>
                    </div>
                </div>
            </div>
        </div>
	);
} // End - TopComponent

function TitleComponent( props:any ) {
	const {postData, postType} = props;

    var showBest = postData && postData.best == API_TYPES.BOOLEAN.TRUE;

    var timeStr = "";
    var body_box_width = window.outerWidth - 40;
    var title_width = window.outerWidth - 85;

    if( postData ) {
        var d = makeDateFromTimeStamp(postData.insert_time);
        timeStr = makePostInsertFullTime(d);
    }
    return(
        <div className='title_box'>
            { postData && <>
                <div className='body_box' style={{width:body_box_width}}>
                    <div className='top'>
                        { showBest && <div className='best'><span>Best</span></div> }
                        <div className='title' style={{width:title_width}}><span>{postData.title}</span></div>
                    </div>
                    <div className='bottom'>
                        <div className='level'><LevelIcon is_admin={postData.user_is_admin} level={postData.user_level}/></div>
                        <div className='nickname'>{postData.nickname}</div>
                        <div className='time'>{timeStr}</div>
                        <div className='hits'>{makeThousandNumberStr( postData.hits )}</div>
                        <div className='hits_title'>조회</div>
                    </div>
                </div>
            </>}
        </div>
    )
} // End - TitleComponent

function BodyComponent(props:any) {
	const {postData, postType} = props;

    return(<> 
    {postData&&
        <div className='body_box'>
            <div className='contents'>
                {postData.use_state!=API_TYPES.POST_USE_STATE.ABLE && <DeletePostBoxComponent {...props}/>}
                {postData.choice_show==API_TYPES.BOOLEAN.TRUE && <ChoiceBoxComponent {...props}/> }
                <ImagesComponent {...props}/>
                <div className='body_str'>
                    <HyperLink content={postData.body}/> 
                </div>
            </div>
            <LikeHateComponent  {...props}/>
            <BodyBottomComponent {...props}/>
        </div>
    }</>)
} // End - BodyComponent


/* 삭제된글일때 박스 표시 */
function DeletePostBoxComponent(props:any){
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const {postData, postType, onClickToList} = props;
    const [requestDelete, setRequestDelete] = useState(false);

    const clickDelete = () => {
        if( requestDelete ) return;

		var title = "글을 영구 삭제하시겠습니까?\n비밀번호를 입력하세요";
        var returnValue = window.prompt(title, "");

        if( !!returnValue ) {
            var pw = returnValue;

            setRequestDelete( true );
            const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);
    
                    onClickToList( true );
                    alert("글이 영구 삭제되었습니다");
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert("글을 찾을 수 없습니다");
                        navigate(PAGE_URL.MAIN, { replace:true });
                    }
                }
            }
            
            apiAxios.admin.authCheckPw(dispatch, pw, (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);
    
                    if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                        apiAxios.admin.dataCommunityPostCompletelyDelete( dispatch, postData.idx, postData.year, callback);
                    }
                    else if( postType == API_TYPES.POST_TYPE.STORY ) {
                        apiAxios.admin.dataStoryPostCompletelyDelete( dispatch, postData.idx, postData.year, callback);
                    }
                    else { setRequestDelete( false ); }
    
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert("비밀번호를 잘못입력하였습니다.");
                    }
                    setRequestDelete( false );
                }
            })
        }
    }

    return(
        <div className='delete_box'>
            <div className='guide'>
                <span>{"삭제된 글입니다."}</span>
                <span>{"사용자에게는 노출되지 않습니다"}</span>
            </div>
            <div className='delete_btn' onClick={clickDelete}>
                <span>영구삭제</span>
            </div>
        </div>
    )
} // DeletePostBoxComponent

/* 채택사연 박스 */
function ChoiceBoxComponent(props:any) {
	const {postData} = props;

    const clickedYoutube = () => {
        window.open(postData.choice_video, "_blank");
    }

    //https://www.youtube.com/watch?v=5ePKBm4spBg
    const parseId = ( youtubeUrl : string ) => {
        var urls = null;
        if( youtubeUrl.indexOf("watch?v=") > -1 ) {
            urls = youtubeUrl.split("watch?v=");
        }
        
        if( urls && urls.length > 1 ) {
            return urls[1];
        }

        return "";
    }

    // 740 / 380

    var width = window.outerWidth;
    var height = Math.floor( width * 380 / 740);

    return(
        <div className='choice_box'>
            
            <div className='top'>
                <div className='choice_text'>
                    {/*<img src={IMG_URL_CHOICE_WHITE}/>*/}
                    <span>채택사연</span>
                </div>
                <div className='youtube_button' onClick={clickedYoutube}>
                    <div className='icon'>
                        <FaPlay size={18}/>
                    </div>
                    <div className='text'>
                        <span>YouTube 로 보기</span>
                    </div>
                </div>
            </div>
            <div className='youtube' style={{height:height}}>
                <YouTube
                    //videoId : https://www.youtube.com/watch?v={videoId} 유튜브 링크의 끝부분에 있는 고유한 아이디
                    videoId={parseId(postData.choice_video)}
                    //opts(옵션들): 플레이어의 크기나 다양한 플레이어 매개 변수를 사용할 수 있음.
                    //밑에서 더 설명하겠습니다.
                    opts={{
                        width: ("" + width),
                        height: ("" + height),
                        playerVars: {
                            autoplay: 0, //자동재생 O
                            rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                            modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                        },
                    }}
                    //이벤트 리스너 
                    onEnd={(e)=>{e.target.stopVideo(0);}}      
                    />
            </div>
            
            <div className='bottom'>
                <div className='text_span'>
                    <span>사연이 영상으로 제작되었으며 글쓴이에게 소정의 상품을 전달 드립니다</span>
                </div>
            </div>
        </div>
    )
}


function ImagesComponent(props:any) {
	const {postData} = props;
    return(
        <> { postData && !!postData.image01 &&
            <div className='image'>
                { !!postData.image01 && <img src={postData.image01}/>}
                { !!postData.image02 && <img src={postData.image02}/>}
                { !!postData.image03 && <img src={postData.image03}/>}
                { !!postData.image04 && <img src={postData.image04}/>}
                { !!postData.image05 && <img src={postData.image05}/>}
                { !!postData.image06 && <img src={postData.image06}/>}
                { !!postData.image07 && <img src={postData.image07}/>}
                { !!postData.image08 && <img src={postData.image08}/>}
                { !!postData.image09 && <img src={postData.image09}/>}
                { !!postData.image10 && <img src={postData.image10}/>}
            </div>
            }
        </>
    )
} // End - ImagesComponent

function LikeHateComponent(props:any) {
	const {postData, setPostData, postType} = props;
    const dispatch = useDispatch();
    const loginData = useSelector((state:RootState)=>state.data.loginData);

    const clickedLike = () => {
        if( !loginData ) { 
            alert(TEXT_PLEASE_LOGIN); 
            return;
        }
        if( loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
			alert(TEXT_CANT_ADMIN_LOGIN);
            return;
		}

        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                var cnt = resData.count;
                var newData = {...postData};
                newData.likes = cnt;
                setPostData(newData);
                alert(ALERT_TEXT_SUCCESS_LIKE);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
					alert(TEXT_HAVE_LIKE);
				}
			}
		}
        if( postType == API_TYPES.POST_TYPE.STORY ) {
            apiAxios.user.dataStoryLike(dispatch, postData.idx, postData.year, null, callback);
        }
        else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
            apiAxios.user.dataCommunityLike(dispatch, postData.idx, postData.year, null, callback);
        }
    }

    const clickedHate = () => {
        if( !loginData ) { 
            alert(TEXT_PLEASE_LOGIN); 
            return;
        }
        if( loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
			alert(TEXT_CANT_ADMIN_LOGIN);
            return;
		}

        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                var cnt = resData.count;
                var newData = {...postData};
                newData.hates = cnt;
                setPostData(newData);
                alert(ALERT_TEXT_SUCCESS_HATE);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
					alert(TEXT_HAVE_HATE);
				}
			}
		}

        if( postType == API_TYPES.POST_TYPE.STORY ) {
            apiAxios.user.dataStoryHate(dispatch, postData.idx, postData.year, null, callback);
        }
        else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
            apiAxios.user.dataCommunityHate(dispatch, postData.idx, postData.year, null, callback);
        }
    }

    return(
    <> { postData &&
        <div className='like_tate_box'>
            <div className='count count_left'>
                <span>{makeThousandNumberStr(postData.likes)}</span>
            </div>
            <div className='btn btn_left' onClick={clickedLike}>
                <AiOutlineLike className='icon' size={25}/>
                <span>추천</span>
            </div>
            <div className='btn btn_right' onClick={clickedHate}>
                <AiOutlineDislike className='icon' size={25}/>
                <span>반대</span>
            </div>
            <div className='count count_right'>
                <span>{makeThousandNumberStr(postData.hates)}</span>
            </div>
        </div>
    } </>
    )
} // End - LikeHateComponent




/* 상단 컴포넌트 */
function BodyBottomComponent(props:any) {
	const {postData, setPostData, postType, onClickToBeforePost, onClickToNextPost, beforeUrl, nextUrl, onClickToList} = props;
    const dispatch = useDispatch();
    const location = useLocation();

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
    const loginData = useSelector((state:RootState)=>state.data.loginData);

	var categoryName = "";

    
	var categoryList = null;
	if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
		categoryList = communityCategoryList;
	}
	else if( postType == API_TYPES.POST_TYPE.STORY ) {
		categoryList = storyCategoryList;
	}

	if( !!categoryList ) {
		var categoryData = categoryList.filter( (i:any) => !!postData && i.idx == postData.cate_idx );
		if( !!categoryData && categoryData.length > 0 ) { categoryName = categoryData[0].name; }
	}
    
    const clickedBlock = () => {

        addBlockUser( dispatch, loginData, postData.nickname, postData.user_idx, postData.user_is_admin, ()=>{
            onClickToList();
        } );
    }

    const clickedReport = () => {
        if( !loginData ) { 
            alert(TEXT_PLEASE_LOGIN); 
            return;
        }
        if( loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
			alert(TEXT_CANT_ADMIN_LOGIN);
            return;
		}

        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                var cnt = resData.count;
                var newData = {...postData};
                newData.reports = cnt;
                setPostData(newData);
                alert(ALERT_TEXT_SUCCESS_REPORT);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
					alert(TEXT_HAVE_REPORT);
				}
			}
		}

        if( postType == API_TYPES.POST_TYPE.STORY ) {
            apiAxios.user.dataStoryReport(dispatch, postData.idx, postData.year, null, callback);
        }
        else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
            apiAxios.user.dataCommunityReport(dispatch, postData.idx, postData.year, null, callback);
        }
    }
    
	return (
		<div className='bottom'>
            <div className='share' onClick={()=>{
                handleCopyClipBoard(window.location.href);
            }}>
                <div className='icon'>
                    <AiOutlineShareAlt size={16}/>
                </div>
                <div>
                    <span>공유</span>
                </div>
            </div>
            <div className='right'>
                <div className='report' onClick={clickedBlock}>
                    <div className='icon'>
                        <BsBan size={16}/>
                    </div>
                    <div>
                        <span>차단</span>
                    </div>
                </div>
                <div className='report' onClick={clickedReport}>
                    <div className='icon'>
                        <AiOutlineAlert size={16}/>
                    </div>
                    <div>
                        <span>신고</span>
                    </div>
                </div>
                <div className={'before' + (!beforeUrl?" disable":"")} onClick={onClickToBeforePost}>
                    <div className='icon'>
                        <AiOutlineLeft size={16}/>
                    </div>
                    <div>
                        <span>이전글</span>
                    </div>
                </div>
                <div className={'next' + (!nextUrl?" disable":"")} onClick={onClickToNextPost}>
                    <div>
                        <span>다음글</span>
                    </div>
                    <div className='icon'>
                        <AiOutlineRight size={16}/>
                    </div>
                </div>
            </div>
        </div>
	);
} // End - BottomComponent

function BottonButtonComponent(props:any) {
    const {postData, adminAbleManage, postType, year, onClickToList, setShowChoicePopup, setDeletePopupData} = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    
    var canAble = postData&&postData.use_state==API_TYPES.POST_USE_STATE.ABLE;
    var showEdit = canAble&&postData&&loginData&&postData.user_idx==loginData.idx&&loginData.is_admin!=API_TYPES.BOOLEAN.TRUE;
    var showChoice = canAble&&postData&&loginData&&adminAbleManage&&postType==API_TYPES.POST_TYPE.STORY;
    var showDelete = canAble&&postData&&loginData&&(postData.user_idx==loginData.idx||adminAbleManage);
    var showRollback = postData&&loginData&&adminAbleManage&&postData.use_state==API_TYPES.POST_USE_STATE.ADMIN_DELETE;

    const clickedDelete = () => {
        // 관리자인경우
        if( loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
            setDeletePopupData({data:postData, type:DeletePopupType.POST});
        }
        else {
            if( checkChoice() ) return;

            var title = "게시글을 삭제하시겠습니까?";
            var returnValue = window.confirm(title);
            if (returnValue) {
                const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
                    if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                        onClickToList( true );
                        alert(ALERT_TEXT_SUCCESS_DELETE);
                    }
                    else if( error ) {
                        const errorData : any = API_UTILS.getErrorResponseData(error);
                        if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                            alert(TEXT_NOT_FOUND_POST);
                        }
                    }
                }
    
                if( postType == API_TYPES.POST_TYPE.STORY ) {
                    apiAxios.user.dataStoryDelete(dispatch, postData.idx, year, callback);
                }
                else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                    apiAxios.user.dataCommunityDelete(dispatch, postData.idx, year, callback);
                }
            }
        }
    }

    const clickedUpdate = () => {
		if( loginData.is_admin != API_TYPES.BOOLEAN.TRUE ) {
            if( checkChoice() ) return;

            const params : PAGE_WRITE_POST = {
                category:postData.cate_idx,
                year:year,
                idx:postData.idx,
                post_type:postType
            }
            var url = makeUrl(PAGE_URL.WRITE_POST, params);
            navigate(url);
        }
    }

    const checkChoice = () => {
        if( postType == API_TYPES.POST_TYPE.STORY && postData.choice == API_TYPES.BOOLEAN.TRUE ) {
            alert("채택된 사연은 수정&삭제가 불가합니다");
            return true;
        }
        return false;
    }

    const clickedChoice = () => {
        setShowChoicePopup(true);
    }

    const clickedRollback = () => {
        var title = "삭제된 게시글을 다시 복원하시겠습니까?";
        var returnValue = window.prompt(title, "");
        console.log("returnValue : " + returnValue);
        if( returnValue != null ) {
            
            const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    onClickToList( true );
                    alert(ALERT_TEXT_SUCCESS_DELETE_CANCEL);
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert(TEXT_NOT_FOUND_POST);
                    }
                }
            }

            if( postType == API_TYPES.POST_TYPE.STORY ) {
                apiAxios.admin.dataStoryPostDeleteCancel(dispatch, postData.idx, year, returnValue, callback);
            }
            else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                apiAxios.admin.dataCommunityPostDeleteCancel(dispatch, postData.idx, year, returnValue, callback);
            }
        }
    }

    return(
        <div className='btn_box'>
            <div className='list' onClick={onClickToList}> <span>목록</span> </div>
            { showEdit      && <div className='edit' onClick={clickedUpdate}>     <span>수정</span> </div> }
            { showDelete    && <div className='delete' onClick={clickedDelete}>   <span>삭제</span> </div> }
            { showChoice    && <div className='choice' onClick={clickedChoice}>   <span>채택</span> </div> }
            { showRollback  && <div className='rollback' onClick={clickedRollback}> <span>복원</span> </div> }
        </div>
    )
}