import { useState } from "react";
import { Oval } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux_saga/modules";

function MobileInputBox( props:any ) {
    const {leftIcon, placeholder, disabled, value, setValue, rightIcon, rightText, position, isPassword, onClickRightText, onKeyUp, rightLoading} = props;

    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const [isFocus, setFocus] = useState(false);

    var posClassName = "";

    var inputWidth = window.outerWidth - 90;
    if( !!leftIcon ) inputWidth = inputWidth - 30;
    if( !!rightIcon ) inputWidth = inputWidth - 30;
    if( !!rightText ) inputWidth = inputWidth - 70;

    var inputHeight = 56;
    if( applicationOS == "ios" ) {
        inputHeight = 50;
        inputWidth = inputWidth - 20;
    }

    return(
        <div className={"input_box" + posClassName + (disabled?" disabled":"")}>
            { leftIcon!= null && 
                <div className="left_icon"> {leftIcon} </div> 
            }

            <input 
                type={isPassword?"password":"text"} 
                placeholder={placeholder} 
                value={value} 
                onChange={(e)=> { 
                    if(setValue) {
                        var text = e.target.value;
                        text = text.replace(/ /g, ''); // 공백제거
                        setValue(text); 
                    }
                }} 
                style={{width:inputWidth, height:inputHeight}}
                onFocus={()=>{setFocus(true)}}
                onBlur={()=>{setFocus(false)}}
                disabled={disabled}
                onKeyUp={onKeyUp}/>

            { rightIcon!= null && 
                <div className="right_icon"> {rightIcon} </div> 
            }
            { !!rightText && 
                <div className="right_text" onClick={onClickRightText}> 
                    { rightLoading == true && <div className="loader">
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                        </div>}
                    { rightLoading != true && <span>{rightText}</span> }
                </div> 
            }
        </div>
    )
}

export default MobileInputBox;