import { TABLE_START_YEAR_DEFAULT } from "./ConstValue";

export enum PAGE_URL {
    MAIN                = "/",                  // 메인
    SEARCH              = "/search",            // 검색화면
    STORY               = "/story",             // 사연 메인
    STORY_POST          = "/story/post",        // 사연 게시글
    COMMUNITY           = "/community",         // 커뮤니티 메인
    COMMUNITY_POST      = "/community/post",    // 커뮤니티 게시글
    NOTICE              = "/notice",            // 공지사항
    NOTICE_POST         = "/notice/post",       // 공지사항 게시글
    WRITE_POST          = "/write/post",        // 게시글 작성
    USER_INFO           = "/my/user_info",      // 사용자 정보 수정
    MY_POST             = "/my/post",           // 내가 쓴글
    MY_BLOCK            = "/my/block",          // 내가 차단한 사용자
    MY_INQUIRY          = "/my/inquiry",        // 나의 문의 내역
    MY_INQUIRY_POST     = "/my/inquiry/post",   // 나의 문의 내역 게시글 읽기
    WRITE_INQUIRY       = "/write/inquiry",     // 문의 내용 작성
    PERSONAL            = "/personal",          // 개인정보 처리방침

    SIGNUP              = "/auth/signup",       // 회원가입
    FIND_ID             = "/auth/find/id",       // 회원가입
    FIND_PW             = "/auth/find/pw",       // 회원가입
    CERTIFICATION       = "/auth/certification", // 인증페이지

    MOBILE_LOGIN        = "/auth/login",        // 모바일 로그인화면

    ADMIN_MAIN          = "/admin/main",        // 관리자 메인
    ADMIN_STORY         = "/admin/story",       // 관리자 사연
    ADMIN_COMMUNITY     = "/admin/community",   // 관리자 커뮤니티
    ADMIN_NOTICE        = "/admin/notice",      // 관리자 공지사항
    ADMIN_USER          = "/admin/user",        // 관리자 계정
    ADMIN_INQUIRY       = "/admin/inquiry",     // 관리자 문의
    ADMIN_INQUIRY_POST  = "/admin/inquiry/post",// 관리자 문의
    ADMIN_SETTING       = "/admin/setting",     // 관리자 설정
    ADMIN_NOTICE_WIRTE  = "/admin/notice/write" // 관리자 공지사항 작성
}

/*
export enum MOBILE_PAGE_URL {
    MAIN                = "/",                  // 메인
    SEARCH              = "/search",            // 검색화면
    STORY               = "/story",             // 사연 메인
    STORY_POST          = "/story/post",        // 사연 게시글
    COMMUNITY           = "/community",         // 커뮤니티 메인
    COMMUNITY_POST      = "/community/post",    // 커뮤니티 게시글
    NOTICE              = "/notice",            // 공지사항
    NOTICE_POST         = "/notice/post",       // 공지사항 게시글
    WRITE_POST          = "/write/post",        // 게시글 작성
    USER_INFO           = "/my/user_info",      // 사용자 정보 수정
    MY_POST             = "/my/post",           // 내가 쓴글
    MY_INQUIRY          = "/my/inquiry",        // 나의 문의 내역
    MY_INQUIRY_POST     = "/my/inquiry/post",   // 나의 문의 내역 게시글 읽기
    WRITE_INQUIRY       = "/write/inquiry",     // 문의 내용 작성
    PERSONAL            = "/personal",          // 개인정보 처리방침

    SIGNUP              = "/auth/signup",       // 회원가입
    FIND_ID             = "/auth/find/id",       // 회원가입
    FIND_PW             = "/auth/find/pw",       // 회원가입
    CERTIFICATION       = "/auth/certification", // 인증페이지

    ADMIN_MAIN          = "/admin/main",        // 관리자 메인
    ADMIN_STORY         = "/admin/story",       // 관리자 사연
    ADMIN_COMMUNITY     = "/admin/community",   // 관리자 커뮤니티
    ADMIN_NOTICE        = "/admin/notice",      // 관리자 공지사항
    ADMIN_USER          = "/admin/user",        // 관리자 계정
    ADMIN_INQUIRY       = "/admin/inquiry",     // 관리자 문의
    ADMIN_SETTING       = "/admin/setting",     // 관리자 설정
    ADMIN_NOTICE_WIRTE  = "/admin/notice/write" // 관리자 공지사항 작성
}*/

export enum PAGE_NUMBER_TYPE {
	NUMBER=1,
	BEFORE=2,
	NEXT=3,
	BEFORE_YEAR=4,
	NEXT_YEAR=5
}

export interface PAGE_NUMBER_DATA {
	page:number,
	type:PAGE_NUMBER_TYPE
}

export const getStartPage = ( page : number ) => {
    return page%10==0?page-9:page - ( page % 10 - 1 );
}

export const makeBottomPageNumberList = ( page:number, totalData:any, year:number|null ) => {
    if( !totalData ) return null;
    if( totalData.total_page == 0 ) totalData.total_page = 1;

    const startPage : number = getStartPage( page );
    const currentYear : number = ( new Date() ).getFullYear();

    var list : PAGE_NUMBER_DATA[] = [];

    // 현재년보다 이전인 경우 다음년도 추가 
    if( year != null ) { list.push( { type:PAGE_NUMBER_TYPE.BEFORE_YEAR, page:year < currentYear?year+1:-1 } ); }

    // 10페이지 이후의 페이지 인경우 이전 버튼 추가
    if( startPage > 10 ) { list.push( { type:PAGE_NUMBER_TYPE.BEFORE, page:0 } ); }

    // 페이지 번호 추가
    for( var i=0; i<10; i++ ) {
        var pageData : PAGE_NUMBER_DATA = {
            page:startPage+i,
            type:PAGE_NUMBER_TYPE.NUMBER
        }
        if( totalData.total_page < pageData.page ) break;

        list.push(pageData);
    }
    
    // 다음 10의자리 페이지가 있는경우 다음 버튼 추가
    if( totalData.total_page > startPage + 9 ) { list.push( { type:PAGE_NUMBER_TYPE.NEXT, page:0 } ); }

    // 현재년보다 다음인 경우 이전 년도 추가 
    if( year != null ) { list.push( { type:PAGE_NUMBER_TYPE.NEXT_YEAR, page:year > TABLE_START_YEAR_DEFAULT?year-1:-1 } ); }

    console.log("makeBottomPageNumberList " , list);
    return list;
}

export const makeUrl = ( url:string, params:any ) => {
    var fullUrl = url;
    if( params ) {
        for (const key in params) {
            let devide = "?";
            if( fullUrl.indexOf("?") > -1 ) devide = "&"
            if( params[key] != null ) {
                fullUrl += devide+key+"="+params[key];
            }
        }
    }

    return fullUrl;
}

/*****************************************************************************
 *
 * 관리자 서브 탭 타입
 * 
*****************************************************************************/

export const ADMIN_SUB_TAB_NONE = 0;

export enum ADMIN_SUB_TAP_STORY {
	CATEGORY		=1,
	COICE			=2,
	DELETE_POST		=3,
	DELETE_REPLY	=4,
	REPORT_POST		=5,
	REPORT_REPLY	=6
}

export enum ADMIN_SUB_TAP_COMMUNITY {
	CATEGORY		=1,
	DELETE_POST		=2,
	DELETE_REPLY	=3,
	REPORT_POST		=4,
	REPORT_REPLY	=5
}

export enum ADMIN_SUB_TAP_SETTING {
	INFO_UPDATE		=1,
	TABOO			=2,
	SYSTEM_SETTING	=3
}

export enum ADMIN_SUB_TAP_USER {
	ADMIN_USER	=1,
	USER		=2,
	BLACKLIST	=3
}

/** End - 관리자 서브 탭 타입
*****************************************************************************/


/*****************************************************************************
 *
 * 페이지 주소 파라미터 
 * 
*****************************************************************************/

/* 게시글 읽기 통합 */
export interface PAGE_MAIN {
    year:number, post_list_type:number
}

/* 게시글 읽기 통합 */
export interface PAGE_POST_PARAMS {
    idx:number, year:number, post_list_type:number, post_type:number, category:number|null, search:string|null, searchType:number|null
}

/* 게시글 메인 통합 */
export interface PAGE_POST_HOME_PARAMS {
    category:number, year:number, page:number
}

/* 내가쓴글 통합 */
export interface PAGE_MY_POST_PARAMS {
    year:number, page:number, post_list_sort_type:number|null, filter:number|null
}

/* 공지사항 게시글 읽기 통합 */
export interface PAGE_NOTICE_POST_PARAMS {
    idx:number, post_type:number|null, category:number|null
}

/* 공지사항 게시글 통합 */
export interface PAGE_NOTICE_HOME_PARAMS {
    page:number
}

/* 나의 문의 내역 게시글 읽기 통합 */
export interface PAGE_MY_INQUIRY_POST {
    idx:number
}

/* 나의 문의 내역 게시글 쓰기 통합 */
export interface PAGE_WRITE_POST {
    category:number|null
    year:number|null
    idx:number|null
    post_type:number|null
}

/* 나의 문의 내역 게시글 쓰기 통합 */
export interface PAGE_SEARCH {
    search:string
    page:number|null
    type:number|null
    tabType:number|null
    year:number|null
}

/* 관리자 기본  */
export interface PAGE_ADMIN_DEFAULT {
    tab:number|null, year:number|null, page:number|null, search:string|null, searchType:number|null
}

/* 사연 */
export interface PAGE_ADMIN_STORY_CATEGORY { tab:number|null, search:string|null }
export interface PAGE_ADMIN_STORY_CHOICE extends PAGE_ADMIN_DEFAULT { 
    type:number|null, sendType:number|null, showType:number|null, sort:number|null
}
export interface PAGE_ADMIN_STORY_DELETE_POST extends PAGE_ADMIN_DEFAULT { sort:number|null }
export interface PAGE_ADMIN_STORY_DELETE_REPLY extends PAGE_ADMIN_DEFAULT { sort:number|null }
export interface PAGE_ADMIN_STORY_REPORT_POST extends PAGE_ADMIN_DEFAULT { sort:number|null, deleteType:number|null }
export interface PAGE_ADMIN_STORY_REPORT_REPLY extends PAGE_ADMIN_DEFAULT { sort:number|null, deleteType:number|null }

/* 커뮤니티 */
export interface PAGE_ADMIN_COMMUNITY_CATEGORY { tab:number|null, search:string|null }
export interface PAGE_ADMIN_COMMUNITY_DELETE_POST extends PAGE_ADMIN_DEFAULT { sort:number|null }
export interface PAGE_ADMIN_COMMUNITY_DELETE_REPLY extends PAGE_ADMIN_DEFAULT { sort:number|null }
export interface PAGE_ADMIN_COMMUNITY_REPORT_POST extends PAGE_ADMIN_DEFAULT { sort:number|null, deleteType:number|null }
export interface PAGE_ADMIN_COMMUNITY_REPORT_REPLY extends PAGE_ADMIN_DEFAULT { sort:number|null, deleteType:number|null }

/* 공지사항 */
export interface PAGE_ADMIN_NOTICE extends PAGE_ADMIN_DEFAULT { noticeState:number|null, postType:number|null, category:number|null }
export interface PAGE_ADMIN_NOTICE_WRITE { idx:number|null, page:number|null}

/* 계정 */
export interface PAGE_ADMIN_ACCOUNT_ADMIN_USER { tab:number|null, state:number|null, sort:number|null, page:number|null, search:string|null }
export interface PAGE_ADMIN_ACCOUNT_USER { tab:number|null, state:number|null, sort:number|null, page:number|null, search:string|null }

/* 문의 */
export interface PAGE_ADMIN_INQUIRY { type:number|null, read:number|null, page:number|null, search:string|null }
export interface PAGE_ADMIN_INQUIRY_POST { idx:number|null}

/* 설정 */
export interface PAGE_ADMIN_TABOO { tab:number|null, page:number|null, search:string|null }
/** End - 페이지 주소 파라미터 
*****************************************************************************/

