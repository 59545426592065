
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminListAdminUser } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { ADMIN_SUB_TAP_USER, PAGE_ADMIN_ACCOUNT_ADMIN_USER, PAGE_ADMIN_STORY_CHOICE, PAGE_ADMIN_STORY_DELETE_POST, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import AdminListItemLoading from '../../sub/AdminListItemLoading';
import ButtonDefault from '../../../sub/ButtonDefault';
import { RootState } from '../../../../redux_saga/modules';
import { isMobile } from 'react-device-detect';


const AdminUserList = (props:any) => {
    const {refreshAdminData, setRefreshAdminData} = props;

    const [itemList, setItemList] = useState<any>(null);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectState, setSelectState] = useState(FILTER_VALUE_ALL);
    const [selectSort, setSelectSort] = useState(API_TYPES.POST_LIST_SORT_TYPE.INSERT);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newSort         = getParamData(l_p, "sort", API_TYPES.POST_LIST_SORT_TYPE.INSERT );
        var newState         = getParamData(l_p, "state", FILTER_VALUE_ALL );

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );

        setSelectSort(newSort);
        setSelectState(newState);
        setSearchText(newSearch);
        setPage(newPage);

        reqList(newSort, newState, newSearch, newPage);

        return(()=>{})
    },[location]);


	useEffect(()=>{
        if( refreshAdminData ) {
            reqList(selectSort, selectState, searchText, page);
            setRefreshAdminData(false);
        }

        return(()=>{})
    },[refreshAdminData, selectSort, selectState, searchText, page]);

    



    const reqList = ( sort:number, use_state:number|null, searchText:string|null, page:number ) => {

        var param : ParamAdminListAdminUser = {
            sort            :sort,
            use_state       :use_state,
            total_search    :searchText,
            page            :page
        }

        if( param.use_state == FILTER_VALUE_ALL ) param.use_state = null;

        apiAxios.admin.listAdminUser( dispatch, param, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_ACCOUNT_ADMIN_USER => {
        var params : PAGE_ADMIN_ACCOUNT_ADMIN_USER = {
            tab         :ADMIN_SUB_TAP_USER.ADMIN_USER, 
            state       :selectState==FILTER_VALUE_ALL?null:selectState,
            sort        :selectSort,
            page        :1,
            search      :!!searchText?searchText:null
        }
        if( !!param ) {
            if( param == "sort" )           params["sort"]      = value;
            else if( param == "state" )     params["state"]     = value;
            else if( param == "search" )    params["search"]    = value;
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_ACCOUNT_ADMIN_USER ) => {
        var url = makeUrl(PAGE_URL.ADMIN_USER, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_account_admin_user_post_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                selectState={selectState}
                setSelectState={(value:any)=>{
                    setSelectState(value);
                    
                    changePage( getMovePageParam( "state", value ) );
                }}
                selectSort={selectSort}
                setSelectSort={(value:any)=>{
                    setSelectSort(value);
                    
                    changePage( getMovePageParam( "sort", value ) );
                }}
                />
			<ul>
                { isMobile != true && 
                <li className={'title' + titleBg}>
                    <div className='insert_time'>등록일</div>
                    <div className={'state'}>상태</div>
                    <div className={'id'}>아이디</div>
                    <div className={'name'}>이름</div>
                    <div className='number'>전화번호</div>
                    <div className={'email'}>이메일</div>
                    <div className='access'>권한</div>
                    <div className={'edit'}>수정 / 사용</div>
                </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index} index={index} itemList={itemList} setItemList={setItemList}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>관리자 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_ACCOUNT_ADMIN_USER = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}

					var url = makeUrl(PAGE_URL.ADMIN_USER, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default AdminUserList;

function ListTop( props:any ) {
    const {
        selectState, setSelectState,
        selectSort, setSelectSort,
        onSearch, searchText,
        openAdminPopup
    } = props;

    const stateOption : OptionData[] = [
        ALL_FilTER_DATA,
        {value:API_TYPES.USE_STATE.ABLE, name:"사용중"}, 
        {value:API_TYPES.USE_STATE.DISABLE, name:"정지"}, 
        {value:API_TYPES.USE_STATE.WITHDRAW, name:"탈퇴"}
    ];

    const sortOption : OptionData[] = [
        {value:API_TYPES.POST_LIST_SORT_TYPE.INSERT, name:"등록순"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.ID, name:"아이디"}
    ];

    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
            <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
            <ButtonDefault 
                {...props}
                className="write_btn"
                value={"추가"}
                onClick={()=>{
                    openAdminPopup( null );
                }}
                />
            </div>
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"아이디&이름&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>
            </div>
        </>)
    }

    return(
        <div className='list_top'>
            <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
            
            <AdminSearchBox {...props} placeholder={"아이디&이름&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>
            
            <ButtonDefault 
                {...props}
                className="write_btn"
                value={"추가"}
                onClick={()=>{
                    openAdminPopup( null );
                }}
                />

            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openPostPage, index, itemList, setItemList, openAdminPopup} = props;
	const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);
	const loginData = useSelector((state:RootState)=>state.data.loginData);



    const clickedUpdateState = () => {
        if( requesting ) return;
        var title = item.name + "을 ";
        if( item.use_state==API_TYPES.USE_STATE.ABLE ) {
            title += "사용정지로 변경하시겠습니까?";
        }
        else {
            title += "사용상태로 변경하시겠습니까?";
        }
        
		var returnValue = window.confirm(title);
        if( returnValue ) {
            setRequesting(true);
            var use_state = item.use_state==API_TYPES.USE_STATE.ABLE?API_TYPES.USE_STATE.DISABLE:API_TYPES.USE_STATE.ABLE;
            apiAxios.data_middleware.updateUseState(dispatch, itemList, setItemList, API_TYPES.TABLE_TYPE.ADMIN, item.idx, null, index, use_state, ()=>{
                setRequesting(false);
            });
        }
    }

    const stateStr = item.use_state==API_TYPES.USE_STATE.ABLE?"사용"
                    :item.use_state==API_TYPES.USE_STATE.DISABLE?"정지"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?"탈퇴":"";
    const stateClass = item.use_state==API_TYPES.USE_STATE.DISABLE?" color_red"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?" color_red":"";

    if( isMobile ) {
        return(
            <li className="item">
                <div className='sub'>
                    <div className='id'>{item.id}</div>
                    <div className='divide'>·</div>
                    <div className={'state' + stateClass}>{stateStr}</div>
                    <div className='divide'>·</div>
                    <div className='access'>{makeAccessStr(item)}</div>
                    <div className='divide'>·</div>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
                </div>
                <div className='sub sub_bg top_border'>
                    <div className='name'>{item.name}</div>
                    { !!item.number && <>
                        <div className='divide'>·</div>
                        <div className='number'>{item.number}</div>
                    </>}
                    { !!item.email && <>
                        <div className='divide'>·</div>
                        <div className='email'>{item.email}</div>
                    </>}
                </div>
                <div className='sub button_area'>
                    
                    { !isTopAdmin(item) && loginData.idx != item.idx && <>
                    <div className={'edit'} onClick={(e)=>{ openAdminPopup(item); }}>{"수정"}</div>
                    <div className={'state' + (item.use_state==API_TYPES.USE_STATE.ABLE?"":" color_red")} onClick={clickedUpdateState}>
                        {requesting?<AdminListItemLoading/>:item.use_state==API_TYPES.USE_STATE.ABLE?"사용":"정지"}
                        </div>
                    </>}
                    { ( isTopAdmin(item) || loginData.idx == item.idx ) && 
                        <div className='divide'>{"."}</div>
                    }
                </div>
            </li>
        )
    }

    return(
        <li className="item">
            <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
            <div className={'state' + stateClass}>{stateStr}</div>
            <div className='id'>{item.id}</div>
            <div className='name'>{item.name}</div>
            <div className='number'>{item.number}</div>
            <div className='email'>{item.email}</div>
            <div className='access'>{makeAccessStr(item)}</div>
            <div className='edit'>
                { requesting && <AdminListItemLoading/>}
                { !requesting && !isTopAdmin(item) && loginData.idx != item.idx && <>
                    <span className='edit' onClick={(e)=>{ openAdminPopup(item); }}>수정</span>
                    <span className='divide'>{" / "}</span>
                    <span className={'state' + (item.use_state==API_TYPES.USE_STATE.ABLE?"":" color_red")} onClick={clickedUpdateState}>{item.use_state==API_TYPES.USE_STATE.ABLE?"사용":"정지"}</span>
                </>}
                { !requesting && ( isTopAdmin(item) || loginData.idx == item.idx ) && 
                    <span className='divide'>{"."}</span>
                }
            </div>
        </li>
    )
}

const isTopAdmin = (item:any) => {
    return item.id == "admin";
}

const makeAccessStr = ( item : any ) => {

    if( item.access == API_TYPES.MANAGE_ACCESS.ALL ) {
        return "전체";
    }
    else if( item.access == API_TYPES.MANAGE_ACCESS.PART ) {
        return "부분";
    }

    return "";
}