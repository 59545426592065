/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getCookieLoginData } from '../../../util/cookie_helper';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../sub/MobileWrapper';
import MobileStoryBestArea from './sub/MobileStoryBestArea';
import MobileStoryPostList from './sub/MobileStoryPostList';
import MobileNoticeBox from '../sub/MobileNoticeBox';
import MobileCategoryBox from '../sub/MobileCategoryBox';
import { RootState } from '../../../redux_saga/modules';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../sub/Adsense';
import { API_TYPES } from '../../../apiAxios';

const MobileStory = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentCategory, setCurrentCategory] = useState(API_TYPES.CATEGORY_TYPE.ALL);
	
	const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
	
	
	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		if( c ) {
			try{ setCurrentCategory( parseInt(c) ); }catch(e){ console.log("e",e);}
		}
		else{
			setCurrentCategory( API_TYPES.CATEGORY_TYPE.ALL );
		}

		reqCategory();
		
		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
	}

	return (
		<MobileWrapper
			currentCategory={currentCategory}
			tabType={MOBILE_TAB_TYPE.STORY}>
			<MiddleComponent 
				{...props} 
				currentCategory={currentCategory}
				categoryList={storyCategoryList}/>
		</MobileWrapper>
	);
};

export default MobileStory;


function MiddleComponent( props:any ) {
	const navigate = useNavigate();

	return(
		<>
			<MobileStoryBestArea {...props}/>
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				marginTop={10}
				marginBottom={10}
				pagePosition={AD_PAGE_POSITION.MOBILE_STORY_TOP}/>
			<MobileStoryPostList {...props}/>

			<MobileCategoryBox {...props}
				onClick={(cateIdx:number)=>{
					const params : PAGE_POST_HOME_PARAMS = {
						category:cateIdx,
						year:(new Date()).getFullYear(),
						page:1
					}
					var url = makeUrl(PAGE_URL.STORY, params);
					navigate(url);
				}}
				/>
		</>
	)
}