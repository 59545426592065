/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { Oval } from 'react-loader-spinner';

const AgreeSignup = (props:any) => {
    const { onNext } = props;
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();    

    const [checkUseAgreement, setCheckUseAgreement] = useState(false);
    const [checkPersonal, setCheckPersonal] = useState(false);
    const [checkChoiceAgree, setCheckChoiceAgree] = useState(false);
	
	useEffect(()=>{

		return(()=>{})
	},[]);

    const clickedNext = () => {
        if( checkUseAgreement && checkPersonal && checkChoiceAgree ) {
            if( onNext ) { onNext(); }
        }
        else {
            alert("필수 항목에 동의해야 합니다");
        }
    }

	return (
        <div className='sign_up_agree'>
            <div className='title'><span>회원가입을 시작합니다!</span></div>
            <div className='agree_title'><span>약관 동의</span></div>
            <div className='divider'></div>
            
            <ItemBox title="[필수] 모여봐 이용약관" check={checkUseAgreement} onChange={setCheckUseAgreement}>
                <UseAgreement {...props}/>
            </ItemBox>
            <ItemBox title="[필수] 개인정보 수집 및 이용" check={checkPersonal} onChange={setCheckPersonal}>
                <Personal {...props}/>
            </ItemBox>
            <ItemBox title="[필수] 사연 채택 동의" check={checkChoiceAgree} onChange={setCheckChoiceAgree}>
                <ChoiceAgree {...props}/>
            </ItemBox>
            <ItemBox title="전체 동의하기" 
                allAgree={true}
                check={checkUseAgreement&&checkPersonal&&checkChoiceAgree} 
                onChange={(ck:boolean)=>{
                    setCheckUseAgreement(ck);
                    setCheckPersonal(ck);
                    setCheckChoiceAgree(ck);
                }}
                onClickNext={clickedNext}
                />
                
		</div>
	);
};

export default AgreeSignup;

function ItemBox( props:any ){
    const {check, onChange, title, children, allAgree, onClickNext} = props;

    return( 
        <div className='item_box'>
            {/* 상단 부분 */}
            <div className='top' onClick={()=>{
                if( onChange ) onChange(!check);
            }}>
                <div className={'ck_box' + (check?" ck_select":"")}>
                    {check==true&&<BsCheckSquareFill size={30}/>}
                    {check!=true&&<BsCheckSquare size={30}/>}
                </div>
                <div className='title'>{title}</div>
            </div>

            { allAgree == true &&
                <div className='next_btn' onClick={onClickNext}><span>다음</span></div>
            }

            { allAgree != true &&
            <div className='middle'>{children}</div>
            }
        </div>
    )
}

/**
 * 이용약관
 * @param props 
 * @returns 
 */
function UseAgreement( props:any ) {
    const dispatch = useDispatch();

    const [dataStr, setDataStr] = useState("");
    const [requesting, setRequesting] = useState(false);

	useEffect(()=>{
        reqData();

		return(()=>{})
	},[]);

    const reqData = () => {
        setRequesting(true);
        apiAxios.global.dataUseAgreement(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				
				setDataStr(resData);
			}
            setRequesting(false);
		})
    }


    return(
        <div className={requesting?"use_agreement_loading":"use_agreement"}>
            { requesting && 
                <div>
                    <Oval 
                        color="#000" 
                        height={40} 
                        width={40}
                        />
                </div>
            }
            { !requesting && <span>{dataStr}</span> }
        </div>
    )
}

function Personal( props:any ) {

    const str1 = () => {
        var s = "- 서비스 이용에 따른 본인확인, 개인 식별, 비인가 사용 방지, 가입 의사 확인";
        s += "\n- 불만 처리, 민원처리, 고지사항 전달, 기타 의사소통을 위한 절차 및 이벤트, 개인 맞춤 서비스, 연령별 서비스, 인구통계학적 분석 등 정보의 전달을 위한 절차에 이용";
        return s;
    }
    const str2 = () => {
        var s = "<회원가입 시>";
        s += "\n- 모여봐 개인 식별 코드, 비밀번호, 닉네임, 이메일";
        
        s += "\n\n<서비스 이용 시>";
        s += "\n- 서비스 이용 기록, IP 주소, 기기 정보, 결제 내역, 불량 이용 기록, 성인 인증 값, 쿠키 등";
        s += "\n※ 쿠키는 해당 브라우저 또는 기기 등에서 수집하는 것으로 회사가 수집하는 것은 아닙니다.";
        
        s += "\n\n<서비스 이용 시 기기 정보 상세>";
        s += "\n- PC웹: Window, Mac 등 OS 버전 및 웹 브라우저(Chrome, Safari 등) 버전 정보";
        s += "\n- 모바일웹: 단말기 OS 버전 및 웹 브라우저(Chrome, Safari 등) 버전 정보";

        return s;
    }
    const str3 = () => {
        var s = "- 회원 탈퇴 시 파기 처리";
        s += "\n- 11개월 동안 로그인 기록이 없는 회원은 자동 탈퇴 처리";
        return s;
    }
    const bottomStr = () => {
        var s = "ㆍ 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의 거부 시 회원가입이 제한됩니다.";
        s += "\nㆍ 서비스 제공을 위해서 필요한 최소한의 개인 정보이므로 동의를 하셔야 서비스 이용이 가능합니다.";
        s += "\nㆍ 이 외 서비스 이용 시 별도 동의를 통해 추가 정보 수집이 있을 수 있습니다.";
        return s;
    }
    return(
        <div className='personal'>
            <table>
                <tr className='title'>
                    <td className='left'>구분</td>
                    <td className='right'>필수 수집</td>
                </tr>
                <tr className='item'>
                    <td className='left'>개인정보 수집 및 이용 목적</td>
                    <td className='right' align='left'>{str1()}</td>
                </tr>
                <tr className='item'>
                    <td className='left'>개인정보 수집 항목</td>
                    <td className='right' align='left'>{str2()}</td>
                </tr>
                <tr className='item'>
                    <td className='left'>보유 및 이용 기간</td>
                    <td className='right' align='left'>{str3()}</td>
                </tr>
            </table>
            <div className='bottom_text'>
                <span>{bottomStr()}</span>
            </div>
        </div>
    )
}

function ChoiceAgree( props:any ) {
    return(
        <div className='choice_agree'>
            <div><span>ㆍ 이용자가 작성한 모든 사연은 모여봐의 유투브의 영상내용으로 제작될 수 있습니다.</span></div>
            <div className='middle'><span>ㆍ 영상제작은 모여봐 관리자가 사연채택시 진행되며 채택된 사연의 작성자에게 소정의 상품을 지급합니다.</span></div>
            <div><span>ㆍ 모든글은 기본적으로 이용자가 수정,삭제 할 수 있으나 채택된 사연에 한하여 불가 합니다.</span></div>
        </div>
    )
}