import React, { useEffect, useRef, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import { RootState } from '../../redux_saga/modules';
import { isMobile } from 'react-device-detect';
import { AiOutlineClose } from "react-icons/ai";

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupMoveApplication = ( props:any ) => {
    //const { tabooData }=props;
    const [isCheck, setIsCheck] = useState(false);

    
    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
	const [isMobileWeb, setMobileWeb] = useState(false);
	const [showMoveAppPopup, setShowMoveAppPopup] = useState(true);
    
	useEffect(()=>{

        if( !applicationOS && isMobile ) {
            setMobileWeb( true );
        }
        else {
            setMobileWeb( false );
        }
		return ()=>{}
	},[applicationOS])

    
	useEffect(()=>{
        if( showMoveAppPopup && isMobileWeb ) {
            handleScroll();
    
            window.addEventListener("scroll", handleScroll);
            return ()=>{
                window.removeEventListener("scroll", handleScroll);
            }
        }
        else {
            return ()=>{}
        }
	},[showMoveAppPopup && isMobileWeb])

    const handleScroll = () => {
        console.log("scrolling");
        
        const ele : any = document.getElementById("popup_wrapper");
        if (ele) { ele.style.marginTop = window.scrollY + "px"; }
        const ele2 : any = document.getElementById("popup_body");
        if (ele2) { ele2.style.marginTop = window.scrollY + "px"; }
    };


    if( !showMoveAppPopup || !isMobileWeb ) {
        return null;
    }

    
    return (<>
        <div className='popup_wrapper move_application_wrapper' id='popup_wrapper' >
            
        </div>
        <div className='popup_move_application' id='popup_body'>
            <div className='popup_body_area'>
                
                <div className="thumbnail"><img src={"/react_images/app_move_logo.png"}/></div>
                <div className='text_div'><span>{"앱스토어에서 어플리케이션을 설치할수 있습니다."}</span></div>
            </div>
            <div className='app_button' onClick={()=>{
                    window.location.href = "https://moyoba.co.kr/applink";
                    setShowMoveAppPopup(false);
                }}>
                <div><span>앱으로 보기</span></div>
            </div>
            <div className='web_button' onClick={()=>{setShowMoveAppPopup(false);}}>
                <span>모바일 웹으로 볼게요</span>
            </div>
        </div>
        </>
    );
};
  
export default PopupMoveApplication;