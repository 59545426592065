import React, { useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp, makeInsertTimeStr, makePostInsertFullTime } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { DeletePopupType, DeleteReasonType, getDeleteReasonStr } from './PopupDeletePost';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';

export interface BalckListPopupData {
    data:any,
    type:DeletePopupType|null,
    userIdx:number
}

const INFINITE_BLOCK = -1; // 영구차단

export type PopupBlackListCompleteFunc = (isAdd:boolean, isDelete:boolean) => void;

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupBlackList = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, userIdx, type, onComplete, postType, onClose}=props;

    const [requesting, setRequesting] = useState(false);
    const [userData, setUserData] = useState<any>(null);
    const [reportCount, setReportCount] = useState<any>(0);
    const [blackListArray, setBlackListArray] = useState<any>(null);
    const [blackList, setBlackList] = useState<any>(null);

    const [selectType, setSelectType] = useState<any>(DeleteReasonType.TABOO);
    const [selectBlock, setSelectBlock] = useState<any>(1);
    const [etcStr, setEtcStr] = useState("");
    const [cancelStr, setCancelStr] = useState("");

    const [requestDeleteCancel, setRequestDeleteCancel] = useState(false);


    useEffect(()=>{
        if( userIdx != null && userIdx > 0 ) {
            requestUserBlacklistData();
        }
        else {
            setRequesting( false );
            setUserData(null);
            setReportCount(0);
            setBlackListArray(null);
            setBlackList(null);

            setSelectType(DeleteReasonType.TABOO);
            setSelectBlock(1);
            setEtcStr("");
            setCancelStr("");
        }
        return(()=>{})
    },[userIdx]);

    const requestUserBlacklistData = () => {
        setRequesting( true );

        apiAxios.admin.dataUserBlacklistData(dispatch, userIdx, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setUserData(resData.user_data);
                setReportCount(resData.report_count);
                setBlackListArray(resData.blacklist_array);
                setBlackList(resData.blacklist);

                checkAdmin(resData.user_data);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("사용자를 찾을 수 없습니다");
                    setUserData(null);
                    setReportCount(0);
                    setBlackListArray(null);
                    setBlackList(null);
				}
			}
            setRequesting( false );
		});
    }

    const checkAdmin = ( user_data : any ) => {
        if( user_data ) {
            if( user_data.is_admin == API_TYPES.BOOLEAN.TRUE ) {
                alert("관리자는 블랙리스트로 추가할 수 없습니다");
                onClose();
            }
        }
    }
    
    const requestDelete = ( callBack : any ) => {

        var deleteReasonStr = getDeleteReasonStr( selectType, etcStr );
        if( !deleteReasonStr ) {
            alert("삭제 사유가 없습니다");
            return;
        }
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                if( callBack ) callBack();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("글을 찾을 수 없습니다");
					navigate(PAGE_URL.MAIN, { replace:true });
				}
                setRequestDeleteCancel( false );
			}
		}

        setRequestDeleteCancel( true );

        if( type == DeletePopupType.POST ) {
            if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                apiAxios.admin.dataCommunityPostDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else if( postType == API_TYPES.POST_TYPE.STORY ) {
                apiAxios.admin.dataStoryPostDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else { setRequestDeleteCancel( false ); }
        }
        else if( type == DeletePopupType.REPLY ) {
            if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                apiAxios.admin.dataCommunityReplyDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else if( postType == API_TYPES.POST_TYPE.STORY ) {
                apiAxios.admin.dataStoryReplyDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else { setRequestDeleteCancel( false ); }
        }
        else { setRequestDeleteCancel( false ); }
    }

    // 블랙리스트 추가
    const clickedAdd = () => {
        if( requestDeleteCancel ) return;

		var title = "블랙리스트에 추가 하시겠습니까?";
        if( data ) {
            title += "\n글도 함께 삭제 됩니다.";
        }
		var returnValue = window.confirm(title);
        if( returnValue ) {
            
            const deleteCallback = () => {
                console.log("deleteCallback 1");
                setRequestDeleteCancel( true );
                console.log("deleteCallback 2");

                var infinite = selectBlock == INFINITE_BLOCK?API_TYPES.BOOLEAN.TRUE:API_TYPES.BOOLEAN.FALSE;
                var block_day = selectBlock == INFINITE_BLOCK?0:selectBlock;
                var deleteReasonStr = getDeleteReasonStr( selectType, etcStr );
                
                console.log("deleteCallback 3 infinite : " + infinite);
                console.log("block_day : " + block_day + ", deleteReasonStr : " + deleteReasonStr);
                apiAxios.admin.dataBlackListAdd(dispatch, userIdx, infinite, block_day, deleteReasonStr, (response:AxiosResponse|null, error:AxiosError|null) => {
                    console.log("deleteCallback 4");
                    if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                        const resData = API_UTILS.getResponseSuccessData(response);
        
                        if( onComplete ) onComplete( true, true );
                    }
                    else if( error ) {
                        const errorData : any = API_UTILS.getErrorResponseData(error);
                        if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                            alert("현재 블랙리스트 입니다");
                        }
                    }
                    setRequestDeleteCancel( false );
                });
            }

            if( data ) {
                requestDelete( deleteCallback );
            }
            else {
                deleteCallback();
            }
        }
    }

    // 블랙리스트 취소
    const clickedCancel = () => {
        if( requestDeleteCancel ) return;

		var title = "블랙리스트를 취소하시겠습니까?";
		var returnValue = window.confirm(title);
        if( returnValue ) {
            setRequestDeleteCancel( true );
            apiAxios.admin.dataBlackListCancel(dispatch, userData.idx, cancelStr, (response:AxiosResponse|null, error:AxiosError|null) => {
                if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                    const resData = API_UTILS.getResponseSuccessData(response);
    
                    if( onComplete ) onComplete( false, false);
                }
                else if( error ) {
                    const errorData : any = API_UTILS.getErrorResponseData(error);
                    if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                        alert("현재 블랙리스트가 아닙니다");
                    }
                }
                setRequestDeleteCancel( false );
            });
        }
    }

    var buttonChildren = null;
    if( !requesting ) {
        buttonChildren = (<>
            <BlackButton {...props} title={blackList?"취소":"추가"} disable={requestDeleteCancel} loading={requestDeleteCancel} onClick={()=>{
                if( blackList ) clickedCancel();
                else            clickedAdd();
            }}/>
        </>)
    }


    //if( !data ) return null;

    return (
        <PopupWrapper {...props}
            title={"블랙리스트" + (requesting?"":blackList?" 취소":" 추가")}
            width={300}
            buttonChildren={buttonChildren}>
            
            <div className='body_area popup_blacklist'>

                { requesting && 
                    <div className='loader'>
                        <Oval 
                            color="#00f" 
                            height={40} 
                            width={40}
                            />
                    </div>
                }

                { !requesting && userData && 
                    <table className='post_box'>
                        <tr>
                            <td className='left'>아이디</td>
                            <td className='right'><span>{userData.id + " (" + userData.nickname + ")"}</span></td>
                        </tr>
                        <tr>
                            <td className='left'>신고수</td>
                            <td className='right'><span>{reportCount + "회"}</span></td>
                        </tr>
                    </table>
                }

                { !requesting && !blackList &&
                    <BlackListAdd 
                        {...props}
                        blackListArray={blackListArray}
                        etcStr={etcStr}
                        setEtcStr={setEtcStr}
                        selectType={selectType}
                        setSelectType={setSelectType}
                        selectBlock={selectBlock}
                        setSelectBlock={setSelectBlock}
                        />
                }

                { !requesting && blackList &&
                    <BlackListCancel 
                        {...props}
                        blackList={blackList}
                        cancelStr={cancelStr}
                        setCancelStr={setCancelStr}
                        />
                }


            </div>
        </PopupWrapper>
    );
};
  
export default PopupBlackList;

function BlackListAdd( props:any ) {
    const {blackListArray, etcStr, setEtcStr, selectType, setSelectType, selectBlock, setSelectBlock} = props;


    var blacklist_box_style : any = {};
    var blackListCount = blackListArray?blackListArray.length:0;

    if( blackListCount > 0 ) {
        var boxHeight = blackListArray.length * 50;
        blacklist_box_style = {height:boxHeight};
        if( boxHeight > 150 ) {
            blacklist_box_style = {height:150, overflowY:"auto"};
        }
    }

    return(<>
        { blackListCount > 0 &&
            <div className='blacklist_box_title'><span>블랙리스트 기록 </span><span className='red'>{blackListCount}</span><span>회</span></div>
        }
        <div className='blacklist_box' style={blacklist_box_style}>
            { blackListCount == 0 &&
                <div className='empty'><span>블랙리스트 기록이 없습니다</span></div>
            }
            { blackListCount > 0 &&
                <ul>
                    {blackListArray.map((item:any, index:number)=>{
                        var timeStr = item.infinite == API_TYPES.BOOLEAN.TRUE?"영구정지":item.block_day+"일정지";
                        return (<li key={index}>
                            <div className='block_reason'>
                                <span>{item.block_reason}</span>
                            </div>
                            <div className='bottom'>
                                <span className='block'>{timeStr}</span>
                                <span className='time'>{makeInsertTimeStr(item.insert_time)}</span>
                            </div>
                        </li>)
                    })}
                </ul>
            }
        </div>

        <div className='select_box'>
            <div className='title'><span>차단사유</span></div>
            <select className="select"  value={selectType} 
                onChange={(e)=> {
                    setSelectType(e.target.value);  
                }}>
                <option value={DeleteReasonType.TABOO}>불건전한 내용</option>
                <option value={DeleteReasonType.DISS}>비방글</option>
                <option value={DeleteReasonType.POLITICS}>정치글</option>
                <option value={DeleteReasonType.PLASTER}>도배</option>
                <option value={DeleteReasonType.REPORT}>신고누적</option>
                <option value={DeleteReasonType.ETC}>기타</option>
            </select>

        </div>

        <div className={'input_title_box' + (selectType!=DeleteReasonType.ETC?" input_title_box_disable":"")}>
            <div className='title'><span>기타 사유</span></div>
            <input className='input' type='text' disabled={selectType!=DeleteReasonType.ETC } value={etcStr} onChange={(e)=> { setEtcStr(e.target.value); }}/>
        </div>

        <div className='select_box'>
            <div className='title'><span>차단기간</span></div>
            <select className="select"  value={selectBlock} 
                onChange={(e)=> {
                    setSelectBlock(e.target.value);  
                }}>
                <option value={1}>1일</option>
                <option value={3}>3일</option>
                <option value={7}>7일</option>
                <option value={30}>30일</option>
                <option value={INFINITE_BLOCK}>영구차단</option>
            </select>

        </div>
    </>)
}

function BlackListCancel( props:any ) {
    const {blackList, cancelStr, setCancelStr} = props;

    var timeStr = blackList.infinite == API_TYPES.BOOLEAN.TRUE?"영구정지":blackList.block_day+"일정지";
    var endTime = new Date();
    endTime.setTime(blackList.end * 1000);

    return(<>
        <table className='post_box'>
            <tr>
                <td className='left'>날짜</td>
                <td className='right'><span>{makeInsertTimeFullFromTimeStamp(blackList.insert_time)}</span></td>
            </tr>
            { blackList.infinite == API_TYPES.BOOLEAN.FALSE &&
            <tr>
                <td className='left'>해제</td>
                <td className='right'><span>{makePostInsertFullTime(endTime)}</span></td>
            </tr>
            }
            <tr>
                <td className='left'>기간</td>
                <td className='right block_time'><span>{timeStr}</span></td>
            </tr>
            <tr>
                <td className='left l_reason'><span>사유</span></td>
                <td className='right r_reason'><span>{blackList.block_reason}</span></td>
            </tr>
        </table>

        <div className={'input_title_box'}>
            <div className='title'><span>취소 사유</span></div>
            <input className='input' type='text' value={cancelStr} onChange={(e)=> { setCancelStr(e.target.value); }}/>
        </div>
    </>)
}