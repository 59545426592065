/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../../sub/RightArea';
import LeftAd from '../../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import MyPostListComponent from './sub/MyPostListComponent';
import { checkLogin } from '../../../util/Global';
import { RootState } from '../../../redux_saga/modules';
import Wrapper from '../../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../../sub/Adsense';

const MyPost = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{
		checkLogin(navigate, loginData);
        return(()=>{})
    },[loginData]);
	
	useEffect(()=>{
		console.log("Story useEffect");
		
		return(()=>{})
	},[location]);

	return (
		<Wrapper
			tabType={TAB_TYPE.NONE}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default MyPost;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.MY_POST_TOP} marginBottom={35}/>
			<MyPostListComponent {...props}/>
		</div>
	)
}