
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminListUser } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, isToday, makeInsertTimFullStr, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { ADMIN_SUB_TAP_USER, PAGE_ADMIN_ACCOUNT_USER, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import { isMobile } from 'react-device-detect';


const UserList = (props:any) => {
    const {refreshBlackListData, setRefreshBlackListData} = props;

    const [itemList, setItemList] = useState<any>(null);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectState, setSelectState] = useState(FILTER_VALUE_ALL);
    const [selectSort, setSelectSort] = useState(API_TYPES.POST_LIST_SORT_TYPE.INSERT);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newSort         = getParamData(l_p, "sort", API_TYPES.POST_LIST_SORT_TYPE.INSERT );
        var newState         = getParamData(l_p, "state", FILTER_VALUE_ALL );

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );

        setSelectSort(newSort);
        setSelectState(newState);
        setSearchText(newSearch);
        setPage(newPage);

        reqList(newSort, newState, newSearch, newPage);

        return(()=>{})
    },[location]);
    
	useEffect(()=>{

        if( refreshBlackListData ) {
            reqList(selectSort, selectState, searchText, page);

            setRefreshBlackListData(false);
        }

        return(()=>{})
    },[refreshBlackListData]);


    const reqList = ( sort:number, use_state:number|null, searchText:string|null, page:number ) => {

        var param : ParamAdminListUser = {
            sort            :sort,
            use_state       :use_state==USE_STATE_BLACKLIST?null:use_state,
            total_search    :searchText,
            page            :page,
            is_blacklist    :use_state==USE_STATE_BLACKLIST?API_TYPES.BOOLEAN.TRUE:null
        }

        if( param.use_state == FILTER_VALUE_ALL ) param.use_state = null;

        apiAxios.admin.listUser( dispatch, param, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_ACCOUNT_USER => {
        var params : PAGE_ADMIN_ACCOUNT_USER = {
            tab         :ADMIN_SUB_TAP_USER.USER, 
            state       :selectState==FILTER_VALUE_ALL?null:selectState,
            sort        :selectSort,
            page        :1,
            search      :!!searchText?searchText:null
        }
        if( !!param ) {
            if( param == "sort" )           params["sort"]      = value;
            else if( param == "state" )     params["state"]     = value;
            else if( param == "search" )    params["search"]    = value;
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_ACCOUNT_USER ) => {
        var url = makeUrl(PAGE_URL.ADMIN_USER, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_account_user_post_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                selectState={selectState}
                setSelectState={(value:any)=>{
                    setSelectState(value);
                    
                    changePage( getMovePageParam( "state", value ) );
                }}
                selectSort={selectSort}
                setSelectSort={(value:any)=>{
                    setSelectSort(value);
                    
                    changePage( getMovePageParam( "sort", value ) );
                }}
                />
			<ul>
                { isMobile != true && 
                <li className={'title' + titleBg}>
                    <div className='insert_time'>등록일</div>
                    <div className='login_time'>로그인</div>
                    <div className={'state'}>상태</div>
                    <div className={'id'}>아이디</div>
                    <div className={'name'}>이름</div>
                    <div className={'nickname'}>닉네임</div>
                    <div className='number'>전화번호</div>
                    <div className={'email'}>이메일</div>
                    <div className={'posts two_line'}>{"게시글\n수"}</div>
                    <div className={'replys two_line'}>{"댓글\n수"}</div>
                    <div className={'reports two_line'}>{"누적\n신고"}</div>
                    <div className={'blocks two_line'}>{"차단\n횟수"}</div>
                    <div className={'blacklist'}>블랙리스트</div>
                </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>사용자 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_ACCOUNT_USER = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}

					var url = makeUrl(PAGE_URL.ADMIN_USER, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default UserList;

const USE_STATE_BLACKLIST = 100;

function ListTop( props:any ) {
    const {
        selectState, setSelectState,
        selectSort, setSelectSort,
        onSearch, searchText
    } = props;

    const stateOption : OptionData[] = [
        ALL_FilTER_DATA,
        {value:API_TYPES.USE_STATE.ABLE, name:"사용중"}, 
        {value:API_TYPES.USE_STATE.DISABLE, name:"정지"}, 
        {value:API_TYPES.USE_STATE.WITHDRAW, name:"탈퇴"}, 
        {value:USE_STATE_BLACKLIST, name:"블랙리스트"}
    ];

    const sortOption : OptionData[] = [
        {value:API_TYPES.POST_LIST_SORT_TYPE.INSERT, name:"등록순"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.LOGIN, name:"로그인"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.ID, name:"아이디"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.NAME, name:"이름"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.POST_COUNT, name:"게시글수"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.REPLY_COUNT, name:"댓글수"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.REPORT_COUNT, name:"누적신고수"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.BLOCK_COUNT, name:"차단횟수"}
    ];

    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
            <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
            
            </div>
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"아이디&이름&닉네임&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>
            </div>
        </>)
    }

    return(
        <div className='list_top'>
            <AdminSelectBox {...props} title={"상태"} className={"select_state"}  options={stateOption} selectValue={selectState} SetSelectValue={setSelectState}/>
            
            <AdminSearchBox {...props} placeholder={"아이디&이름&닉네임&전화번호&이메일"} onSearch={onSearch} defaultSearchText={searchText}/>

            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, setBlacklistPopupData} = props;

    const getBlacklistStr = () => {
        if( item.blacklist_infinite == API_TYPES.BOOLEAN.TRUE ) {
            return "블랙리스트\n영구차단";
        }
        else {
            return "블랙리스트\n" + item.blacklist_block_day + "일차단";
        }
    }

    const stateStr = item.use_state==API_TYPES.USE_STATE.ABLE&&item.blacklist_idx>0?getBlacklistStr()
                    :item.use_state==API_TYPES.USE_STATE.ABLE?"사용"
                    :item.use_state==API_TYPES.USE_STATE.DISABLE?"정지"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?"탈퇴":"";

    const stateClass = item.use_state==API_TYPES.USE_STATE.DISABLE?" color_red"
                    :item.use_state==API_TYPES.USE_STATE.WITHDRAW?" color_red"
                    :item.blacklist_idx>0?" color_red two_line":"";


    const blacklistStr = item.use_state==API_TYPES.USE_STATE.ABLE&&item.blacklist_idx>0?"취소"
                    :item.use_state==API_TYPES.USE_STATE.ABLE?"추가":".";

    const blacklistClass = item.use_state==API_TYPES.USE_STATE.ABLE&&item.blacklist_idx>0?" color_red pointer"
                    :item.use_state==API_TYPES.USE_STATE.ABLE?" pointer":"";

    var loginDate : any = null;
    if( item.login > 0 ) {
        loginDate = new Date();
        loginDate.setTime( item.login * 1000);
    }
    const loginTime = loginDate?makeInsertTimFullStr(loginDate):".";

    const clickedBlackList = () => {
        if( (item.use_state==API_TYPES.USE_STATE.ABLE&&item.blacklist_idx>0) || item.use_state==API_TYPES.USE_STATE.ABLE ) {
            setBlacklistPopupData({
                data:null,
                type:null,
                userIdx:item.idx
            });
        }
    }

    if( isMobile ) {
        return(
            <li className="item">
                <div className='sub'>
                    <div className='id'>{item.id}</div>
                    <div className='divide'>·</div>
                    <div className={'state' + stateClass}>{stateStr}</div>
                    <div className='divide'>·</div>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
                    { loginTime != "." && <>
                    <div className='divide'>·</div>
                    <div className={'insert_time'}>{"[ "+loginTime+" ]"}</div>
                    </>}
                </div>
                <div className='sub height_25'>
                    <div className='name'>{item.name}</div>
                    <div className='divide'>·</div>
                    <div className='nickname'>{item.nickname}</div>
                </div>
                <div className='sub height_25'>
                    <div className='number'>{item.number}</div>
                    <div className='divide'>·</div>
                    <div className='email'>{item.email}</div>
                </div>
                <div className='sub sub_bg top_border'>
                    <div className='posts'>     {"게시글 "+(item.total_post>0?item.total_post:"0")}</div>
                    <div className='replys'>    {"댓글 "+(item.total_reply>0     ?item.total_reply:"0")}</div>
                    <div className='reports'>   {"신고 "+(item.total_reports>0   ?item.total_reports:"0")}</div>
                    <div className='blocks'>    {"차단 "+(item.total_blacklist>0 ?item.total_blacklist:"0")}</div>
                </div>
                <div className='sub button_area'>
                    <div className={'blacklist' + blacklistClass} onClick={clickedBlackList}>{blacklistStr}</div>
                </div>
            </li>
        )
    }
    
    return(
        <li className="item">
            <div className={'insert_time' + (isToday(item.insert_time)?'':' two_line')}>{makeInsertTimFullStr(item.insert_time)}</div>
            <div className={'insert_time' + (isToday(loginDate)?'':' two_line')}>{loginTime}</div>
            <div className={'state' + stateClass}>{stateStr}</div>
            <div className='id'>{item.id}</div>
            <div className='name'>{item.name}</div>
            <div className='nickname'>{item.nickname}</div>
            <div className='number'>{item.number}</div>
            <div className='email'>{item.email}</div>
            <div className='posts'>     {item.total_post>0      ?item.total_post:"."}</div>
            <div className='replys'>    {item.total_reply>0     ?item.total_reply:"."}</div>
            <div className='reports'>   {item.total_reports>0   ?item.total_reports:"."}</div>
            <div className='blocks'>    {item.total_blacklist>0 ?item.total_blacklist:"."}</div>
            <div className={'blacklist' + blacklistClass} onClick={clickedBlackList}>{blacklistStr}</div>
        </li>
    )
}

const isTopAdmin = (item:any) => {
    return item.id == "admin";
}

const makeAccessStr = ( item : any ) => {

    if( item.access == API_TYPES.MANAGE_ACCESS.ALL ) {
        return "전체";
    }
    else if( item.access == API_TYPES.MANAGE_ACCESS.PART ) {
        return "부분";
    }

    return "";
}