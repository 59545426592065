import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";


function LevelIcon( props : any ) {
    const { is_admin, level } = props;
    
    useEffect(()=>{

        return(()=>{})
    },[]);

    var levelImageUrl = "";
    var levelStr = "";

    console.log("LevelIcon is_admin : " + is_admin);

    if( is_admin == API_TYPES.BOOLEAN.TRUE ) {
        levelStr = "A";
        levelImageUrl = "/react_images/admin.png";
    }
    else {
        levelStr = level+"";
        levelImageUrl = "/react_images/level" + level + ".png";
    }


    return (
        <div className='level'>
            <img className="thumbnail" src={levelImageUrl}/>
            <span>{levelStr}</span>
        </div>
    );
}

export default LevelIcon;