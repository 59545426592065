/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { IMG_URL_CHOICE } from '../../../../util/ConstValue';
import { PAGE_URL, PAGE_POST_PARAMS, makeUrl } from '../../../../util/Page';

const MainBest = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();

    const [storyList, setStoryList] = useState<any>(null);
    const [communityList, setCommunityList] = useState<any>(null);
	
	useEffect(()=>{
        reqList();
		
		return(()=>{})
	},[]);

    const reqList = () => {

        apiAxios.global.listMainBest( dispatch,  (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                setStoryList(resData.story_list);
                setCommunityList(resData.community_list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	return (
		<>
            { storyList && storyList.length > 0 &&
                <BestBox
                    {...props}
                    title={"사연 베스트"}
                    list={storyList}
                    post_type={API_TYPES.POST_TYPE.STORY}
                    emptyName={"베스트 사연이 없습니다."}
                    />
            }
            { communityList && communityList.length > 0 &&
                <BestBox
                    {...props}
                    title={"커뮤니티 베스트"}
                    list={communityList}
                    post_type={API_TYPES.POST_TYPE.COMMUNITY}
                    emptyName={"베스트 게시글이 없습니다."}
                    />
                }
        </>
	);
};

export default MainBest;

function BestBox( props:any ) {
    const {title, list, emptyName, post_type} = props;
	const navigate = useNavigate();


    const clickItem = (item:any) =>{

        const params : PAGE_POST_PARAMS = {
            idx:item.idx,
            year:item.year,
            post_list_type:API_TYPES.POST_LIST_TYPE.MAIN_BEST_COMMUNITY,
            post_type:post_type,
            category:item.cate_idx,
            search:null,
            searchType:null
        }
        var url = makeUrl(PAGE_URL.COMMUNITY_POST, params);
        navigate(url);
    }

    return(
		<div className='main_best'>
            <div className='title'>
                <span>{title}</span>
            </div>
            { list && list.length > 0 &&
                <ul>
                    { list.map((item:any, index:number)=>{

                        return (
                            <li key={index} className={""} onClick={()=>{clickItem(item)}}>
                                <PostItem {...props} item={item}/>
                            </li>
                        )
                    })}
                </ul>
            }
            { ( !list || list.length == 0 ) &&
                <EmptyComponent name={emptyName}/>
            }
        </div>
    )
}

function getReplyWidth( num : number ) {
    if( !num ) return 15;

    if( num == 0 ) return 0;
    if( num < 10 ) return 50;
    if( num < 100 ) return 55;
    if( num < 1000 ) return 60;
    if( num < 10000 ) return 65;
    return 0;
}

function PostItem( props:any ) {
    const {item} = props;
	const haveImage = !!item.image01;
    const isChoice = item.choice_show == API_TYPES.BOOLEAN.TRUE;
    const haveReplyNum = item.reply_num && item.reply_num > 0;
    
    const maxWidth = window.outerWidth - (haveImage?50:0) - (isChoice?25:0) - getReplyWidth(item.reply_num) - 20;

    return(
        <> 
            { haveImage && <img className="body_image" src={!!item.thumbnail?item.thumbnail:item.image01}/> }
            <div className='best_body_div' style={{maxWidth:maxWidth}}>
                <span className='cate_name'>{"["+item.cate_name+"]"}</span>      
                <span className='title'>{item.title}</span>     
            </div>

            { haveReplyNum && 
                <div className='reply'><span>{"(" + item.reply_num + ")"}</span> </div>
            }
            { isChoice && <img className="choice_image" src={IMG_URL_CHOICE}/> }
        </>
    )
}

function EmptyComponent( props:any ) {
    const { name } = props;
    
	return(
        <div className='empty'>
            <span>{name}</span>
        </div>
	)
}