

// 수신 코드
const response_codes = [
    {code:200, name:"성공", reason:"success"},
    {code:400, name:"잘못된 요청", reason:"Bad Request"},
    {code:401, name:"인증에러", reason:"Unauthorized"},
    {code:500, name:"서버에러", reason:"Server Error"}
];

const response_code_dic = ( code : number ) => {
    for( var i=0; i<response_codes.length; i++ ) {
        if( code == response_codes[i].code ) {
            return response_codes[i];
        }
    }
    return {code:0, name:"알수없음", reason:"unknown"};
}

/*********************************************************************************************************
 * 
 * 서버 config.php 데이터 타입
 * 
 *********************************************************************************************************/

/* 테이블 타입 번호 */
export enum TABLE_TYPE {
    API_KEY=1,              // API Key
    SETTING=2,              // 설정
    STANDARD_OF_LEVEL_UP=3,	// 회원레벨업 기준
    TABOO=4,	            // 금지어
    INQUIRY=5,	            // 문의
    INQUIRY_REPLY=6,	    // 문의 답변
    NOTICE=7,	            // 공지사항
    BLACKLIST=8,	        // 블랙리스트
    ALL_USER=9,	            // 모든 계정(아이디 중복 확인용)
    ADMIN=10,	            // 관리자 계정
    USER=11,	            // 사용자 계정
    VISITANT=12,	        // 방문자 카운트 [연도별 테이블]
    SEARCH=13,	            // 검색 기록 [연도별 테이블]
    READ=14,	            // 글 조회 카운트 [연도별 테이블]
    STORY_CATEGORY=15,	    // 사연 카테고리
    STORY_POST=16,	        // 사연 게시글 [연도별 테이블]
    STORY_REPLY=17,	        // 사연 댓글 [연도별 테이블]
    STORY_LIKE=18,	        // 사연 좋아요 [연도별 테이블]
    STORY_HATE=19,	        // 사연 싫어요 [연도별 테이블]
    STORY_REPORT=20,	    // 사연 신고 [연도별 테이블]
    COMMUNITY_CATEGORY=21,	// 커뮤니티 카테고리
    COMMUNITY_POST=22,	    // 커뮤니티 게시글 [연도별 테이블]
    COMMUNITY_REPLY=23,	    // 커뮤니티 댓글 [연도별 테이블]
    COMMUNITY_LIKE=24,	    // 커뮤니티 좋아요 [연도별 테이블]
    COMMUNITY_HATE=25,	    // 커뮤니티 싫어요 [연도별 테이블]
    COMMUNITY_REPORT=26,    // 커뮤니티 신고 [연도별 테이블]
    BLOCK_USER=27	        // 사용자 차단
}

/* 상태 코드값 */
 export enum RESPONSE_CODE {
    SUCCESS = 200,      // 성공
    BAD_REQUEST = 400,  // 잘못된 요청
    UNAUTHORIZED = 401, // 인증에러
    NOT_FOUND = 404,    // 페이지를 찾을 수 없음
    SERVER_ERROR = 500  // 서버에러
}

/* 결과 코드 */
export enum RESULT_CODE {
    SUCCESS = 0,            // 성공
    EMPTY_PARAMS = 1,       // 파라미터 없음
    ERROR_TOKEN = 2,        // 토큰 인증 에러
    EXPIRATION_TOKEN = 3,   // 토큰 만료
    NOT_FOUND = 4,          // 찾을 수 없음
    HAVE_DATA = 5,          // 존재하는 데이터
    WRONG_DATA = 6,         // 잘못된 데이터
    DB_ERROR = 7,           // DB 에러
    PERMISSTION_DENIED = 8, // 권한 없음
    CAN_NOT_USE = 9,        // 사용 불가
    WRONG_API_KEY = 10,     // 잘못된 api key
    WITHDRAW = 11,          // 탈퇴한 유저
    TABOO = 12,             // 금지어
    BLACKLIST = 13,         // 블랙리스트
    UNKNOWN = 99            // 알수 없음
}

/* 결과 에러 메시지 */
export enum RESULT_MSG {
    EMPTY_PARAMS = "empty params",	        // 파라미터 없음
    ERROR_TOKEN = "error token",	        // 토큰 인증 에러
    EXPIRATION_TOKEN = "expiration token",	// 토큰 만료
    NOT_FOUND = "not found",	            // 찾을 수 없음
    HAVE_DATA = "have data",	            // 존재하는 데이터
    WRONG_DATA = "wrong data",	            // 잘못된 데이터
    DB_ERROR = "db error",	                // DB 에러
    PERMISSION_DENIED = "permission denied",// 권한없음
    CAN_NOT_USE = "can not use",		    // 사용불가
    WRONG_API_KEY = "wrong api key",	    // 잘못된 API 키
    WITHDRAW = "withdraw",			        // 탈퇴한 유저
    UNKNOWN = "unknown error"	            // 알수 없음
}

/* 상수 */
export enum ConstValue {
    TABLE_START_YEAR_DEFAULT = 2023
}

/* 토큰 체크 결과 값 */
export enum TOKEN_CHECK_TYPE {
	SUCCESS =1,	    // 토큰 체크 성공
	FAIL    =-1,	    // 토큰 체크 실패 :: DB에서 찾을수 없음
	TIME_OUT=-2,    // 토큰 만료
	WRONG   =-3	    // 잘못된 토큰
}

/* 사용상태 */
export enum USE_STATE {
    ABLE = 0,       // 사용가능
    DISABLE = 1,    // 사용불가
    WITHDRAW = 2,   // 탈퇴
    EXPIRATION = 3  // 만료  
}

/* 게시글 사용상태 */
export enum POST_USE_STATE {
    ABLE = 0,           // 사용
    DELETE = 1,         // 삭제
    ADMIN_DELETE = 2,   // 관리자 삭제
    REPORT = 3          // 신고누적
}

/* 공지사항 사용상태 */
export enum NOTICE_STATE {
    ABLE = 0,       // 사용
    DISABLE = 1,     // 사용불가
    WAIT = 2,       // 대기
    EXPIRATION = 3  // 만료
}

/* 불리언 값 */
export enum BOOLEAN {
    FALSE = 0,    // 거짓
    TRUE = 1      // 참
}

/* 카테고리 타입 */
export enum CATEGORY_TYPE {
    ALL = -2,
    BEST = -1
}

/* 설정 데이터 타입 */
export enum SETTING_DATA_TYPE {
    LOGIN_CODE = 1, // 코드번호
    BEST_POST = 2,  // 베스트글 기준
    BEST_REPLY = 3, // 베스트댓글 기준
    REPORT_HIDE = 4 // 신고 숨김 개수 기준
}

/* 문의 타입 */
export enum INQUIRY_TYPE {
    CUSTOMER = 1,   // 고객
    AD = 2          // 광고
}

/* 하단 게시글 리스트 타입 */
export enum POST_LIST_TYPE {
    ALL = 0,	                // 전체
    ALL_STORY = 1,	            // 사연 전체
    ALL_COMMUNITY = 2,	        // 커뮤니티 전체
    NOMAL = 10,	                // 일반 카테고리 내 리스트
    BEST = 11,	                // 일반 베스트
    DAY_BEST = 12,	            // 일 순위 베스트
    WEEK_BEST = 13,	            // 주간 순위 베스트
    MONTH_BEST = 14,	        // 월간 순위 베스트
    MAIN_BEST_STORY = 15,       // 메인 사연 베스트
    MAIN_BEST_COMMUNITY = 16    // 메인 커뮤니티 베스트
}

/* 하단 게시글 리스트 정렬 타입 */
export enum POST_LIST_SORT_TYPE {
    NONE = -1,          // 없음
    LIKE = 1,	        // 추천순
    HITS = 2,	        // 조회순
    REPLY = 3,	        // 댓글순
    INSERT = 4,	        // 작성순
    CHOICE_DATE = 5,	// 채택일
    CHOICE_SEND = 6,	// 지급일
    DELETE_DATE = 7,	// 삭제일
    REPORTS = 8,	    // 신고수
    ID = 9,	            // 아이디
    LOGIN = 10,	        // 로그인순
    NAME = 11,	        // 이름순
    POST_COUNT = 12,	// 게시글수
    REPLY_COUNT = 13,	// 댓글수
    REPORT_COUNT = 14,	// 누적신고수
    BLOCK_COUNT = 15,	// 차단횟수
    END = 16	        // 종료일
}

/* 게시글 타입 */
export enum POST_TYPE {
    ALL = 0,	    // 전체
    STORY = 1,	    // 사연
    COMMUNITY = 2,	// 커뮤니티
    NOTICE = 3,	    // 공지
}

/* 계정 타입 */
export enum USER_TYPE {
    NOMAL = 1,   // 일반유저
    ADMIN = 2	// 관리자
}

/* 관리자 타입 */
export enum ADMIN_TYPE {
    TOP = 1,    // 최고관리자
    NOMAL = 2   // 일반관리자
}

/* 권한 영역 타입 */
export enum MANAGE_ACCESS {
    ALL = 1,    // 전체 관리
    PART = 2    // 부분 관리
}

/* 부분 권한 영역 타입 */
export enum MANAGE_ACCESS_PART {
    STORY           = "STORY",		    // 사연 관리
    STORY_PART      = "ST_PART",	    // 사연 부분 관리
    COMMUNITY       = "COMMUNITY",	    // 커뮤니티 관리
    COMMUNITY_PART  = "CMU_PART",       // 커뮤니티 부분 관리
    NOTICE          = "NOTICE",		    // 공지사항 관리
    USER_ADMIN      = "ACCOUNT_ADMIN",	// 관리자 관리
    USER            = "ACCOUNT_USER",	// 사용자 관리
    SYSTEM          = "SYSTEM",		    // 시스템 관리
    TABOO           = "TABOO",		    // 금지어 관리
    INQUIRY         = "INQUIRY"		    // 문의 관리
}

/* 사연 채택 타입 */
export enum STORY_CHOICE_TYPE {
    NONE = 0,   // 없음
    NOMAL = 1   // 일반
}

/* 검색 타입 */
export enum SEARCH_TYPE {
    ALL = 0,            // 전체 검색
    TITLE = 1,          // 제목 검색
    WRITER = 2,         // 글쓴이 검색
    REPLY_WRITER = 3,   // 댓글 작성자
    REPLY_BODY = 4      // 댓글 내용
}

/* 성별 타입 */
export enum GENDER_TYPE {
    MALE = 1,   // 남자
    FEMALE = 2   // 여자
}

/** End - 서버 config.php 데이터 타입
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 로컬 타입
 * 
 *********************************************************************************************************/

export interface ConnectInformation {
    os:string|null,
    fcm_token:string|null,
    app_version:string|null,
    version_code:string|null,
    version_name:string|null,
    uuid:string|null
}

/** End - 로컬 타입
 *********************************************************************************************************/



/*********************************************************************************************************
 * 
 * 공통 기본 파라미터
 * 
 *********************************************************************************************************/

export interface DefaultParamAddImage {
    thumbnail:File|null,
    image_file_01:File|null,
    image_file_02:File|null,
    image_file_03:File|null,
    image_file_04:File|null,
    image_file_05:File|null,
    image_file_06:File|null,
    image_file_07:File|null,
    image_file_08:File|null,
    image_file_09:File|null,
    image_file_10:File|null
}

export interface DefaultParamUpdateImage extends DefaultParamAddImage {
    image_url_01:string|null,
    image_url_02:string|null,
    image_url_03:string|null,
    image_url_04:string|null,
    image_url_05:string|null,
    image_url_06:string|null,
    image_url_07:string|null,
    image_url_08:string|null,
    image_url_09:string|null,
    image_url_10:string|null,
    delete_thumbnail:string|null,
    delete_url_01:string|null,
    delete_url_02:string|null,
    delete_url_03:string|null,
    delete_url_04:string|null,
    delete_url_05:string|null,
    delete_url_06:string|null,
    delete_url_07:string|null,
    delete_url_08:string|null,
    delete_url_09:string|null,
    delete_url_10:string|null,
}