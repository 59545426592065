import React, { useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupDeletePost = ( props:any ) => {
    const { visible, data, type, postType, onDelete, onBlackList}=props;
    const [selectType, setSelectType] = useState<any>(API_TYPES.STORY_CHOICE_TYPE.NOMAL);
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [etcReasonStr, setEtcReasonStr] = useState("");
    const [requestDelete, setRequestDelete] = useState(false);


    useEffect(()=>{
        if( visible != true ) { initData(); }
        return(()=>{})
    },[visible]);
    

    const initData = () => {
        setRequestDelete(false);
        setEtcReasonStr("");
        setSelectType(API_TYPES.STORY_CHOICE_TYPE.NOMAL);
    }
    
    if( !data ) return null;

    const clickedBlacklist = () => {
        if( onBlackList ) onBlackList();
    }

    const clickedDelete = () => {
        if( requestDelete ) return;

        var deleteReasonStr = getDeleteReasonStr( selectType, etcReasonStr);
        if( !deleteReasonStr ) {
            alert("삭제 사유가 없습니다");
            return;
        }
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                if( onDelete ) onDelete();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("글을 찾을 수 없습니다");
					navigate(PAGE_URL.MAIN, { replace:true });
				}
			}
		}

        setRequestDelete( true );

        if( type == DeletePopupType.POST ) {
            if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                apiAxios.admin.dataCommunityPostDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else if( postType == API_TYPES.POST_TYPE.STORY ) {
                apiAxios.admin.dataStoryPostDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else { setRequestDelete( false ); }
        }
        else if( type == DeletePopupType.REPLY ) {
            if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                apiAxios.admin.dataCommunityReplyDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else if( postType == API_TYPES.POST_TYPE.STORY ) {
                apiAxios.admin.dataStoryReplyDelete(dispatch,data.idx, data.year, deleteReasonStr, callback);
            }
            else { setRequestDelete( false ); }
        }
        else { setRequestDelete( false ); }
    }

    var title = type == DeletePopupType.POST?data.title:data.body;
    var popupTitle = "";
    var titleLeft = "";

    if( type == DeletePopupType.POST ) {
        popupTitle = "글 삭제";
        titleLeft = "글제목";
    }
    else if( type == DeletePopupType.REPLY ) {
        popupTitle = "댓글 삭제";
        titleLeft = "댓글내용";
    }

    return (
        <PopupWrapper {...props}
            title={popupTitle}
            width={300}
            buttonChildren={<>
                <RedButton {...props} title="블랙리스트" onClick={clickedBlacklist} disable={requestDelete}/>
                <BlackButton {...props} title="삭제" onClick={clickedDelete} loading={requestDelete}/>
            </>}>
            
            <div className='body_area popup_delete'>

                <table className='post_box'>
                    <tr>
                        <td className='left'>{titleLeft}</td>
                        <td className='right'><span>{title}</span></td>
                    </tr>
                    <tr>
                        <td className='left'>글쓴이</td>
                        <td className='right'><span>{data.user_id + " (" + data.nickname + ")"}</span></td>
                    </tr>
                    <tr>
                        <td className='left'>작성일</td>
                        <td className='right'><span>{makeInsertTimeFullFromTimeStamp(data.insert_time)}</span></td>
                    </tr>
                </table>

                <div className='select_box'>
                    <div className='title'><span>삭제타입</span></div>
                    <select className="select"  value={selectType} 
                        onChange={(e)=> {
                            setSelectType(e.target.value);  
                        }}>
                        <option value={DeleteReasonType.TABOO}>불건전한 내용</option>
                        <option value={DeleteReasonType.DISS}>비방글</option>
                        <option value={DeleteReasonType.POLITICS}>정치글</option>
                        <option value={DeleteReasonType.PLASTER}>도배</option>
                        <option value={DeleteReasonType.REPORT}>신고누적</option>
                        <option value={DeleteReasonType.ETC}>기타</option>
                    </select>

                </div>

                <div className={'input_title_box' + (selectType!=DeleteReasonType.ETC?" input_title_box_disable":"")}>
                    <div className='title'><span>기타 사유</span></div>
                    <input className='input' type='text' disabled={selectType!=DeleteReasonType.ETC} value={etcReasonStr} onChange={(e)=> { setEtcReasonStr(e.target.value); }}/>
                </div>
            </div>
        </PopupWrapper>
    );
};
  
export default PopupDeletePost;

export const getDeleteReasonStr = ( selectType:number, etcStr:string|null ) => {
    if( selectType == DeleteReasonType.TABOO ) { return "불건전한 내용을 포함하고 있습니다"; }
    if( selectType == DeleteReasonType.DISS ) { return "비방글 입니다"; }
    if( selectType == DeleteReasonType.POLITICS ) { return "정치글 입니다"; }
    if( selectType == DeleteReasonType.PLASTER ) { return "도배"; }
    if( selectType == DeleteReasonType.REPORT ) { return "신고누적"; }
    if( selectType == DeleteReasonType.ETC ) { 
        return etcStr; 
    }
    return null;
}