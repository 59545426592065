import { useState } from "react";
import { Oval } from "react-loader-spinner";

import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { BsPerson } from "react-icons/bs";
import { FiLock } from "react-icons/fi";
import ButtonDefault from "../../../../sub/ButtonDefault";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../../../util/Page";
import { useDispatch, useSelector } from "react-redux";
import apiAxios, { API_TYPES, API_UTILS } from "../../../../../apiAxios";
import { AxiosError, AxiosResponse } from "axios";
import { setCookieLoginData } from "../../../../../util/cookie_helper";
import reduxActions from "../../../../../redux_saga/modules/moduleActions";
import { APP_INTERFACE_SEND_TYPE, APP_INTERFACE_LOGIN_DATA, sendDataToApp } from "../../../../../util/AppInterface";
import { RootState } from "../../../../../redux_saga/modules";

function LoginBox( props:any ) {
	const dispatch = useDispatch();
    const navigate = useNavigate();

    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const fcmToken : string|null = useSelector((state:RootState)=>state.data.fcmToken);

    const [showPassword, setShowPassword] = useState(false);

    const [idValue, setIdValue] = useState("");
    const [pwValue, setPwValue] = useState("");
    const [isRequest, setIsRequest] = useState(false);

    const clickedLogin = () => {
        if( isRequest ) return;
        if( checkEmptyInsertData() ) return;

        reqLogin(idValue, pwValue, null);
    }

    const checkEmptyInsertData = () => {
        if( !idValue ) { alert("아이디를 입력하세요"); return true; }
        else if( !pwValue ) { alert("비밀번호를 입력하세요"); return true; }
        return false;
    }
    
    const handleKeyUp = (e:any) => {
        if( e.keyCode == 13){ // 키보드 Enter 클릭
            clickedLogin();
        }
    }

    const reqLogin = ( id:string, pw:string, code:string|null ) => {
        setIsRequest(true);
		apiAxios.global.authLogin(dispatch, id, pw, code, applicationOS, fcmToken, (response:AxiosResponse|null, error:AxiosError|null) => {
            setIsRequest(false);

			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const newData : any = {...resData};
				newData["pw"] = pw;
				newData["code"] = code;

                setCookieLoginData(newData);
                reduxActions.data.setLoginData(dispatch, newData);
                
                navigate(PAGE_URL.MAIN, {replace:true});
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData ) {
                    if( errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND && 
                        ( errorData.data == 1 || errorData.data == 2 || errorData.data == 4 ) ) { // 아이디를 찾을 수 없음
                        alert("아이디 또는 비밀번호가 잘못 되었습니다");
                    }
                    else  if( errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND && errorData.data == 3 ) { // 로그인 코드가 맞지 않을 때
                        // 코드 입력인 경우라면
                        if( !!code ) {
                            alert("관리자 로그인 코드가 잘못 되었습니다");
                        }
                        else {
                            var title = "관리자 로그인 입니다. 코드번호를 입력하세요";
                            var returnValue = window.prompt(title, "");
                            if (returnValue) {
                                reqLogin( id, pw, returnValue);
                            }
                        }
                    }
                    else if( errorData.result_code == API_TYPES.RESULT_CODE.BLACKLIST ) {
                        reduxActions.data.setLoginData(dispatch, null);
                        setIdValue("");
                        setPwValue("");
                        setShowPassword(false);
                        //setCookieLoginData(null);
                    }
				}
			}
		})
    }

    return(
    <div className='mobile_login_box'>
        <InputBox 
            {...props}
            value={idValue}
            setValue={setIdValue}
            onKeyUp={handleKeyUp}
            placeholder="아이디 입력"
            name='id'
            leftIcon={<BsPerson size={20}/>}/>
        <InputBox 
            {...props}
            value={pwValue}
            setValue={setPwValue}
            placeholder="비밀번호 입력"
            isPassword={!showPassword}
            onKeyUp={handleKeyUp}
            name='password'
            leftIcon={<FiLock size={20}/>}
            rightIcon={ <PasswordRightIcon show={showPassword} setShow={setShowPassword}/> }
            />
        <ButtonDefault
            {...props}
            className='login_btn'
            value={"로그인"}
            isLoading={isRequest}
            onClick={clickedLogin}
            />
        <BottomTextBox
            {...props}/>
    </div>
    )
}
export default LoginBox;

function BottomTextBox( props:any ) {
    const navigate = useNavigate();

    const clickedSignup = () => {
        navigate(PAGE_URL.SIGNUP);
    }

    const clickedFindId = () => {
        navigate(PAGE_URL.FIND_ID);
    }

    const clickedFindPw = () => {
        navigate(PAGE_URL.FIND_PW);
    }
    return(
        <div className="bottom_text">
            <div className="sign_up" onClick={clickedSignup}><span>회원가입</span></div>
            <div onClick={clickedFindPw}><span>비번찾기</span></div>
            <div className="devider"><span>/</span></div>
            <div onClick={clickedFindId}><span>아이디</span></div>
        </div>
    )
}


function InputBox( props:any ) {
    const {leftIcon, name, placeholder, disabled, value, setValue, rightIcon, rightText, isPassword, onClickRightText, onKeyUp, rightLoading} = props;

    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const [isFocus, setFocus] = useState(false);

    var inputWidth = window.outerWidth - 60 - 50 - 9;
    if( !!leftIcon ) inputWidth = inputWidth - 30;
    if( !!rightIcon ) inputWidth = inputWidth - 30;
    if( !!rightText ) inputWidth = inputWidth - 70;

    var inputHeight = 56;
    if( applicationOS == "ios" ) {
        inputHeight = 50;
    }

    return(
        <div className={"input_box"}>
            { leftIcon!= null && 
                <div className="left_icon"> {leftIcon} </div> 
            }

            <input 
                type={isPassword?"password":"text"} 
                name={name}
                placeholder={placeholder} 
                value={value} 
                onChange={(e)=> { 
                    if(setValue) {
                        var text = e.target.value;
                        text = text.replace(/ /g, ''); // 공백제거
                        setValue(text); 
                    }
                }} 
                style={{width:inputWidth, height:inputHeight}}
                onFocus={()=>{setFocus(true)}}
                onBlur={()=>{setFocus(false)}}
                disabled={disabled}
                onKeyUp={onKeyUp}/>

            { rightIcon!= null && 
                <div className="right_icon"> {rightIcon} </div> 
            }
            { !!rightText && 
                <div className="right_text" onClick={onClickRightText}> 
                    { rightLoading == true && <div className="loader">
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                        </div>}
                    { rightLoading != true && <span>{rightText}</span> }
                </div> 
            }
        </div>
    )
}


const SHOW_TEXT = "숨김해제";
const HIDE_TEXT = "입력숨김";

/**
 * 비밀번호 입력 우측 아이콘
 * @param props 
 * @returns 
 */
function PasswordRightIcon( props:any ) {
    const {show, setShow} = props;

    return( <>
        { show && <FiEye title={HIDE_TEXT} size={24} onClick={()=>{setShow(false)}}/> }
        { !show && <FiEyeOff title={SHOW_TEXT} size={24} onClick={()=>{setShow(true)}}/> }
    </>)
}