/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { RootState } from '../../../../redux_saga/modules';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { TAB_TYPE } from '../../../sub/TopTab';
import Wrapper from '../../../sub/Wrapper';
import MobileBodyComponent from './sub/MobileBodyComponent';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import TopAdComponent from '../../../sub/TopAdComponent';
import MobileWrapper from '../../sub/MobileWrapper';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../../sub/Adsense';

const MobileWritePost = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const [categoryIdx, setCategoryIdx] = useState(-2);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [idx, setIdx] = useState(-1);
	const [postType, setPostType] = useState(API_TYPES.POST_TYPE.ALL);
	
	const [postData, setPostData] = useState<any>(null);
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
	
	useEffect(()=>{
		if( !loginData || loginData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
			alert("로그인하셔야 글을 작성할 수 있습니다");
			clickToList( true );
		}

		return(()=>{})
	},[loginData]);


	useEffect(()=>{
		console.log("Story useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		const y = l_p.get("year");
		const i = l_p.get("idx");
		const pt = l_p.get("post_type");


		var newCategory = -1;
		var newYear = (new Date()).getFullYear();
		var newIdx = -1;
		var newPostType = API_TYPES.POST_TYPE.ALL;

		if( c ) 	{ try{ newCategory 			= parseInt(c); 		}catch(e){ console.log("e",e);} }
		if( y ) 	{ try{ newYear				= parseInt(y); 		}catch(e){ console.log("e",e);} }
		if( i ) 	{ try{ newIdx 				= parseInt(i); 		}catch(e){ console.log("e",e);} }
		if( pt ) 	{ try{ newPostType 			= parseInt(pt); 	}catch(e){ console.log("e",e);} }

		setCategoryIdx(newCategory);
		setYear(newYear);
		setIdx(newIdx);
		setPostType(newPostType);
		
        reqCategory();

		if( newIdx != -1 ) {
			reqData(newYear, newIdx, newPostType, newCategory);
		}

		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
	}

	const reqData = (year:number, idx:number, postType:number, newCategory:number) => {

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);


				setPostData(resData);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("내가 작성한 게시물이 아닙니다");
					clickToList(true);
				}
			}
		}

		apiAxios.user.dataPost(dispatch,idx,year, postType, callback);

		/*const param : ParamGlobalDataPost = {
			idx:idx,
			year:year,
			post_type:postType,
			post_list_type:postListType,
			post_list_sort_type:postListSortType!=API_TYPES.POST_LIST_SORT_TYPE.NONE?postListSortType:null
		}

		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);


				setPostData(resData.post_data);
				setReplyTotalData(resData.reply_total_data);
				setReplyList(resData.reply_list);
				setPostList(resData.post_list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
					alert("게시글을 찾을 수 없습니다");
					navigate(PAGE_URL.MAIN, { replace:true });
				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {
					alert(getDeletePostMsg(API_TYPES.POST_USE_STATE.DELETE));
					navigate(PAGE_URL.MAIN, { replace:true });
				}
			}
		}

		apiAxios.global.dataPost(dispatch,param,callback);*/
	}

	const clickToList = ( isReplace : boolean = false) => {
        var url = "";
		var year = (new Date()).getFullYear();

		if( postType == API_TYPES.POST_TYPE.ALL ) {
			url = makeUrl(PAGE_URL.MAIN, null);
		}
		else if( postType == API_TYPES.POST_TYPE.COMMUNITY ) {
			const params : PAGE_POST_HOME_PARAMS = {
				category:categoryIdx, year:year, page:1
			}
			url = makeUrl(PAGE_URL.COMMUNITY, params);
		}
		else if( postType == API_TYPES.POST_TYPE.STORY ) {
			const params : PAGE_POST_HOME_PARAMS = {
				category:categoryIdx, year:year, page:1
			}
			url = makeUrl(PAGE_URL.STORY, params);
		}

        if( !!url ) {
            if( isReplace ) {
                navigate(url, {replace:true});
            }
            else {
                navigate(url);
            }
        }
	}

	var tabType = -1;

	var title = "게시글";

    if( idx && idx > 0 ) {
        title += " 수정";
    }
    else {
        title += " 쓰기";
    }

	return (<>
		<MobileWrapper
			tabType={tabType}
			subTitle={title}
			hideNotice={true}
			hideWriteBtn={true}>
			<MiddleComponent 
				{...props} 
                categoryIdx={categoryIdx}
                year={year}
                idx={idx}
                postType={postType}
                postData={postData}
				onClickToList={clickToList}
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileWritePost;

function MiddleComponent( props:any ) {
	const {postData, onClickBottomList, postList, setPostList} = props;

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
			
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				pagePosition={AD_PAGE_POSITION.MOBILE_POST_WRITE_TOP}/>
            <MobileBodyComponent {...props}/>
		</div>
	)
}

