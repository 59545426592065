/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { RootState } from '../../../../redux_saga/modules';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { TAB_TYPE } from '../../../sub/TopTab';
import Wrapper from '../../../sub/Wrapper';
import MobileBodyComponent from './sub/MobileBodyComponent';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../../util/Page';
import TopAdComponent from '../../../sub/TopAdComponent';
import { checkLogin } from '../../../../util/Global';
import MobileWrapper from '../../sub/MobileWrapper';
import PopupWithdraw from '../../../popup/PopupWithdraw';

const MobileUserInfo = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const loginData = useSelector((state:RootState)=>state.data.loginData);

	const [showWithdrawPopup, setShowWithdrawPopup] = useState(false);
    
	
	useEffect(()=>{

		checkLogin(navigate, loginData);

		return(()=>{})
	},[loginData]);

	var tabType = -1;

	return (<>
		<MobileWrapper
			tabType={tabType}
			hideNotice={true}
			subTitle="정보수정"
			hideBottomCompanyInfo={true}
			popup={<>
				<PopupWithdraw
					{...props}
					visible={showWithdrawPopup}
					onClose={()=>{
						setShowWithdrawPopup(false);
					}}
					/>
			</>}>
			<MiddleComponent 
				{...props} 
				setShowWithdrawPopup={setShowWithdrawPopup}
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileUserInfo;

function MiddleComponent( props:any ) {
    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
            <MobileBodyComponent {...props}/>
		</div>
	)
}

