
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import ButtonDefault from '../../../sub/ButtonDefault';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import AdminPageComponent from '../../sub/AdminPageComponent';
import { PAGE_ADMIN_INQUIRY, PAGE_ADMIN_INQUIRY_POST, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import { getParamData, makeInsertTimeStr, movePage } from '../../../../util/Global';
import { isMobile } from 'react-device-detect';


const InquiryList = (props:any) => {
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
    const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectInquiryType, setSelectInquiryType] = useState(FILTER_VALUE_ALL);
    const [selectRead, setSelectRead] = useState(FILTER_VALUE_ALL);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

    useEffect(()=>{
        /*page        :page,
        search      :!!searchText?searchText:null, 
        type        :selectInquiryType,
        read        :selectRead*/
        const l_p = new URLSearchParams(location.search);

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );
        var newType         = getParamData(l_p, "type", FILTER_VALUE_ALL );
        var newRead         = getParamData(l_p, "read", FILTER_VALUE_ALL );

        setPage( newPage );
        setSearchText( newSearch );
        setSelectInquiryType( newType );
        setSelectRead( newRead );

        reqList( newType, newRead, newSearch, newPage );
        
        return(()=>{})
    },[location]);

    const reqList = ( type:number|null, read:number|null, total_search:string|null, page:number|null ) => {

        type = type==FILTER_VALUE_ALL?null:type;
        read = read==FILTER_VALUE_ALL?null:read;

        apiAxios.admin.listInquiry( dispatch, type, read, total_search, page, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

				setItemList( resData.list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_INQUIRY => {
        //export interface PAGE_ADMIN_INQUIRY { type:number|null, read:number|null, page:number|null, search:string|null }
        var params : PAGE_ADMIN_INQUIRY = {
            page        :1,
            search      :!!searchText?searchText:null, 
            type        :selectInquiryType==FILTER_VALUE_ALL?null:selectInquiryType,
            read        :selectRead==FILTER_VALUE_ALL?null:selectRead
        }
        if( !!param ) {
            if( param == "type" )         params["type"]    = value==FILTER_VALUE_ALL?null:value;
            else if( param == "read" )    params["read"]    = value==FILTER_VALUE_ALL?null:value;
            else if( param == "search" )  params["search"]  = !value?null:value;
        }

        return params;
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const changePage = ( params : PAGE_ADMIN_INQUIRY ) => {
        var url = makeUrl(PAGE_URL.ADMIN_INQUIRY, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_inquiry_list_box'>
            <ListTop 
                {...props}
                onSearch={onSearch}
                selectInquiryType={selectInquiryType}                
                setSelectInquiryType={(value:any)=>{
                    setSelectInquiryType(value);
                    changePage( getMovePageParam( "type", value ) );
                }}
                selectRead={selectRead}          
                setSelectRead={(value:any)=>{
                    setSelectRead(value);
                    changePage( getMovePageParam( "read", value ) );
                }}/>
			<ul>
                { isMobile != true && 
                <li className={'title' + titleBg}>
                    <div className='name'>제목</div>
                    <div className='insert_time'>등록일</div>
                    <div className='type'>배너</div>
                    <div className='nickname two_line'>{"닉네임\n(아이디)"}</div>
                    <div className='reply'>답변</div>
                </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>문의사항 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_INQUIRY = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}

					var url = makeUrl(PAGE_URL.ADMIN_INQUIRY, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default InquiryList;

function ListTop( props:any ) {
    const {selectInquiryType, setSelectInquiryType, selectRead, setSelectRead}= props;
    
    const inquiryTypeOption : OptionData[] = [ ALL_FilTER_DATA,
        {value:API_TYPES.INQUIRY_TYPE.CUSTOMER, name:"고객"}, 
        {value:API_TYPES.INQUIRY_TYPE.AD, name:"광고"}
    ];

    const readOption : OptionData[] = [ ALL_FilTER_DATA,
        {value:API_TYPES.BOOLEAN.TRUE, name:"답변"}, 
        {value:API_TYPES.BOOLEAN.FALSE, name:"미답변"}
    ];

    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
                <AdminSelectBox {...props} title={"타입"} className={"select_type"}  options={inquiryTypeOption} selectValue={selectInquiryType} SetSelectValue={setSelectInquiryType}/>
                <AdminSelectBox {...props} title={"답변"} className={"select_read"}  options={readOption} selectValue={selectRead} SetSelectValue={setSelectRead}/>
            </div>
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"제목, 닉네임, 아이디"}/>
            </div>
        </>)
    }

    return(
        <div className='list_top'>
            
            <AdminSelectBox {...props} title={"타입"} className={"select_type"}  options={inquiryTypeOption} selectValue={selectInquiryType} SetSelectValue={setSelectInquiryType}/>
            <AdminSelectBox {...props} title={"답변"} className={"select_read"}  options={readOption} selectValue={selectRead} SetSelectValue={setSelectRead}/>
            <AdminSearchBox {...props} placeholder={"제목, 닉네임, 아이디"}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openCategoryAddPopup} = props;
    const navigate = useNavigate();

    const clickedItem = ( e:any ) => {
        
        var params : PAGE_ADMIN_INQUIRY_POST = {
            idx: item.idx
        }

        var url = makeUrl(PAGE_URL.ADMIN_INQUIRY_POST, params);
        movePage(navigate, e, url);
    }

    var typeStr = item.type==API_TYPES.INQUIRY_TYPE.CUSTOMER?"고객":item.type==API_TYPES.INQUIRY_TYPE.AD?"광고":"";


    if( isMobile ) {
        return(
            <li className="item" onClick={clickedItem}>
                <div className='sub'>
                    <div className='title'>{item.title}</div>
                </div>
                <div className='sub height_25'>
                    <div className={'type'}>{typeStr}</div>
                    { item.read==API_TYPES.BOOLEAN.TRUE && <>
                        <div className='divide'>·</div>
                        <div className={'reply'}>{item.read==API_TYPES.BOOLEAN.TRUE?"답변완료":""}</div>
                    </>}
                    <div className='divide'>·</div>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
                </div>
                <div className='sub sub_bg top_border'>
                    <div className={'id'}>{item.user_id}</div>
                    <div className='divide'>·</div>
                    <div className={'nickname'}>{item.user_nickname}</div>
                </div>
            </li>
        )
    }

    return(
        <li className='item' onClick={clickedItem}>
            <div className='name'>{item.title}</div>
            <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
            <div className={'type'}>{typeStr}</div>
            <div className={'nickname two_line'}>{item.user_nickname + "\n(" + item.user_id + ")"}</div>
            <div className={'reply'}>{item.read==API_TYPES.BOOLEAN.TRUE?"완료":"."}</div>
        </li>
    )
}