/****************************************************************************************
 * 
 *  홈페이지 상단 로고 및 검색입력 부분
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { AiOutlineSearch } from "react-icons/ai";
import { AiFillEdit } from "react-icons/ai";
import { PAGE_URL, PAGE_SEARCH, makeUrl, PAGE_WRITE_POST } from '../../../util/Page';
import { BsPerson } from "react-icons/bs";
import { BsPersonFill } from "react-icons/bs";

import IconButton from './IconButton';
import { MOBILE_TAB_TYPE } from './MobileWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux_saga/modules';
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { TAB_TYPE } from '../../sub/TopTab';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { AxiosError, AxiosResponse } from 'axios';
import { TEST_VERSION } from '../../../util/ConstValue';
import { AiFillHome } from "react-icons/ai";
import { getParamData } from '../../../util/Global';
import { APP_INTERFACE_SEND_TYPE, makeAppInterfaceSendData, sendDataToApp } from '../../../util/AppInterface';

function MobileHeader( props : any ) {
    const { hideSearch, subTitle, hideTab } = props;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginData = useSelector((state:RootState)=>state.data.loginData);
    const userData = useSelector((state:RootState)=>state.data.userData);
    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);


    //application
    /**
     * 웹 페이지 변경될때 상단으로 이동
     */    
	const location = useLocation();
	
	useEffect(()=>{
		window.scrollTo(0,0);

		return ()=>{}
	},[location])


    useEffect(()=>{
        if( loginData && !userData ) { reqUserData(); }
        else if( !loginData ) { reduxActions.data.setUserData(dispatch, null); }

        return(()=>{})
    },[loginData, userData]);

    const reqUserData = () => {
        apiAxios.global.authUserData(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                reduxActions.data.setUserData(dispatch, resData);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData ) {
                    if( errorData.result_code == API_TYPES.RESULT_CODE.ERROR_TOKEN ) { // 아이디를 찾을 수 없음
                        
                    }
				}
			}
		})
    }

    return ( <>
        { !applicationOS &&
        <div className='mobile_header'>
            { !subTitle && 
                <div className='logo'>
                    <span onClick={()=>{ navigate( PAGE_URL.MAIN ); }}>{"모여봐" + (TEST_VERSION?" (테스트)":"")}</span>
                </div>
            }
            { subTitle && 
                <div className='logo_left'>
                    <span className='logo' onClick={()=>{ navigate( PAGE_URL.MAIN ); }}>{"모여봐"}</span>
                    <span className='sub'>{(TEST_VERSION?"(테스트) ":"")+subTitle}</span>
                </div>
            }

            <IconButton 
                {...props}
                className={'user ' + (loginData?"":" user_disable")}
                icon={loginData?<BsPersonFill size={30}/>:<BsPerson size={30}/>}
                iconSize={30}
                onClick={()=>{
                    navigate( PAGE_URL.MOBILE_LOGIN );
                }}
                />

        </div>
        }
        { hideTab != true && <TabComponent {...props}/> }
    </>);
}

export default MobileHeader;

function TabComponent( props: any ) {
    const { tabType, currentCategory, categoryData, hideWriteBtn } = props;
    const loginData = useSelector((state:RootState)=>state.data.loginData);
    const userData = useSelector((state:RootState)=>state.data.userData);
    const [showSearch, setShowSearch] = useState(false);
    const navigate = useNavigate();


    const clickedWritePost = () => {
        var url = "";
        var categoryIdx = currentCategory>0?currentCategory:null;
        var post_type = API_TYPES.POST_TYPE.ALL;
        if( tabType == TAB_TYPE.COMMUNITY ) {
            post_type = API_TYPES.POST_TYPE.COMMUNITY;
        }
        else if( tabType == TAB_TYPE.STORY ) {
            post_type = API_TYPES.POST_TYPE.STORY;
        }
        var params : PAGE_WRITE_POST = {
            category:categoryIdx,
            year:null,
            idx:null,
            post_type:post_type
        }

        if( userData ) {
            if( userData.is_admin == API_TYPES.BOOLEAN.TRUE ) {
                if( params.post_type == API_TYPES.POST_TYPE.ALL ) params.post_type = null;
                url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, params);
            }
            else {
                url = makeUrl(PAGE_URL.WRITE_POST, params);
            }
            navigate( url );
        }
    }

    return(<>
        { !showSearch &&
            <div className='mobile_tab'>
                <TabItem {...props} tab={MOBILE_TAB_TYPE.HOME} icon={<AiFillHome size={25}/>}/>
                <TabItem {...props} tab={MOBILE_TAB_TYPE.STORY} tabName="사연"/>
                <TabItem {...props} tab={MOBILE_TAB_TYPE.COMMUNITY} tabName="커뮤니티"/>
                { loginData && loginData.is_admin == API_TYPES.BOOLEAN.TRUE &&
                    <TabItem {...props} tab={MOBILE_TAB_TYPE.ADMIN} tabName="관리자"/>
                }
                

                <IconButton 
                    {...props}
                    className='search_btn'
                    icon={<AiOutlineSearch size={25}/>}
                    iconSize={25}
                    onClick={()=>{
                        setShowSearch(true);
                    }}
                    />

                { loginData && hideWriteBtn != true &&
                    <IconButton 
                        {...props}
                        className='write_btn'
                        icon={<AiFillEdit size={25}/>}
                        iconSize={25}
                        onClick={()=>{
                            clickedWritePost();
                        }}
                        />
                }
            </div>
        }
        <SearchBox {...props} showSearch={showSearch} setShowSearch={setShowSearch}/>
    </>)
}

function TabItem( props:any ) {
    const {tabType, tab, tabName, icon} = props;
    const navigate = useNavigate();
    
    const movePage = () => {
        if( tabType == tab ) return;
        
        if( tab == MOBILE_TAB_TYPE.HOME ) {
            navigate( PAGE_URL.MAIN );
        }
        else if( tab == MOBILE_TAB_TYPE.STORY ) {

            navigate( PAGE_URL.STORY );
        }
        else if( tab == MOBILE_TAB_TYPE.COMMUNITY ) {
            navigate( PAGE_URL.COMMUNITY );
        }
        else if( tab == MOBILE_TAB_TYPE.ADMIN ) {
            navigate( PAGE_URL.ADMIN_MAIN );
        }
    }

    var cName = "";
    if( tabType == tab ) cName = " select";
    return(
        <div className={'item' + cName} onClick={movePage}>
            { !!icon && <div className='icon'> {icon}</div> }
            { !icon && <span>{tabName}</span> }
        </div>
    )
}
function SearchBox( props : any ) {
    const {showSearch, setShowSearch} = props;
    const navigate = useNavigate();
	const location = useLocation();
    
    const [searchSelect, SetSearchSelect] = useState<any>(API_TYPES.SEARCH_TYPE.ALL);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [isSearchPage, setIsSearchPage] = useState(false);

	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const s = l_p.get("search");

        var newSearch = "";

		if( s ) 	{ try{ newSearch = s; 		    }catch(e){ console.log("e",e);} }

        console.log("SearchBox search : " + newSearch);

        setSearchInputValue(newSearch);
        if( !!newSearch ) {
            console.log("!!newSearch");
            setShowSearch(true);
            setIsSearchPage(true);
        }
		
		return(()=>{})
	},[location]);


    const onClickSearch = () => {
        if( !searchInputValue ) {
            alert("검색어를 입력하세요");
            return;
        }

        var params : PAGE_SEARCH = {
            search:searchInputValue,
            page:null,
            type:searchSelect,
            tabType:null,
            year:null
        }
        var url = makeUrl( PAGE_URL.SEARCH, params );
        navigate( url );
    }
  
    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        onClickSearch();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
        
      }
    }

    if( !showSearch ) return null;

    return (
        <div className='mobile_tab'>
            <div className='header_search_area'>
                <IconButton 
                    {...props}
                    className='close_btn_area'
                    icon={<AiOutlineArrowLeft size={25}/>}
                    iconSize={25}
                    onClick={()=>{
                        setShowSearch(false);
                        if( isSearchPage ) {
                            navigate(-1);
                        }
                    }}
                    />

                <input type='search' placeholder="검색" className="search_input" name='admin_id' autoComplete="off"
                    value={searchInputValue} 
                    onChange={(e)=> {
                        setSearchInputValue(e.target.value);
                    }}
                    onKeyUp={handleKeyUp} 
                />
                <IconButton 
                    {...props}
                    className='search_btn_area'
                    icon={<AiOutlineSearch size={25}/>}
                    iconSize={25}
                    onClick={onClickSearch}
                    />
                <div className='divider'><img/></div>
                <select className="select"  value={searchSelect} 
                    onChange={(e)=> {
                        SetSearchSelect(e.target.value);  
                    }}>
                    <option value={API_TYPES.SEARCH_TYPE.ALL}>전체</option>
                    <option value={API_TYPES.SEARCH_TYPE.TITLE}>제목</option>
                    <option value={API_TYPES.SEARCH_TYPE.WRITER}>작성자</option>
                </select>

                <div className='divider'><img/></div>
            </div>
        </div>
    )
}