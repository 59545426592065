/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../sub/TopTab';
import LeftAd from '../../sub/LeftAd';
import RightArea from '../../sub/RightArea';
import { getCookieLoginData } from '../../../util/cookie_helper';
import MobilePostList from './sub/MobilePostList';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch } from 'react-redux';
import Wrapper from '../../sub/Wrapper';
import { API_TYPES } from '../../../apiAxios';
import { PAGE_URL } from '../../../util/Page';
import TopAdComponent from '../../sub/TopAdComponent';
import Adsense, { AdType } from '../../sub/Adsense';
import MobileNoticeBox from '../sub/MobileNoticeBox';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../sub/MobileWrapper';

const MobileSearch = (props:any) => {
	
	useEffect(()=>{
		
		return(()=>{})
	},[]);

	return (
		<MobileWrapper
			tabType={MOBILE_TAB_TYPE.NONE}>
			<MiddleComponent 
				{...props} />
		</MobileWrapper>
	);
};

export default MobileSearch;

function MiddleComponent( props:any ) {
	const navigate = useNavigate();

	return(
		<>
			<MobilePostList {...props}/>
		</>
	)
}