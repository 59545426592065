import { forwardRef, useEffect, useRef, useState } from "react";
import apiAxios, { API_TYPES, API_UTILS, ParamAdminDataNoticeAdd, ParamAdminDataNoticeUpdate, ParamUserDataPostAdd, ParamUserDataPostUpdate } from "../../../../../apiAxios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux_saga/modules";
import { ImageData, ResponseImage, getImageFile, makePreViewFile } from "../../../../../util/Image";
import { AiFillCloseCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { INPUT_ACCEPT_TYPE, MAX_NICKNAME_LENGTH, TEXT_OVER_NICKNAME_LENGTH } from "../../../../../util/ConstValue";
import { chkNickName, makeInsertTimeStr } from "../../../../../util/Global";
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, AdminAccessResult, checkAbleManageAdminPage, makeAdminManageData } from "../../../../../util/AdminAccessHelper";
import { PAGE_ADMIN_NOTICE, PAGE_URL, makeUrl } from "../../../../../util/Page";


import { AiFillCalendar } from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko'; 
var local : any = ko;
registerLocale('ko', local);

const SELECT_CATEGORY_ALL = -1;

const BodyComponent = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {postType, noticeData, categoryIdx, page} = props;
    const [bodyStr, setBodyStr] = useState("");
    const [imageDataList, setImageDataList] = useState<UploadImageData[]>(getDefaultImageDataList());
    const [titleStr, setTitleStr] = useState("");
    const [nickNameStr, setNickNameStr] = useState("");
    const [requestingSave, setRequestingSave] = useState(false);

    const [selectPostType, setSelectPostType] = useState<any>(API_TYPES.POST_TYPE.ALL);
    const [selectCategory, setSelectCategory] = useState<any>(API_TYPES.POST_TYPE.ALL);
    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

	const loginData = useSelector((state:RootState)=>state.data.loginData);
    
    const getBodyPlaceHolder = () => {
        var text = "공지";
        text += "를 작성해 주세요.";
        return text;
    }

	useEffect(()=>{
        if( noticeData ) {
            setNickNameStr( noticeData.nickname );
            setTitleStr( noticeData.title );
            setBodyStr(noticeData.body);
            setPostImageDataList( noticeData );

            var d = new Date();
            d.setTime(noticeData.start * 1000);
            setStartDate( d );

            d = new Date();
            d.setTime(noticeData.end * 1000);
            setEndDate( d );
        }
        else if( loginData ) {
            setNickNameStr( loginData.nickname );
        }
		return(()=>{})
	},[loginData, noticeData]);


    const setPostImageDataList = ( noticeData : any ) => {
        var newList : UploadImageData[] = [...getDefaultImageDataList()];
        
        if( noticeData.image01 ) newList[0].url = noticeData.image01;
        if( noticeData.image02 ) newList[1].url = noticeData.image02;
        if( noticeData.image03 ) newList[2].url = noticeData.image03;
        if( noticeData.image04 ) newList[3].url = noticeData.image04;
        if( noticeData.image05 ) newList[4].url = noticeData.image05;
        if( noticeData.image06 ) newList[5].url = noticeData.image06;
        if( noticeData.image07 ) newList[6].url = noticeData.image07;
        if( noticeData.image08 ) newList[7].url = noticeData.image08;
        if( noticeData.image09 ) newList[8].url = noticeData.image09;
        if( noticeData.image10 ) newList[9].url = noticeData.image10;

        setImageDataList(newList);
    }


    const clickedSave = async () => {

        if( requestingSave ) return;

        var msg = checkDataMsg();
        if( msg ) {
            alert( msg );
            return;
        }
        
        setRequestingSave(true);

        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setRequestingSave(false);
                alert("저장완료");

                                
                var params : PAGE_ADMIN_NOTICE = {
                    tab         :null, 
                    year        :null,
                    page        :page==-1?1:page,
                    search      :null, 
                    searchType  :null,
                    noticeState :null,
                    postType    :null,
                    category    :null,
                }

                var url = makeUrl(PAGE_URL.ADMIN_NOTICE, params);
                navigate(-1);
                navigate(url, {replace:true});
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("찾을 수 없습니다");
				}
                setRequestingSave(false);
			}
		}


        
        if( noticeData ) {
            var updateParam = await makeParamUpdate();
            apiAxios.admin.dataNoticeUpdate(dispatch, updateParam, callback);
        }
        else {
            apiAxios.admin.dataNoticeAdd(dispatch, makeParamAdd(), callback);
        }

    }

    const checkDataMsg = () => {

        // 공지사항 전체관리가 아닌경우
        
        
        if( loginData.admin_type != API_TYPES.ADMIN_TYPE.TOP && loginData.access != API_TYPES.MANAGE_ACCESS.ALL ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) < 0 ) {
                if( selectPostType == API_TYPES.POST_TYPE.ALL ) {  return "게시글 타입을 선택하세요";  }
                // 사연 부분 관리인데 카테고리를 지정안했을 때
                else if( selectPostType == API_TYPES.POST_TYPE.STORY &&
                        loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) < 0 &&
                        selectCategory == SELECT_CATEGORY_ALL ) { 
                            return "카테고리를 선택하세요"; 
                }
                // 커뮤니티 부분 관리인데 카테고리를 지정안했을 때
                else if( selectPostType == API_TYPES.POST_TYPE.COMMUNITY &&
                    loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) < 0 &&
                    selectCategory == SELECT_CATEGORY_ALL ) { 
                        return "카테고리를 선택하세요"; 
                }
            }
        }

        if( !titleStr )     { return "제목을 입력하세요"; }
        if( !bodyStr ) {
            var haveImage = false;
            for( var i=0; i<imageDataList.length; i++ ) {
                var data : UploadImageData = imageDataList[i];
                if( data.file || (data.url&&!data.deleteUrl) ) {
                    haveImage = true;
                    break;
                }
            }

            if( haveImage == false ) {
                return "내용을 입력하세요";  
            }   
        }

        return null;
    }

    const makeParamAdd = () => {

        var thumbnail : any = null;
        for( var i=0; i<imageDataList.length; i++ ) {
            if( !!imageDataList[i].preView ) {
                thumbnail = imageDataList[i].preView?.file;
                break;
            }
        }

        var param : ParamAdminDataNoticeAdd = {
            post_type:postType,
            cate_idx:selectCategory,
            start:makeStartEndSecondsTime(startDate, true),
            end:makeStartEndSecondsTime(endDate, false),
            title:titleStr,
            body:bodyStr,
            thumbnail:thumbnail,
            image_file_01:imageDataList[0].file,
            image_file_02:imageDataList[1].file,
            image_file_03:imageDataList[2].file,
            image_file_04:imageDataList[3].file,
            image_file_05:imageDataList[4].file,
            image_file_06:imageDataList[5].file,
            image_file_07:imageDataList[6].file,
            image_file_08:imageDataList[7].file,
            image_file_09:imageDataList[8].file,
            image_file_10:imageDataList[9].file
        }
        return param;
    }

    const makeParamUpdate = async () => {

        var uploadDataList : UploadImageData[] = reMakeUploadImageDataList(imageDataList);

        var thumbnail : any = null;
        var delete_thumbnail : any = null;

        // 기존의 첫번째 이미지와 다르다면
        var firstUrl = getUrlFromImageData(uploadDataList[0]);
        if( firstUrl != noticeData.image01 ) {
            delete_thumbnail = noticeData.thumbnail;

            for( var i=0; i<uploadDataList.length; i++ ) {
                // 프리뷰가 존재하거나 이전의 주소가 있는경우
                if( uploadDataList[i].preView || getUrlFromImageData(uploadDataList[i]) ) {
                    if( uploadDataList[i].preView ) {
                        thumbnail = uploadDataList[i].preView?.file;
                    }
                    else {
                        const url = getUrlFromImageData(uploadDataList[i])
                        const image : ImageData = await makePreViewFile(url);
                        thumbnail = image.file;
                    }
                    break;
                }
            }
        }


        var param : ParamAdminDataNoticeUpdate = {
            idx:noticeData.idx,
            post_type:postType,
            cate_idx:selectCategory,
            start:makeStartEndSecondsTime(startDate, true),
            end:makeStartEndSecondsTime(endDate, false),
            title:titleStr,
            body:bodyStr,
            thumbnail:thumbnail,
            delete_thumbnail:delete_thumbnail,
            image_file_01:uploadDataList[0].file,
            image_file_02:uploadDataList[1].file,
            image_file_03:uploadDataList[2].file,
            image_file_04:uploadDataList[3].file,
            image_file_05:uploadDataList[4].file,
            image_file_06:uploadDataList[5].file,
            image_file_07:uploadDataList[6].file,
            image_file_08:uploadDataList[7].file,
            image_file_09:uploadDataList[8].file,
            image_file_10:uploadDataList[9].file,
            image_url_01:getUrlFromImageData(uploadDataList[0]),
            image_url_02:getUrlFromImageData(uploadDataList[1]),
            image_url_03:getUrlFromImageData(uploadDataList[2]),
            image_url_04:getUrlFromImageData(uploadDataList[3]),
            image_url_05:getUrlFromImageData(uploadDataList[4]),
            image_url_06:getUrlFromImageData(uploadDataList[5]),
            image_url_07:getUrlFromImageData(uploadDataList[6]),
            image_url_08:getUrlFromImageData(uploadDataList[7]),
            image_url_09:getUrlFromImageData(uploadDataList[8]),
            image_url_10:getUrlFromImageData(uploadDataList[9]),
            delete_url_01:uploadDataList[0].deleteUrl,
            delete_url_02:uploadDataList[1].deleteUrl,
            delete_url_03:uploadDataList[2].deleteUrl,
            delete_url_04:uploadDataList[3].deleteUrl,
            delete_url_05:uploadDataList[4].deleteUrl,
            delete_url_06:uploadDataList[5].deleteUrl,
            delete_url_07:uploadDataList[6].deleteUrl,
            delete_url_08:uploadDataList[7].deleteUrl,
            delete_url_09:uploadDataList[8].deleteUrl,
            delete_url_10:uploadDataList[9].deleteUrl,
        }
        return param;
    };

    const makeStartEndSecondsTime = ( date:Date, is_start:boolean ) => {
        var newDate = new Date();
        newDate.setTime(date.getTime());
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0);

        if( is_start == false ) {
            newDate.setDate(newDate.getDate()+1);
            newDate.setSeconds( newDate.getSeconds() - 1 );
        }
        return Math.floor(newDate.getTime()/1000);
    }

    const reMakeUploadImageDataList = ( list : UploadImageData[] ) => {
        
        var beforeList = [...list];

        var newList : UploadImageData[] = [];
        var imageList : UploadImageData[] = [];
        var deleteUrlList : UploadImageData[] = [];


        for( var i=0; i<beforeList.length; i++ ) {
            var d : UploadImageData = beforeList[i];

            // 새 파일인 경우
            if( d.file ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.file = d.file;
                emptyData.preView = d.preView;
                imageList.push(emptyData);
            }
            // 삭제되지 않은 이미지 주소가 있는경우
            else if( d.url && !d.deleteUrl ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.url = d.url;
                imageList.push(emptyData);
            }

            // 삭제된 이미지가 있는 경우
            if( d.deleteUrl ) {
                var emptyData : UploadImageData = getEmptyImageData();
                emptyData.deleteUrl = d.deleteUrl;
                deleteUrlList.push(emptyData);
            }
        }

        // 이미지 추가
        for( var i=0; i<imageList.length; i++ ) {
            newList.push( imageList[i] );
        }

        // 빈데이터 추가
        var moreAddCount = 10 - imageList.length;
        for( var i=0; i<moreAddCount; i++ ) {
            newList.push(getEmptyImageData());
        }

        // 삭제 주소 추가
        for( var i=0; i<deleteUrlList.length; i++ ) {
            newList[i].deleteUrl = deleteUrlList[i].deleteUrl;
        }

        return newList;
    }

    const getUrlFromImageData = ( imageData : UploadImageData ) => {
        if( imageData.url && !imageData.blob && !imageData.file ) {
            return imageData.url;
        }
        return null;
    }

    return(
        <>
        <div className="write_notice">
            <Top {...props}/>
            <Title {...props}
                selectPostType={selectPostType}
                setSelectPostType={setSelectPostType}
                selectCategory={selectCategory}
                setSelectCategory={setSelectCategory}
                titleStr={titleStr}
                setTitleStr={setTitleStr}
                nickNameStr={nickNameStr}
                setNickNameStr={setNickNameStr}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                />
            <div className="body_textarea"><textarea value={bodyStr} placeholder={getBodyPlaceHolder()} onChange={(e)=> { setBodyStr(e.target.value); }}/></div>
            <ImageAreaComplete 
                {...props}
                imageDataList={imageDataList}
                setImageDataList={setImageDataList}
                />
        </div>
        <BottonButtonComponent 
            {...props}
            onSave={clickedSave}
            requestingSave={requestingSave}
            />
        </>
    )
}
export default BodyComponent;

function Top( props:any ) {
    const {idx} = props;

    var title = "공지사항";

    if( idx && idx > 0 ) {
        title += " 수정";
    }
    else {
        title += " 쓰기";
    }

    return(
        <div className="top">
            <span>{title}</span>
        </div>
    )
}

function Title( props:any ) {
    const {categoryIdx, 
        titleStr, setTitleStr, 
        nickNameStr, setNickNameStr, postType, noticeData, selectPostType, setSelectPostType, selectCategory, setSelectCategory, startDate, setStartDate, endDate, setEndDate} = props;
    
    const loginData = useSelector((state:RootState)=>state.data.loginData);

    const [categoryList, setCategoryList] = useState<any>(null);
    const [showStory, setShowStory] = useState(false);
    const [showCommunity, setShowCommunity] = useState(false);
    const [categoryErrMsg, setCategoryErrMsg] = useState("");

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

	useEffect(()=>{
        resetCategoryList(postType);
        setSelectPostType(postType);
		return(()=>{})
	},[postType, storyCategoryList, communityCategoryList]);

	useEffect(()=>{
    
        const story = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.STORY)).accessType == ADMIN_ACCESS_TYPE.ALL;
        const community = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.COMMUNITY)).accessType == ADMIN_ACCESS_TYPE.ALL;
        
        setShowStory( story );
        setShowCommunity( community );

		return(()=>{})
	},[loginData]);


	useEffect(()=>{

        var errMsg = "";
        if( loginData && loginData.admin_type != API_TYPES.ADMIN_TYPE.TOP && loginData.access != API_TYPES.MANAGE_ACCESS.ALL ) {
            if( loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) < 0 ) {
                if( selectPostType == API_TYPES.POST_TYPE.ALL ) { 
                    errMsg = "게시글 타입을 선택하세요";  
                }
                // 사연 부분 관리인데 카테고리를 지정안했을 때
                else if( selectPostType == API_TYPES.POST_TYPE.STORY &&
                        loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) < 0 &&
                        selectCategory == SELECT_CATEGORY_ALL ) { 
                            errMsg = "카테고리를 선택하세요"; 
                }
                // 커뮤니티 부분 관리인데 카테고리를 지정안했을 때
                else if( selectPostType == API_TYPES.POST_TYPE.COMMUNITY &&
                    loginData.access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) < 0 &&
                    selectCategory == SELECT_CATEGORY_ALL ) { 
                        errMsg = "카테고리를 선택하세요"; 
                }
            }
        }
        setCategoryErrMsg(errMsg);
    
		return(()=>{})
	},[loginData, selectPostType, selectCategory]);


    const resetCategoryList = ( type:any ) => {

        if( type == API_TYPES.POST_TYPE.COMMUNITY ) {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            
            var result : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.COMMUNITY_LIST));
            var categorys : any = result.accessType == ADMIN_ACCESS_TYPE.PART?result.categorys:null;
            var c_list = categorys?"_"+categorys+"_":null;
        
            var newCate : any = communityCategoryList.filter( 
                (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST 
                && (c_list == null || c_list.indexOf("_"+i.idx+"_") > -1 )
            );
            newList.push(...newCate);
            setSelectCategory( categoryIdx );
            setCategoryList(newList);
        }
        else if( type == API_TYPES.POST_TYPE.STORY ) {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            
            var result : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.STORY_LIST));
            var categorys : any = result.accessType == ADMIN_ACCESS_TYPE.PART?result.categorys:null;
            var c_list = categorys?"_"+categorys+"_":null;

            var newCate : any = storyCategoryList.filter( 
                (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST 
                && (c_list == null || c_list.indexOf("_"+i.idx+"_") > -1 )
            );
            newList.push(...newCate);
            setSelectCategory( categoryIdx );
            setCategoryList(newList);
        }
        else {
            var newList = [];
            newList.push({idx:SELECT_CATEGORY_ALL,name:"카테고리선택"});
            setSelectCategory( SELECT_CATEGORY_ALL );
            setCategoryList(newList);
        }
    }

    return(
        <div className="title">
            <div>
                <div className="left">카테고리</div>
                <div className="right">
                    
                    <select className={"select" + (!!noticeData?" disable":"")}  value={selectPostType} disabled={!!noticeData}
                        onChange={(e)=> {
                            setSelectPostType(e.target.value);  

                            resetCategoryList(e.target.value);
                        }}>
                        <option value={API_TYPES.POST_TYPE.ALL}>타입선택</option>
                        { showStory && <option value={API_TYPES.POST_TYPE.STORY}>사연</option> }
                        { showCommunity && <option value={API_TYPES.POST_TYPE.COMMUNITY}>커뮤니티</option> }
                    </select>
                    
                    <select className={"select" + (selectPostType==API_TYPES.POST_TYPE.ALL?" disable":"")} style={{marginLeft:10, width:150}} value={selectCategory} disabled={selectPostType==API_TYPES.POST_TYPE.ALL}
                        onChange={(e)=> {
                            setSelectCategory(e.target.value);  
                        }}>
                        
                        { categoryList && categoryList.map((item:any, index:number)=>{
                            return(<option value={item.idx} key={index}>{item.name}</option>)
                        })}
                    </select>
                    
                    { !!categoryErrMsg && 
                        <div className="error_msg">
                            <span>{categoryErrMsg}</span>
                        </div>
                    }
                </div>
            </div>
            <div>
                <div className="left">시작/종료</div>
                <div className="right">
                    

                    <div className={'date'}>
                        <input className={'input'} type='text' value={makeInsertTimeStr(startDate, true)+""} onChange={(e)=> {}} />
                        <div className='unit'>
                            <DatePicker className="datepicker" selected={startDate} onChange={(date:Date) => {
                                    if( checkAbleDate( date , endDate) )   setStartDate(date);
                                    else alert("시작날짜는 종료날짜보다 이후일 수 없습니다");
                                } }
                                locale="ko"
                                popperPlacement="top-end"
                                customInput={<ExampleCustomInput />}/>
                        </div>
                    </div>    

                    <div className={'date'}>
                        <input className={'input'} type='text' value={makeInsertTimeStr(endDate, true)+""} onChange={(e)=> {}} />
                        <div className='unit'>
                            <DatePicker className="datepicker" selected={endDate} onChange={(date:Date) => {
                                    if( checkAbleDate( startDate , date) )   setEndDate(date);
                                    else alert("종료날짜는 시작날짜보다 이전일 수 없습니다");
                                }} 
                                locale="ko"
                                popperPlacement="top-end"
                                customInput={<ExampleCustomInput />}/>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="left">제목</div>
                <div className="right"><input type="text" className="input_title" value={titleStr} onChange={(e)=> { setTitleStr(e.target.value); }}/></div>
            </div>
        </div>
    )
}

const checkAbleDate = ( startDate : Date , endDate : Date ) => {
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    endDate.setHours(0);
    endDate.setMinutes(0);
    endDate.setSeconds(1);

    return startDate.getTime() < endDate.getTime();
}

/* 달력용 input */
const ExampleCustomInput = forwardRef(
  ( props : any, ref:any ) => (
    <div className={ "datepicker-custom-input-disabled"} style={{marginTop:5}} onClick={props.onClick} ref={ref}>
      <AiFillCalendar size={20}/>
    </div>
  ),
);

function ImageAreaComplete( props:any ) {
    const {imageDataList, setImageDataList} = props;

	const [loadingImage, setLoadingImage] = useState(false);
	const fileRef : any = useRef();

    const checkAbleGetImage = () => {
        var ablePosition = -1;

        for( var i=0; i<imageDataList.length; i++ ) {
            var d : UploadImageData = imageDataList[i];
            if( !d.blob && ( !d.url || ( d.url && d.deleteUrl ) ) ) {
                ablePosition = i;
                break;
            }
        }
        return ablePosition;
    }

    const clickedGetImage = () => {
        if( checkAbleGetImage() != -1 ) {
            document.getElementById("image_box_input")?.click();
        }
        else {
            alert("이미지는 최대 10개까지 추가 가능합니다");
        }
    }

	const onSelectFile = async (e : any) => {
		console.log("onSelectFile");
		setLoadingImage(true);
        setLoadingInImageData();

		getImageFile(e, (responseImage : ResponseImage)=>{
			//document.getElementById(uniqId)?.value = null;
			console.log("getImageFile receive");

			if( responseImage && responseImage.image ) {
				setImageData(responseImage);
			}
					
			if( fileRef && fileRef.current ) {
				fileRef.current.value = "";
			}
			setLoadingImage(false);
		})
	}

    const setLoadingInImageData = () => {
        var pos : number = checkAbleGetImage();
        var newList = imageDataList.map((i:UploadImageData, index:number)=>{
            if( index == pos ) {
                i.loading = true;
            }
            return i;
        })
        setImageDataList(newList);
    }

    const setImageData = ( responseImage : ResponseImage ) => {
        var newList = imageDataList.map((i:UploadImageData, index:number)=>{
            if( i.loading == true ) {
                i.loading = false;
                
                if( responseImage.image ) {
                    i.blob = responseImage.image.blob;
                    i.file = responseImage.image.file;
                }
                if( responseImage.preView ) {
                    i.preView = responseImage.preView;
                }
            }
            return i;
        })
        setImageDataList(newList);
    }

    return(
        <div className="image_area">
            <div className="title">
                <div className="title_str"><span>이미지</span></div>
                <div className="btn" onClick={clickedGetImage}><span>가져오기</span></div>
            </div>
            <div className="image_box">
                {
                    imageDataList.map((item:UploadImageData,index:number)=>{
                        
                        var url = item.blob?item.blob:!item.deleteUrl&&item.url?item.url:null;

                        const clickedDelete = () => {
                            var newList = imageDataList.map((i:UploadImageData, idx:number)=>{
                                if( index == idx ) {
                                    if( i.url ) i.deleteUrl = i.url;
                                    i.blob = null;
                                    i.file = null;
                                    i.preView = null;
                                }
                                return i;
                            })
                            setImageDataList(newList);
                            console.log("clickedDelete " , newList);
                        }

                        return( 
                            <div>
                                { !item.loading && url && <>
                                    <img src={url}/>
                                    <div className="delete" onClick={clickedDelete}>
                                        <AiFillCloseCircle size={20}/>
                                    </div>
                                </>}
                                
                                { item.loading &&
                                    <div className='loader'>
                                        <Oval 
                                            color="#00f" 
                                            height={20} 
                                            width={20}
                                            />
                                    </div>
                                }
                            </div> 
                        )
                    })
                }
            </div>
            <input
                ref={fileRef}
                type='file'
                id='image_box_input'
                name='images'
                hidden={true}
                onChange={onSelectFile}
                accept={INPUT_ACCEPT_TYPE}
				/>
        </div>
    )
}

function BottonButtonComponent(props:any) {
    const {onSave, requestingSave} = props;
	const navigate = useNavigate();
    
    const clickedCancel = () => {
        navigate(-1);
    }

    return(
        <div className='write_notice_btn_box'>
            <div className='save' onClick={onSave}>  
                { !requestingSave && <span>확인</span> }
                { requestingSave && 
                    <div className='loader'>
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                    </div> 
                }
            </div> 
            <div className='cancel' onClick={clickedCancel}>  <span>취소</span> </div> 
        </div>
    )
}

function getEmptyImageData() {
    return {
        url:null, 
        blob:null, 
        file:null,
        deleteUrl:null,
        loading:false,
        preView:null
    };
}

function getDefaultImageDataList() {
    const list : UploadImageData[] = [];
    for( var i=0; i<10; i++ ) {
        list.push(getEmptyImageData());
    }

    return list;
}

interface UploadImageData extends ImageData {
    deleteUrl:any,
    loading:boolean
    preView:ImageData|null
}