/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../sub/RightArea';
import LeftAd from '../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import CommunityBestAreaComponent from './sub/CommunityBestAreaComponent';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import CommunityPostListComponent from './sub/CommunityPostListComponent';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { RootState } from '../../redux_saga/modules';
import Wrapper from '../sub/Wrapper';
import Adsense, { AD_PAGE_POSITION, AdType } from '../sub/Adsense';

const Community = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [currentCategory, setCurrentCategory] = useState(API_TYPES.CATEGORY_TYPE.ALL);
	
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
	
	useEffect(()=>{
		console.log("Community useEffect");

		const l_p = new URLSearchParams(location.search);
		const c = l_p.get("category");
		if( c ) {
			try{ setCurrentCategory( parseInt(c) ); }catch(e){ console.log("e",e);}
		}
		else{
			setCurrentCategory( API_TYPES.CATEGORY_TYPE.ALL );
		}

		reqCategory();
		
		return(()=>{})
	},[location]);

	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
	}

	return (
		<Wrapper
			tabType={TAB_TYPE.COMMUNITY}
			rightCategoryData={
				!!communityCategoryList?{
					type:API_TYPES.POST_TYPE.COMMUNITY,
					currentCategory:!!currentCategory?currentCategory:API_TYPES.CATEGORY_TYPE.ALL ,
					list:communityCategoryList
				}
				:null
			}>
			<MiddleComponent {...props} currentCategory={currentCategory}/>
		</Wrapper>
	);
};

export default Community;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} marginBottom={30}/>
			<CommunityBestAreaComponent {...props}/>
			<CommunityPostListComponent {...props}/>
		</div>
	)
}

function TopAdComponent( props:any ) {
	const { currentCategory } = props;
	const [youtubeChannel, setYoutubeChannel] = useState<string|null>(null);
	const [bannerUrl, setBannerUrl] = useState<string|null>(null);
	
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);


    useEffect(()=>{

		if( !!communityCategoryList ) {
			var categoryData = communityCategoryList.filter( (i:any) => i.idx == currentCategory );
			if( !!categoryData && categoryData.length > 0 ) {
				const d = categoryData[0];
				if( !!d.channel && !!d.banner ) {
					setYoutubeChannel(d.channel);
					setBannerUrl(d.banner);
				}
				else {
					setYoutubeChannel(null);
					setBannerUrl(null);
				}
			}
		}

        return(()=>{})
    },[communityCategoryList]);

	if( !!bannerUrl ) {
		return(
			<div className='top_banner'>
				<img src={bannerUrl}/>
				<div onClick={()=>{
					if( !!youtubeChannel ) {
						window.open(youtubeChannel, "_blank");
					}
				}}/>
			</div>
		)
	}

	return(
        <Adsense 
            {...props}
            className='top_ad'
            type={AdType.WEB_TOP}
			pagePosition={AD_PAGE_POSITION.COMMUNITY_TOP}
            />
	)
}