/****************************************************************************************
 * 
 *  상단 탭
 *  
 *  홈 , 사연 , 커뮤니티
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

export interface OptionData {
    value:number
    name:string
}

export const FILTER_VALUE_ALL = -1;
export const ALL_FilTER_DATA : OptionData = {value:FILTER_VALUE_ALL, name:"전체"};

function AdminSelectBox( props : any ) {
    const {options, title, className, selectValue, SetSelectValue} = props;

    return (
        <div className={'admin_select_area' + (className?" " + className:"")}>
            { !!title && 
                <div className='title'>{title}</div>
            }
            { !!options && options.length > 0 && 
                <select className="select"  value={selectValue} 
                    onChange={(e)=> {
                        SetSelectValue(e.target.value);  
                    }}>
                    
                    { options.map((item:OptionData, index:number)=>{
                        return(
                            <option value={item.value} key={index}>{item.name}</option>
                        )
                    })}
                </select>
            }
        </div>
    )
}

export default AdminSelectBox;
