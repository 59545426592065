import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import apiAxios, { API_TYPES, API_UTILS, ParamAdminDataStandardUserLevelUpdate } from "../../../../apiAxios";
import ButtonDefault from "../../../sub/ButtonDefault";
import { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { isNumber } from "../../../../util/Global";
import { useNavigate } from "react-router-dom";
import { ADMIN_MANAGE_TYPE, checkAccessRePage, makeAdminManageData } from "../../../../util/AdminAccessHelper";

function SystemSetting( props:any ) {
	const dispatch = useDispatch();
    const [isCheckPassword, setIsCheckPassword] = useState(false);
    const [settings, setSettings] = useState<any>(null);
    const [levels, setLevels] = useState<any>(null);

    const reqSystemData = () => {
        apiAxios.admin.dataSystemSettingData(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setLevels(resData.levels);
                setSettings(resData.settings);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    useEffect(()=>{
        if( isCheckPassword ) {
            reqSystemData();
        }
        return(()=>{})
    },[isCheckPassword]);

    return(
        <div className="admin_system_box">
            { isCheckPassword == false && 
                <CheckPwBox {...props} setIsCheckPassword={setIsCheckPassword}/> 
            }
            { isCheckPassword == true && <>
                <SystemBox {...props} settings={settings}/>
                <GradeBox {...props} levels={levels}/>
            </>}
        </div>
    )
}
export default SystemSetting;

function CheckPwBox( props:any ) {
    const {setIsCheckPassword} = props;

    const dispatch = useDispatch();

    const [pwStr, setPwStr] = useState("");
    const [requesting, setRequesting] = useState(false);

    const clicked = () => {
        if( !pwStr ) { alert("비밀번호를 입력하세요"); return; }

        setRequesting( true );
        apiAxios.admin.authCheckPw(dispatch, pwStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                
                setIsCheckPassword(true);
			}
			else if( error ) {
                alert("비밀번호를 잘못 입력하셨습니다");
			}
            setRequesting( false );
		})
    }

    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        clicked();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
      }
    }

    return(
        <>
            <div className={"password_box" + (requesting?" disable":"")}>
                <input 
                    type={"password"}
                    value={pwStr} 
                    placeholder="비밀번호를 입력하세요"
                    onChange={(e)=> { 
                        var text = e.target.value;
                        text = text.replace(/ /g, ''); // 공백제거
                        setPwStr(text); 
                    }} 
                    disabled={requesting?true:false}
                    onKeyUp={handleKeyUp}/>
            </div>

            <ButtonDefault 
                {...props}
                className="edit_btn"
                value={"확인"}
                onClick={clicked}
                isLoading={requesting}
                />
        </>
    )
}

function SystemBox( props:any ) {
    const {settings} = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    const dispatch = useDispatch();

    const [codeStr, setCodeStr] = useState("");
    const [bestPostStr, setBestPostStr] = useState("");
    const [bestReplyStr, setBestReplyStr] = useState("");
    const [reportStr, setReportStr] = useState("");

    useEffect(()=>{

        if( settings ) {
            for( var i=0; i<settings.length; i++ ) {
                var sData = settings[i];
                if( sData.type == API_TYPES.SETTING_DATA_TYPE.LOGIN_CODE ) { setCodeStr(sData.data); }
                if( sData.type == API_TYPES.SETTING_DATA_TYPE.BEST_POST ) { setBestPostStr(sData.data); }
                if( sData.type == API_TYPES.SETTING_DATA_TYPE.BEST_REPLY ) { setBestReplyStr(sData.data); }
                if( sData.type == API_TYPES.SETTING_DATA_TYPE.REPORT_HIDE ) { setReportStr(sData.data); }
            }
        }

        return(()=>{})
    },[settings]);

    const reqUpdateLoginCode = ( value : string, callback : any ) => { apiAxios.admin.dataLoginCodeUpdate(dispatch, !!value?parseInt(value):0, callback); }
    const reqUpdateBestPost = ( value : string, callback : any ) => { apiAxios.admin.dataStandardCountBestPostUpdate(dispatch, !!value?parseInt(value):0, callback); }
    const reqUpdateBestReply = ( value : string, callback : any ) => { apiAxios.admin.dataStandardCountBestReplyUpdate(dispatch, !!value?parseInt(value):0, callback); }
    const reqUpdateReportHide = ( value : string, callback : any ) => { apiAxios.admin.dataStandardCountHideReportUpdate(dispatch, !!value?parseInt(value):0, callback); }

    return(
        <>
            <div className="title">시스템 설정</div>
            <div className="system_box">
                <SystemBoxItem
                    {...props}
                    value={codeStr}
                    setValue={setCodeStr}
                    title={"관리자 로그인\n코드번호"}
                    placeholder={"코드번호입력"}
                    guide={"관리자 로그인때 입력하는 코드 번호"}
                    reqChange={reqUpdateLoginCode}/>
                <SystemBoxItem
                    {...props}
                    value={bestPostStr}
                    setValue={setBestPostStr}
                    title={"베스트글\n좋아요 개수"}
                    placeholder={"개수 입력"}
                    guide={"베스트글의 기준이 되는 좋아요 개수"}
                    reqChange={reqUpdateBestPost}/>
                <SystemBoxItem
                    {...props}
                    value={bestReplyStr}
                    setValue={setBestReplyStr}
                    title={"베스트 댓글\n좋아요 개수"}
                    placeholder={"개수 입력"}
                    guide={"베스트 댓글의 기준이 되는 좋아요 개수"}
                    reqChange={reqUpdateBestReply}/>
                <SystemBoxItem
                    {...props}
                    value={reportStr}
                    setValue={setReportStr}
                    title={"숨김처리\n신고 개수"}
                    placeholder={"개수 입력"}
                    guide={"숨김처리의 기준이 되는 신고 개수"}
                    reqChange={reqUpdateReportHide}/>
            </div>
        </>
    )
}

function SystemBoxItem( props:any ) {
    const {guide, title, value, setValue, reqChange, placeholder} = props;
    const [requesting, setRequesting] = useState(false);

    const clickedChange = () => {
        if( !value || value == 0 ) {
            alert(title+"를 입력하세요");
            return;
        }

        setRequesting(true);
        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("변경완료");
			}  
			else if( error ) {
                alert("변경실패");
			}
            setRequesting(false);
		}

        if( reqChange ) {
            reqChange( value, callback);
        }
    }

    return (
        <div className="item">
            <div>
                <div className="input_div">
                    <div className="title">{title}</div>
                    <input 
                        value={value} 
                        placeholder={placeholder}
                        onChange={(e)=> { 
                            var text = e.target.value;
                            text = text.replace(/ /g, ''); // 공백제거

                            if( isNumber(text) ) {
                                if( setValue ) setValue(text); 
                            }
                        }} 
                        disabled={requesting?true:false}/>
                </div>

                <ButtonDefault 
                    {...props}
                    className="button_div"
                    value={"변경"}
                    onClick={clickedChange}
                    isLoading={requesting}
                    />
            </div>
            <div className="guide">{"※ " + guide}</div>
        </div>
    )
}

function GradeBox( props:any ) {
    const {levels} = props;

    const dispatch = useDispatch();

	const loginData = useSelector((state:RootState)=>state.data.loginData);

    const [gradeData2, setGradeData2] = useState<GradeData>(getDefaultGradeData());
    const [gradeData3, setGradeData3] = useState<GradeData>(getDefaultGradeData());
    const [gradeData4, setGradeData4] = useState<GradeData>(getDefaultGradeData());
    const [gradeData5, setGradeData5] = useState<GradeData>(getDefaultGradeData());
    const [gradeData6, setGradeData6] = useState<GradeData>(getDefaultGradeData());

    useEffect(()=>{

        if( levels ) {
            for( var i=0; i<levels.length; i++ ) {
                var lData = levels[i];

                var gData : GradeData = {
                    best:lData.best, 
                    post:lData.post, 
                    reply:lData.reply, 
                    login:lData.login
                };
                if( lData.level == 2 ) { setGradeData2(gData); }
                if( lData.level == 3 ) { setGradeData3(gData); }
                if( lData.level == 4 ) { setGradeData4(gData); }
                if( lData.level == 5 ) { setGradeData5(gData); }
                if( lData.level == 6 ) { setGradeData6(gData); }
            }
        }

        return(()=>{})
    },[levels]);

    const makeParam = ( level:number, gradeData:GradeData ) => {
        var param : ParamAdminDataStandardUserLevelUpdate = {
            level:level,
            best:!!gradeData.best   ?parseInt(gradeData.best):0,
            post:!!gradeData.post   ?parseInt(gradeData.post):0,
            reply:!!gradeData.reply ?parseInt(gradeData.reply):0,
            login:!!gradeData.login ?parseInt(gradeData.login):0
        }
        return param;
    }

    const reqChange2 = ( gradeData:GradeData, callback : any ) => { apiAxios.admin.dataStandardUserLevelUpdate(dispatch, makeParam( 2, gradeData ), callback); }
    const reqChange3 = ( gradeData:GradeData, callback : any ) => { apiAxios.admin.dataStandardUserLevelUpdate(dispatch, makeParam( 3, gradeData ), callback); }
    const reqChange4 = ( gradeData:GradeData, callback : any ) => { apiAxios.admin.dataStandardUserLevelUpdate(dispatch, makeParam( 4, gradeData ), callback); }
    const reqChange5 = ( gradeData:GradeData, callback : any ) => { apiAxios.admin.dataStandardUserLevelUpdate(dispatch, makeParam( 5, gradeData ), callback); }
    const reqChange6 = ( gradeData:GradeData, callback : any ) => { apiAxios.admin.dataStandardUserLevelUpdate(dispatch, makeParam( 6, gradeData ), callback); }

    return(
        <>
            <div className="title">회원등급 기준 개수 설정</div>
            <ul className="grade_box">
                <li className="title">
                    <div className="level">레벨</div>
                    <div className="best">베스트</div>
                    <div className="post">글</div>
                    <div className="reply">댓글</div>
                    <div className="login">로그인</div>
                    <div className="change">변경</div>
                </li>
                <GradeBoxItem {...props} level={2} gradeData={gradeData2} setGradeData={setGradeData2} reqChange={reqChange2}/>
                <GradeBoxItem {...props} level={3} gradeData={gradeData3} setGradeData={setGradeData3} reqChange={reqChange3}/>
                <GradeBoxItem {...props} level={4} gradeData={gradeData4} setGradeData={setGradeData4} reqChange={reqChange4}/>
                <GradeBoxItem {...props} level={5} gradeData={gradeData5} setGradeData={setGradeData5} reqChange={reqChange5}/>
                <GradeBoxItem {...props} level={6} gradeData={gradeData6} setGradeData={setGradeData6} reqChange={reqChange6}/>
            </ul>
        </>
    )
}

function GradeBoxItem( props:any ) {
    const {level, gradeData, setGradeData, reqChange} = props;
    const [requesting, setRequesting] = useState(false);

    const setValue = ( params:string, value:string ) => {
        var newData = {...gradeData};
        newData[params] = value;

        if( isNumber(value) ) {
            setGradeData(newData);
        }
    }/*
    best:string,
    post:string,
    reply:string,
    login:string*/

    const clickedChange = () => {
        /*if( gradeData.best)
        if( !value || value == 0 ) {
            alert(title+"를 입력하세요");
            return;
        }*/

        setRequesting(true);
        const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("변경완료");
			}  
			else if( error ) {
                alert("변경실패");
			}
            setRequesting(false);
		}

        if( reqChange ) {
            reqChange( gradeData, callback);
        }
    }

    return(
        <li className="item">
            <div className="level">{level}</div>
            <div className="best"><input value={gradeData.best} onChange={(e)=> { setValue("best", e.target.value); }} disabled={requesting?true:false}/></div>
            <div className="post"><input value={gradeData.post} onChange={(e)=> { setValue("post", e.target.value); }} disabled={requesting?true:false}/></div>
            <div className="reply"><input value={gradeData.reply} onChange={(e)=> { setValue("reply", e.target.value); }} disabled={requesting?true:false}/></div>
            <div className="login"><input value={gradeData.login} onChange={(e)=> { setValue("login", e.target.value); }} disabled={requesting?true:false}/></div>
            <div className="change"><ButtonDefault 
                    {...props}
                    className="button_div"
                    value={"변경"}
                    onClick={clickedChange}
                    isLoading={requesting}
                    /></div>
        </li>
    )
}

function getDefaultGradeData() {
    return{
        best:"",
        post:"",
        reply:"",
        login:""
    };
}

interface GradeData {
    best:string,
    post:string,
    reply:string,
    login:string
}