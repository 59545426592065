/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../../apiAxios';
import { RootState } from '../../../../../redux_saga/modules';
import { PAGE_URL } from '../../../../../util/Page';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import ButtonDefault from '../../../../sub/ButtonDefault';
import MobileInputBox from '../sub/MobileInputBox';
import { BsPerson } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import MobileWrapper from '../../../sub/MobileWrapper';
import MobileCertification from '../../certification';
import { APP_INTERFACE_SEND_TYPE, sendDataToApp } from '../../../../../util/AppInterface';

const MobileFindId = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();    
    const loginData = useSelector((state:RootState)=>state.data.loginData);
	
	useEffect(()=>{
        if( loginData ) {
            navigate(PAGE_URL.MAIN, {replace:true});
        }

		return(()=>{})
	},[loginData]);

	var tabType = -1;

	return (<>
		<MobileWrapper
            hideNotice={true}
            hideTab={true}
            hideBottomCompanyInfo={true}
            subTitle={"아이디 찾기"}
			tabType={tabType}>
			<MiddleComponent 
				{...props} 
				/>
		</MobileWrapper>
		</>
	);
};

export default MobileFindId;

enum Tab {
    CERTIFICATION=1,
    EMAIL=2
}

const BTN_FONT_SIZE = 14;

function MiddleComponent( props:any ) {

    const [tab, setTab] = useState(Tab.CERTIFICATION);
    const [id, setId] = useState("");

    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
            <div className='mobile_find'>
                <div className='box'>
                    { !id && <>
                    <FindUseCertification 
                        {...props}
                        tab={tab}
                        setTab={setTab}
                        setId={setId}
                        />
                    <FindUseEmail 
                        {...props}
                        tab={tab}
                        setTab={setTab}
                        />
                    </>}
                    { !!id && 
                        <div className='id_result'>
                            <span className='title'>{"아이디"}</span>
                            <span className='id'>{id}</span>
                        </div>
                    }
                </div>
            </div>
		</div>
	)
}

function FindUseCertification( props:any ) {
    const {tab, setTab, setId} = props;
    const [showCertification, setShowCertification] = useState(false);
    const dispatch = useDispatch();
    
    const receiveMessage = (receiveData : any) => {
        // event.data에 전송된 데이터가 있음
        console.log('받은 데이터:', receiveData);

        if( receiveData ) {
          var resData : any = null;
          try{resData = JSON.parse(receiveData);}
          catch(e){}
          
          // 수신 성공
          if( resData && resData.state == 0 ) {
              if( resData.data && resData.data.code == 0 ) {
                  const responseData = resData.data.response;
                  console.log("responseData", responseData);

                  apiAxios.user.authFindId(dispatch, responseData.unique_key, null, null, (response:AxiosResponse|null, error:AxiosError|null) => {
                      if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                          const resData = API_UTILS.getResponseSuccessData(response);

                          setId( resData.id );
                          alert("아이디를 찾았습니다");
                      }
                      else if( error ) {
                          const errorData : any = API_UTILS.getErrorResponseData(error);
                          if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                              alert("가입한 계정이 없습니다");
                          }
                      }
                  })
              }
          }
          else if( resData && resData.state == -2 ) {
              alert("사용자 정보를 가지고 오지 못합니다.");
          }
        }
    };

    return(
        <div className='item'>
            <div className={'tab' + (tab==Tab.CERTIFICATION?" tab_focus":"")} onClick={()=>{setTab(Tab.CERTIFICATION);}}>
                <div className='checkbox'>
                    {tab==Tab.CERTIFICATION && <BsCheckSquareFill size={20}/>}
                    {tab!=Tab.CERTIFICATION && <BsCheckSquare size={20}/>}
                </div>
                <div className='tab_title'><span>본인인증으로 찾기</span></div>
            </div>

            {tab==Tab.CERTIFICATION && 
                <div className='item_body'>
                    <ButtonDefault
                        value={"본인인증"}
                        isLoading={!!showCertification}
                        fontSize={BTN_FONT_SIZE}
                        onClick={()=>{ 
                            setShowCertification( true );
						}}
                        />

                </div>
            }
        
            { showCertification && 
                <MobileCertification {...props}
                    onResult={receiveMessage}
                    show={showCertification}
                    setShow={setShowCertification}
                />
            }
        </div>
    )
}

function FindUseEmail( props:any ) {
    const {tab, setTab} = props;
    const dispatch = useDispatch();
	const navigate = useNavigate();

    const [nameStr, setNameStr] = useState("");
    const [emailStr, setEmailStr] = useState("");
    const [requesting, setRequesting] = useState(false);

    const clickFind = () => {
        if( requesting ) return;

        var errorMsg = checkInput();
        if( !!errorMsg ) {
            alert(errorMsg);
            return;
        }

        setRequesting(true);
        
        apiAxios.user.authFindId(dispatch, null, nameStr, emailStr, (response:AxiosResponse|null, error:AxiosError|null) => {
            if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
                const resData = API_UTILS.getResponseSuccessData(response);
                alert(resData.email + "로\n아이디 찾기 결과를 전송했습니다");

                setNameStr("");
                setEmailStr("");
				navigate(PAGE_URL.MAIN, {replace:true});
            }
            else if( error ) {
                const errorData : any = API_UTILS.getErrorResponseData(error);
                if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("계정을 찾을 수 없습니다");
                }
            }
            setRequesting(false);
        })
    }

    const checkInput = () => {
        if( !nameStr ) return "이름을 입력하세요";
        if( !emailStr ) return "이메일을 입력하세요";
        return null;
    }

    return(
        <div className='item second'>
            <div className={'tab' + (tab==Tab.EMAIL?" tab_focus":"")} onClick={()=>{setTab(Tab.EMAIL);}}>
                <div className='checkbox'>
                    {tab==Tab.EMAIL && <BsCheckSquareFill size={20}/>}
                    {tab!=Tab.EMAIL && <BsCheckSquare size={20}/>}
                </div>
                <div className='tab_title'><span>이메일로 찾기</span></div>
            </div>

            {tab==Tab.EMAIL && 
                <div className='item_body'>
                    <MobileInputBox 
                        disabled={!!requesting}
                        placeholder={"이름 입력"}
                        leftIcon={<BsPerson size={20}/>}
                        value={nameStr}
                        setValue={(text:string)=>{
                            text = text.replace(/ /g, ''); // 공백제거
                            setNameStr(text); 
                        }}
                        />
                    <MobileInputBox 
                        disabled={!!requesting}
                        placeholder={"이메일 입력"}
                        leftIcon={<BsEnvelope size={20}/>}
                        value={emailStr}
                        setValue={(text:string)=>{
                            text = text.replace(/ /g, ''); // 공백제거
                            setEmailStr(text); 
                        }}
                        />
                    <ButtonDefault
                        value={"찾기"}
                        onClick={clickFind}
                        isLoading={requesting}
                        fontSize={BTN_FONT_SIZE}
                        />
                </div>
            }
        </div>
    )
}

