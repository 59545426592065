/****************************************************************************************
 * 
 *  상단 탭
 *  
 *  홈 , 사연 , 커뮤니티
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";

export interface FilterOptionData {
    value:number
    name:string
}

function AdminSearchBox( props : any ) {
    const {filterOption, placeholder, onSearch, searchSelect, SetSearchSelect, defaultSearchText} = props;
	
    const navigate = useNavigate();
    
    const [searchInputValue, setSearchInputValue] = useState("");
    const [searchText, setSearchText] = useState("");
    
    
	useEffect(()=>{
        setSearchText(defaultSearchText);

        return(()=>{})
    },[defaultSearchText]);

    const onClickSearch = () => {
        if( !searchInputValue ) {
            alert("검색어를 입력하세요");
            return;
        }
        if( onSearch ) onSearch( searchInputValue );

        if( !!searchInputValue && searchInputValue != "" ) {
            setSearchText( searchInputValue );
        }
    }

    const onClickCancel = () => {
        setSearchText("");
        setSearchInputValue("");
        if( onSearch ) onSearch( "" );
    }
  
    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        onClickSearch();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
        onClickCancel();
      }
    }

    return (
        <div className='admin_search_area'>
            { !!filterOption && filterOption.length > 0 && 
                <select className="select"  value={searchSelect} 
                    onChange={(e)=> {
                        SetSearchSelect(e.target.value);  
                    }}>
                    
                    { filterOption.map((item:FilterOptionData, index:number)=>{
                        return(
                            <option value={item.value} key={index}>{item.name}</option>
                        )
                    })}
                </select>
            }

            <input type='search' placeholder={placeholder?placeholder:"검색"} className="search_input" name='admin_id' autoComplete="off"
                value={searchInputValue} 
                onChange={(e)=> {
                    setSearchInputValue(e.target.value);
                }}
                onKeyUp={handleKeyUp} 
            />
            { !!searchText &&
                <div className='search_btn_area' onClick={onClickCancel}>
                    <AiOutlineClose className='search_btn' size={20}/>
                </div>
            }
            { !searchText &&
            <div className='search_btn_area' onClick={onClickSearch}>
                <AiOutlineSearch className='search_btn' size={20}/>
            </div>
            }
        </div>
    )
}

export default AdminSearchBox;
