/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import NoticeList from './sub/NoticeList';
import { PAGE_NOTICE_POST_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import { RootState } from '../../../redux_saga/modules';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, AdminAccessResult, AdminNoticeAccessResult, checkAbleManageAdminPage, getAbleManageAdminNoticeListData, makeAdminManageData } from '../../../util/AdminAccessHelper';

const AdminNotice = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(()=>{
		
		return(()=>{})
	},[]);

	const openPostPage = ( idx : number, post_type:number, category:number) => {

		
		var fullUrl = "";
		const params : PAGE_NOTICE_POST_PARAMS = {
			idx:idx,
			post_type:post_type, 
			category:category
		}

		fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
		window.open(fullUrl);
		//navigate(fullUrl);
	}

	return (
		<Wrapper
			hideSubTab={true}
			tabType={ADMIN_TAB_TYPE.NOTICE}>
			<MiddleComponent {...props} openPostPage={openPostPage}/>
		</Wrapper>
	);
};

export default AdminNotice;


function MiddleComponent( props:any ) {

	const loginData = useSelector((state:RootState)=>state.data.loginData);

	const accessResult : AdminNoticeAccessResult = getAbleManageAdminNoticeListData(loginData);

	return(
		<div className='middle_area'>
			<NoticeList {...props} accessResult={accessResult}/>
		</div>
	)
}