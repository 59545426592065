import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Main from './component/main';
import NotFound from './component/NotFound';
import { isMobile } from 'react-device-detect';
import './css/App.css';
import './css/MobileApp.css';

import Search from './component/search';
import Story from './component/story';
import Community from './component/community';
import Notice from './component/notice';
import NoticePost from './component/notice/post';
import Inquiry from './component/my/inquiry';
import InquiryPost from './component/my/inquiry/post';

import UserInfo from './component/my/user_info';
import MyPost from './component/my/post';
import Post from './component/post';
import WritePost from './component/write/post';
import WriteInquiry from './component/write/inquiry';

import Signup from './component/auth/signup';
import Certification from './component/auth/certification';
import FindId from './component/auth/find/id';
import FindPw from './component/auth/find/pw';
import Personal from './component/personal';

import { useDispatch, useSelector } from 'react-redux';
import reduxActions from './redux_saga/modules/moduleActions';
import { PAGE_POST_PARAMS, PAGE_URL, makeUrl } from './util/Page';
import { getCookieLoginData, setCookieLoginData } from './util/cookie_helper';
import AdminMain from './component/admin/main';
import MobileMain from './component/mobile/main';
import MobileStory from './component/mobile/story';
import MobileCommunity from './component/mobile/community';
import MobilePost from './component/mobile/post';
import MobileNotice from './component/mobile/notice';
import MobileNoticePost from './component/mobile/notice/post';
import MobilePersonal from './component/mobile/personal';
import MobileSearch from './component/mobile/search';
import MobileLogin from './component/mobile/auth/login';
import MobileInquiry from './component/mobile/my/inquiry';
import MobileMyPost from './component/mobile/my/post';
import MobileInquiryPost from './component/mobile/my/inquiry/post';
import MobileUserInfo from './component/mobile/my/user_info';
import MobileWritePost from './component/mobile/write/post';
import MobileWriteInquiry from './component/mobile/write/inquiry';
import MobileSignup from './component/mobile/auth/signup';
import MobileFindId from './component/mobile/auth/find/id';
import MobileFindPw from './component/mobile/auth/find/pw';

import AdminStory from './component/admin/story';
import AdminCommunity from './component/admin/community';
import AdminNotice from './component/admin/notice';
import AdminAccount from './component/admin/account';
import AdminInquiry from './component/admin/inquiry';
import AdminSetting from './component/admin/setting';
import AdminNoticeWrite from './component/admin/notice/write';
import AdminInquiryPost from './component/admin/inquiry/post';
import { APP_INTERFACE_RECEIVE_DATA, APP_INTERFACE_RECEIVE_TYPE, APP_INTERFACE_SEND_TYPE, APP_INTERFACE_LOGIN_DATA, APP_INTERFACE_LOGOUT_DATA, MobileListener, removeMobileListener, sendDataToApp, setMobileListener } from './util/AppInterface';
import apiAxios, { API_TYPES, API_UTILS } from './apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './redux_saga/modules';
import { getParamData } from './util/Global';
import PopupMoveApplication from './component/popup/PopupMoveApplication';
import MobileBlockUser from './component/mobile/my/block';
import BlockUser from './component/my/block';

const App = () => {
	const dispatch = useDispatch();

	useEffect(()=>{
		document.title = "모여봐";

		const loginData = getCookieLoginData();
		if( loginData ) reduxActions.data.setLoginData(dispatch, loginData);
		
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
		reduxActions.axios.ReqDataAdminEmail(dispatch, reqData);

		if( isMobile ) { reduxActions.data.setMobileOpenUseEffect(dispatch, true); }
		return ()=>{
			reduxActions.data.setInit(dispatch);
		}
	},[])

	console.log("App!!!!!!!!!!");
  
	return (
		<div className='App'>
			<BrowserRouter>
				{ /* 모바일 화면 */ isMobile &&
					<Routes>
						<Route path={PAGE_URL.MAIN} 		element={<MobileMain />}></Route>
						<Route path={PAGE_URL.SEARCH} 		element={<MobileSearch />}></Route>
						<Route path={PAGE_URL.STORY} 		element={<MobileStory />}></Route>
						<Route path={PAGE_URL.COMMUNITY}	element={<MobileCommunity />}></Route>
						<Route path={PAGE_URL.NOTICE}  		element={<MobileNotice />}></Route>
						<Route path={PAGE_URL.NOTICE_POST}  element={<MobileNoticePost />}></Route>
						<Route path={PAGE_URL.MY_INQUIRY}  		element={<MobileInquiry />}></Route>
						<Route path={PAGE_URL.MY_INQUIRY_POST}  element={<MobileInquiryPost />}></Route>
						<Route path={PAGE_URL.MY_BLOCK}  		element={<MobileBlockUser />}></Route>

						<Route path={PAGE_URL.USER_INFO}  		element={<MobileUserInfo />}></Route>
						<Route path={PAGE_URL.MY_POST}  		element={<MobileMyPost />}></Route>
						<Route path={PAGE_URL.STORY_POST}  		element={<MobilePost />}></Route>
						<Route path={PAGE_URL.COMMUNITY_POST}  	element={<MobilePost />}></Route>
						<Route path={PAGE_URL.WRITE_POST}  		element={<MobileWritePost />}></Route>
						<Route path={PAGE_URL.WRITE_INQUIRY}	element={<MobileWriteInquiry />}></Route>
						
						<Route path={PAGE_URL.MOBILE_LOGIN}		element={<MobileLogin />}></Route>
						<Route path={PAGE_URL.SIGNUP}			element={<MobileSignup />}></Route>
						<Route path={PAGE_URL.FIND_ID}			element={<MobileFindId />}></Route>
						<Route path={PAGE_URL.FIND_PW}			element={<MobileFindPw />}></Route>
						<Route path={PAGE_URL.CERTIFICATION}	element={<NotFound />}></Route>
						
						<Route path={PAGE_URL.PERSONAL}	element={<MobilePersonal />}></Route>
						
						{/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
						<Route path="*" element={<NotFound />}></Route>
				
						{/* 관리자 화면 WEB , MOBILE 공통 */}
						<Route path={PAGE_URL.ADMIN_MAIN}		element={<AdminMain />}></Route>
						<Route path={PAGE_URL.ADMIN_STORY}		element={<AdminStory />}></Route>
						<Route path={PAGE_URL.ADMIN_COMMUNITY}	element={<AdminCommunity />}></Route>
						<Route path={PAGE_URL.ADMIN_NOTICE}		element={<AdminNotice />}></Route>
						<Route path={PAGE_URL.ADMIN_NOTICE_WIRTE} element={<AdminNoticeWrite />}></Route>
						<Route path={PAGE_URL.ADMIN_USER}		element={<AdminAccount />}></Route>
						<Route path={PAGE_URL.ADMIN_INQUIRY}	element={<AdminInquiry />}></Route>
						<Route path={PAGE_URL.ADMIN_INQUIRY_POST}	element={<AdminInquiryPost />}></Route>
						<Route path={PAGE_URL.ADMIN_SETTING}	element={<AdminSetting />}></Route>
					</Routes>
				}
				{ /* WEB 화면 */ isMobile == false &&
					<Routes>
						<Route path={PAGE_URL.MAIN} 			element={<Main />}></Route>
						<Route path={PAGE_URL.SEARCH} 			element={<Search />}></Route>
						<Route path={PAGE_URL.STORY}  			element={<Story />}></Route>
						<Route path={PAGE_URL.COMMUNITY}  		element={<Community />}></Route>
						<Route path={PAGE_URL.NOTICE}  			element={<Notice />}></Route>
						<Route path={PAGE_URL.NOTICE_POST}  	element={<NoticePost />}></Route>
						<Route path={PAGE_URL.MY_INQUIRY}  		element={<Inquiry />}></Route>
						<Route path={PAGE_URL.MY_INQUIRY_POST}  element={<InquiryPost />}></Route>
						<Route path={PAGE_URL.MY_BLOCK}  		element={<BlockUser />}></Route>
						
						
						<Route path={PAGE_URL.USER_INFO}  		element={<UserInfo />}></Route>
						<Route path={PAGE_URL.MY_POST}  		element={<MyPost />}></Route>
						<Route path={PAGE_URL.STORY_POST}  		element={<Post />}></Route>
						<Route path={PAGE_URL.COMMUNITY_POST}  	element={<Post />}></Route>
						<Route path={PAGE_URL.WRITE_POST}  		element={<WritePost />}></Route>
						<Route path={PAGE_URL.WRITE_INQUIRY}	element={<WriteInquiry />}></Route>
						
						<Route path={PAGE_URL.SIGNUP}			element={<Signup />}></Route>
						<Route path={PAGE_URL.FIND_ID}			element={<FindId />}></Route>
						<Route path={PAGE_URL.FIND_PW}			element={<FindPw />}></Route>
						<Route path={PAGE_URL.CERTIFICATION}	element={<Certification />}></Route>
						
						<Route path={PAGE_URL.PERSONAL}			element={<Personal />}></Route>
				
						{/* 관리자 화면 WEB , MOBILE 공통 */}
						<Route path={PAGE_URL.ADMIN_MAIN}		element={<AdminMain />}></Route>
						<Route path={PAGE_URL.ADMIN_STORY}		element={<AdminStory />}></Route>
						<Route path={PAGE_URL.ADMIN_COMMUNITY}	element={<AdminCommunity />}></Route>
						<Route path={PAGE_URL.ADMIN_NOTICE}		element={<AdminNotice />}></Route>
						<Route path={PAGE_URL.ADMIN_NOTICE_WIRTE} element={<AdminNoticeWrite />}></Route>
						<Route path={PAGE_URL.ADMIN_USER}		element={<AdminAccount />}></Route>
						<Route path={PAGE_URL.ADMIN_INQUIRY}	element={<AdminInquiry />}></Route>
						<Route path={PAGE_URL.ADMIN_INQUIRY_POST}	element={<AdminInquiryPost />}></Route>
						<Route path={PAGE_URL.ADMIN_SETTING}	element={<AdminSetting />}></Route>
						
						{/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
						<Route path="*" element={<NotFound />}></Route>
					</Routes>
				}
				<MobileInterfaceComponent />
				<PopupMoveApplication />
			</BrowserRouter>
		</div>
	);
};

export default App;

function MobileInterfaceComponent( props:any) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const fcmToken : string|null = useSelector((state:RootState)=>state.data.fcmToken);
    const loginData = useSelector((state:RootState)=>state.data.loginData);

	useEffect(()=>{

        if( !applicationOS ) {
            const l_p = new URLSearchParams(location.search);
    
            var newApplication = getParamData(l_p, "application", null, true );
            var newFcmToken = getParamData(l_p, "fcmToken", null, true );
            
            reduxActions.data.setApplicationOS( dispatch, newApplication );
            reduxActions.data.setFcmToken( dispatch, newFcmToken );
    
            if( !!newApplication ) {
                sendDataToApp( window, APP_INTERFACE_SEND_TYPE.PAGE_OPEN, loginData );
            }
        }
		return ()=>{}
	},[location, loginData, applicationOS])

	useEffect(()=>{
		const listener : MobileListener = (e:any) => {
			try{
				var receiveData : APP_INTERFACE_RECEIVE_DATA = JSON.parse(e.data);
				if( receiveData.type == APP_INTERFACE_RECEIVE_TYPE.MOVE_PAGE_HOME ) {
                	navigate(PAGE_URL.MAIN);
				}
				else if( receiveData.type == APP_INTERFACE_RECEIVE_TYPE.MOVE_PAGE_LOGIN ) {
                	navigate(PAGE_URL.MOBILE_LOGIN);
				}
				else if( receiveData.type == APP_INTERFACE_RECEIVE_TYPE.MOVE_PAGE_POST ) {
					if( receiveData.data ) {
						const d = receiveData.data;

						const params : PAGE_POST_PARAMS = {
							idx:d.post_idx,
							year:d.year,
							post_list_type:API_TYPES.POST_LIST_TYPE.NOMAL,
							post_type:d.post_type,
							category:API_TYPES.CATEGORY_TYPE.ALL,
							search:null,
							searchType:null
						}
						var url = makeUrl(d.post_type==API_TYPES.POST_TYPE.COMMUNITY?PAGE_URL.COMMUNITY_POST:PAGE_URL.STORY_POST, params);
						navigate(url);
					}
				}
				else if( receiveData.type == APP_INTERFACE_RECEIVE_TYPE.LOGIN ) {
					if( receiveData.data ) {
						const d : APP_INTERFACE_LOGIN_DATA = receiveData.data;
						reqLogin( d.id, d.pw, d.code );
					}
				}
			}catch(error:any){
				//alert("error : " + error.message + ", data : " + e.data);
			}
		}

		if( applicationOS == "ios" ) {
			window.addEventListener('message', listener);
		}
		else {
			document.addEventListener('message', listener);
		}
		//setMobileListener(window, listener);
		return ()=>{ 
			if( applicationOS == "ios" ) {
				window.removeEventListener('message', listener);
			}
			else {
				document.removeEventListener('message', listener);
			}
			//removeMobileListener( listener ); 
		}
	},[applicationOS, fcmToken])


	
	const reqLogin = ( id:string, pw:string, code:string|null ) => {

		apiAxios.global.authLogin(dispatch, id, pw, code, applicationOS, fcmToken, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const newData : any = {...resData};
				newData["pw"] = pw;
				newData["code"] = code;

                setCookieLoginData(newData);
                reduxActions.data.setLoginData(dispatch, newData);
			}
			else if( error ) {
				const logoutData : APP_INTERFACE_LOGOUT_DATA = {token:""};
				sendDataToApp( window, APP_INTERFACE_SEND_TYPE.LOGOUT, logoutData );
			}
		})
    }

	return null;
}