/****************************************************************************************
 * 
 *  메인화면 게시글 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { getDeletePostMsg, isToday, makeDateFromTimeStamp, makeInsertTimeStr, makeTwoNumberStr } from '../../../../util/Global';
import { CANT_READ_POST_ON_DELETE } from '../../../../util/ConstValue';
import { PAGE_NOTICE_HOME_PARAMS, PAGE_NOTICE_POST_PARAMS, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_POST_HOME_PARAMS, PAGE_POST_PARAMS, PAGE_URL, getStartPage, makeBottomPageNumberList, makeUrl } from '../../../../util/Page';
import { AiOutlineCaretLeft } from "react-icons/ai";
import { AiOutlineCaretRight } from "react-icons/ai";
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../../redux_saga/modules';
import { AiFillPicture } from "react-icons/ai";
import MobilePageNumberBox, { MobileTopPageNumberBox } from '../../sub/MobilePageNumber';

function MobileNoticePostList( props:any ) {
	const {currentCategory} = props;
	
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);

	const [page, setPage] = useState(1);
	const [itemList, setItemList] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    useEffect(()=>{

		const l_p = new URLSearchParams(location.search);
		const p = l_p.get("page");

		var newPage = 1;

		if( p ) try{ newPage = parseInt(p); }catch(e){ console.log("e",e);}

		setItemList(null);
		setTotalData(null);

		setPage( newPage );

		reqNotice(newPage);

        return(()=>{})
    },[location]);
	

    const reqNotice = ( page:number ) => {

		apiAxios.global.listNotice(dispatch, API_TYPES.POST_TYPE.ALL, 0, page, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				
				const newList = !resData.list?null:resData.list.map((item:any,index:number)=>{ item.is_notice = true; return item; })
								
				setItemList( newList );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

	const onClickPage = (page:number, isYear:boolean = false)=>{
		var params : PAGE_NOTICE_HOME_PARAMS = {
			page:page
		}

		if( isYear ) {
			params.page = 1;
		}
		else {
			params.page = page;
		}

		var url = makeUrl(PAGE_URL.NOTICE, params);
		navigate(url);
	}

	return(
		<>
			<div className='mobile_post_home__list_area' id="mobile_post_home__list_area">
				<div className='title'>
					<div className={'category'}><span>{"공지사항"}</span></div>
					<MobileTopPageNumberBox 
						{...props}
						page={page}
						totalData={totalData}
						marginTop={5}
						onClickPage={onClickPage}/>
				</div>
				<PostComponent {...props} 
					page={page}
					itemList={itemList}
					/>
				<MobilePageNumberBox {...props}
					totalData={totalData}
					page={page}
					onClickPage={onClickPage}
				/>
			</div>
		</>
	)
}
export default MobileNoticePostList;

function PageComponent(props:any) {
	const {totalData, page, onClickPage} = props;
	const [pageList, setPageList] = useState<PAGE_NUMBER_DATA[]|null>(null);

    useEffect(()=>{

		var list = makeBottomPageNumberList( page, totalData, null );
		setPageList(list);

        return(()=>{})
    },[totalData, page]);

	if( !pageList || pageList.length == 0 ) return null;

	return(
		<div className='home__post_page_area'>
			{ pageList.map((item:PAGE_NUMBER_DATA, index:number)=>{

				var numberStr = "";
				var className = "";
				var yearStyle = {};

				if( item.type==PAGE_NUMBER_TYPE.NUMBER ) 			{ numberStr = item.page+"";	    className="number"; }
				else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) 		{ numberStr = "이전"; 			className="before"; }
				else if( item.type==PAGE_NUMBER_TYPE.NEXT ) 		{ numberStr = "다음"; 			className="next"; }

				if( item.type==PAGE_NUMBER_TYPE.NUMBER && page == item.page ) {
					className = className + " select";
				}

				return( 
					<div className={className} key={index} style={yearStyle}>
						<span className="page" onClick={()=>{ if( onClickPage ) { onClickPage(item); } }}>
							{item.page != -1?numberStr:""}
						</span>
					</div>
				)
			})}
		</div>
	)
}

function PostComponent( props:any ) {
	const { itemList, year, page, currentCategory } = props;
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	

	const dispatch = useDispatch();
	const navigate = useNavigate();

    useEffect(()=>{

        return(()=>{})
    },[year, page]);

	

	const clickedPost = ( item:any ) => {
		
		var fullUrl = "";
		const params : PAGE_NOTICE_POST_PARAMS = {
			idx:item.idx,
			post_type:null, 
			category:null
		}
		fullUrl = makeUrl(PAGE_URL.NOTICE_POST, params);
		navigate(fullUrl);
	}

	if( !itemList || itemList.length == 0 ) {
		return(
			<div className='empty'>
				<span>게시글이 없습니다</span>
			</div>
		)
	}

	return(
		<ul>
			{ !!itemList && itemList.length > 0 &&
				itemList.map((item:any, index:number)=>{
					return( <PostItem {...props} item={item} index={index} onClick={clickedPost}/> )
				})
			}
		</ul>
	)
}

function PostItem( props:any ) {
	const {item, onClick, currentCategory} = props;
	
	const timeDate = makeDateFromTimeStamp(item.insert_time);

	const showNew = isToday(timeDate);
	const havePicture = !!item.image01;
	const showCategory = item.is_notice!=true && currentCategory == -1;


	var titleMaxWidth = window.outerWidth - 40;
	titleMaxWidth = titleMaxWidth - (showNew?25:0);
	titleMaxWidth = titleMaxWidth - (havePicture?25:0);

	const insertTimeStr = makeInsertTimeStr( timeDate );

	return(
		<li onClick={()=>{if(onClick) onClick(item); }} >
			<div className='top_area'>

				<div className='title_body' style={{maxWidth:titleMaxWidth}}>
					{ showCategory && <span className='category'>{"["+item.cate_name+"]"}</span> }
					<span className='title'>{item.title}</span>
				</div>
				
				{ havePicture 							&& <div className='picture'><AiFillPicture size={20}/></div> }
				{ showNew 								&& <div className='new'><span>N</span></div> }
			</div>
			<div className='bottom'>
				<div className='hits'><span>{"조회:"+item.hits}</span></div>
				<div className='insert_time'><span>{insertTimeStr}</span></div>
			</div>
		</li>
	)
}