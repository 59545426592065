/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../../sub/RightArea';
import LeftAd from '../../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import BlockListComponent from './sub/BlockListComponent';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../redux_saga/modules';
import Wrapper from '../../sub/Wrapper';
import { checkLogin } from '../../../util/Global';
import TopAdComponent from '../../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../../sub/Adsense';

const BlockUser = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{
		checkLogin(navigate, loginData);
        return(()=>{})
    },[loginData]);

	return (
		<Wrapper
			tabType={TAB_TYPE.NONE}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default BlockUser;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.BLOCK_USER_TOP} marginBottom={35}/>
			<BlockListComponent {...props}/>
		</div>
	)
}