/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { RootState } from '../../../redux_saga/modules';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { TAB_TYPE } from '../../sub/TopTab';
import Wrapper from '../../sub/Wrapper';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import TopAdComponent from '../../sub/TopAdComponent';
import { checkLogin } from '../../../util/Global';
import AgreeSignup from './sub/AgreeSignup';
import InputSignup from './sub/InputSignup';

const Signup = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();    
    const loginData = useSelector((state:RootState)=>state.data.loginData);
    const [isAgree, setAgree] = useState(false);
	
	useEffect(()=>{
        if( loginData ) {
            navigate(PAGE_URL.MAIN, {replace:true});
        }

		return(()=>{})
	},[loginData]);


	return (<>
		<Wrapper
            hideRight={true}
            hideLeft={true}
            hideTab={true}
            hideBottomCompanyInfo={isAgree}
			subTitle={"회원가입"}
			tabType={TAB_TYPE.NONE}>
			<MiddleComponent 
				{...props} 
                isAgree={isAgree}
                setAgree={setAgree}
				/>
		</Wrapper>
		</>
	);
};

export default Signup;

function MiddleComponent( props:any ) {
    const { isAgree, setAgree } = props;
    useEffect(()=>{

        return(()=>{})
    },[]);

	return(
		<div className='middle_area'>
            { isAgree == false && <AgreeSignup {...props} onNext={()=>{setAgree(true);}}/> }
            { isAgree == true && <InputSignup {...props}/>}
		</div>
	)
}

