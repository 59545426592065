import { ChangeEvent } from "react";

function HyperLink( props:any ) {
    const { content } = props;

    const urlRegex = /(https?:\/\/[^\s]+)/g;

    const convertContent = content.replace(urlRegex, function (url : string) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })

    const htmlArr : any = [];
    convertContent.split('\n').forEach(function (text : string) {
        const textHtml = "<span>" + text + "</span>";
        htmlArr.push(textHtml)
    })


    // return htmlArr
    return <div dangerouslySetInnerHTML={{__html: htmlArr.join("")}}></div>
}
export default HyperLink;