/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../sub/RightArea';
import LeftAd from '../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { RootState } from '../../redux_saga/modules';
import { checkLogin } from '../../util/Global';
import Header from './Header';
import { isMobile } from 'react-device-detect';
import { PAGE_URL } from '../../util/Page';

const Wrapper = (props:any) => {
    const {
        popup,
        children,
        tabType,
        hideBottomCompanyInfo,
        rightCategoryData,
        rightHideNotice,
        isCheckLogin,
        hideRight,
        hideLeft,
        hideTab,
        hideSearch
    } = props;
	const navigate = useNavigate();

    const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{
        if( isCheckLogin == true ) {
            checkLogin(navigate, loginData);
        }
        return(()=>{})
    },[loginData]);

	/*useEffect(()=>{
        if( isMobile ) { navigate(PAGE_URL.MAIN, {replace:true}); }   
		return ()=>{ }
	},[isMobile])*/

	return ( <>
		<div className='body_div'>
            <Header {...props} />
            { hideTab != true &&
			<TopTab foucsType={tabType}/>
            }
			<table >
                <tr>
                    
                    <td className='left' valign='top'>
                        { hideLeft != true && 
                        <LeftAd {...props}/>
                        }
                    </td> 
                    <td className='middle' valign='top'>{children}</td>                    
                    <td className='right' valign='top'>
                    { hideRight != true &&
						<RightArea
							{...props}
                            tabType={tabType}
							categoryData={rightCategoryData}
                            hideNotice={rightHideNotice}/>
                    }
					</td>
                </tr>
                { hideBottomCompanyInfo != true && 
                    <tr>
                        <td colSpan={3} align='center'>
                            <BottomCompanyInfo {...props}/>
                        </td>
                    </tr>
                }
            </table>	
		</div>	
        {popup}
        </>
	);
};

export default Wrapper;