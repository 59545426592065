/****************************************************************************************
 * 
 *  상단 탭
 *  
 *  홈 , 사연 , 커뮤니티
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { PAGE_URL } from '../../../util/Page';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux_saga/modules';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, checkAbleManageAdminPage, makeAdminManageData } from '../../../util/AdminAccessHelper';
import { movePage } from '../../../util/Global';

export enum ADMIN_TAB_TYPE {
    NONE        = -1,
    HOME        = 1,
    STORY       = 2,
    COMMUNITY   = 3,
    NOTICE      = 4,
    USER        = 5,
    INQUIRY     = 6,
    SETTING     = 7
}

function AdminTopTab( props : any ) {
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    const [showStory, setShowStory] = useState(false);
    const [showCommunity, setShowCommunity] = useState(false);
    const [showNotice, setShowNotice] = useState(false);
    const [showUser, setShowUser] = useState(false);
    const [showInquiry, setShowInquiry] = useState(false);

    useEffect(()=>{

        if( loginData ) {

            const showStory = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.STORY)).accessType == ADMIN_ACCESS_TYPE.ALL;
            const showCommunity = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.COMMUNITY)).accessType == ADMIN_ACCESS_TYPE.ALL;
            const showNotice = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.NOTICE)).accessType == ADMIN_ACCESS_TYPE.ALL;
            const showUser = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.ACCOUNT)).accessType == ADMIN_ACCESS_TYPE.ALL;
            const showInquiry = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.INQUIRY)).accessType == ADMIN_ACCESS_TYPE.ALL;

            setShowStory( showStory );
            setShowCommunity( showCommunity );
            setShowNotice( showNotice );
            setShowUser( showUser );
            setShowInquiry( showInquiry );
        }

        return(()=>{})
    },[loginData]);
    
    return (
        <div className='admin_top_tab'>
            <div className='top_tab_body'>
                <Tab {...props} tabType={ADMIN_TAB_TYPE.HOME}       name="홈"/>
                { showStory     && <Tab {...props} tabType={ADMIN_TAB_TYPE.STORY}      name="사연"/> }
                { showCommunity && <Tab {...props} tabType={ADMIN_TAB_TYPE.COMMUNITY}  name="커뮤니티"/> }
                { showNotice    && <Tab {...props} tabType={ADMIN_TAB_TYPE.NOTICE}     name="공지사항"/> }
                { showUser      && <Tab {...props} tabType={ADMIN_TAB_TYPE.USER}       name="계정"/> }
                { showInquiry   && <Tab {...props} tabType={ADMIN_TAB_TYPE.INQUIRY}    name="문의"/> }
                <Tab {...props} tabType={ADMIN_TAB_TYPE.SETTING}    name="설정"/>
            </div>
        </div>
    );
}

export default AdminTopTab;

function Tab( props : any ) {
    const { foucsType, tabType, name } = props;
	const navigate = useNavigate();
    const isFocus = foucsType == tabType;

    const move = ( e:any ) => {
        if( isFocus ) return;
        
        if( tabType == ADMIN_TAB_TYPE.HOME )            { movePage(navigate, e,  PAGE_URL.ADMIN_MAIN ); }
        else if( tabType == ADMIN_TAB_TYPE.STORY )      { movePage(navigate, e,  PAGE_URL.ADMIN_STORY ); }
        else if( tabType == ADMIN_TAB_TYPE.COMMUNITY )  { movePage(navigate, e,  PAGE_URL.ADMIN_COMMUNITY ); }
        else if( tabType == ADMIN_TAB_TYPE.NOTICE )     { movePage(navigate, e,  PAGE_URL.ADMIN_NOTICE ); }
        else if( tabType == ADMIN_TAB_TYPE.USER )       { movePage(navigate, e,  PAGE_URL.ADMIN_USER ); }
        else if( tabType == ADMIN_TAB_TYPE.INQUIRY )    { movePage(navigate, e,  PAGE_URL.ADMIN_INQUIRY ); }
        else if( tabType == ADMIN_TAB_TYPE.SETTING )    { movePage(navigate, e,  PAGE_URL.ADMIN_SETTING ); }
    }

    var rightStyle = {};

    return (
        <div className={isFocus?"tab_item_select":'tab_item'} onClick={move} style={rightStyle}>
            <span>{name}</span>
        </div>
    )
}