import { useEffect, useRef, useState } from "react";
import apiAxios, { API_TYPES, API_UTILS, ParamUserDataPostAdd, ParamUserDataPostUpdate } from "../../../../apiAxios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux_saga/modules";
import { ImageData, ResponseImage, getImageFile } from "../../../../util/Image";
import { AiFillCloseCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { chkNickName, chkPW } from "../../../../util/Global";
import { MAX_NICKNAME_LENGTH, TEXT_OVER_NICKNAME_LENGTH } from "../../../../util/ConstValue";
import { PAGE_URL } from "../../../../util/Page";
import { FiLock } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { BsPersonVcard } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { getCookieLoginData, setCookieLoginData } from "../../../../util/cookie_helper";
import reduxActions from "../../../../redux_saga/modules/moduleActions";

const BodyComponent = ( props:any ) => {
    const navigate = useNavigate();    
    const [isCheckPw, setCheckPw] = useState(false);
    const { setShowWithdrawPopup } = props;
	
	useEffect(()=>{
		return(()=>{})
	},[]);

    return(
        <>
        <div className="user_info">
            <Top {...props}/>

            
            { isCheckPw && <>
                <ChangePw {...props} onSuccess={()=>{
                    
                }}/> 
                <CheckNickname {...props} onSuccess={()=>{
                    
                }}/> 
                <CheckEmail {...props} onSuccess={()=>{

                }}/> 

                <div className="withdraw_box">
                    <div onClick={()=>{setShowWithdrawPopup(true);}}>회원탈퇴</div>
                </div>
            </>}

            { !isCheckPw &&
                <CheckPw {...props} onSuccess={()=>{setCheckPw(true)}}/>
            }
        </div>
        </>
    )
}
export default BodyComponent;

function Top( props:any ) {
	const loginData = useSelector((state:RootState)=>state.data.loginData);
    return(
        <div className="top">
            <span>정보수정</span>
            { !loginData && <span className="no_login">※
             문의는 로그인 후 가능합니다. 로그인이 어려우시면 아래 이메일로 문의주세요</span> }
        </div>
    )
}

const SHOW_TEXT = "숨김해제";
const HIDE_TEXT = "입력숨김";

/**
 * 비밀번호 체크
 * @param props 
 * @returns 
 */
function CheckPw( props:any ) {
    const {onSuccess} = props;
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [passwordStr, setPasswordStr] = useState("");

    const clickedButton = () => {
        if( requesting ) return;

        if( !passwordStr ) {
            alert("비밀번호를 입력하세요");
            return;
        }

        setRequesting( true );

        apiAxios.user.authCheckPw(dispatch, passwordStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                if( onSuccess ) onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("비밀번호를 잘못 입력하였습니다");
				}
			}
            setRequesting( false );
		})
    }
    
    const handleKeyUp = (e:any) => {
        if( e.keyCode == 13){ // 키보드 Enter 클릭
            clickedButton();
        }
    }
    return(
        <InputWrapper
            title={"비밀번호를 입력해 주세요"}
            buttonName={"확인"}
            requesting={requesting}
            onClickButton={clickedButton}>
            <InputBox 
                placeholder={"비밀번호"}
                leftIcon={<FiLock size={20}/>}
                rightIcon={ <PasswordRightIcon show={showPassword} setShow={setShowPassword}/> }
                value={passwordStr}
                setValue={setPasswordStr}
                isPassword={showPassword==false}
                onKeyUp={handleKeyUp}
                />
        </InputWrapper>
    )
}

/**
 * 비밀번호 변경
 * @param props 
 * @returns 
 */
function ChangePw( props:any ) {
    const {onSuccess} = props;
    const dispatch = useDispatch();

    const [requesting, setRequesting] = useState(false);

    const [showPassword01, setShowPassword01] = useState(false);
    const [showPassword02, setShowPassword02] = useState(false);
    const [showPassword03, setShowPassword03] = useState(false);

    const [passwordStr01, setPasswordStr01] = useState("");
    const [passwordStr02, setPasswordStr02] = useState("");
    const [passwordStr03, setPasswordStr03] = useState("");

    const clickedButton = () => {
        if( requesting ) return;
        if( !passwordStr01 ) { alert("기존 비밀번호를 입력하세요"); return; }
        if( !passwordStr02 ) { alert("새 비밀번호를 입력하세요"); return; }
        if( !passwordStr03 ) { alert("새 비밀번호를 다시 입력하세요"); return; }

        if( passwordStr02 != passwordStr03) { alert("새로 입력한 비밀번호가 동일하지 않습니다"); return; }

        var errMsg = chkPW( passwordStr02 );
        if( !!errMsg ) {alert(errMsg); return; }

        setRequesting( true );
        apiAxios.user.authPwUpdate(dispatch, passwordStr01, passwordStr02, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                setPasswordStr01("");
                setPasswordStr02("");
                setPasswordStr03("");

                alert("비밀번호 변경완료");

                if( onSuccess ) onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("기존 비밀번호를 잘못 입력하였습니다");
				}
			}
            setRequesting( false );
		})

        if( onSuccess ) onSuccess();
    }
    
    const handleKeyUp = (e:any) => {
        if( e.keyCode == 13){ // 키보드 Enter 클릭
            clickedButton();
        }
    }

    return(
        <InputWrapper
            title={"비밀번호 변경"}
            buttonName={"비밀번호 변경"}
            requesting={requesting}
            onClickButton={clickedButton}>
            <InputBox 
                position={InputBoxPosition.TOP}
                placeholder={"기존 비밀번호"}
                leftIcon={<FiLock size={20}/>}
                rightIcon={ <PasswordRightIcon show={showPassword01} setShow={setShowPassword01}/> }
                value={passwordStr01}
                setValue={setPasswordStr01}
                isPassword={showPassword01==false}
                onKeyUp={handleKeyUp}
                />
            <InputBox 
                position={InputBoxPosition.MIDDLE}
                placeholder={"새 비밀번호"}
                leftIcon={<FiLock size={20}/>}
                rightIcon={ <PasswordRightIcon show={showPassword02} setShow={setShowPassword02}/> }
                value={passwordStr02}
                setValue={setPasswordStr02}
                isPassword={showPassword02==false}
                onKeyUp={handleKeyUp}
                />
            <InputBox 
                position={InputBoxPosition.BOTTOM}
                placeholder={"새 비밀번호 다시입력"}
                leftIcon={<FiLock size={20}/>}
                rightIcon={ <PasswordRightIcon show={showPassword03} setShow={setShowPassword03}/> }
                value={passwordStr03}
                setValue={setPasswordStr03}
                isPassword={showPassword03==false}
                onKeyUp={handleKeyUp}
                />
        </InputWrapper>
    )
}

/**
 * 닉테임 변경
 * @param props
 * @returns 
 */
function CheckNickname( props:any ) {
    const {onSuccess} = props;
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);
    const [requestingOverlap, setRequestingOverlap] = useState(false);
    const [isCheckOverlap, setCheckOverlap] = useState(false);

    const [nicknameStr, setNicknameStr] = useState("");

    const clickedButton = () => {
        if( requesting || requestingOverlap ) return;
        if( !nicknameStr ) { alert("닉네임을 입력하세요"); return; }
        if( !isCheckOverlap ) { alert("중복체크를 해주세요"); return; }

        setRequesting( true );
        apiAxios.user.authNickNameUpdate(dispatch, nicknameStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                var cld = getCookieLoginData();
                var newLoginData = {...cld};
                newLoginData.nickname = nicknameStr;
                setCookieLoginData(newLoginData);
                reduxActions.data.setLoginData(dispatch, newLoginData);

                setNicknameStr("");

                alert("닉네임 변경완료");

                if( onSuccess ) onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("유저를 찾을 수 없습니다");
				}
                else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.CAN_NOT_USE ) {
                    alert("닉네임에 금지어가 포함되어 있습니다");
				}
                else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 닉네임 입니다");
                    setNicknameStr("");
				}
			}
            setRequesting( false );
		})
    }

    const reqCheckOverlap = () => {
        if( requestingOverlap ) return;
        if( !nicknameStr ) { alert("닉네임을 입력하세요"); return; }

        // 닉네임 유효성 검사
        var errMsg = chkNickName( nicknameStr );
        if( !!errMsg ) {alert(errMsg); return;}

        setRequestingOverlap( true );
        apiAxios.global.dataCheckNickNameOverlap(dispatch, nicknameStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("사용가능한 닉네임 입니다");

                setCheckOverlap( true );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 닉네임 입니다");
                    setNicknameStr("");
				}
			}
            setRequestingOverlap( false );
		})
    }

    return(
        <InputWrapper
            title={"닉네임 변경"}
            buttonName={"닉네임 변경"}
            requesting={requesting}
            onClickButton={clickedButton}>
            <InputBox 
                placeholder={"닉네임"}
                leftIcon={<BsPersonVcard size={20}/>}
                rightText={ isCheckOverlap?null:"중복확인" }
                value={nicknameStr}
                rightLoading={requestingOverlap}
                setValue={(text:string)=>{
                    if( text.length > MAX_NICKNAME_LENGTH ) {
                        alert(TEXT_OVER_NICKNAME_LENGTH);
                    }
                    else {
                        setNicknameStr(text);
                        if( isCheckOverlap ) setCheckOverlap(false);
                    }
                }}
                onClickRightText={()=>{
                    reqCheckOverlap();
                }}
                />
        </InputWrapper>
    )
}

/**
 * 이메일 변경
 * @param props
 * @returns 
 */
function CheckEmail( props:any ) {
    const {onSuccess} = props;
    const dispatch = useDispatch();
    const [requesting, setRequesting] = useState(false);

    const [emailStr, setEmailStr] = useState("");

    const clickedButton = () => {
        if( requesting ) return;
        if( !emailStr ) { alert("이메일을 입력하세요"); return; }

        setRequesting( true );
        apiAxios.user.authEmailUpdate(dispatch, emailStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                var cld = getCookieLoginData();
                var newLoginData = {...cld};
                newLoginData.email = emailStr;
                setCookieLoginData(newLoginData);
                reduxActions.data.setLoginData(dispatch, newLoginData);

                setEmailStr("");

                alert("이메일 변경완료");

                if( onSuccess ) onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("유저를 찾을 수 없습니다");
				}
			}
            setRequesting( false );
		})

        if( onSuccess ) onSuccess();
    }
    return(
        <InputWrapper
            title={"이메일 변경"}
            buttonName={"이메일 변경"}
            requesting={requesting}
            onClickButton={clickedButton}>
            <InputBox 
                placeholder={"이메일"}
                leftIcon={<BsEnvelope size={20}/>}
                value={emailStr}
                setValue={setEmailStr}
                onClickRightText={()=>{

                }}
                />
        </InputWrapper>
    )
}

function InputWrapper( props:any ) {
    const { children, title, onClickButton, buttonName, requesting } = props;

    return(
        <div className="edit_box">
            <div className="title"><span>{title}</span></div>
            { children }
            <div className="button" onClick={onClickButton}>
                { requesting && <div className="loader">
                        <Oval 
                            color="#fff" 
                            height={20} 
                            width={20}
                            /></div>}
                { !requesting && <span>{buttonName}</span>}
            </div>
        </div>
    )
}

/**
 * 비밀번호 입력 우측 아이콘
 * @param props 
 * @returns 
 */
function PasswordRightIcon( props:any ) {
    const {show, setShow} = props;

    return( <>
        { show && <FiEye title={HIDE_TEXT} size={24} onClick={()=>{setShow(false)}}/> }
        { !show && <FiEyeOff title={SHOW_TEXT} size={24} onClick={()=>{setShow(true)}}/> }
    </>)
}

enum InputBoxPosition {
    TOP=1, MIDDLE=2, BOTTOM=3
}

function InputBox( props:any ) {
    const {leftIcon, placeholder, value, setValue, rightIcon, rightText, position, isPassword, onClickRightText, onKeyUp, rightLoading} = props;
    var posClassName = "";
    if( position == InputBoxPosition.TOP )          posClassName = " input_top";
    else if( position == InputBoxPosition.MIDDLE )  posClassName = " input_middle";
    else if( position == InputBoxPosition.BOTTOM )  posClassName = " input_bottom";

    var inputWidth = 550;
    if( !!leftIcon ) inputWidth = inputWidth - 30;
    if( !!rightIcon ) inputWidth = inputWidth - 30;
    if( !!rightText ) inputWidth = inputWidth - 70;

    return(
        <div className={"input_box"+posClassName}>
            { leftIcon!= null && 
                <div className="left_icon"> {leftIcon} </div> 
            }

            <input 
                type={isPassword?"password":"text"} 
                placeholder={placeholder} 
                value={value} 
                onChange={(e)=> { 
                    if(setValue) {
                        var text = e.target.value;
                        text = text.replace(/ /g, ''); // 공백제거
                        setValue(text); 
                    }
                }}
                style={{width:inputWidth}}
                onKeyUp={onKeyUp}/>

            { rightIcon!= null && 
                <div className="right_icon"> {rightIcon} </div> 
            }
            { !!rightText && 
                <div className="right_text" onClick={onClickRightText}> 
                    { rightLoading == true && <div className="loader">
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                        </div>}
                    { rightLoading != true && <span>{rightText}</span> }
                </div> 
            }
        </div>
    )
}