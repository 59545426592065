import React from 'react';

const NotFound = () => {
    return ( <>
        <div>
            {"404 Error"}
        </div>
        <div>
            {"" + window.location}
        </div>
    </>);
};
  
export default NotFound;