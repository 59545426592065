import React, { forwardRef, useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { chkID, chkPW, isNumber, makeInsertTimeFullFromTimeStamp, makeInsertTimeStr, makePostInsertFullTime } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminDataAdminAdd, ParamAdminDataAdminUpdate, ParamAdminDataStoryChoiceUpdate } from '../../apiAxios';
import { DeletePopupType, DeleteReasonType, getDeleteReasonStr } from './PopupDeletePost';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';
import { request } from 'http';
import { AiFillCalendar } from "react-icons/ai";
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ko from 'date-fns/locale/ko'; 
import { RootState } from '../../redux_saga/modules';
import { makePartCategoryArray } from '../../util/AdminAccessHelper';
var local : any = ko;
registerLocale('ko', local);


interface AccessPartData {
    story:number,
    story_categorys:number[],
    community:number,
    community_categorys:number[],
    notice:boolean,
    user_admin:boolean,
    user:boolean,
    system:boolean
    taboo:boolean,
    inquiry:boolean
}

const NOT_SELECT = -1;

function defaultAccessPartData() {
    return {
        story:NOT_SELECT,
        story_categorys:[],
        community:NOT_SELECT,
        community_categorys:[],
        notice:false,
        user_admin:false,
        user:false,
        system:false,
        taboo:false,
        inquiry:false
    }
}



/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupAdmin = ( props:any ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {data, onSuccess}=props;

    const [requesting, setRequesting] = useState(false);
    
    const [idStr, setIdStr] = useState("");
    const [pwStr, setPwStr] = useState("");
    const [pwReStr, setPwReStr] = useState("");
    const [nameStr, setNameStr] = useState("");
    const [numberStr, setNumberStr] = useState("");
    const [emailStr, setEmailStr] = useState("");
    const [selectAccess, setSelectAccess] = useState<any>(API_TYPES.MANAGE_ACCESS.PART);
    const [accessPartData, setAccessPartData] = useState<AccessPartData>(defaultAccessPartData());

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    
    useEffect(()=>{

        if( data ) {
            setIdStr( data.id );
            setNameStr( data.name );
            setNumberStr( data.number );
            setEmailStr( data.email );
            setSelectAccess( data.access );
            setAccessPartData(makeAccessPartData(data));
        }
        else {
            setIdStr( "" );
            setNameStr( "" );
            setNumberStr( "" );
            setEmailStr( "" );
            setSelectAccess( API_TYPES.MANAGE_ACCESS.PART );
            setAccessPartData(defaultAccessPartData());
        }

        setPwStr("");
        setPwReStr("");

        setRequesting( false );
        return(()=>{})
    },[data]);

    const makeAccessPartData = ( data : any ) => {
        const access_type = data.access_type;

        const story = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY) > -1?API_TYPES.MANAGE_ACCESS.ALL
                    :access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1?API_TYPES.MANAGE_ACCESS.PART:NOT_SELECT;

        const community = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY) > -1?API_TYPES.MANAGE_ACCESS.ALL
                    :access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1?API_TYPES.MANAGE_ACCESS.PART:NOT_SELECT;
        
        var story_categorys : number[] = [];
        if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.STORY_PART) > -1 ) {
            story_categorys = makePartCategoryArray( access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART)
        }
        var community_categorys : number[] = [];
        if( access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART) > -1 ) {
            community_categorys = makePartCategoryArray( access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART)
        }

        const notice = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.NOTICE) > -1;
        
        const user_admin    = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER_ADMIN) > -1;
        const user          = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.USER) > -1;
        const system        = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.SYSTEM) > -1;
        const taboo         = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.TABOO) > -1;
        const inquiry       = access_type.indexOf(API_TYPES.MANAGE_ACCESS_PART.INQUIRY) > -1;
        return {
            story               :story,
            story_categorys     :story_categorys,
            community           :community,
            community_categorys :community_categorys,
            notice              :notice,
            user_admin          :user_admin,
            user                :user,
            system              :system,
            taboo               :taboo,
            inquiry             :inquiry
        }
    }
    
    const requestUpdate = () => {

        var errMsg = checkInsertData();
        if( !!errMsg ) { alert(errMsg); return; }

        setRequesting( true );
        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    if( errorData && errorData.data == 1 ) {
                        alert("이미 존재하는 아이디입니다");
                    }
                    else if( errorData && errorData.data == 2 ) {
                        alert("이미 존재하는 닉네임입니다");
                    }
				}
				else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {
                    alert("아이디를 찾을 수 없습니다");
                }
			}
            setRequesting( false );
		}

        var params : ParamAdminDataAdminUpdate | ParamAdminDataAdminAdd | null = null;

        if( data ) {
            params = {
                idx:data.idx,
                pw:!!pwStr?pwStr:null,
                name:nameStr,
                number:!!numberStr?numberStr:null,
                email:!!emailStr?emailStr:null,
                access:selectAccess,
                access_type:getAccessType()
            }
            apiAxios.admin.dataAdminUpdate(dispatch, params, callback);
        }
        else {
            params = {
                id:idStr,
                pw:pwStr,
                name:nameStr,
                number:!!numberStr?numberStr:null,
                email:!!emailStr?emailStr:null,
                access:selectAccess,
                access_type:getAccessType()
            }

            apiAxios.admin.dataAdminAdd(dispatch, params, callback);
        }
    }

    const checkInsertData = () => {
        if( data ) {
            if( !!pwStr ) {
                if( !pwReStr ) return "비밀번호를 다시 입력하세요";
                if( pwStr != pwReStr ) return "비밀번호가 일치하지 않습니다";
                
                var errMsg = chkPW(pwStr);
                if( errMsg ) return errMsg;
            }
            if( !nameStr ) return "이름을 입력하세요";
        }
        else {
            if( !idStr ) return "아이디를 입력하세요";
            if( !pwStr ) return "비밀번호를 입력하세요";
            if( !pwReStr ) return "비밀번호를 다시 입력하세요";
            if( !nameStr ) return "이름을 입력하세요";

            if( pwStr != pwReStr ) return "비밀번호가 일치하지 않습니다";

            var errMsg = chkID(idStr);
            if( errMsg ) return errMsg;

            errMsg = chkPW(pwStr);
            if( errMsg ) return errMsg;
        }
        return null;
    }

    const getAccessType = () => {
        if( selectAccess == API_TYPES.MANAGE_ACCESS.ALL ) {return null;}

        var access_type = "";
        if( accessPartData.story == API_TYPES.MANAGE_ACCESS.ALL ) {
            access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.STORY );
        }
        else if( accessPartData.story == API_TYPES.MANAGE_ACCESS.PART ) {
            access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.STORY_PART, accessPartData.story_categorys );
        }
        
        if( accessPartData.community == API_TYPES.MANAGE_ACCESS.ALL ) {
            access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY );
        }
        else if( accessPartData.community == API_TYPES.MANAGE_ACCESS.PART ) {
            access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.COMMUNITY_PART, accessPartData.community_categorys );
        }

        if( accessPartData.notice )     access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.NOTICE );
        if( accessPartData.user_admin ) access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.USER_ADMIN );
        if( accessPartData.user )       access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.USER );
        if( accessPartData.system )     access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.SYSTEM );
        if( accessPartData.taboo )      access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.TABOO );
        if( accessPartData.inquiry )    access_type = appendAccessType( access_type, API_TYPES.MANAGE_ACCESS_PART.INQUIRY );

        return access_type;
    }

    const appendAccessType = ( access_type:string, type:string, categorys?:number[] ) => {
        if( !!access_type ) { access_type += "$"; }
        access_type += type;

        if( categorys && categorys.length > 0 ) {
            for( var i=0; i<categorys.length; i++ ) {
                access_type += "_" + categorys[i];
            }
        }
        return access_type;
    }

    /**
     * 리스트내 고유값 위치를 가져오는 메소드
     * @param idxList 
     * @param idx 
     * @returns 
     */
    const getIdxPositionInList = ( idxList:number[], idx:number ) => {
        for( var i=0; i<idxList.length; i++ ) {
            if( idxList[i] == idx ) return i;
        }
        return -1
    }

    //if( !data ) return null;

    var choiceDate = new Date(); 
    if( data ) {
        choiceDate.setTime(data.choice_date*1000);
    }

    return (
        <PopupWrapper {...props}
            title={"관리자 " + (data?"수정":"추가")}
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title={"저장"} disable={requesting} loading={requesting} onClick={requestUpdate}/>
            </>}>
            
            <div className='body_area popup_admin'>

                <div className={'input_title_box' + (requesting||!!data?" input_title_box_disable":"")}>
                    <div className='title'><span>아이디</span></div>
                    <input className='input' type='text' disabled={requesting||!!data} placeholder='아이디 입력' value={idStr} onChange={(e)=> { setIdStr(e.target.value); }}/>
                </div>

                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <div className='title'><span>비밀번호</span></div>
                    <input className='input' type='text' disabled={requesting} placeholder='비밀번호 입력' value={pwStr} onChange={(e)=> { setPwStr(e.target.value); }}/>
                </div>

                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <div className='title'><span>재입력</span></div>
                    <input className='input' type='text' disabled={requesting} placeholder='비밀번호 재 입력' value={pwReStr} onChange={(e)=> { setPwReStr(e.target.value); }}/>
                </div>

                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <div className='title'><span>이름</span></div>
                    <input className='input' type='text' disabled={requesting} placeholder='이름입력 (ex:OO관리자)' value={nameStr} onChange={(e)=> { setNameStr(e.target.value); }}/>
                </div>

                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <div className='title'><span>전화번호</span></div>
                    <input className='input' type='text' disabled={requesting} placeholder='(선택) 전화번호 입력' value={numberStr} onChange={(e)=> { setNumberStr(e.target.value); }}/>
                </div>

                <div className={'input_title_box' + (requesting?" input_title_box_disable":"")}>
                    <div className='title'><span>이메일</span></div>
                    <input className='input' type='text' disabled={requesting} placeholder='(선택) 이메일 입력' value={emailStr} onChange={(e)=> { setEmailStr(e.target.value); }}/>
                </div>

                <div className='select_box'>
                    <div className='title'><span>권한</span></div>
                    <select className="select"  value={selectAccess} 
                        onChange={(e)=> {
                            setSelectAccess(e.target.value);  
                        }}>
                        <option value={API_TYPES.MANAGE_ACCESS.ALL}>전체</option>
                        <option value={API_TYPES.MANAGE_ACCESS.PART}>일부</option>
                    </select>
                </div>

                { selectAccess == API_TYPES.MANAGE_ACCESS.PART &&
                <div className='post_box'>
                    <table>
                        <tr className='story'>
                            <PartAccessPostItem {...props} name="사연전체" isSelect={accessPartData.story==API_TYPES.MANAGE_ACCESS.ALL} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.story = accessPartData.story==API_TYPES.MANAGE_ACCESS.ALL?NOT_SELECT:API_TYPES.MANAGE_ACCESS.ALL;
                                setAccessPartData( newData );
                            }}/>
                            <PartAccessPostItem {...props} name="사연부분" isSelect={accessPartData.story==API_TYPES.MANAGE_ACCESS.PART} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.story = accessPartData.story==API_TYPES.MANAGE_ACCESS.PART?NOT_SELECT:API_TYPES.MANAGE_ACCESS.PART;
                                setAccessPartData( newData );
                            }}/>
                        </tr>

                        { accessPartData.story==API_TYPES.MANAGE_ACCESS.PART && storyCategoryList && 
                        <tr className='categorys'>
                            <td colSpan={2}>

                            { storyCategoryList.filter( (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST ).map(
                                (item:any, index:number)=>{
                                    var selectPosition = getIdxPositionInList(accessPartData.story_categorys, item.idx);
                                    var isSelect = selectPosition > -1;

                                    return(
                                        <div key={index} className={isSelect?"select":""} onClick={(e)=>{
                                                var newData = {...accessPartData};
                                                if( isSelect )  {newData.story_categorys.splice(selectPosition,1)}
                                                else            {newData.story_categorys.push(item.idx);}
                                                setAccessPartData( newData );
                                            }}>
                                            <div className='checkbox'>
                                                { !isSelect && <BsCheckSquare size={18}/> }
                                                { isSelect && <BsCheckSquareFill size={18}/> }
                                            </div>
                                            <div className='name'><span>{item.name}</span></div>
                                        </div>
                                    )
                                }) 
                            }
                            </td>
                        </tr>
                        }

                        <tr className='community'>
                            <PartAccessPostItem {...props} name="커뮤니티전체" isSelect={accessPartData.community==API_TYPES.MANAGE_ACCESS.ALL} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.community = accessPartData.community==API_TYPES.MANAGE_ACCESS.ALL?NOT_SELECT:API_TYPES.MANAGE_ACCESS.ALL;
                                setAccessPartData( newData );
                            }}/>
                            <PartAccessPostItem {...props} name="커뮤니티부분" isSelect={accessPartData.community==API_TYPES.MANAGE_ACCESS.PART} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.community = accessPartData.community==API_TYPES.MANAGE_ACCESS.PART?NOT_SELECT:API_TYPES.MANAGE_ACCESS.PART;
                                setAccessPartData( newData );
                            }}/>
                        </tr>

                        { accessPartData.community==API_TYPES.MANAGE_ACCESS.PART && communityCategoryList && 
                        <tr className='categorys'>
                            <td colSpan={2}>

                            { communityCategoryList.filter( (i:any) => i.idx != API_TYPES.CATEGORY_TYPE.ALL && i.idx != API_TYPES.CATEGORY_TYPE.BEST ).map(
                                (item:any, index:number)=>{
                                    var selectPosition = getIdxPositionInList(accessPartData.community_categorys, item.idx);
                                    var isSelect = selectPosition > -1;

                                    return(
                                        <div key={index} className={isSelect?"select":""} onClick={(e)=>{
                                                var newData = {...accessPartData};
                                                if( isSelect )  {newData.community_categorys.splice(selectPosition,1)}
                                                else            {newData.community_categorys.push(item.idx);}
                                                setAccessPartData( newData );
                                            }}>
                                            <div className='checkbox'>
                                                { !isSelect && <BsCheckSquare size={18}/> }
                                                { isSelect && <BsCheckSquareFill size={18}/> }
                                            </div>
                                            <div className='name'><span>{item.name}</span></div>
                                        </div>
                                    )
                                }) 
                            }
                            </td>
                        </tr>
                        }

                        <tr>
                            <PartAccessPostItem {...props} name="공지사항전체" isSelect={accessPartData.notice} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.notice = accessPartData.notice?false:true;
                                setAccessPartData( newData );
                            }}/>
                            <PartAccessPostItem {...props} name="금지어" isSelect={accessPartData.taboo} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.taboo = accessPartData.taboo?false:true;
                                setAccessPartData( newData );
                            }}/>
                        </tr>

                        <tr>
                            <PartAccessPostItem {...props} name="사용자" isSelect={accessPartData.user} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.user = accessPartData.user?false:true;
                                setAccessPartData( newData );
                            }}/>
                            <PartAccessPostItem {...props} name="관리자" isSelect={accessPartData.user_admin} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.user_admin = accessPartData.user_admin?false:true;
                                setAccessPartData( newData );
                            }}/>
                        </tr>

                        <tr>
                            <PartAccessPostItem {...props} name="문의사항" isSelect={accessPartData.inquiry} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.inquiry = accessPartData.inquiry?false:true;
                                setAccessPartData( newData );
                            }}/>
                            <PartAccessPostItem {...props} name="시스템" isSelect={accessPartData.system} onClick={(e:any)=>{
                                var newData = {...accessPartData};
                                newData.system = accessPartData.system?false:true;
                                setAccessPartData( newData );
                            }}/>
                        </tr>
                    </table>
                </div>
                }
            </div>
        </PopupWrapper>
    );
};

export default PopupAdmin;

function PartAccessPostItem( props: any ) {
    const {name, isSelect, onClick} = props;

    return( 
        <td className='half'>
            <div className={isSelect?"select":""} onClick={onClick}>
                <div className='checkbox'>
                    { !isSelect && <BsCheckSquare size={18}/> }
                    { isSelect && <BsCheckSquareFill size={18}/> }
                </div>
                <div className='name'><span>{name}</span></div>
            </div>
        </td>
    )
}