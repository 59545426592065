/****************************************************************************************
 * 
 *  메인화면 카테고리 영역
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_POST_HOME_PARAMS, PAGE_URL, makeUrl } from '../../../util/Page';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../redux_saga/modules';
import { movePage } from '../../../util/Global';

function MainCategoryAreaComponent( props:any ) {

    useEffect(()=>{

        return(()=>{})
    },[]);
	return(
        <table className='home__category_area' border={1}>
            <tr>
                <th>사연</th>
                <th>커뮤니티</th>
            </tr>
            <tr>
                <td valign='top'><StoryComponent {...props}/></td>
                <td><CommunityComponent {...props}/></td>
            </tr>
        </table>
	)
}
export default MainCategoryAreaComponent;

function StoryComponent( props : any ) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);

    useEffect(()=>{
        reqCategory();

        return(()=>{})
    },[]);

    
	const reqCategory = () => {
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);

		/*apiAxios.global.listStoryCategory(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx == -1 || i.use_state == API_TYPES.USE_STATE.ABLE ):null;
                setCategoryList(list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})*/
	}

    const clickedCategory = ( item:any, e:any ) => {
        const params : PAGE_POST_HOME_PARAMS = {
            category:item.idx,
            year:(new Date()).getFullYear(),
            page:1
        }
        var url = makeUrl(PAGE_URL.STORY, params);
        movePage(navigate, e, url);
    }

    return ( <>
        { !!storyCategoryList && storyCategoryList.length > 0 &&
            storyCategoryList.map((item:any, index:number)=>{
                return( 
                    <div onClick={(e:any)=>{clickedCategory(item, e)}} key={index}>{item.name}</div>
                )
            })
        }
    </>)
}

function CommunityComponent( props : any ) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    useEffect(()=>{
        reqCategory();

        return(()=>{})
    },[]);

    
	const reqCategory = () => {
        console.log("CommunityComponent reqCategory!!!!!!!!!!!!");
        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
        /*
		apiAxios.global.listCommunityCategory(dispatch, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx == -1 || i.use_state == API_TYPES.USE_STATE.ABLE ):null;
                setCategoryList(list);
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})*/
	}
    
    const clickedCategory = ( item:any, e:any ) => {
        movePage(navigate, e,  PAGE_URL.COMMUNITY + "?category="+item.idx );
    }

    return ( <>
        { !!communityCategoryList && communityCategoryList.length > 0 &&
            communityCategoryList.map((item:any, index:number)=>{
                return( 
                    <div onClick={(e:any)=>{clickedCategory(item, e)}} key={index}>{item.name}</div>
                )
            })
        }
    </>)
}