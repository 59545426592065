/****************************************************************************************
 * 
 *  메인화면
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import BottomCompanyInfo from '../../sub/BottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../../sub/TopAdComponent';
import { ADMIN_TAB_TYPE } from '../sub/AdminTopTab';
import { ADMIN_SUT_TAB_DATA } from '../sub/AdminSubTopTab';
import { ADMIN_SUB_TAP_SETTING, PAGE_URL } from '../../../util/Page';
import UserInfo from './sub/UserInfo';
import { ADMIN_ACCESS_TYPE, ADMIN_MANAGE_TYPE, AdminAccessResult, checkAbleManageAdminPage, makeAdminManageData } from '../../../util/AdminAccessHelper';
import { RootState } from '../../../redux_saga/modules';
import TabooList from './sub/TabooList';
import SystemSetting from './sub/SystemSetting';
import PopupTaboo from '../../popup/PopupTaboo';

const SUB_TAB_DATA_LIST : ADMIN_SUT_TAB_DATA[] = [
	{type:ADMIN_SUB_TAP_SETTING.INFO_UPDATE		, name:"정보수정"},
	{type:ADMIN_SUB_TAP_SETTING.TABOO				, name:"금지어"},
	{type:ADMIN_SUB_TAP_SETTING.SYSTEM_SETTING	, name:"시스템설정"}
];

export interface REFRESH_TABOO_DATA {
	idx:number, name:string|null
}

const AdminSetting = (props:any) => {
	const [subTabType, setSubTabType] = useState(ADMIN_SUB_TAP_SETTING.INFO_UPDATE);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	
	const loginData = useSelector((state:RootState)=>state.data.loginData);
	const [subTabDataList, setSubTabDataList] = useState(SUB_TAB_DATA_LIST);
	const [popupTabooData, setPopupTabooData] = useState<any>(null);
	const [showPopupTaboo, setShowPopupTaboo] = useState(false);
	const [refreshTaboo, setRefreshTaboo] = useState<REFRESH_TABOO_DATA|null>(null);

	useEffect(()=>{
		
		if( loginData ) {

			var newList : ADMIN_SUT_TAB_DATA[] = [];
			newList.push({type:ADMIN_SUB_TAP_SETTING.INFO_UPDATE, name:"정보수정"});
			
			var tabooResult : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.TABOO));
			var systemResult : AdminAccessResult = checkAbleManageAdminPage( loginData, makeAdminManageData(ADMIN_MANAGE_TYPE.SYSTEM));

			if( tabooResult.accessType==ADMIN_ACCESS_TYPE.ALL ) {
				newList.push({type:ADMIN_SUB_TAP_SETTING.TABOO	, name:"금지어"});
			}
			if( systemResult.accessType==ADMIN_ACCESS_TYPE.ALL ) {
				newList.push({type:ADMIN_SUB_TAP_SETTING.SYSTEM_SETTING	, name:"시스템설정"});
			}
			setSubTabDataList( newList );
		}

		return(()=>{})
	},[loginData]);

	const openTabooPopup = ( tabooData:any ) => {
		setShowPopupTaboo(true);
		setPopupTabooData(tabooData);
	}

	return (
		<Wrapper
			tabType={ADMIN_TAB_TYPE.SETTING}
			defaultSubTab={ADMIN_SUB_TAP_SETTING.INFO_UPDATE}
			setSubTabType={setSubTabType}
			subTabType={subTabType}
			pageUrl={PAGE_URL.ADMIN_SETTING}
			tabDataList={subTabDataList}
			popup={
				<PopupTaboo
					visible={showPopupTaboo}
					onClose={()=>{ setShowPopupTaboo(false); }}
					tabooData={popupTabooData}
					onSuccess={( isClosePopup : boolean, name:string )=>{
						if( popupTabooData ) {
							setRefreshTaboo({idx:popupTabooData.idx, name:name});
						}
						else {
							setRefreshTaboo({idx:-1,name:null});
						}

						if( isClosePopup ) {
							setShowPopupTaboo(false); 
							setPopupTabooData(null);
						}
					}}
					/>
			}>
			<MiddleComponent {...props}
				subTabType={subTabType}
				openTabooPopup={openTabooPopup}
				refreshTaboo={refreshTaboo}
				setRefreshTaboo={setRefreshTaboo}/>
		</Wrapper>
	);
};

export default AdminSetting;


function MiddleComponent( props:any ) {
	const { subTabType } = props;

	return(
		<div className='middle_area'>
			{ subTabType == ADMIN_SUB_TAP_SETTING.INFO_UPDATE 		&& <UserInfo {...props}/>}
			{ subTabType == ADMIN_SUB_TAP_SETTING.TABOO 			&& <TabooList {...props}/>}
			{ subTabType == ADMIN_SUB_TAP_SETTING.SYSTEM_SETTING 	&& <SystemSetting {...props}/>}
		</div>
	)
}