/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../redux_saga/modules';
import { checkLogin } from '../../../util/Global';
import Header from './Header';
import AdminTopTab from './AdminTopTab';
import AdminSubTopTab from './AdminSubTopTab';
import { isMobile } from 'react-device-detect';
import { checkAccessRePage } from '../../../util/AdminAccessHelper';

const Wrapper = (props:any) => {
    const {
        popup,
        children,
        tabType,
        isCheckLogin,
        hideTab,
        hideSubTab,
        defaultSubTab,
        setSubTabType
    } = props;
	const navigate = useNavigate();
    const distpatch = useDispatch();

    const loginData = useSelector((state:RootState)=>state.data.loginData);
    
	const location = useLocation();

    useEffect(()=>{
        checkLogin(navigate, loginData, true, distpatch );
        if( loginData ) {
            checkAccessRePage( navigate, loginData, location );
        }
        return(()=>{})
    },[loginData, location]);
	
	useEffect(()=>{
        if( hideSubTab != true ) {
            const l_p = new URLSearchParams(location.search);
            const t = l_p.get("tab");
    
            var newTabType = defaultSubTab;
    
            if( t ) 	{ try{ newTabType = parseInt(t); }catch(e){ console.log("e",e);} }
            
            setSubTabType(newTabType);
        }

        return(()=>{})
    },[location]);

	return ( <>
		<div className={'admin_body_div' + (isMobile?" admin_mobile_body_div":"")}>
            <Header {...props} />
            { hideTab != true &&
			<AdminTopTab foucsType={tabType}/>
            }
            { hideSubTab != true &&
			<AdminSubTopTab {...props}/>
            }
            
			{children}
		</div>	
        {popup}
        </>
	);
};

export default Wrapper;