/****************************************************************************************
 * 
 *  홈페이지 상단 로고 및 검색입력 부분
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_TYPES } from '../../apiAxios';
import {AiOutlineSearch} from "react-icons/ai";
import { PAGE_SEARCH, PAGE_URL, makeUrl } from '../../util/Page';
import { TEST_VERSION } from '../../util/ConstValue';

function Header( props : any ) {
    const { hideSearch, subTitle } = props;
    const navigate = useNavigate();

    /**
     * 웹 페이지 변경될때 상단으로 이동
     */    
	const location = useLocation();
	
	useEffect(()=>{
		window.scrollTo(0,0);
		return ()=>{}
	},[location])

    useEffect(()=>{

        return(()=>{})
    },[]);

    return (
        <div className='header'>
            <div className='header_body'>
                <div className='header_title_area'>
                    <div className='logo'>
                        <span onClick={()=>{
                            navigate( PAGE_URL.MAIN );
                        }}>{"모여봐" + (TEST_VERSION?" (테스트)":"")}</span>
                    </div>

                    { !!subTitle && 
                        <div className='sub_title'>
                            <span>{subTitle}</span> 
                        </div>
                    }
                </div>
                
                { hideSearch != true &&
                <SearchBox {...props}/>
                }
            </div>
        </div>
    );
}

export default Header;

function SearchBox( props : any ) {
    const navigate = useNavigate();
	const location = useLocation();
    
    const [searchSelect, SetSearchSelect] = useState<any>(API_TYPES.SEARCH_TYPE.ALL);
    const [searchInputValue, setSearchInputValue] = useState("");
    

	useEffect(()=>{
		const l_p = new URLSearchParams(location.search);
		const s = l_p.get("search");

        var newSearch = "";

		if( s ) 	{ try{ newSearch = s; 		    }catch(e){ console.log("e",e);} }

        setSearchInputValue(newSearch);
		
		return(()=>{})
	},[location]);


    const onClickSearch = () => {
        if( !searchInputValue ) {
            alert("검색어를 입력하세요");
            return;
        }

        var params : PAGE_SEARCH = {
            search:searchInputValue,
            page:null,
            type:searchSelect,
            tabType:null,
            year:null
        }
        var url = makeUrl( PAGE_URL.SEARCH, params );
        navigate( url );
    }
  
    const handleKeyUp = (e:any) => {
      if( e.keyCode == 13){ // 키보드 Enter 클릭
        onClickSearch();
      }
      else if( e.keyCode == 27 ){ // ESC 클릭
        
      }
    }

    return (
        <div className='header_search_area'>
            <select className="select"  value={searchSelect} 
              onChange={(e)=> {
                SetSearchSelect(e.target.value);  
              }}>
              <option value={API_TYPES.SEARCH_TYPE.ALL}>전체</option>
              <option value={API_TYPES.SEARCH_TYPE.TITLE}>제목</option>
              <option value={API_TYPES.SEARCH_TYPE.WRITER}>작성자</option>
            </select>

            <input type='search' placeholder="검색" className="search_input" autoComplete="off"
                value={searchInputValue} 
                onChange={(e)=> {
                    setSearchInputValue(e.target.value);
                }}
                onKeyUp={handleKeyUp} 
            />
            <div className='search_btn_area' onClick={onClickSearch}>
                <AiOutlineSearch className='search_btn' size={20}/>
            </div>
        </div>
    )
}