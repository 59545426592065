
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS} from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, isToday, makeInsertTimFullStr, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { PAGE_ADMIN_NOTICE, PAGE_ADMIN_NOTICE_WRITE, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import ButtonDefault from '../../../sub/ButtonDefault';
import { RootState } from '../../../../redux_saga/modules';
import reduxActions from '../../../../redux_saga/modules/moduleActions';
import { ADMIN_ACCESS_TYPE, AdminNoticeAccessResult, AdminPostAccessResult, makeCategoryIdxArray } from '../../../../util/AdminAccessHelper';
import { isMobile } from 'react-device-detect';

interface MovePageParam {
    param:string, value:any
}

const NoticeList = (props:any) => {
    const { accessResult } = props;
    
    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    const [itemList, setItemList] = useState<any>(null);
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectNoticeState, setSelectNoticeState] = useState<any>(FILTER_VALUE_ALL);
    const [selectPostType, setSelectPostType] = useState<any>(FILTER_VALUE_ALL);
    const [selectCategory, setSelectCategory] = useState<any>(FILTER_VALUE_ALL);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();

	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );
        var newNoticeState  = getParamData(l_p, "noticeState", FILTER_VALUE_ALL );
        var newPostType     = getParamData(l_p, "postType", FILTER_VALUE_ALL );
        var newCategory     = getParamData(l_p, "category", FILTER_VALUE_ALL );

        setPage(newPage);
        setSearchText(newSearch);
        setSelectNoticeState(newNoticeState);
        setSelectPostType(newPostType);
        setSelectCategory(newCategory);

        reqList(newPostType, newCategory, newPage, newNoticeState, newSearch);

        const reqData = reduxActions.axios.makeReqData(dispatch, null);
        reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
        reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);

        return(()=>{})
    },[location]);

    
	useEffect(()=>{
        if( !storyCategoryList || storyCategoryList.length == 0 ) {
            const reqData = reduxActions.axios.makeReqData(dispatch, null);
            reduxActions.axios.ReqListStoryCategory(dispatch, reqData);
        }

        return(()=>{})
    },[storyCategoryList]);

    
	useEffect(()=>{
        if( !communityCategoryList || communityCategoryList.length == 0 ) {
            const reqData = reduxActions.axios.makeReqData(dispatch, null);
            reduxActions.axios.ReqListCommunityCategory(dispatch, reqData);
        }

        return(()=>{})
    },[communityCategoryList]);


    const reqList = ( post_type:number|null, cate_idx:number|null, page:number, use_state:number|null, total_search:string|null ) => {

        if( post_type == FILTER_VALUE_ALL ) post_type = null;
        if( cate_idx == FILTER_VALUE_ALL ) cate_idx = null;
        if( use_state == FILTER_VALUE_ALL ) use_state = null;
        if( total_search == "" ) total_search = null;

        var post_categorys = cate_idx==null?makeReqListPostCategorys():null;

        apiAxios.admin.listNotice( dispatch, post_type, cate_idx, page, use_state, total_search, post_categorys, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const makeReqListPostCategorys = () => {
        var result : any = null;
        
        if( accessResult.accessType == ADMIN_ACCESS_TYPE.PART ) {
            result = [];

            if( accessResult.posts && accessResult.posts.length > 0 ) {
                for( var i=0; i<accessResult.posts.length; i++ ) {
                    var post : AdminPostAccessResult = accessResult.posts[i];
                    if( post.accessType == ADMIN_ACCESS_TYPE.ALL ) {
                        result.push({post_type:post.postType, categorys:null});
                    }
                    else if( post.accessType == ADMIN_ACCESS_TYPE.PART ) {
                        result.push({post_type:post.postType, categorys:post.categorys});
                    }
                }
            }
        }

        if( result ) {
            return JSON.stringify(result);
        }
        else {
            return null;
        }
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( [{param:"search", value:searchText}]) );
    }

    const getMovePageParam = ( movePageParam : MovePageParam[]|null ) : PAGE_ADMIN_NOTICE => {
        
        var params : PAGE_ADMIN_NOTICE = {
            tab         :null, 
            year        :null,
            page        :1,
            search      :!!searchText?searchText:null, 
            searchType  :null,
            noticeState :selectNoticeState     ==FILTER_VALUE_ALL?null:selectNoticeState,
            postType    :selectPostType ==FILTER_VALUE_ALL?null:selectPostType,
            category    :selectCategory ==FILTER_VALUE_ALL?null:selectCategory,
        }

        if( movePageParam ) {
            
            for( var i=0; i<movePageParam.length; i++ ) {
                var p : MovePageParam = movePageParam[i];

                if( p.param == "search" )             params["search"]        = p.value;
                else if( p.param == "noticeState" )   params["noticeState"]   = p.value==FILTER_VALUE_ALL?null:p.value;
                else if( p.param == "postType" )      params["postType"]      = p.value==FILTER_VALUE_ALL?null:p.value;
                else if( p.param == "category" )      params["category"]      = p.value==FILTER_VALUE_ALL?null:p.value;
            }
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_NOTICE ) => {
        var url = makeUrl(PAGE_URL.ADMIN_NOTICE, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_notice_list_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                selectNoticeState={selectNoticeState}
                setSelectNoticeState={(value:any)=>{
                    setSelectNoticeState(value);
                    
                    changePage( getMovePageParam( [{param:"noticeState", value:value}]) );
                }}
                selectPostType={selectPostType}
                setSelectPostType={(value:any)=>{
                    setSelectPostType(value);
                    
                    var movePageParams : MovePageParam[] = [];
                    movePageParams.push({param:"postType", value:value});
                    movePageParams.push({param:"category", value:null});
                    changePage( getMovePageParam( movePageParams ) );
                }}
                selectCategory  ={selectCategory}
                setSelectCategory={(value:any)=>{
                    setSelectCategory(value);
                    
                    changePage( getMovePageParam( [{param:"category", value:value}]) );
                }}
                />
			<ul>
                { isMobile != true && 
                    <li className={'title' + titleBg}>
                        <div className='title'>제목</div>
                        <div className='state'>상태</div>
                        <div className='insert_time'>작성일</div>
                        <div className='type'>구분</div>
                        <div className='category'>카테고리</div>
                        <div className='start'>시작</div>
                        <div className='end'>종료</div>
                        <div className='edit'>수정</div>
                    </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index} page={page}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>공지사항 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_NOTICE = getMovePageParam(null);

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}

					var url = makeUrl(PAGE_URL.ADMIN_NOTICE, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default NoticeList;

function ListTop( props:any ) {
    const {
        selectNoticeState, setSelectNoticeState,
        selectPostType, setSelectPostType,
        selectCategory, setSelectCategory,
        onSearch, searchText,
        accessResult
    } = props;

    const navigate = useNavigate();

    const [categoryList, setCategoryList] = useState<OptionData[]>([ALL_FilTER_DATA]) ;

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);
    
    const makeCategotyList = ( list : any, post_type:number ) => {
        var newData : OptionData[] = [];
        newData.push(ALL_FilTER_DATA);

        if( list ) {
            if( iaAddNotSelect(post_type) ) {
                newData.push({value:0, name:"지정안함"});
            }
            for( var i=0; i<list.length; i++ ) {
                var item = list[i];
                if( item.idx != API_TYPES.CATEGORY_TYPE.ALL && item.idx != API_TYPES.CATEGORY_TYPE.BEST ) {
                    if( isAddCategory( post_type, item.idx ) ) {
                        newData.push({value:item.idx, name:item.name});
                    }
                }
            }
        }
        return newData;
    }

    /* 선택안함 카테고리 추가 유무 */
    const iaAddNotSelect = ( post_type:number ) => {
        if( accessResult.accessType == ADMIN_ACCESS_TYPE.ALL ) {
            return true;
        }
        else if( accessResult.accessType == ADMIN_ACCESS_TYPE.PART && accessResult.posts && accessResult.posts.length > 0 ) {
            for( var i=0; i<accessResult.posts.length; i++ ) {
                var post : AdminPostAccessResult = accessResult.posts[i];
                if( post.postType == post_type ) {
                    if( post.accessType == ADMIN_ACCESS_TYPE.ALL ) return true
                    else    break;
                }
            }
        }
        return false;
    }

    /* 카테고리 추가 유무 */
    const isAddCategory = ( post_type:number, cate_idx:number ) => {
        if( accessResult.accessType == ADMIN_ACCESS_TYPE.ALL ) {
            return true;
        }
        else if( accessResult.accessType == ADMIN_ACCESS_TYPE.PART && accessResult.posts && accessResult.posts.length > 0 ) {
            for( var i=0; i<accessResult.posts.length; i++ ) {
                var post : AdminPostAccessResult = accessResult.posts[i];
                if( post.postType == post_type ) {
                    if( post.accessType == ADMIN_ACCESS_TYPE.ALL ) {
                        return true;
                    }
                    else if( post.accessType == ADMIN_ACCESS_TYPE.PART ) {
                        var categoryArr = makeCategoryIdxArray( post.categorys );
                        if( categoryArr ) {
                            for( var j=0; j<categoryArr.length; j++ ) {
                                if( categoryArr[j] == cate_idx ) return true;
                            }
                        }
                    }

                    break;
                }
            }
        }
        return false;
    }
    

    const getPostTypeOption = ( result: AdminNoticeAccessResult ) => {
        if( result.accessType == ADMIN_ACCESS_TYPE.ALL ) {
            return [ALL_FilTER_DATA, 
                {value:API_TYPES.POST_TYPE.ALL, name:"기본"}, 
                {value:API_TYPES.POST_TYPE.STORY, name:"사연"}, 
                {value:API_TYPES.POST_TYPE.COMMUNITY, name:"커뮤니티"}]
        }
        else if( result.accessType == ADMIN_ACCESS_TYPE.PART && accessResult.posts && accessResult.posts.length > 0 ) {
            var option : OptionData[] = [];
            option.push(ALL_FilTER_DATA);

            for( var i=0; i<accessResult.posts.length; i++ ) {
                var post : AdminPostAccessResult = accessResult.posts[i];
                if( post.postType == API_TYPES.POST_TYPE.STORY ) {
                    if( post.accessType == ADMIN_ACCESS_TYPE.ALL || post.accessType == ADMIN_ACCESS_TYPE.PART ) {
                        option.push({value:API_TYPES.POST_TYPE.STORY, name:"사연"});
                    }
                }
                else if( post.postType == API_TYPES.POST_TYPE.COMMUNITY ) {
                    if( post.accessType == ADMIN_ACCESS_TYPE.ALL || post.accessType == ADMIN_ACCESS_TYPE.PART ) {
                        option.push({value:API_TYPES.POST_TYPE.COMMUNITY, name:"커뮤니티"});
                    }
                }
            }
            return option;
        }

        return [ALL_FilTER_DATA];
    }
    
	useEffect(()=>{
        // 
        if( selectPostType == API_TYPES.POST_TYPE.STORY ) {
            setCategoryList(makeCategotyList(storyCategoryList, API_TYPES.POST_TYPE.STORY));
        }
        else if( selectPostType == API_TYPES.POST_TYPE.COMMUNITY ) {
            setCategoryList(makeCategotyList(communityCategoryList, API_TYPES.POST_TYPE.COMMUNITY));
        }
        else {
            setCategoryList([ALL_FilTER_DATA]);
        }
        return(()=>{})
    },[selectPostType, storyCategoryList, communityCategoryList]);


    const stateOption : OptionData[] = [ALL_FilTER_DATA, 
        {value:API_TYPES.NOTICE_STATE.ABLE, name:"사용"}, 
        {value:API_TYPES.NOTICE_STATE.DISABLE, name:"정지"}, 
        {value:API_TYPES.NOTICE_STATE.WAIT, name:"대기"}, 
        {value:API_TYPES.NOTICE_STATE.EXPIRATION, name:"만료"}
    ];
    const postTypeOption : OptionData[] = getPostTypeOption(accessResult);
    

    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
            <AdminSelectBox {...props} title={"상태"} className={"notice_state"}     options={stateOption} selectValue={selectNoticeState} SetSelectValue={setSelectNoticeState}/>
            <AdminSelectBox {...props} title={"구분"} className={"post_type"}        options={postTypeOption} selectValue={selectPostType} SetSelectValue={(value:any)=>{setSelectPostType(value);}}/>
            <ButtonDefault 
                {...props}
                className="write_btn"
                value={"글쓰기"}
                onClick={(e:any)=>{
                    var url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, null);
                    //navigate(url);
                    movePage(navigate, e, url);
                }}
                />
            </div>
            { ( selectPostType == API_TYPES.POST_TYPE.STORY || selectPostType == API_TYPES.POST_TYPE.COMMUNITY ) &&
            <div className={'list_top'}>
                <AdminSelectBox {...props} title={"카테고리"} className={"category_type"} options={categoryList} selectValue={selectCategory} SetSelectValue={setSelectCategory}/>
            </div>
            }
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"제목검색"} onSearch={onSearch} defaultSearchText={searchText}/>
            </div>
        </>)
    }

    return(
        <div className='list_top'>
            <AdminSelectBox {...props} title={"상태"} className={"notice_state"}     options={stateOption} selectValue={selectNoticeState} SetSelectValue={setSelectNoticeState}/>
            <AdminSelectBox {...props} title={"구분"} className={"post_type"}        options={postTypeOption} selectValue={selectPostType} 
                SetSelectValue={(value:any)=>{setSelectPostType(value);}}/>
            { ( selectPostType == API_TYPES.POST_TYPE.STORY || selectPostType == API_TYPES.POST_TYPE.COMMUNITY ) &&
                <AdminSelectBox {...props} title={"카테고리"} className={"category_type"} options={categoryList} selectValue={selectCategory} SetSelectValue={setSelectCategory}/>
            }
            <AdminSearchBox {...props} placeholder={"제목검색"} onSearch={onSearch} defaultSearchText={searchText}/>
            <ButtonDefault 
                {...props}
                className="write_btn"
                value={"글쓰기"}
                onClick={(e:any)=>{
                    var url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, null);
                    //navigate(url);
                    movePage(navigate, e, url);
                }}
                />
        </div>
    )
}

const getCategoryName = ( list:any, idx:number|null ) => {
    var name = ".";
    if( list ) {
        for(var i=0; i<list.length; i++) {
            if( list[i].idx == idx ) {
                name = list[i].name;
                break;
            }
        }
    }
    return name;
}    

function CategoryItem( props : any ) {
    const {item, openPostPage, page} = props;
    
    const navigate = useNavigate();

    const storyCategoryList = useSelector((state:RootState)=>state.data.storyCategoryList);
    const communityCategoryList = useSelector((state:RootState)=>state.data.communityCategoryList);

    const stateStr = item.notice_state==API_TYPES.NOTICE_STATE.ABLE?"노출"
                    :item.notice_state==API_TYPES.NOTICE_STATE.DISABLE?"정지"
                    :item.notice_state==API_TYPES.NOTICE_STATE.WAIT?"대기"
                    :item.notice_state==API_TYPES.NOTICE_STATE.EXPIRATION?"만료":"";

    const stateClass = item.notice_state==API_TYPES.NOTICE_STATE.ABLE?""
                    :item.notice_state==API_TYPES.NOTICE_STATE.DISABLE?" color_red"
                    :item.notice_state==API_TYPES.NOTICE_STATE.WAIT?" color_blue"
                    :item.notice_state==API_TYPES.NOTICE_STATE.EXPIRATION?" color_red":"";

    const typeStr = item.post_type==API_TYPES.POST_TYPE.ALL?"기본"
                    :item.post_type==API_TYPES.POST_TYPE.STORY?"사연"
                    :item.post_type==API_TYPES.POST_TYPE.COMMUNITY?"커뮤니티":"";

    const categoryStr = item.post_type==API_TYPES.POST_TYPE.ALL?"."
                    :item.post_type==API_TYPES.POST_TYPE.STORY?getCategoryName(storyCategoryList, item.cate_idx)
                    :item.post_type==API_TYPES.POST_TYPE.COMMUNITY?getCategoryName(communityCategoryList, item.cate_idx):"";

    const startDate = new Date();
    startDate.setTime(item.start*1000);

    const endDate = new Date();
    endDate.setTime(item.end*1000);

    const clickedEdit = (e:any) => {
        var params : PAGE_ADMIN_NOTICE_WRITE = {idx:item.idx, page:page};
        var url = makeUrl(PAGE_URL.ADMIN_NOTICE_WIRTE, params);
        movePage(navigate, e, url);
    }

    const clickedOpenPage = () => {
        openPostPage(item.idx, item.post_type, item.cate_idx);
    }

    if( isMobile ) {
        return(
            <li className="item">
                <div className='title' onClick={clickedOpenPage}> {item.title} </div>
                <div className='sub' onClick={clickedOpenPage}>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
                    <div className='divide'>·</div>
                    <div className={'state' + stateClass}> {stateStr} </div>
                    <div className='divide'>·</div>
                    <div className={'type'}>{typeStr}</div>
                    { categoryStr != "." && <>
                    <div className='divide'>·</div>
                    <div className={'category'}>{categoryStr}</div>
                    </>}
                </div>
                <div className='sub sub_bg top_border' onClick={clickedOpenPage}>
                    <div className={'start'}>{"시작 : " + makeInsertTimeStr(startDate, true)}</div>
                    <div className='divide'>·</div>
                    <div className={'end'}>{"종료 : " + makeInsertTimeStr(endDate, true)}</div>
                </div>
                <div className='sub button_area'>
                    <div className={'edit pointer'} onClick={clickedEdit}>{"수정"}</div>
                </div>
            </li>
        )
    }

    return(
        <li className="item">
            <div className='title' onClick={clickedOpenPage}> {item.title} </div>
            <div className={'state' + stateClass}> {stateStr} </div>
            <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time)}</div>
            <div className={'type'}>{typeStr}</div>
            <div className={'category'}>{categoryStr}</div>
            <div className={'start'}>{makeInsertTimeStr(startDate, true)}</div>
            <div className={'end'}>{makeInsertTimeStr(endDate, true)}</div>
            <div className={'edit pointer'} onClick={clickedEdit}>{"수정"}</div>
        </li>
    )
}
