import { AxiosError, AxiosResponse } from 'axios';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { REQ_DATA } from '../domain/axios';
import { RootState } from '.';
import { SetAdminEmail, SetCommunityCategoryList, SetStoryCategoryList } from './data';

const SAGA_GLOBAL_LIST_STORY_CATEGORY      = 'axios::saga::global_list_story_category' as const;
const SAGA_GLOBAL_LIST_COMMUNITY_CATEGORY  = 'axios::saga::global_list_community_category' as const;
const SAGA_GLOBAL_DATA_ADMIN_EMAIL         = 'axios::saga::global_data_admin_email' as const;

export interface DataAction {
    type:string,
    reqData:REQ_DATA
}

/**
 * 요청데이터를 만드는 메소드
 * @param dispatch 
 * @param param 
 * @param listParams 
 * @returns 
 */
export const makeReqData = ( dispatch:any, param:any) : REQ_DATA => {
    return {dispatch:dispatch, param:param};
}

/* async 데이터 저장 요청 */
export const AxiosReqListStoryCategory      = ( d : REQ_DATA ) => ({type:SAGA_GLOBAL_LIST_STORY_CATEGORY, reqData:d})
export const AxiosReqListCommunityCategory  = ( d : REQ_DATA ) => ({type:SAGA_GLOBAL_LIST_COMMUNITY_CATEGORY, reqData:d})
export const AxiosReqDataAdminEmail         = ( d : REQ_DATA ) => ({type:SAGA_GLOBAL_DATA_ADMIN_EMAIL, reqData:d})

export function* axiosSaga() {
    yield takeEvery(SAGA_GLOBAL_LIST_STORY_CATEGORY,        fetchGlobalListStoryCategory);
    yield takeEvery(SAGA_GLOBAL_LIST_COMMUNITY_CATEGORY,    fetchGlobalListCommunityCategory);
    yield takeEvery(SAGA_GLOBAL_DATA_ADMIN_EMAIL,           fetchGlobalDataAdminEmail);
}


/***********************************************************************************
 * 데이터 요청
 ***********************************************************************************/
/**
 * 사연 카테고리 요청
 * @param action 
 */
function* fetchGlobalListStoryCategory( action : DataAction ) { 
    try {         
        console.log("fetchGlobalListStoryCategory!!!!");
        const reqData : REQ_DATA = action.reqData;
        const response : AxiosResponse = yield apiAxios.global.listStoryCategory(reqData.dispatch, null);
        console.log("fetchGlobalListStoryCategory response : ", response);
        if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
            const resData = API_UTILS.getResponseSuccessData(response);
            const list = resData.list?resData.list.filter( (i:any) => i.use_state == API_TYPES.USE_STATE.ABLE ):null;
            
            yield put(SetStoryCategoryList(list));  
        }
    }catch(e){ 
        console.log("fetchGlobalListStoryCategory",e); 
    } 
}

/**
 * 리쿼샵 리스트 요청
 * @param action 
 */
function* fetchGlobalListCommunityCategory( action : DataAction ) { 
    try {         
        const reqData : REQ_DATA = action.reqData;
        const response : AxiosResponse = yield apiAxios.global.listCommunityCategory(reqData.dispatch, null);
        if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
            const resData = API_UTILS.getResponseSuccessData(response);
            const list = resData.list?resData.list.filter( (i:any) => i.use_state == API_TYPES.USE_STATE.ABLE ):null;
            
            yield put(SetCommunityCategoryList(list));  
        }
    }catch(e){ 
        console.log("fetchGlobalListStoryCategory",e); 
    } 
}

/**
 * 관리자 메일주소 요청
 * @param action 
 */
function* fetchGlobalDataAdminEmail( action : DataAction ) { 
    try {         
        const reqData : REQ_DATA = action.reqData;
        const response : AxiosResponse = yield apiAxios.global.dataAdminEmail(reqData.dispatch, null);
        if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
            const resData = API_UTILS.getResponseSuccessData(response);            
            yield put(SetAdminEmail(resData));  
        }
    }catch(e){ 
        console.log("fetchGlobalListStoryCategory",e); 
    } 
}



/***********************************************************************************************
 * 
 * 공통 메소드
 * 
 ***********************************************************************************************/

/**
 * 수신받은 데이터로 리스트 데이터를 만들어내는 메소드
 * @param response 
 * @returns 
 *//*
function makeListDataFromResponse( response, type ) {
    
    var date = new Date();
    var data : LIST_DATA = {nextUrl:null, total:-1, list:[], requesting:false, type:type, update_time:date.getTime()};

    var responseData = response.data;
    
    if( responseData.data && responseData.data.datas && responseData.data.datas.length > 0 ) {
      console.log("makeListDataFromResponse is have");

      data.list = responseData.data.datas;
    }
    else {
      console.log("makeListDataFromResponse is nothing");
      data.list = [];
    }

    console.log("makeListDataFromResponse total_count : " + responseData.data.total_count);

    data.total = responseData.data.total_count?responseData.data.total_count:0;
    
    if( responseData.data.next ) data.nextUrl = responseData.data.next;
    return data;
}*/