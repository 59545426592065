
/****************************************************************************************
 * 
 *  사연
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import apiAxios, { API_TYPES, API_UTILS, ParamAdminListStoryChoice } from '../../../../apiAxios';
import { AxiosError, AxiosResponse } from 'axios';
import AdminSearchBox from '../../sub/AdminSearchBox';
import { getParamData, isToday, makeInsertTimFullStr, makeInsertTimeStr, movePage } from '../../../../util/Global';
import AdminSelectBox, { ALL_FilTER_DATA, FILTER_VALUE_ALL, OptionData } from '../../sub/AdminSelectBox';
import { ADMIN_SUB_TAP_STORY, PAGE_ADMIN_STORY_CHOICE, PAGE_NUMBER_DATA, PAGE_NUMBER_TYPE, PAGE_URL, getStartPage, makeUrl } from '../../../../util/Page';
import AdminPageComponent from '../../sub/AdminPageComponent';
import { isMobile } from 'react-device-detect';

const Choice = (props:any) => {
    const { accessCatagorys, refreshChoiceData, setRefreshChoiceData, refreshChoiceCancel, setRefreshChoiceCancel } = props;

    const [itemList, setItemList] = useState<any>(null);
	const [year, setYear] = useState((new Date()).getFullYear());
	const [page, setPage] = useState(1);
	const [searchText, setSearchText] = useState<any>(null);
	const [totalData, setTotalData] = useState<any>(null);

    const [selectType, setSelectType] = useState(FILTER_VALUE_ALL);
    const [selectSendType, setSelectSendType] = useState(FILTER_VALUE_ALL);
    const [selectShowType, setSelectShowType] = useState(FILTER_VALUE_ALL);
    const [searchSelect, SetSearchSelect] = useState(API_TYPES.SEARCH_TYPE.ALL);
    const [selectSort, setSelectSort] = useState(API_TYPES.POST_LIST_SORT_TYPE.CHOICE_DATE);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const location = useLocation();
	
	useEffect(()=>{
        const l_p = new URLSearchParams(location.search);

        var newYear         = getParamData(l_p, "year", (new Date()).getFullYear() );
        var newPage         = getParamData(l_p, "page", 1 );
        var newSearch       = getParamData(l_p, "search", null, true );
        var newSearchType   = getParamData(l_p, "searchType", API_TYPES.SEARCH_TYPE.ALL );
        var newSelectType   = getParamData(l_p, "type", FILTER_VALUE_ALL );
        var newSendType     = getParamData(l_p, "sendType", FILTER_VALUE_ALL );
        var newShowType     = getParamData(l_p, "showType", FILTER_VALUE_ALL );
        var newSort         = getParamData(l_p, "sort", API_TYPES.POST_LIST_SORT_TYPE.CHOICE_DATE );

        setYear(newYear);
        setPage(newPage);
        setSearchText(newSearch);
        SetSearchSelect(newSearchType);
        setSelectType(newSelectType);
        setSelectSendType(newSendType);
        setSelectShowType(newShowType);
        setSelectSort(newSort);

        reqList(newYear, newPage, newSearch, newSelectType, newSendType, newShowType, newSort, newSearchType);

        return(()=>{})
    },[location]);

	useEffect(()=>{

        if( refreshChoiceData ) {

            var newItemList = itemList.map((item:any, index:number)=>{
                if( item.idx == refreshChoiceData.idx ) {
                    var newItem = {...refreshChoiceData};
                    return newItem;
                }
                return item;
            })
            setItemList(newItemList);


            setRefreshChoiceData(null);
        }
        return(()=>{})
    },[refreshChoiceData]);

	useEffect(()=>{

        if( refreshChoiceCancel ) {
            reqList(year, page, searchText, selectType, selectSendType, selectShowType, selectSort, searchSelect);

            setRefreshChoiceCancel(false);
        }
        return(()=>{})
    },[refreshChoiceCancel, year, page, searchText, selectType, selectSendType, selectShowType, selectSort, searchSelect]);


    const reqList = ( year:number, page:number, searchText:string|null, choice_type:number, choice_is_send:number, choice_is_show:number, sort:number, search_type:number ) => {

        var param : ParamAdminListStoryChoice = {
            year            :year,
            page            :page,
            choice_type     :choice_type    ==FILTER_VALUE_ALL?null:choice_type,
            choice_is_send  :choice_is_send ==FILTER_VALUE_ALL?null:choice_is_send,
            choice_is_show  :choice_is_show ==FILTER_VALUE_ALL?null:choice_is_show,
            sort            :sort,
            search_type     :search_type,
            total_search    :searchText,
            categorys       :accessCatagorys
        }

        apiAxios.admin.listStoryChoice( dispatch, param, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
				const list = resData.list?resData.list.filter( (i:any) => i.idx > 0 ):null;

				setItemList( list );
				setTotalData( resData.total_data );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.NOT_FOUND ) {

				}
			}
		})
    }

    const onSearch = ( search : string ) => {

        var searchText : any = search;
        if( !!search && search != "" ) {
            setSearchText( search );
        }
        else {
            searchText = null;
            setSearchText( null );
        }

        changePage( getMovePageParam( "search", searchText ) );
    }

    const getMovePageParam = ( param:string|null = null, value:any = null ) : PAGE_ADMIN_STORY_CHOICE => {
        var params : PAGE_ADMIN_STORY_CHOICE = {
            tab         :ADMIN_SUB_TAP_STORY.COICE, 
            year        :year,
            page        :1,
            search      :!!searchText?searchText:null, 
            searchType  :searchSelect,
            type        :selectType     ==FILTER_VALUE_ALL?null:selectType,
            sendType    :selectSendType ==FILTER_VALUE_ALL?null:selectSendType,
            showType    :selectShowType ==FILTER_VALUE_ALL?null:selectShowType,
            sort        :selectSort
        }
        if( !!param ) {
            if( param == "search" )             params["search"]        = value;
            else if( param == "searchType" )    params["searchType"]    = value==FILTER_VALUE_ALL?null:value;
            else if( param == "type" )          params["type"]          = value==FILTER_VALUE_ALL?null:value;
            else if( param == "sendType" )      params["sendType"]      = value==FILTER_VALUE_ALL?null:value;
            else if( param == "showType" )      params["showType"]      = value==FILTER_VALUE_ALL?null:value;
            else if( param == "sort" )          params["sort"]          = value;
        }

        return params;
    }

    const changePage = ( params : PAGE_ADMIN_STORY_CHOICE ) => {
        var url = makeUrl(PAGE_URL.ADMIN_STORY, params);
        navigate(url);
    }

    var titleBg = !itemList || itemList.length == 0?" title_empty":"";

	return (
		<div className='admin_list_box admin_story_choice_box'>
            <ListTop 
                {...props}
                searchText={searchText}
                onSearch={onSearch}
                selectType={selectType}
                setSelectType={(value:any)=>{
                    setSelectType(value);
                    
                    changePage( getMovePageParam( "type", value ) );
                }}
                selectSendType={selectSendType}
                setSelectSendType={(value:any)=>{
                    setSelectSendType(value);
                    
                    changePage( getMovePageParam( "sendType", value ) );
                }}
                selectShowType  ={selectShowType}
                setSelectShowType={(value:any)=>{
                    setSelectShowType(value);
                    
                    changePage( getMovePageParam( "showType", value ) );
                }}
                searchSelect  ={searchSelect}
                SetSearchSelect={(value:any)=>{
                    SetSearchSelect(value);
                    
                    changePage( getMovePageParam( "searchType", value ) );
                }}
                selectSort={selectSort}
                setSelectSort={(value:any)=>{
                    setSelectSort(value);
                    
                    changePage( getMovePageParam( "sort", value ) );
                }}
                />
			<ul>
                { isMobile != true && 
                    <li className={'title' + titleBg}>
                        <div className='title'>제목 / 내용</div>
                        <div className={'writer'}>글쓴이</div>
                        <div className='insert_time'>작성일</div>
                        <div className={'choice_type'}>채택타입</div>
                        <div className='choice_time'>채택일</div>
                        <div className={'video'}>영상</div>
                        <div className='price'>상품권가격</div>
                        <div className={'price_send'}>상품권지급</div>
                        <div className='edit'>수정/노출</div>
                        <div className={'cancel'}>취소</div>
                    </li>
                }
                { itemList && itemList.length > 0 && itemList.map((item:any, index:number)=>{
                    return( <CategoryItem {...props} item={item} key={index}/>)
                })}
                { (!itemList || itemList.length == 0 ) &&
                    <li className='empty'><span>채택사연 없음</span></li>
                }
            </ul>
            
			<AdminPageComponent {...props}
				totalData={totalData}
				year={year}
				page={page}
				onClickPage={(item:PAGE_NUMBER_DATA, e:any)=>{

					var params : PAGE_ADMIN_STORY_CHOICE = getMovePageParam();

					if( item.type==PAGE_NUMBER_TYPE.NUMBER ) { 
						params.page = item.page;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE ) { 
						params.page = getStartPage(page) - 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT ) { 
						params.page = getStartPage(page) + 10;
					}
					else if( item.type==PAGE_NUMBER_TYPE.BEFORE_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}
					else if( item.type==PAGE_NUMBER_TYPE.NEXT_YEAR ) { 
						params.year = item.page;
						params.page = 1;
					}

					var url = makeUrl(PAGE_URL.ADMIN_STORY, params);
					movePage(navigate, e, url);
				}}
			/>
		</div>
	);
};

export default Choice;

function ListTop( props:any ) {
    const {
        selectType, setSelectType,
        selectSendType, setSelectSendType,
        selectShowType, setSelectShowType,
        searchSelect, SetSearchSelect,
        selectSort, setSelectSort,
        onSearch, searchText
    } = props;

    const typeOption : OptionData[] = [ALL_FilTER_DATA, {value:API_TYPES.STORY_CHOICE_TYPE.NOMAL, name:"일반"}];
    const sendOption : OptionData[] = [ALL_FilTER_DATA, {value:API_TYPES.BOOLEAN.TRUE, name:"지급"}, {value:API_TYPES.BOOLEAN.FALSE, name:"미지급"}];
    const showOption : OptionData[] = [ALL_FilTER_DATA, {value:API_TYPES.BOOLEAN.TRUE, name:"노출"}, {value:API_TYPES.BOOLEAN.FALSE, name:"비노출"}];
    const searchOption : OptionData[] = [{value:API_TYPES.SEARCH_TYPE.ALL, name:"전체"}, {value:API_TYPES.SEARCH_TYPE.TITLE, name:"제목"}, {value:API_TYPES.SEARCH_TYPE.WRITER, name:"글쓴이"}];
    
    const sortOption : OptionData[] = [
        {value:API_TYPES.POST_LIST_SORT_TYPE.CHOICE_DATE, name:"채택일"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.INSERT, name:"작성일"}, 
        {value:API_TYPES.POST_LIST_SORT_TYPE.CHOICE_SEND, name:"지급일"}
    ];


    if( isMobile ) {
        return( <>
            <div className={'list_top'}>
                <AdminSelectBox {...props} title={"타입"} className={"select_type"}       options={typeOption} selectValue={selectType}     SetSelectValue={setSelectType}/>
                <AdminSelectBox {...props} title={"지급"} className={"select_send_type"}  options={sendOption} selectValue={selectSendType} SetSelectValue={setSelectSendType}/>
                <AdminSelectBox {...props} title={"노출"} className={"select_show_type"}  options={showOption} selectValue={selectShowType} SetSelectValue={setSelectShowType}/>
            </div>
            <div className={'list_top'}>
                <AdminSearchBox {...props} placeholder={"검색"} filterOption={searchOption} searchSelect={searchSelect} SetSearchSelect={SetSearchSelect} onSearch={onSearch} defaultSearchText={searchText}/>
                <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
            </div>
        </>)
    }
    return(
        <div className={'list_top'}>
            <AdminSelectBox {...props} title={"타입"} className={"select_type"}       options={typeOption} selectValue={selectType}     SetSelectValue={setSelectType}/>
            <AdminSelectBox {...props} title={"지급"} className={"select_send_type"}  options={sendOption} selectValue={selectSendType} SetSelectValue={setSelectSendType}/>
            <AdminSelectBox {...props} title={"노출"} className={"select_show_type"}  options={showOption} selectValue={selectShowType} SetSelectValue={setSelectShowType}/>
            <AdminSearchBox {...props} placeholder={"검색"} filterOption={searchOption} searchSelect={searchSelect} SetSearchSelect={SetSearchSelect} onSearch={onSearch} defaultSearchText={searchText}/>
            <AdminSelectBox {...props} title={"정렬"} className={"select_sort"}  options={sortOption} selectValue={selectSort} SetSelectValue={setSelectSort}/>
        </div>
    )
}

function CategoryItem( props : any ) {
    const {item, openPostPage, setChoiceUpdateData, setChoiceCancelData} = props;

    var choiceDate = null;
    if( item.choice_date>0 ) { choiceDate = new Date(); choiceDate.setTime(item.choice_date*1000); }
    var choiceTypeStr = item.choice_type == API_TYPES.STORY_CHOICE_TYPE.NOMAL?"일반":".";
    var choiceSendDate = null;
    if( item.choice_send_date>0 ) { choiceSendDate = new Date(); choiceSendDate.setTime(item.choice_send_date*1000); }

    const showReplyNum = item.reply_num > 0;
    
    const listMaxWidth = isMobile?window.outerWidth:500;

    var titleWidth = listMaxWidth - 20;
    var bodyWidth = listMaxWidth - 20;
    if( !!item.thumbnail ) {
        titleWidth = titleWidth - 50;
        bodyWidth = bodyWidth - 50;
    }
    if( showReplyNum ) titleWidth = titleWidth - 40;

    var titleStyle = {maxWidth:titleWidth};
    var bodyStyle = {maxWidth:bodyWidth};

    var editClass = item.choice_show==API_TYPES.BOOLEAN.TRUE?" color_green":"";


    const clickChoiceVideo = () => {
        if( !!item.choice_video ) { window.open(item.choice_video); }
    }

    const clickedOpenPage = () => {
        openPostPage(item.idx, item.year, item.cate_idx);
    }

    if( isMobile ) {
        return(
            <li className="item">
                <div className='title two_line' onClick={clickedOpenPage}>
                    { !!item.thumbnail &&
                        <img src={item.thumbnail}/>
                    }
                    <div>
                        <div className='title'>
                            <div className='top' style={titleStyle}> <span>{"[" + item.cate_name + "]" + item.title}</span> </div>
                            { showReplyNum && <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
                        </div>
                        <div className='body' style={bodyStyle}>{item.body}</div>
                    </div>
                </div>
                <div className='sub' onClick={clickedOpenPage}>
                    <div className={'insert_time'}>{makeInsertTimeStr(item.insert_time, true)}</div>
                    <div className='divide'>·</div>
                    <div className={'writer'}>{item.user_id + "(" + item.nickname + ")"}</div>
                </div>
                <div className='sub sub_bg top_border' onClick={clickedOpenPage}>
                    <div className='choice_type'>{choiceTypeStr}</div>
                    <div className='divide'>·</div>
                    <div className={'choice_time'}>{makeInsertTimeStr(choiceDate, true)}</div>
                    { item.choice_price > 0 && <>
                        <div className='divide'>·</div>
                        <div className='price'>{item.choice_price + "원"}</div>
                    </>}
                    { !!choiceSendDate && <>
                        <div className='divide'>·</div>
                        <div className='price'>{"지급일:" + makeInsertTimeStr(choiceSendDate, true)}</div>
                    </>}
                </div>
                <div className='sub button_area'>
                    <div className='video pointer' onClick={clickChoiceVideo}>{item.choice_video?"영상보기":"."}</div>
                    <div className={'edit pointer' + editClass} onClick={()=>{setChoiceUpdateData(item);}}>{item.choice_show==API_TYPES.BOOLEAN.TRUE?"노출중":"수정"}</div>
                    <div className={'cancel pointer'} onClick={()=>{setChoiceCancelData(item);}}>{"취소"}</div>
                </div>
            </li>
        )
    }

    return(
        <li className="item">
            <div className='title two_line' onClick={clickedOpenPage}>
                { !!item.thumbnail &&
				    <img src={item.thumbnail}/>
                }
                <div>
                    <div className='title'>
                        <div className='top' style={titleStyle}> <span>{"[" + item.cate_name + "]" + item.title}</span> </div>
				        { showReplyNum && <div className='reply'> <span>{"(" + item.reply_num + ")"}</span> </div> }
                    </div>
                    <div className='body' style={bodyStyle}>{item.body}</div>
                </div>
            
            </div>
            <div className='writer two_line'>{item.user_id + "\n(" + item.nickname + ")"}</div>
            <div className={'insert_time' + (isToday(item.insert_time)?'':' two_line')}>{makeInsertTimFullStr(item.insert_time)}</div>
            <div className='choice_type'>{choiceTypeStr}</div>
            <div className={'choice_time' + (choiceDate?isToday(choiceDate)?"":" two_line":"")}>{choiceDate?makeInsertTimFullStr(choiceDate):"."}</div>
            <div className='video pointer' onClick={clickChoiceVideo}>{item.choice_video?"보기":"."}</div>
            <div className='price'>{item.choice_price}</div>
            <div className={'price_send' + (choiceSendDate?isToday(choiceSendDate)?"":" two_line":"")}>{choiceSendDate?makeInsertTimFullStr(choiceSendDate):"."}</div>
            <div className={'edit pointer' + editClass} onClick={()=>{setChoiceUpdateData(item);}}>{item.choice_show==API_TYPES.BOOLEAN.TRUE?"노출중":"수정"}</div>
            <div className={'cancel pointer'} onClick={()=>{setChoiceCancelData(item);}}>{"취소"}</div>
        </li>
    )
}
