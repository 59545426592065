import React from 'react';

import rootReducer, {rootSaga} from '.';
import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, legacy_createStore as createStore, compose } from 'redux';

const loggerMiddleware = ({ dispatch, getState } : any ) =>
  (next : any) =>
  (action : any) => {
    console.log('loggerMiddleware(redux-thunk): ', action);
    return next(action);
  };

export default function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    
    const enhancer : any = process.env.NODE_ENV === 'production'
    ? compose(applyMiddleware(sagaMiddleware))
    : composeWithDevTools(applyMiddleware(sagaMiddleware, loggerMiddleware));

    const store = createStore( rootReducer, enhancer );
    sagaMiddleware.run(rootSaga);

    return store;
}