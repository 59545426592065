import React, { useEffect, useState } from 'react';
import PopupWrapper, { BlackButton, RedButton } from './PopupWrapper';
import { makeInsertTimeFullFromTimeStamp } from '../../util/Global';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { PAGE_URL } from '../../util/Page';

export enum DeletePopupType { POST=1, REPLY=2 }
export enum DeleteReasonType { 
    TABOO=1,    // 불건전한 내용
    DISS=2,     // 비방글
    POLITICS=3, // 정치글
    PLASTER=4,  // 도배
    REPORT=5,   // 신고누적
    ETC=6       // 기타
}

/**
 * 사연채택 팝업
 * @param props 
 * @returns 
 */
const PopupStoryCategory = ( props:any ) => {
    const { categoryData, onSuccess }=props;
	const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nameStr, setNameStr] = useState("");
    const [requesting, setRequesting] = useState(false);

    useEffect(()=>{
        if( categoryData ) {
            setNameStr(categoryData.name);
        }
        else {
            setNameStr("");
        }
        return(()=>{})
    },[categoryData])

    var popupTitle = "카테고리";
    if( categoryData )  { popupTitle += " 수정"; }
    else                { popupTitle += " 추가"; }

    const clickedSave = () => {
        if( nameStr == "" ) { alert("카테고리명을 입력하세요"); return; }

        setRequesting(true);
        
		const callback = (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);

                onSuccess();
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 카테고리 입니다");
				}
			}
            setRequesting(false);
		}

        if( categoryData )  apiAxios.admin.dataStoryCategoryUpdate(dispatch, categoryData.idx, nameStr, callback);
        else                apiAxios.admin.dataStoryCategoryAdd(dispatch, nameStr, callback);
    }

    return (
        <PopupWrapper {...props}
            title={popupTitle}
            width={300}
            buttonChildren={<>
                <BlackButton {...props} title={"저장"} onClick={clickedSave} loading={requesting}/>
            </>}>
            
            <div className='body_area popup_category'>
                <input value={nameStr} placeholder='카테고리명 입력' onChange={(e)=> { setNameStr(e.target.value); }}/>
            </div>
        </PopupWrapper>
    );
};
  
export default PopupStoryCategory;