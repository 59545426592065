/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MobileInquiryList from './sub/MobileInquiryList';
import MobileWrapper, { MOBILE_TAB_TYPE } from '../../sub/MobileWrapper';
import Adsense, { AD_PAGE_POSITION, AdType } from '../../../sub/Adsense';

const MobileInquiry = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	
	useEffect(()=>{
		console.log("Story useEffect");
		
		return(()=>{})
	},[location]);

	return (
		<MobileWrapper
			tabType={MOBILE_TAB_TYPE.NONE}
			hideNotice={true}
			subTitle={"나의 문의 내역"}>
			<MiddleComponent 
				{...props} />
		</MobileWrapper>
	);
};

export default MobileInquiry;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<Adsense 
				{...props}
				type={AdType.MOBILE_TOP}
				marginTop={10}
				marginBottom={10}
				pagePosition={AD_PAGE_POSITION.MOBILE_INQUIRY_TOP}/>
			<MobileInquiryList {...props}/>
		</div>
	)
}