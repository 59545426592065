/****************************************************************************************
 * 
 *  페이지 랩퍼
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import RightArea, { RIGHT_CATEGORY_DATA } from '../../sub/RightArea';
import LeftAd from '../../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import MobileBottomCompanyInfo from './MobileBottomCompanyInfo';
import reduxActions from '../../../redux_saga/modules/moduleActions';
import { RootState } from '../../../redux_saga/modules';
import { checkLogin } from '../../../util/Global';
import MobileHeader from './MobileHeader';
import { isMobile } from 'react-device-detect';
import { PAGE_URL } from '../../../util/Page';
import MobileNoticeBox from './MobileNoticeBox';


export enum MOBILE_TAB_TYPE {
    ADMIN = -2,
    NONE = -1,
    HOME = 1,
    STORY = 2,
    COMMUNITY = 3
}

const MobileWrapper = (props:any) => {
    const {
        popup,
        children,
        tabType,
        hideBottomCompanyInfo,
        hideNotice,
        categoryData,
        rightHideNotice,
        isCheckLogin,
        hideRight,
        hideLeft,
        hideTab,
        hideSearch
    } = props;
	const navigate = useNavigate();

    const loginData = useSelector((state:RootState)=>state.data.loginData);

    useEffect(()=>{
        if( isCheckLogin == true ) {
            checkLogin(navigate, loginData);
        }
        return(()=>{})
    },[loginData]);

	/*useEffect(()=>{
        if( isMobile != true ) { navigate(PAGE_URL.MAIN, {replace:true}); }   
		return ()=>{ }
	},[isMobile])*/
    
    const middleHeight = window.innerHeight;

	return ( <>
		<div className='mobile_body_div' style={{minHeight:middleHeight}}>
            <MobileHeader {...props} />
            <div className='mobile_middle'>
                {children}
            </div>
            { hideNotice != true && <MobileNoticeBox {...props} marginTop={10}/> }
			{ hideBottomCompanyInfo != true && <MobileBottomCompanyInfo {...props}/> }
		</div>	
        {popup}
        </>
	);
};

export default MobileWrapper;