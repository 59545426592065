/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TopTab, { TAB_TYPE } from '../sub/TopTab';
import RightArea, { RIGHT_CATEGORY_DATA } from '../sub/RightArea';
import LeftAd from '../sub/LeftAd';
import apiAxios, { API_TYPES, API_UTILS } from '../../apiAxios';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import BottomCompanyInfo from '../sub/BottomCompanyInfo';
import NoticePostListComponent from './sub/NoticePostListComponent';
import reduxActions from '../../redux_saga/modules/moduleActions';
import { RootState } from '../../redux_saga/modules';
import Wrapper from '../sub/Wrapper';
import TopAdComponent from '../sub/TopAdComponent';
import { AD_PAGE_POSITION } from '../sub/Adsense';

const Notice = (props:any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	
	useEffect(()=>{
		console.log("Story useEffect");
		
		return(()=>{})
	},[location]);

	return (
		<Wrapper
			tabType={TAB_TYPE.NONE}
			rightHideNotice={true}>
			<MiddleComponent {...props}/>
		</Wrapper>
	);
};

export default Notice;

function MiddleComponent( props:any ) {

	return(
		<div className='middle_area'>
			<TopAdComponent {...props} pagePosition={AD_PAGE_POSITION.NOTICE_TOP} marginBottom={35}/>
			<NoticePostListComponent {...props}/>
		</div>
	)
}