/****************************************************************************************
 * 
 *  사연 게시글 리스트
 * 
 ****************************************************************************************/

import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation, NavigateOptions } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import { BsCheckSquare } from "react-icons/bs";
import { BsCheckSquareFill } from "react-icons/bs";
import apiAxios, { API_TYPES, API_UTILS, ParamAuthSignup } from '../../../../../apiAxios';
import { Oval } from 'react-loader-spinner';
import { BsPersonVcard } from "react-icons/bs";
import { FiLock } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { BsEnvelope } from "react-icons/bs";
import { BsPerson } from "react-icons/bs";
import { CertificationData, chkID, chkNickName, chkPW, openCertificationPopup } from '../../../../../util/Global';
import { PAGE_URL } from '../../../../../util/Page';
import { RootState } from '../../../../../redux_saga/modules';
import { BiCheckShield } from "react-icons/bi";
import ButtonDefault from '../../../../sub/ButtonDefault';
import { MAX_NICKNAME_LENGTH, TEXT_OVER_NICKNAME_LENGTH } from '../../../../../util/ConstValue';
import MobileCertification from '../../certification';
import { APP_INTERFACE_SEND_TYPE, sendDataToApp } from '../../../../../util/AppInterface';

enum InputBoxPosition {
    TOP=1, MIDDLE=2, BOTTOM=3
}

interface InputData {
    id:string
    pw:string
    rePw:string
    email:string
    nickname:string
    overlabId:boolean
    overlabNickname:boolean
}

const getEmptyInputData = () => {
    return {
        id:"",
        pw:"",
        rePw:"",
        email:"",
        nickname:"",
        overlabId:false,
        overlabNickname:false
    };
}

const MobileInputSignup = (props:any) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();    

    const [certificationData, setCertificationData] = useState<CertificationData|null>(null);
    const [inputData, setInputData] = useState<InputData>(getEmptyInputData());
    const [requestingSignup, setRequestingSignup] = useState(false);
	
    const receiveCertificationMessage = ( receiveData : string ) => {
        if( receiveData ) {
            var resData : any = null;
            try{resData = JSON.parse(receiveData);}
            catch(e){}
            
            // 수신 성공
            if( resData && resData.state == 0 ) {
                if( resData.data && resData.data.code == 0 ) {
                    const responseData = resData.data.response;
                    console.log("responseData", responseData);
                    // responseData.birth       // 생일 ( 초단위 )
                    // responseData.unique_key  // 인증 고유번호
                    // responseData.phone       // 전화번호
                    // responseData.name        // 이름
                    // responseData.gender      // 성별 male, female
                    // responseData.foreigner   // 외국인유무 true, false

                    var data : CertificationData = {
                        birth:responseData.birth,
                        unique_key:responseData.unique_key,
                        phone:responseData.phone,
                        name:responseData.name,
                        gender:responseData.gender=="male"?API_TYPES.GENDER_TYPE.MALE:API_TYPES.GENDER_TYPE.FEMALE,
                        foreigner:responseData.foreigner==false?API_TYPES.BOOLEAN.FALSE:API_TYPES.BOOLEAN.TRUE
                    };
                    setCertificationData(data);
                }
            }
            else if( resData && resData.state == -2 ) {
                alert("사용자 정보를 가지고 오지 못합니다.");
            }
          }
    }

	return (
        <>
        <div className='mobile_sign_up_input'>
            <div className='box_title'><span>정보입력</span></div>
            <UserInfomation {...props} 
                setInputData={setInputData}
                requestingSignup={requestingSignup}
                />
            <CertificationBox {...props}
                certificationData={certificationData}
                setCertificationData={setCertificationData}
                />
            <BottomButtonComponent {...props}
                requestingSignup={requestingSignup}
                setRequestingSignup={setRequestingSignup}
                inputData={inputData}
                certificationData={certificationData}/>
		</div>
        </>
	);
};

export default MobileInputSignup;

function UserInfomation( props:any ) {
    const {setInputData, requestingSignup} = props;

	const dispatch = useDispatch();
    
    const [idStr, setIdStr] = useState("");
    const [isCheckIdOverlap, setCheckIdOverlap] = useState(false);
    const [requestingIdOverlap, setRequestingIdOverlap] = useState(false);
    
    const [nicknameStr, setNicknameStr] = useState("");
    const [isCheckNicknameOverlap, setCheckNicknameOverlap] = useState(false);
    const [requestingNicknameOverlap, setRequestingNicknameOverlap] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [passwordStr, setPasswordStr] = useState("");
    const [showRePassword, setShowRePassword] = useState(false);
    const [rePasswordStr, setRePasswordStr] = useState("");
    
    const [emailStr, setEmailStr] = useState("");

    
	useEffect(()=>{

        const newData : InputData = {
            id:idStr, pw:passwordStr, rePw:rePasswordStr, email:emailStr, nickname:nicknameStr, overlabId:isCheckIdOverlap, overlabNickname:isCheckNicknameOverlap
        }
        setInputData(newData);

		return(()=>{})
	},[idStr, isCheckIdOverlap, nicknameStr, isCheckNicknameOverlap, passwordStr, rePasswordStr, emailStr]);


    const reqCheckIdOverlap = () => {
        if( requestingIdOverlap ) return;
        if( !idStr ) { alert("아이디를 입력하세요"); return; }

        // 아이디 유효성 검사
        var errMsg = chkID( idStr );
        if( !!errMsg ) {alert(errMsg); return;}

        setRequestingIdOverlap( true );
        apiAxios.global.dataCheckIdOverlap(dispatch, idStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("사용가능한 아이디 입니다");

                setCheckIdOverlap( true );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 아이디 입니다");
                    setIdStr("");
				}
			}
            setRequestingIdOverlap( false );
		})
    }

    const reqCheckNicknameOverlap = () => {
        if( requestingNicknameOverlap ) return;
        if( !nicknameStr ) { alert("닉네임을 입력하세요"); return; }

        // 닉네임 유효성 검사
        var errMsg = chkNickName( nicknameStr );
        if( !!errMsg ) {alert(errMsg); return;}

        setRequestingNicknameOverlap( true );
        apiAxios.global.dataCheckNickNameOverlap(dispatch, nicknameStr, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("사용가능한 닉네임 입니다");

                setCheckNicknameOverlap( true );
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA ) {
                    alert("이미 존재하는 닉네임 입니다");
                    setNicknameStr("");
				}
			}
            setRequestingNicknameOverlap( false );
		})
    }
    
    return(<>
        <InputBox 
            placeholder={"아이디"}
            disabled={requestingSignup}
            leftIcon={<BsPerson size={20}/>}
            rightText={ isCheckIdOverlap?null:"중복확인" }
            value={idStr}
            rightLoading={requestingIdOverlap}
            position={InputBoxPosition.TOP}
            setValue={(text:string)=>{
                setIdStr(text);
                if( isCheckIdOverlap ) setCheckIdOverlap(false);
            }}
            onClickRightText={()=>{
                reqCheckIdOverlap();
            }}
            />
        <InputBox 
            placeholder={"비밀번호"}
            disabled={requestingSignup}
            leftIcon={<FiLock size={20}/>}
            rightIcon={ <PasswordRightIcon show={showPassword} setShow={setShowPassword}/> }
            value={passwordStr}
            setValue={setPasswordStr}
            position={InputBoxPosition.MIDDLE}
            isPassword={showPassword==false}
            />

        <InputBox 
            position={InputBoxPosition.MIDDLE}
            disabled={requestingSignup}
            placeholder={"비밀번호 다시입력"}
            leftIcon={<FiLock size={20}/>}
            rightIcon={ <PasswordRightIcon show={showRePassword} setShow={setShowRePassword}/> }
            value={rePasswordStr}
            setValue={setRePasswordStr}
            isPassword={showRePassword==false}
            />
            
        <InputBox 
            position={InputBoxPosition.MIDDLE}
            disabled={requestingSignup}
            placeholder={"(비밀번호 분실 시 & 상품전송 확인용 이메일)"}
            leftIcon={<BsEnvelope size={20}/>}
            value={emailStr}
            setValue={setEmailStr}
            />
        <InputBox 
            placeholder={"닉네임"}
            disabled={requestingSignup}
            leftIcon={<BsPersonVcard size={20}/>}
            rightText={ isCheckNicknameOverlap?null:"중복확인" }
            value={nicknameStr}
            rightLoading={requestingNicknameOverlap}
            position={InputBoxPosition.MIDDLE}
            setValue={(text:string)=>{
                
                if( text.length > MAX_NICKNAME_LENGTH ) {
                    alert(TEXT_OVER_NICKNAME_LENGTH);
                }
                else {
                    setNicknameStr(text);
                    if( isCheckNicknameOverlap ) setCheckNicknameOverlap(false);
                }
            }}
            onClickRightText={()=>{
                reqCheckNicknameOverlap();
            }}
            />
    </>)
}

function CertificationBox( props:any ) {
    const {certificationData, setCertificationData} = props;

	const [popup, setPopup] = useState<any>(null);
    const [showCertification, setShowCertification] = useState(false);

    useEffect(() => {
		if( popup ) {
			const timer = setInterval(()=>{
				if( popup.closed ) {
					setPopup(null);
				}
			},500);
			return ()=>{
				clearInterval(timer);
			}
		}
		else {
			return () => { };
		}
    }, [popup]);

    
    const receiveMessage = (receiveData : any) => {
        // event.data에 전송된 데이터가 있음
        console.log('받은 데이터:', receiveData);

        if( receiveData ) {
          var resData : any = null;
          try{resData = JSON.parse(receiveData);}
          catch(e){}
          
          // 수신 성공
          if( resData && resData.state == 0 ) {
              if( resData.data && resData.data.code == 0 ) {
                  const responseData = resData.data.response;
                  console.log("responseData", responseData);
                  // responseData.birth       // 생일 ( 초단위 )
                  // responseData.unique_key  // 인증 고유번호
                  // responseData.phone       // 전화번호
                  // responseData.name        // 이름
                  // responseData.gender      // 성별 male, female
                  // responseData.foreigner   // 외국인유무 true, false

                  var data : CertificationData = {
                      birth:responseData.birth,
                      unique_key:responseData.unique_key,
                      phone:responseData.phone,
                      name:responseData.name,
                      gender:responseData.gender=="male"?API_TYPES.GENDER_TYPE.MALE:API_TYPES.GENDER_TYPE.FEMALE,
                      foreigner:responseData.foreigner==false?API_TYPES.BOOLEAN.FALSE:API_TYPES.BOOLEAN.TRUE
                  };
                  setCertificationData(data);
              }
          }
          else if( resData && resData.state == -2 ) {
              alert("사용자 정보를 가지고 오지 못합니다.");
          }
        }
    };

    
    return(<>
        <div className='certification_box'>
            <div className='left_icon'><BiCheckShield size={20}/></div>
            <div className='left'><span>본인인증</span></div>
            
            { certificationData &&
                <div className='success'>
                    <div><BiCheckShield size={20}/></div>
                    <span>인증완료</span>
                </div>
            }

            { !certificationData &&

                <ButtonDefault
                
                    value={"인증"}
                    className='cer_button'
                    isLoading={!!showCertification}
                    onClick={()=>{ 
                        setShowCertification( true );
                        /*var p = openCertificationPopup(window); 
                        setPopup(p);*/
                    }}
                    />
            }
        </div>
        
        
        { showCertification && 
            <MobileCertification {...props}
                onResult={receiveMessage}
                show={showCertification}
                setShow={setShowCertification}
            />
        }
    </>)
}

function BottomButtonComponent( props:any ) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {requestingSignup, setRequestingSignup} = props;

    const inputData : InputData = props.inputData;
    const certificationData : CertificationData = props.certificationData;

    const clickedCancel = () => {
        navigate(PAGE_URL.MAIN, {replace:true});
    }

    const clickedSignup = () => {
        var errMsg = checkInputData();
        if( !!errMsg ) { alert(errMsg); return; }

        console.log("inputData", inputData);

        const params : ParamAuthSignup = {
            id:inputData.id,
            pw:inputData.pw,
            email:inputData.email,
            nickname:inputData.nickname,
            name:certificationData.name,
            number:certificationData.phone,
            birth:certificationData.birth,
            gender:certificationData.gender,
            foreigner:certificationData.foreigner,
            unique_key:certificationData.unique_key
        }

        setRequestingSignup(true);
        
        apiAxios.user.authSignup(dispatch, params, (response:AxiosResponse|null, error:AxiosError|null) => {
			if( response && response.status == API_TYPES.RESPONSE_CODE.SUCCESS ) {
				const resData = API_UTILS.getResponseSuccessData(response);
                alert("회원가입완료");
                navigate(PAGE_URL.MAIN, {replace:true});
			}
			else if( error ) {
				const errorData : any = API_UTILS.getErrorResponseData(error);
				if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA && errorData.data == 1 ) {
                    alert("이미 존재하는 아이디 입니다");
				}
                else if( errorData && errorData.result_code == API_TYPES.RESULT_CODE.HAVE_DATA && errorData.data == 2 ) {
                    var title = "이미 가입한 인증정보 입니다\n아이디 찾기를 하시겠습니까?";
                    var returnValue = window.confirm(title);
                    if( returnValue ) {
                        navigate(PAGE_URL.FIND_ID, {replace:true});
                    }
				}
			}
            setRequestingSignup(false);
		})
    }

    const checkInputData = () => {
        if( !inputData.id )                 { return "아이디를 입력하세요"; }
        if( !inputData.pw )                 { return "비밀번호를 입력하세요"; }
        if( !inputData.rePw )               { return "비밀번호를 다시 입력하세요"; }
        if( !inputData.email )              { return "이메일을 입력하세요"; }
        if( !inputData.nickname )           { return "닉네임을 입력하세요"; }
        if( !inputData.overlabId )          { return "아이디 중복확인을 해 주세요"; }
        if( !inputData.overlabNickname )    { return "닉네임 중복확인을 해 주세요"; }
        if( inputData.pw != inputData.rePw ){ return "비밀번호가 일치하지 않습니다"; }
        if( !certificationData )            { return "본인인증을 해 주세요"; }

        return chkPW(inputData.pw);
    }

    return(
        <div className='button_area'>
            <ButtonDefault
                className='sign_up'
                onClick={clickedSignup}
                isLoading={requestingSignup}
                value={"가입"}/>
            <ButtonDefault
                className='cancel'
                onClick={clickedCancel}
                value={"취소"}/>
        </div>
    )
}


function InputBox( props:any ) {
    const {leftIcon, placeholder, disabled, value, setValue, rightIcon, rightText, position, isPassword, onClickRightText, onKeyUp, rightLoading} = props;

    const applicationOS : string|null = useSelector((state:RootState)=>state.data.applicationOS);
    const [isFocus, setFocus] = useState(false);

    var posClassName = "";
    if( position == InputBoxPosition.TOP )          posClassName = " input_top";
    else if( position == InputBoxPosition.MIDDLE )  posClassName = " input_middle";
    else if( position == InputBoxPosition.BOTTOM )  posClassName = " input_bottom";

    var inputWidth = window.outerWidth - 50;
    if( !!leftIcon ) inputWidth = inputWidth - 30;
    if( !!rightIcon ) inputWidth = inputWidth - 30;
    if( !!rightText ) inputWidth = inputWidth - 70;

    var inputHeight = 56;
    if( applicationOS == "ios" ) {
        inputHeight = 50;
        inputWidth = inputWidth - 20;
    }

    return(
        <div className={"input_box" + posClassName + (disabled?" disabled":"")}>
            { leftIcon!= null && 
                <div className="left_icon"> {leftIcon} </div> 
            }

            <input 
                type={isPassword?"password":"text"} 
                placeholder={placeholder} 
                value={value} 
                onChange={(e)=> { 
                    if(setValue) {
                        var text = e.target.value;
                        text = text.replace(/ /g, ''); // 공백제거
                        setValue(text); 
                    }
                }} 
                style={{width:inputWidth, height:inputHeight}}
                onFocus={()=>{setFocus(true)}}
                onBlur={()=>{setFocus(false)}}
                disabled={disabled}
                onKeyUp={onKeyUp}/>

            { rightIcon!= null && 
                <div className="right_icon"> {rightIcon} </div> 
            }
            { !!rightText && 
                <div className="right_text" onClick={onClickRightText}> 
                    { rightLoading == true && <div className="loader">
                        <Oval 
                            color="#00f" 
                            height={20} 
                            width={20}
                            />
                        </div>}
                    { rightLoading != true && <span>{rightText}</span> }
                </div> 
            }
        </div>
    )
}


const SHOW_TEXT = "숨김해제";
const HIDE_TEXT = "입력숨김";

/**
 * 비밀번호 입력 우측 아이콘
 * @param props 
 * @returns 
 */
function PasswordRightIcon( props:any ) {
    const {show, setShow} = props;

    return( <>
        { show && <FiEye title={HIDE_TEXT} size={24} onClick={()=>{setShow(false)}}/> }
        { !show && <FiEyeOff title={SHOW_TEXT} size={24} onClick={()=>{setShow(true)}}/> }
    </>)
}